import { Row, Col } from "react-bootstrap";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const PopupValidateFile = ({
    maxFiles = 10,
    maxSizeInMB = 200,
    onCancel = undefined,
}) => {
    const { t } = useTranslation();
    const icon = (
        <img
            src={process.env.PUBLIC_URL + `/icon_inquiry.png`}
            srcSet={`${process.env.PUBLIC_URL}/icon_inquiry@2x.png 2x, ${process.env.PUBLIC_URL}/icon_inquiry@3x.png 3x`}
            alt=""
        />
    );
    const header = <div>{t("142")}</div>;
    const body = (
        <Row className="gx-0">
            <Col />
            <Col className="col-lg-auto text-start col-auto">
                <div>{t("553")}</div>
                {maxFiles > 0 && <div>{t("554", {'%d': maxFiles})}</div>}
                {maxSizeInMB > 0 && <div>{t("555", {'%d':maxSizeInMB})}</div>}
            </Col>
            <Col />
        </Row>
    );

    return (
        <Modal
            onRequestClose={() => { onCancel && onCancel(); }}
            onConfirm={() => { onCancel && onCancel(); }}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const PopupValidateImage = ({
    onCancel = undefined,
}) => {
    const { t } = useTranslation();
    const icon = (
        <img
            src={process.env.PUBLIC_URL + `/icon_warning@3x.png`}
            alt=""
        />
    );
    const header = <div className="mt-4">{t("556")}</div>;
    const body = (
        <Row className="gx-0 mt-3">
            <Col />
            <Col className="col-lg-auto text-start col-auto">
                <div>{t("557")}</div>
            </Col>
            <Col />
        </Row>
    );

    return (
        <Modal
            onRequestClose={() => { onCancel && onCancel(); }}
            onConfirm={() => { onCancel && onCancel(); }}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const PopupValidateResolution = ({
    fileName,
    onCancel = undefined,
}) => {
    const { t } = useTranslation();
    const icon = (
        <img
            src={process.env.PUBLIC_URL + `/icon_warning@3x.png`}
            alt=""
        />
    );
    const header = <div className="mt-4">{t("873")}</div>;
    const body = (
        <Row className="gx-0 mt-3">
            <Col />
            <Col className="col-lg-auto text-start col-auto">
                <div>{t("874", {'%s': fileName}).split("\n").map((v, i) => <div key={i}>{v}</div>)}</div>
            </Col>
            <Col />
        </Row>
    );

    return (
        <Modal
            onRequestClose={() => { onCancel && onCancel(); }}
            onConfirm={() => { onCancel && onCancel(); }}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export {
    PopupValidateFile,
    PopupValidateImage,
    PopupValidateResolution
};
