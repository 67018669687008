import React from "react";
import Api from "../../Api";
import { ReportCard } from "../Report/ReportCard";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IsTabletOrPC } from "../common/MediaQuery";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";
import common from "../../common/common";

export default function Marker(props) {
    const { t } = useTranslation();
    props.$onMouseAllow(false);
    const isTabletOrPC = IsTabletOrPC();

    /**
     * marker click event, 클릭 된 마커의 정보를 받아오는 함수
     */
    const markerClick = async () => {
        if (props.preventClick) return;
        let lat = props.lat;
        let lng = props.lng;

        if ( isTabletOrPC ) {
            props.map.panTo({ lat: lat, lng: lng });
            props.map.panBy(80, 150);
        }
        else {
            props.map.panTo({ lat: lat, lng: lng });
            props.map.panBy(0, 150);
        }

        try {
            // 줌 레벨이 14까지는 클러스터로 만들어지기 때문에 겹치는 GPS가 marker일 수 없음
            // 줌 레벨 14 이전에 marker를 클릭했을 때는 겹치지 않기 때문에 곧바로 1개의 marker thumbnail을 생성
            if (props.map.zoom <= props.CLUSTER_MAX_ZOOM) throw new Error(`Zoom level is lower than ${props.CLUSTER_MAX_ZOOM}`);
            const reports = await Api.getSameLocationReportsItem({lat: lat, lng: lng});
            props.selectMarker({ id: props.id, data: reports });
        }
        catch (e) {
            Api.getReportsItem(props.id)
            .then((payload) => {
                props.selectMarker({ id: props.id, data: payload });
            })
            .catch((err) => dgLogger.error(err)());
        }
    };

    // convert iso date to 'YYYY-MM-DD' date
    const convertToLocaleDate = (date) => {
        // notes, redkur; 'fr-CA' formats string as 'YYYY-MM-DD'
        const localDate = new Date(date).toLocaleDateString('fr-CA');
        return localDate;
    };

    // thumbnail에 사용 될 file url 반환
    const getFileUrl = () => {
        if (props.data) {
            const result = props.data.report_form_item_result.find((item) => item.type === "file" && item.representative_picture);
            return result?.representative_picture?.thumbnail_url;
        }
        return undefined;
    };

    // Y scroll이 생겼는 지 판단
    const isOverflowY = (clientHeight, scrollHeight) => {
        return clientHeight < scrollHeight;
    };

    /**
     *
     * @returns 생성 된 thumbnail html tag
     */
    const createThumbnail = () => {
        if (props.data.length > 1 ) {
            return (
                <div className="reports-card-background">
                    <span className="arrow"></span>
                    <Row>
                        <Col className="nanumsquareb-16">
                            {t("73")} {props.lat}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="nanumsquareb-16">
                            {t("74")} {props.lng}
                        </Col>
                    </Row>
                    <hr />
                    <Row className="gx-0">
                        <Col className="text-center nanumsquareb-16">
                            {t("656")}
                        </Col>
                        <Col className="text-center nanumsquareb-16">
                            {t("27")}
                        </Col>
                        <Col className="text-center nanumsquareb-16">
                            {t("26")}
                        </Col>
                    </Row>
                    <Row className="gx-0 reports-card-content-wrap"
                        onWheel={(e) => {
                            if (isOverflowY(e.currentTarget.clientHeight, e.currentTarget.scrollHeight))
                                e.stopPropagation();
                        }}
                    >
                        {props.data.map((data) => {
                            return (
                                <Row className="gx-0">
                                    <Col className="text-center nanumsquarer-13 c-4270e0">
                                        <Link
                                            to={`/Report/${data._id}`}
                                        >
                                            {data.title}
                                        </Link>
                                    </Col>
                                    <Col className="text-center nanumsquarer-13">
                                        {convertToLocaleDate(data.date)}
                                    </Col>
                                    <Col className="text-center nanumsquarer-13">
                                        {data.registered_by}
                                    </Col>
                                </Row>
                            );
                        })}
                    </Row>
                </div>
            );
        }

        else {
            return (
                <div className="report-card-background">
                    <span className="arrow"></span>
                    <ReportCard
                        report_id={props.id}
                        registered_by={props.data.name}
                        date={props.data.date}
                        register_date={props.data.register_date}
                        group_name={common.i18nGroupName(props.data.group)}
                        report_file_url={getFileUrl()}
                        title={props.data.title}
                    />
                </div>
            );
        }
    };

    const marker = () => {
        return (
            <div className="marker" onClick={markerClick}>
                <img
                    key={props.key}
                    src={process.env.PUBLIC_URL + `/map_mark.png`}
                    alt="marker"
                />
            </div>
        );
    };

    // 현재 marker가 선택된 marker라면 thumbnail을 출력
    // 현재 marker가 선택되지 않은 marker라면 marker image만 출력
    if (props.thumbnailMarkerUse) {
        return (
            <div style={{ position: "relative" }} tabIndex={1}>
                {createThumbnail()}
                {marker()}
            </div>
        );
    }
    else {
        return (
            marker()
        );
    }
}
