import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import { dummy } from "@ocean-knight/shared";
import { POPUP as GeneralPopup } from "../../../common/defines";
import PostcodeWindow from "../../PostcodeWindow";
import PostcodeForm from "../../common/PostcodeForm";
import PhoneForm from "./../../common/PhoneForm";
import { Button, Row, Col } from "react-bootstrap";
import { WithdrawGroupMember, WithdrawGroupMemberRequest, WithdrawGroupMemberRequested } from "./WithdrawGroupMemberWindow";
import { UserInfoUpdateWindow } from "../../common/UserInfoUpdateWindow";
import ReactModal from "react-modal";
import ProfileEmblem from "../../common/ProfileEmblem";
import "./GroupMemberInfoWindow.css";
import { utility } from "@ocean-knight/shared";
import { InputText } from "../../Input";
import dgLogger from "../../../common/dgLogger";
import { useTranslation } from "react-i18next";
import common from "../../../common/common";

const ID = {
    profile: "group-member-manage-member-profile",
    mail: "group-member-manage-member-email",
    realname: "group-member-manage-member-realname",
    phone: "group-member-manage-member-phone",
    address1: "group-member-manage-member-address1",
    address2: "group-member-manage-member-address2",
    about: "group-member-manage-member-about",
    sns: "group-member-manage-member-sns",
    name: "group-member-manage-member-name",
    organization: "group-member-manage-member-organization",
    permission: "group-member-manage-grade-target",
};

const POPUP = {
    ...GeneralPopup,
    WithdrawGroupMember: 1037,
    WithdrawGroupMemberRequest: 1038,
    WithdrawGroupMemberRequested: 1039,
};

/**
 * 그룹원 정보를 출력/갱신 합니다.
 *
 * @param {*} props properties
 *  이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  {
 *      user: user 정보,
 *      group: 현재 화면에 표시되는 (유저가 속한) group 정보,
 *      onSave: '저장' 버튼의 callback,
 *      onCancel: '취소' 버튼의 callback,
 *      onWithdrawn: '탈퇴' 버튼의 (post) callback,
 *  }
 *  ```
 * @returns 화면에 출력할 html
 */
export default function GroupMemberInfoWindow(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        avatarUrl: props.user.profile_picture ? props.user.profile_picture.url : null,
        isGroupManager: false,
    });

    const user = props.user;
    const group = props.group;

    useEffect(() => {
        const currentPermission = user.optPermissions.find((item) => item.group === group._id);
        if (!currentPermission) return;
        if (utility.hasGroupManagerPermission(currentPermission.grade))
            setState((prev) => ({
                ...prev, isGroupManager: true
            }));
    }, [group._id, user.optPermissions]);

    /**
     * 그룹 등급을 선택할 수 있는 <option/> 를 생성하여 반환합니다.
     *
     * @param {*} group 그룹 등급 정보를 출력할 group
     * @returns <select/> 의 child 인 <option/> html 객체 list
     */
    const selectOptionsForGrade = (group) => {
        if (!group) return <React.Fragment />;
        const permissions = group.permissions;
        return permissions.map((permission) => (
            <option key={permission._id} value={permission._id}>
                {permission.grade_name}
            </option>
        ));
    };

    /**
     * parameter로 전달된 그룹원 객체가 가지고 있는 권한중, 현재 filter 로 선택된 group 에서의 등급에 해당하는 _id 값을 반환합니다.
     *
     * @param {*} user 그룹원 객체
     * @param {*} group 현재 화면에 표시되는 (유저가 속한) group 정보
     * @returns 현재 그룹원이 filter 로 선택된 group 에서의 그룹 등급 (_id)
     */
    const getGroupMemberGrade = (user, group) => {
        const currentGroup = user.groups.find((item) => item.group === group._id);
        if (!currentGroup) return undefined;
        return currentGroup.permission;
    };

    const popupPostcode = () => {
        return (
            <div id={ID.postcode_popup}>
                <PostcodeWindow
                    onComplete={(address1, address2) => {
                        document.getElementById(ID.address1).value = address1;
                        document.getElementById(ID.address2).value = address2;
                        if (address1) document.getElementById(ID.address2).removeAttribute("disabled");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={() =>
                        setState({ ...state, popup: POPUP.None, popupTarget: null })
                    }
                />
            </div>
        );
    };

    const popupUserInfoUpdate = () => {
        return (
            <UserInfoUpdateWindow
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    props.onSave({
                        _id: user._id,
                        phone: state.popupTarget.phone,
                        address1: state.popupTarget.address1,
                        address2: state.popupTarget.address2,
                        about: state.popupTarget.about,
                        sns: state.popupTarget.sns,
                        profile_picture: state.popupTarget.profile_picture,
                        permission: [{ group: state.popupTarget.group, permission: state.popupTarget.permission }],
                    });

                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Postcode) return popupPostcode();
        if (state.popup === POPUP.UserInfoUpdate) return popupUserInfoUpdate();
        if (state.popup === POPUP.WithdrawGroupMember) {
            return (
                <WithdrawGroupMember
                    users={[state.popupTarget.user]}
                    onConfirmed={(withdrawReason) => {
                        setState({ ...state, popup: POPUP.WithdrawGroupMemberRequest, popupTarget: { ...state.popupTarget, withdrawReason: withdrawReason } });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawGroupMemberRequest) {
            return (
                <WithdrawGroupMemberRequest
                    users={[state.popupTarget.user]}
                    group={state.popupTarget.group}
                    withdrawReason={state.popupTarget.withdrawReason}
                    onConfirmed={(users, group, withdrawReason) => {
                        Api.withdrawGroupMembers({
                            users: users.map((user) => user._id), // _id
                            group: group._id,
                            reason: withdrawReason, // string (withdraw reason)
                            lang: common.getLang()
                        })
                            .then(() => {
                                const user = props.user;
                                user.groups = user.groups.filter((item) => item.group !== group._id);
                                setState({ ...state, popup: POPUP.WithdrawGroupMemberRequested });
                            })
                            .catch((e) => dgLogger.error(e)());
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawGroupMemberRequested) {
            return (
                <WithdrawGroupMemberRequested
                    withdrawReason={state.popupTarget.withdrawReason}
                    onConfirmed={() => {
                        props.onWithdrawn();
                    }}
                />
            );
        }
    };

    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={props.onCancel}
            className={props.className ? props.className : "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"group-member-info"}
        >
            {popups()}
            <div className="title nanumsquareeb-18 nanumsquareeb-16:sm">{t("453")}
                {process.env.NODE_ENV !== "production" && (
                    <Button className="px-2 btn-secondary"
                        onClick={() => {
                            let r = dummy.randomSignupInfo();
                            document.getElementById(ID.phone).value = r.phone;
                            document.getElementById(ID.address1).value = r.address1;
                            document.getElementById(ID.address2).value = r.address2;
                            document.getElementById(ID.about).value = r.about;
                            document.getElementById(ID.sns).value = r.sns;
                        }}
                    >
                        Auto Randomize
                    </Button>)
                }
                <div style={{ position: "absolute", right: "32px" }}>
                    <img src={process.env.PUBLIC_URL + `/icon_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`} onClick={props.onCancel} alt="" style={{ cursor: "pointer" }} />
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col className="col-lg" sm={12}>
                        <div className="profile">
                            <ProfileEmblem
                                width={"121px"}
                                height={"121px"}
                                url={user && state.avatarUrl ? state.avatarUrl : undefined}
                                urlClass={"avatar"}
                                char={user && !state.avatarUrl ? user.name : undefined}
                                charClass={"avatar avatar-121 bg-secondary"}
                                profileId={ID.profile}
                            />
                            <div className="profile-name nanumsquareeb-24 text-break text-center">{user.name}</div>
                        </div>
                    </Col>
                    <Col className="col-lg" sm={12}>
                        <table className="group-info-table">
                            <tbody>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("284")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <InputText id={ID.mail} name="email" defaultValue={user.email} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("896")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <InputText id={ID.realname} name="realname" defaultValue={user.realname} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("327")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <PhoneForm phoneId={ID.phone} defaultValue={user.phone} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("329")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <PostcodeForm
                                            address1Id={ID.address1}
                                            address1DefaultValue={user.address1}
                                            address2Id={ID.address2}
                                            address2DefaultValue={user.address2}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("340")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <textarea id={ID.about} name="about" placeholder={t("341")} defaultValue={user.about} maxLength={50} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("342")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <InputText id={ID.sns} name="sns" placeholder={t("343")} defaultValue={user.sns} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("429")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <select id={ID.permission} defaultValue={getGroupMemberGrade(user, group)} disabled={state.isGroupManager}>
                                            {selectOptionsForGrade(group)}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className="button-group">
                    <Col>
                    </Col>
                    <Col className="text-center condition-button col-md" xs={12}>
                        <button
                            className="save nanumsquareb-14"
                            onClick={async () => {
                                const phone = document.getElementById(ID.phone).value;
                                const address1 = document.getElementById(ID.address1).value;
                                const address2 = document.getElementById(ID.address2).value;
                                const about = document.getElementById(ID.about).value;
                                const sns = document.getElementById(ID.sns).value;
                                const selectedOption = document.getElementById(ID.permission);
                                const permission = selectedOption.options[selectedOption.selectedIndex].value;

                                do {
                                    if (user.phone !== phone) break;
                                    if (user.address1 !== address1) break;
                                    if (user.address2 !== address2) break;
                                    if (user.about !== about) break;
                                    if (user.sns !== sns) break;
                                    if (getGroupMemberGrade(user, group) !== permission) break;

                                    return props.onSave({});
                                } while (false);

                                try {
                                    const data = {
                                        _id: user._id,
                                        phone: phone,
                                        address1: address1,
                                        address2: address2,
                                        about: about,
                                        sns: sns,
                                        group: group._id,
                                        permission: permission, // _id
                                    };

                                    setState({ ...state, popup: POPUP.UserInfoUpdate, popupTarget: data });
                                    // dgLogger.info("prepared to update user info")();
                                } catch (e) {
                                    dgLogger.error(e)();
                                }
                            }}
                        >
                            {t("265")}
                            <img src={process.env.PUBLIC_URL + `/icon_redcheck.png`} srcSet={`${process.env.PUBLIC_URL}/icon_redcheck@2x.png 2x, ${process.env.PUBLIC_URL}/icon_redcheck@3x.png 3x`} alt="" />
                        </button>
                        <button
                            className="cancel nanumsquareb-14"
                            onClick={() => {
                                props.onCancel();
                            }}
                        >
                            {t("80")}
                            <img style={{ width: "18px" }} src={process.env.PUBLIC_URL + `/168-2-20220331-normal.png`} srcSet={`${process.env.PUBLIC_URL}/168-2-20220331-normal@2x.png 2x, ${process.env.PUBLIC_URL}/168-2-20220331-normal@3x.png 3x`} alt="" />
                        </button>
                    </Col>
                    <Col className="text-end">
                        {(!state.isGroupManager && !props.group?.built_in) &&
                            <button
                                className="withdraw nanumsquareb-14"
                                onClick={() => {
                                    setState({ ...state, popup: POPUP.WithdrawGroupMember, popupTarget: { user: props.user, group: props.group } });
                                }}
                            >
                                {t("435")}
                                <img style={{ width: "18px" }} src={process.env.PUBLIC_URL + `/icon_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/icon_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/icon_withdraw@3x.png 3x`} alt=""/>
                            </button>
                        }
                    </Col>
                </Row>
            </div>
        </ReactModal>
    );
}
