import "./TimeRange.css";
import { Col } from "react-bootstrap";

const TimeRangeView = ({
    title,
    description,
    type,
    start,
    end,
    startInvalid,
    startErrorMessage,
    endInvalid,
    endErrorMessage,
    onBlurHandleOfStart,
    onBlurHandleOfEnd
}) => {
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-half-content flex-wrap ${type}`}>
                {/* description을 실제 사용하지 않으나, 값이 있기 때문에 삽입 시 열이 흐트러져 주석 처리 */}
                {/* {description && <label className="top-description notosanskr-500 font-size-15">{description}</label>} */}
                <div>
                    <input
                        key={`start-${start}`}
                        className={startInvalid}
                        type="time"
                        onBlur={onBlurHandleOfStart}
                        defaultValue={start}
                        max={end}
                    />
                    <span>~</span>
                    <input
                        key={`end-${end}`}
                        className={endInvalid}
                        type="time"
                        onBlur={onBlurHandleOfEnd}
                        defaultValue={end}
                        min={start}
                    />
                </div>
                <label className="error">
                    {startErrorMessage || endErrorMessage}
                </label>
            </Col>
        </>
    );
};

export default TimeRangeView;
