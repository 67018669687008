import React from "react";
import common from "../../common/common";
import { useTranslation } from "react-i18next";

export default function PasswordConfirmForm(props) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div>
                <input
                    type="password"
                    id={props.passwordId}
                    name="password"
                    placeholder={t("305")}
                    onChange={(e) => {
                        if (props.onPasswordChange) props.onPasswordChange(e);
                    }}
                    onKeyPress={common.blankBlocking}
                    onBlur={(e) => {
                        if (props.isValid) props.isValid([e.target, document.getElementById(props.confirmId)]);
                    }}
                    max={255}
                    className={props.className}
                    autoComplete="new-password"
                />
            </div>
            <div className={`${props.font ? props.font : "notosanskr-17"} c-f00 mb-16px mt-10px errors`}>{props?.errors?.password?.message}</div>
            <div>
                <input
                    type="password"
                    id={props.confirmId}
                    name="passwordConfirm"
                    placeholder={t("306")}
                    onChange={(e) => {
                        if (props.onPasswordConfirmChange) props.onPasswordConfirmChange(e);
                    }}
                    onKeyPress={common.blankBlocking}
                    onBlur={(e) => {
                        if (props.isValid) props.isValid([e.target, document.getElementById(props.passwordId)]);
                    }}
                    max={255}
                    className={props.className}
                    autoComplete="off"
                />
            </div>
            <div className={`${props.font ? props.font : "notosanskr-17"} c-f00 mb-16px mt-10px errors`}>{props?.errors?.passwordConfirm?.message}</div>
        </React.Fragment>
    );
}
