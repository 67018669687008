import React, { useState, useEffect, useRef, useCallback } from "react";
import Api from "../../../Api";
import { POPUP as GeneralPopup } from "../../../common/defines";
import { MailTransferWindow, MailTransferCompletedWindow, MailTransferFailureWindow } from "../MailTransferWindow";
import GroupMemberInfoWindow from "./GroupMemberInfoWindow";
import { WithdrawGroupMember, WithdrawGroupMemberRequest, WithdrawGroupMemberRequested } from "./WithdrawGroupMemberWindow";
import Pagination from "./../../common/Pagination";
import { Button, Row, Col } from "react-bootstrap";
import Modal from "../../common/Modal";
import { MultiSelect } from "react-multi-select-component";
import SortIcon from "../../common/SortIcon";
import CustomCheckbox from "../../common/CustomCheckbox";
import "./GroupMember.css";
import { useIsMount } from "../../../common/customHook";
import { UpdateMemberGrade } from "./UpdateMemberGradeWindow";
import { utility } from "@ocean-knight/shared";
import { InputText } from "../../Input";
import DateBox from "../../common/DateBox";
import common from "../../../common/common";
import dgLogger from '../../../common/dgLogger';
import { useTranslation } from "react-i18next";

const ID = {
    filterName: "group-member-manage-filter-name",
    filterGroup: "group-member-manage-filter-group",
    filterGrade: "group-member-manage-filter-grade",
    filterOrg: "group-member-manage-filter-organization",
    sendMailTitle: "group-member-manage-send-mail-title",
    sendMailContent: "group-member-manage-send-mail-content",
    sendMailAttaches: "group-member-manage-send-mail-attaches",
    // updatePermissionTarget: "group-member-manage-grade-target",
    // updateTarget: "group-member-manage-target",
    // withdrawReason: "group-member-manage-withdraw-reason",

    checkAll: "group-member-manage-items-check-all",
    resultName: "group-member-manage-filter-result-name",
    resultGrade: "group-member-manage-filter-result-grade",
    resultOrganization: "group-member-manage-filter-result-organization",
    resultRegisterDate: "group-member-manage-filter-result-register-date",
    resultLatestVisit: "group-member-manage-filter-result-latest-visit",
    resultNumberOfComments: "group-member-manage-filter-result-number-of-comments",
    resultNumberOfReports: "group-member-manage-filter-result-number-of-reports",
};

const POPUP = {
    ...GeneralPopup,
    UpdateGroupMemberGrade: 1036,
    WithdrawGroupMember: 1037,
    WithdrawGroupMemberRequest: 1038,
    WithdrawGroupMemberRequested: 1039,
    UpdateGroupMemberGradeConfirm: 1040,
    UpdateGroupMemberGradeUpdate: 1041,
};

export default function ManageGroupMember(props) {
    const { t } = useTranslation();
    const isMount = useIsMount();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        filteredOptions: { // grade 를 제외한, 항목은 새로 검색을 하기 전까지는 초기화 되지 않습니다.
            // 검색에 사용한 옵션 (사용자 정보를 변경후에 기존 옵션으로 다시 서버에서 정보를 얻어올 필요가 있을 수 있음)
            name: null, // string
            group: null, // _id
            grades: [], // [_id]
            startDate: "",
            endDate: "",
            isOrganization: false, // t/f
            currentPage: 1, // 현재 보고있는 페이지
            sort: { register_date: -1 }, // 정렬 기준 (등록일)
        },
        manageGroups: [], // 전체 그룹 목록
        targetGroup: null, // 검색할 타겟 group
        users: [], // 검색된 사용자 목록
        modifyGradeButton: false, // '그룹원 등급 변경' 버튼 활성/비활성화 상태
        withdrawButton: false, // '그룹원 등급 탈퇴' 버튼 활성/비활성화 상태
        sendMailButton: false, // '메일 발송' 등의 버튼 활성/비활성화 상태
    });
    const paginationOption = useRef({ itemsCountPerPage: 10, pageRangeDisplayed: 10, totalItemsCount: 0 });

    const [prevDate, setPrevDate] = useState({startDate: state.filteredOptions.startDate, endDate: state.filteredOptions.endDate, date: t("60")});
    const prevGroupIds = useRef([]);

    useEffect(() => {
        document.getElementById(ID.checkAll).disabled = true;
    }, []);

    const strArraysEqual = (strArray1, strArray2) => {
        // 두 배열을 오름차순으로 정렬합니다.
        const sortedArr1 = strArray1.slice().sort();
        const sortedArr2 = strArray2.slice().sort();

        // 정렬된 배열끼리 비교하여 같은지 확인합니다.
        return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
    };

    useEffect(() => {
        if (!strArraysEqual(props.groupIds, prevGroupIds.current)) {
            prevGroupIds.current = props.groupIds;
            Api.getFilterGroupList({
                _ids: props.groupIds, // 로그인 사용자가 관리하고 있는 그룹 _id 목록
            })
                .then((payload) => {
                    if (!isMount.current) return;
                    const targetGroup = payload.length === 1 ? payload[0] : null;
                    setState((prev) => ({ ...prev, manageGroups: payload, targetGroup: targetGroup }));
                })
                .catch((e) => dgLogger.error(e));
        }
    }, [isMount, props.groupIds]);

    /**
     * parameter로 전달된 filter 에 매칭되는 사용자 정보를 획득하여 state 를 갱신합니다.
     *
     * @param {string} name 검색할 그룹원 이름
     * @param {string[]} groups 검색할 그룹 목록
     * @param {string[]} grades 검색할 그룹 등급 목록
     * @param {string} startDate 자료 등록 시작일 filter
     * @param {string} endDate 자료 등록 종료일 filter
     * @param {boolean} isOrganization 자료활용 공익단체인 그룹원을 검색할지 여부
     * @param {number} page pagination 시 보는 페이지 번호
     * @param {Object} sort 정렬 기준
     * @param {Object} extraState state 를 갱신할때 spread 로 추가할 state
     */
    const getFilteredUserInfoSummaryInDateRange = useCallback((name, groups, grades, startDate, endDate, isOrganization, page, sort, extraState = {}) => {
        const newStartDate = startDate && common.convertToIsoDate(startDate);
        const newEndDate = endDate && common.convertToIsoDate(endDate, 1);

        Api.getFilteredGroupMember({
            name: name,
            groups: groups.map(group => group._id),
            grades: grades,
            isOrganization: isOrganization,
            currentPage: `${page}`,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            startDate: newStartDate,
            endDate: newEndDate,
            sort: sort,
        })
            .then((payload) => {
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                if (paginationOption.current.totalItemsCount) document.getElementById(ID.checkAll).disabled = false;
                else document.getElementById(ID.checkAll).disabled = true;

                // 체크박스 및 하단 버튼(등급 변경, 탈퇴, 메일 발송) 의 활성/비활성화 처리
                const ids = payload.users.map(user => user._id);
                const users = state.users.filter(user => ids.includes(user._id));
                const [checked, managerInList] = checkCheckboxesStatus(users);
                document.getElementById(ID.checkAll).checked = checked > 0 && checked === users.length;
                document.getElementById(ID.checkAll).disabled = !(users.length);

                dgLogger.assert(groups.length === 1)();

                setState((prev) => ({
                    ...prev,
                    users: payload.users,
                    filteredOptions: {
                        name: name,
                        group: groups[0],
                        grades: grades,
                        startDate: newStartDate,
                        endDate: newEndDate,
                        isOrganization: isOrganization,
                        currentPage: page,
                        sort: sort,
                    },
                    modifyGradeButton: checked > 0 && !managerInList,
                    withdrawButton: checked > 0 && !managerInList,
                    sendMailButton: checked > 0,
                    ...extraState,
                }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [state.users]);

    useEffect(() => {
        // 관리하는 그룹이 1개일 때 자동으로 최초 1회 검색, filteredOptions의 group의 값은 최초에만 null
        if (state.manageGroups.length === 1 && !state.filteredOptions.group) {
            const filteredOptions = state.filteredOptions;
            const page = filteredOptions.currentPage;
            getFilteredUserInfoSummaryInDateRange(
                filteredOptions.name,
                [state.manageGroups[0]],
                filteredOptions.grades,
                filteredOptions.startDate,
                filteredOptions.endDate,
                filteredOptions.isOrganization,
                page,
                filteredOptions.sort,
                {
                    popup: POPUP.None,
                    popupTarget: null,
                }
            );
        }
    }, [state.manageGroups, state.filteredOptions, getFilteredUserInfoSummaryInDateRange]);

    const selectOptions = (groups) => {
        return groups.map((group) => (
            <option key={group._id} value={group._id} selected={groups.length === 1}>
                {common.i18nGroupName(group.name)}
            </option>
        ));
    };

    const selectOptionsForGradeMultiSelect = (group) => {
        if (!group) return [];
        const permissions = group.permissions;
        return permissions.sort((a, b) => a.order - b.order).map((permission) => ({ label: permission.grade_name, value: permission._id }));
    };

    const selectedOptionsForGradeMultiSelect = (group, grades) => {
        if (!group || !grades) return [];
        const permissions = group.permissions;
        return permissions.sort((a, b) => a.order - b.order).reduce((acc, cur) => {
            if (grades.includes(cur._id)) {
                acc.push({ label: cur.grade_name, value: cur._id });
            }
            return acc;
        }, []);
    };

    /**
     * 현재 웹 페이지의 filter 항목에서 선택된 값들을 획득하여 반환합니다.
     *
     * @returns 현재 웹 페이지에서 선택된 값들
     */
    const getFilterOptions = () => {
        const name = document.getElementById(ID.filterName).value;
        const selectedOptions = document.getElementById(ID.filterGroup).selectedOptions;
        let groups = Array.from(selectedOptions)
            .map(({ value }) => value)
            .filter((item) => (item ? true : false));
        let grades = state.filteredOptions.grades;
        if (grades.length === 0) {
            // if not selected, mean select all
            grades = state.manageGroups
                .reduce((acc, cur) => acc.concat(cur.permissions), [])
                .reduce((acc, cur) => {
                    acc.push(cur._id);
                    return acc;
                }, []);
        }
        // const isOrganization = document.getElementById(ID.filterOrg).checked;
        const isOrganization = false;
        const startDate = document.getElementById("startDate").value;
        const endDate = document.getElementById("endDate").value;

        return [name, groups, grades, startDate, endDate, isOrganization];
    };

    /**
     * parameter로 전달된 그룹원 객체가 현재 filter 로 선택된 group 에서의 등급에 해당하는 _id 값을 반환합니다.
     *
     * @param {*} user 그룹원 객체
     * @returns 현재 그룹원이 filter 로 선택된 group 에서의 그룹 등급 (_id)
     */
    const getGroupMemberGrade = (user) => {
        const group = user.groups.find((item) => item.group === state.filteredOptions.group?._id);
        return user.permissions.find((permission) => permission?._id === group?.permission);
    };

    const popupUserInfo = () => {
        return (
            <GroupMemberInfoWindow
                user={state.popupTarget}
                group={state.filteredOptions.group}
                onSave={async (updateUserInfoBody) => {
                    try {
                        Object.keys(updateUserInfoBody).length && await Api.updateUserInfo(updateUserInfoBody);

                        const filteredOptions = state.filteredOptions;
                        const page = filteredOptions.currentPage;
                        getFilteredUserInfoSummaryInDateRange(
                            filteredOptions.name,
                            [filteredOptions.group],
                            filteredOptions.grades,
                            filteredOptions.startDate,
                            filteredOptions.endDate,
                            filteredOptions.isOrganization,
                            page,
                            filteredOptions.sort,
                            {
                                popup: POPUP.None,
                                popupTarget: null,
                            }
                        );
                    } catch (e) {
                        dgLogger.error(e)();
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }
                }}
                onCancel={() => {
                    const filteredOptions = state.filteredOptions;
                    const page = filteredOptions.currentPage;
                    getFilteredUserInfoSummaryInDateRange(
                        filteredOptions.name,
                        [filteredOptions.group],
                        filteredOptions.grades,
                        filteredOptions.startDate,
                        filteredOptions.endDate,
                        filteredOptions.isOrganization,
                        page,
                        filteredOptions.sort,
                        {
                            popup: POPUP.None,
                            popupTarget: null,
                        }
                    );
                }}
                onWithdrawn={() => {
                    // 그룹원 목록 갱신
                    const filteredOptions = state.filteredOptions;
                    const page = filteredOptions.currentPage > 1 && state.users.length === 1 ? filteredOptions.currentPage - 1 : filteredOptions.currentPage; // has no item to display. then move previous page
                    getFilteredUserInfoSummaryInDateRange(
                        filteredOptions.name,
                        [filteredOptions.group],
                        filteredOptions.grades,
                        filteredOptions.startDate,
                        filteredOptions.endDate,
                        filteredOptions.isOrganization,
                        page,
                        filteredOptions.sort,
                        {
                            popup: POPUP.None,
                            popupTarget: null,
                        }
                    );
                }}
            />
        );
    };

    const popupUpdateGroupMemberGrade = () => {
        const users = state.users.filter((item) => document.getElementById(item._id).checked);

        return (
            <UpdateMemberGrade
                users={users}
                grades={state.filteredOptions.group.permissions}
                title={t("434")}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                className={props.className}
                onConfirmed={(users, permission) => setState({ ...state, popup: POPUP.UpdateGroupMemberGradeConfirm, popupTarget: { users: users, permission: permission } })}
            />
        );
    };

    const popupUpdateGroupMemberGradeConfirm = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_grade.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_grade@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_grade@3x.png 3x`} alt="" />;
        const header = <div>{t("434")}</div>;
        const body = <div>{t("439")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    Api.updateGroupMembersPermission({
                        users: state.popupTarget.users.map((user) => user._id), // _id
                        group: state.filteredOptions.group._id,
                        permission: state.popupTarget.permission, // _id
                    })
                        .then(() => {
                            const filteredOptions = state.filteredOptions;
                            getFilteredUserInfoSummaryInDateRange(
                                filteredOptions.name,
                                [filteredOptions.group],
                                filteredOptions.grades,
                                filteredOptions.startDate,
                                filteredOptions.endDate,
                                filteredOptions.isOrganization,
                                filteredOptions.currentPage,
                                filteredOptions.sort,
                                {
                                    popup: POPUP.UpdateGroupMemberGradeUpdate,
                                    popupTarget: null,
                                }
                            );
                        })
                        .catch((e) => dgLogger.error(e)());
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupUpdateGroupMemberGradeUpdate = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_grade.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_grade@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_grade@3x.png 3x`} alt="" />;
        const header = <div>{t("434")}</div>;
        const body = <div>{t("440")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.UserInfo) return popupUserInfo();
        if (state.popup === POPUP.UpdateGroupMemberGrade) return popupUpdateGroupMemberGrade();
        if (state.popup === POPUP.UpdateGroupMemberGradeConfirm) return popupUpdateGroupMemberGradeConfirm();
        if (state.popup === POPUP.UpdateGroupMemberGradeUpdate) return popupUpdateGroupMemberGradeUpdate();
        if (state.popup === POPUP.WithdrawGroupMember) {
            const users = state.users.filter((user) => document.getElementById(user._id).checked);
            return (
                <WithdrawGroupMember
                    users={users}
                    onConfirmed={(withdrawReason) => {
                        setState({ ...state, popup: POPUP.WithdrawGroupMemberRequest, popupTarget: withdrawReason });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawGroupMemberRequest) {
            const users = state.users.filter((user) => document.getElementById(user._id) && document.getElementById(user._id).checked);
            return (
                <WithdrawGroupMemberRequest
                    users={users}
                    group={state.filteredOptions.group}
                    withdrawReason={state.popupTarget}
                    onConfirmed={(users, group, withdrawReason) => {
                        Api.withdrawGroupMembers({
                            users: users.map((user) => user._id), // _id
                            group: group._id,
                            reason: withdrawReason, // string (withdraw reason)
                            lang: common.getLang()
                        })
                            .then(() => {
                                const filteredOptions = state.filteredOptions;
                                const page = filteredOptions.currentPage > 1 && state.users.length - users.length === 0 ? filteredOptions.currentPage - 1 : filteredOptions.currentPage; // has no item to display. then move previous page
                                getFilteredUserInfoSummaryInDateRange(
                                    filteredOptions.name,
                                    [filteredOptions.group],
                                    filteredOptions.grades,
                                    filteredOptions.startDate,
                                    filteredOptions.endDate,
                                    filteredOptions.isOrganization,
                                    page,
                                    filteredOptions.sort,
                                    {
                                        popup: POPUP.WithdrawGroupMemberRequested,
                                    }
                                );
                            })
                            .catch((e) => dgLogger.error(e)());
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawGroupMemberRequested) {
            return (
                <WithdrawGroupMemberRequested
                    withdrawReason={state.popupTarget}
                    onConfirmed={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.MailTransfer) {
            return (
                <MailTransferWindow
                    users={state.popupTarget}
                    onSend={(data) => {
                        Api.sendMailToGroupMembers(data)
                            .then(() => {
                                setState({ ...state, popup: POPUP.MailTransferCompleted, popupTarget: null });
                            })
                            .catch((err) => {
                                dgLogger.error(err)();
                                setState({ ...state, popup: POPUP.MailTransferFailure, popupTarget: err.toString() });
                            });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onClose={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.MailTransferCompleted) {
            return (
                <MailTransferCompletedWindow
                    onClose={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.MailTransferFailure) {
            return (
                <MailTransferFailureWindow
                    onClose={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
    };

    const resetSortFlag = (except = null) => {
        const resultName = document.getElementById(ID.resultName);
        if (!except || resultName !== except) resultName.asc = null;
        const resultGrade = document.getElementById(ID.resultGrade);
        if (!except || resultGrade !== except) resultGrade.asc = null;
        // const resultOrganization = document.getElementById(ID.resultOrganization);
        // if (!except || resultOrganization !== except) resultOrganization.asc = null;
        const resultRegisterDate = document.getElementById(ID.resultRegisterDate);
        if (!except || resultRegisterDate !== except) resultRegisterDate.asc = null;
        const resultLatestVisit = document.getElementById(ID.resultLatestVisit);
        if (!except || resultLatestVisit !== except) resultLatestVisit.asc = null;
        const resultNumberOfComments = document.getElementById(ID.resultNumberOfComments);
        if (!except || resultNumberOfComments !== except) resultNumberOfComments.asc = null;
        const resultNumberOfReports = document.getElementById(ID.resultNumberOfReports);
        if (!except || resultNumberOfReports !== except) resultNumberOfReports.asc = null;
    };

    const clearCheckboxes = () => {
        document.getElementById(ID.checkAll).checked = false;
        state.users.forEach((user) => {
            document.getElementById(user._id).checked = false;
        });
    };

    const handleSort = (e, sortKey) => {
        if (!state.users || state.users.length === 0) return;
        clearCheckboxes();
        resetSortFlag(e.target);
        e.target.asc = !e.target.asc;
        const filteredOptions = state.filteredOptions;
        const sort = {};
        sort[sortKey] = e.target.asc ? 1 : -1;
        getFilteredUserInfoSummaryInDateRange(
            filteredOptions.name,
            [filteredOptions.group],
            filteredOptions.grades,
            filteredOptions.startDate,
            filteredOptions.endDate,
            filteredOptions.isOrganization,
            filteredOptions.currentPage,
            sort);
    };

    const checkCheckboxesStatus = (users) => {
        let checked = 0;
        let managerInList = false;
        users.forEach((user) => {
            if (document.getElementById(user._id)?.checked) checked++;
            if (!managerInList && document.getElementById(user._id).checked) managerInList = utility.hasGroupManagerPermission(user.hightestGrade);
        });

        return [checked, managerInList];
    };

    const updateCheckboxesStatus = (users) => {
        const [checked, managerInList] = checkCheckboxesStatus(users);

        document.getElementById(ID.checkAll).checked = checked > 0 && checked === users.length;
        document.getElementById(ID.checkAll).disabled = !(users?.length);
        setState({ ...state,
            modifyGradeButton: checked > 0 && !managerInList,
            withdrawButton: checked > 0 && !managerInList,
            sendMailButton: checked > 0 });
    };

    return (
        <div id="manage-group-member">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("355")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    {process.env.NODE_ENV !== "production" && (
                        <div className="d-table-cell align-bottom">
                            <Button
                                onClick={() => {
                                    Api.devMakeUsers2RandomGroupMember()
                                        .then(() => {
                                            // dgLogger.info("make random group member success")();
                                        })
                                        .catch((e) => dgLogger.error(e)());
                                }}
                            >
                                Make random users to Group member
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>

            <div className="filter-wrap">
                <Row className="gx-0 notosanskr-400 font-size-14">
                    <Col className="filter-inputs-wrap">
                        <Row className="gx-0">
                            <Col className="group col">
                                <select
                                    required
                                    id={ID.filterGroup}
                                    onChange={(e) => {
                                        setState({ ...state, targetGroup: state.manageGroups.find((group) => group._id === e.target.value), filteredOptions: { ...state.filteredOptions, grades: [] } });
                                    }}
                                    disabled={state.manageGroups.length === 1}
                                >
                                    {state.manageGroups.length > 1 && (
                                        <option value="" disabled selected>
                                            {t("38")}
                                        </option>
                                    )}
                                    {selectOptions(state.manageGroups)}
                                </select>
                            </Col>
                            <Col className="grade col">
                                <MultiSelect
                                    id={ID.filterGrade}
                                    disabled={!state.targetGroup}
                                    disabledSearch={!state.targetGroup}
                                    options={selectOptionsForGradeMultiSelect(state.targetGroup)}
                                    value={selectedOptionsForGradeMultiSelect(state.targetGroup, state.filteredOptions.grades)}
                                    onChange={(values) => {
                                        setState({ ...state, filteredOptions: { ...state.filteredOptions, grades: values.map((item) => item.value) } });
                                    }}
                                    disableSearch={true}
                                    overrideStrings={{ selectSomeItems: t("423"), selectAll: t("404"), selectAllFiltered: t("404"), allItemsAreSelected: t("71") }}
                                />
                            </Col>
                            <Col className="date col">
                                <DateBox
                                    startDate={prevDate.startDate}
                                    endDate={prevDate.endDate}
                                    date={prevDate.date}
                                    onChange={(startDate, endDate, date) => setPrevDate({startDate, endDate, date})}
                                    />
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="name col mt-7px">
                                <InputText id={ID.filterName} placeholder={t("90")} />
                            </Col>
                            <Col className="col mt-7px mr-7px"></Col>
                            <Col className="reset col mt-7px notosanskr-400 font-size-16 text-end">
                                <button
                                    onClick={() => {
                                        document.getElementById(ID.filterName).value = "";
                                        document.getElementById(ID.filterGroup).selectedIndex = 0;
                                        const targetGroup = state.manageGroups?.length > 1 ? null : state.targetGroup;
                                        setState({ ...state, targetGroup: targetGroup, filteredOptions: { ...state.filteredOptions, grades: [] } });

                                        document.getElementById("startDate").value = "";
                                        document.getElementById("endDate").value = "";
                                        document.getElementById("date").innerHTML = t("60");
                                        document.getElementById("date").style.color = "#aaa";
                                        setPrevDate({startDate:"", endDate:"", date: t("60")});
                                    }}
                                >
                                    {t("69")}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="find col-auto notosanskr-400 font-size-16">
                        <button
                            onClick={() => {
                                clearCheckboxes();
                                resetSortFlag();
                                const [name, groups, grades, startDate, endDate, isOrganization] = getFilterOptions();
                                getFilteredUserInfoSummaryInDateRange(
                                    name,
                                    state.manageGroups.reduce((acc, cur) => {
                                        if (groups.find(group => group === cur._id)) acc.push(cur);
                                        return acc;
                                    }, []),
                                    grades,
                                    startDate,
                                    endDate,
                                    isOrganization,
                                    1, //first page
                                    state.filteredOptions.sort
                                );
                            }}
                            disabled={!state.targetGroup}
                        >
                            {t("70")}
                        </button>
                    </Col>
                </Row>
            </div>

            <div>
                <table className="result">
                    <thead>
                        <tr>
                            <th className="checkbox">
                                <CustomCheckbox
                                    id={ID.checkAll}
                                    onChange={(e) => {
                                        let managerInList = false;
                                        state.users.forEach((user) => {
                                            document.getElementById(user._id).checked = e.target.checked;
                                            if (!managerInList) managerInList = utility.hasGroupManagerPermission(user.hightestGrade);
                                        });
                                        setState({
                                            ...state,
                                            modifyGradeButton: e.target.checked && !managerInList,
                                            withdrawButton: e.target.checked && !managerInList,
                                            sendMailButton: e.target.checked,
                                        });
                                    }}
                                    disabled={!state.users?.length}
                                />
                            </th>
                            <th
                                id={ID.resultName}
                                className="name notosanskr-500 font-size-18"
                                onClick={(e) => {
                                    handleSort(e, "name");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("90")}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                            <th
                                id={ID.resultGrade}
                                className="grade notosanskr-500 font-size-18"
                                onClick={(e) => {
                                    // Note. 그룹원 등급은, manager 를 제외하고는 동일한 등급에 표시 이름만 다르므로, 그룹원 등급으로 sort 시 manager 미만의 등급은 정상적으로 정렬되어 보이지 않을 수 있습니다.
                                    handleSort(e, "hightestGradeOrder");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("429")}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                            {/* <th
                                id={ID.resultOrganization}
                                className="organization notosanskr-500 font-size-18"
                                onClick={(e) => {
                                    handleSort(e, "organization");
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <div>
                                        자료활용
                                        <br />
                                        공익단체
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th> */}
                            <th
                                id={ID.resultRegisterDate}
                                className="registered-date notosanskr-500 font-size-18"
                                onClick={(e) => {
                                    handleSort(e, "register_date");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("430")}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                            <th
                                id={ID.resultLatestVisit}
                                className="last-visit notosanskr-500 font-size-18"
                                onClick={(e) => {
                                    handleSort(e, "latest_visit");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("431")}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                            <th
                                id={ID.resultNumberOfComments}
                                className="report-count notosanskr-500 font-size-18"
                                onClick={(e) => {
                                    handleSort(e, "numberOfReports");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("432").split('\n').map((v, i) => <div key={i}>{v}</div>)}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                            <th
                                id={ID.resultNumberOfReports}
                                className="comment-count notosanskr-500 font-size-18"
                                onClick={(e) => {
                                    handleSort(e, "numberOfComments");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("433").split('\n').map((v, i) => <div key={i}>{v}</div>)}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.users.map((user) => (
                            <tr key={user._id} className={`notosanskr-500 font-size-16`}>
                                <td className="checkbox">
                                    <CustomCheckbox
                                        id={user._id}
                                        onChange={(_) => {
                                            updateCheckboxesStatus(state.users);
                                        }}
                                    />
                                </td>
                                <td className="name">
                                    <button
                                        onClick={() => {
                                            Api.getUserInfo({ _id: user._id, optPermissions: true, optGroups: true })
                                                .then((payload) => setState({ ...state, popup: POPUP.UserInfo, popupTarget: payload }))
                                                .catch((e) => dgLogger.error(e)());
                                        }}
                                    >
                                        {user.name}
                                    </button>
                                </td>
                                <td className="grade">{getGroupMemberGrade(user).grade_name}</td>
                                {/* <td className="organization">{user.organization?.length ? "O" : "X"}</td> */}
                                <td className="registered-date">{new Date(user.register_date).toLocaleDateString()}</td>
                                <td className="last-visit">{user.latest_visit ? new Date(user.latest_visit).toLocaleDateString() : new Date(user.register_date).toLocaleDateString()}</td>
                                <td className="report-count">{user.numberOfReports}</td>
                                <td className="comment-count">{parseInt(user.numberOfComments)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="text-end button-group notosanskr-400 font-size-16">
                    <button
                        className="modify-grade"
                        disabled={!state.modifyGradeButton}
                        onClick={() => {
                            const users = state.users.filter((user) => document.getElementById(user._id).checked);
                            if (users.length === 0) {
                                return dgLogger.warn("has no selected user")();
                            }
                            setState({ ...state, popup: POPUP.UpdateGroupMemberGrade, popupTarget: null });
                        }}
                    >
                        {t("434")}
                    </button>
                    <button
                        className="withdraw"
                        disabled={!state.withdrawButton || state.filteredOptions.group?.built_in}
                        onClick={() => {
                            const users = state.users.filter((user) => document.getElementById(user._id).checked);
                            if (users.length === 0) {
                                return dgLogger.warn("has no selected user")();
                            }
                            setState({ ...state, popup: POPUP.WithdrawGroupMember, popupTarget: null });
                        }}
                    >
                        {t("435")}
                    </button>
                    <button
                        className="send-mail"
                        disabled={!state.sendMailButton}
                        onClick={() => {
                            const users = state.users.filter((user) => document.getElementById(user._id).checked);
                            if (users.length === 0) {
                                return dgLogger.warn("has no selected user")();
                            }
                            setState({ ...state, popup: POPUP.MailTransfer, popupTarget: users });
                        }}
                    >
                        {t("436")}
                    </button>
                    <button
                        className="send-mail"
                        disabled={state.users.length === 0}
                        onClick={() => {
                            const filteredOptions = state.filteredOptions;

                            Api.getFilteredGroupMember({
                                name: filteredOptions.name,
                                groups: [filteredOptions.group._id],
                                grades: filteredOptions.grades,
                                startDate: filteredOptions.startDate,
                                endData: filteredOptions.endDate,
                                isOrganization: filteredOptions.isOrganization,
                            }).then(payload => setState({ ...state, popup: POPUP.MailTransfer, popupTarget: payload.users }
                            )).catch(e => dgLogger.error(e)());
                        }}
                    >
                        {t("437")}
                    </button>
                </div>

                <Pagination
                    key={state.filteredOptions.group ? state.filteredOptions.group._id : `0`}
                    ref={(newPage) => {
                        clearCheckboxes();
                        const filteredOptions = state.filteredOptions;
                        getFilteredUserInfoSummaryInDateRange(
                            filteredOptions.name,
                            [filteredOptions.group],
                            filteredOptions.grades,
                            filteredOptions.startDate,
                            filteredOptions.endDate,
                            filteredOptions.isOrganization,
                            newPage,
                            filteredOptions.sort);
                    }}
                    page={state.filteredOptions.currentPage}
                    itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                    totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                    pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                />
            </div>
        </div>
    );
}
