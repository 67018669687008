import { useCallback, useContext } from "react";
import useGPSContext from "../Context/GPSContext";

export default function useLocationHook(ref, t) {
    const { GPSContext } = useGPSContext();
    const gpsContext = useContext(GPSContext);

    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const locationValidation = useCallback(({ latitude, longitude, required }) => {
        return ref.current?.locationValidation?.({ latitude, longitude, required });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setLocationValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    const getLocationValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);


    // 현재 context에 있는 GPS 반환
    const getContextGPSValue = useCallback(() => {
        return gpsContext.getGPS();
    }, [gpsContext]);

    // 유효한 위도 값인 지 판별
    const validateLatitude = useCallback((latitude) => {
        if (-90 <= latitude && latitude <= 90) return true;
        else return false;
    }, []);

    // 유효한 경도 값인 지 판별
    const validateLongitude = useCallback((longitude) => {
        if (-180 <= longitude && longitude <= 180) return true;
        else return false;
    }, []);

    return {
        locationValidation,
        setLocationValue,
        getLocationValue,
        getContextGPSValue,
        validateLatitude,
        validateLongitude
    };
};
