import React from "react";
import { InputText } from "../Input";
import { useTranslation } from "react-i18next";

export default function PhoneForm(props) {
    const { t } = useTranslation();
    const verifyField = () => {
        const phone = document.getElementById(props.phoneId).value;
        document.getElementById(props.phoneId).value = phone.replace(/\D/g, "");
        if (props.onChange) props.onChange();
    };

    return (
        <React.Fragment>
            <InputText
                id={props.phoneId}
                name="phone"
                defaultValue={props.defaultValue}
                // disable 이라면, placeholder 를 출력하지 않음
                placeholder={props.disabled ? "" : t("328")}
                onChange={verifyField}
                onBlur={(e) => {
                    if (props.isValid) props.isValid([e.target]);
                }}
                maxLength={20}
                disabled={props.disabled}
                className={props.className}
            />
            {props.errors &&
                <div className={`${props.font ? props.font : "notosanskr-17"} c-f00 mb-16px mt-10px errors`}>{props?.errors?.phone?.message}</div>
            }
        </React.Fragment>
    );
}
