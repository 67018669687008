import React, {useState, forwardRef, useCallback } from "react";
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
// import 'tinymce/skins/ui/oxide/content.min.css';
// import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';

import ImageDropZone from "./common/ImageDropZone";
import Api from "../Api";
import dgLogger from '../common/dgLogger';

const TinyEditor = forwardRef((props, ref) => {
    const [state, setState] = useState({popup: false});

    const imageUpload = useCallback((fileInfo) => {
        ref.current.execCommand('mceInsertContent', false, `<img src="${fileInfo.url}" data-mce-src="${fileInfo.url}">`);
        setState((prev) => ({
            ...prev, popup: false
        }));
    }, [ref]);

    const onDropImageDropZone = useCallback((acceptedFiles) => {
        Api.uploadFiles([acceptedFiles[0]])
            .then((payload) => {
                imageUpload(payload[0]);
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [imageUpload]);

    const popup = () => {
        if (state.popup){
            return (
                <ImageDropZone
                    ref={onDropImageDropZone}
                    accept={"image/*"}
                />
            );
        }
    };

    return (
        <>
        {popup()}
        <Editor
            content_css='tinymce/skins/content/default/content.min.css'
            inline={props.viewer}
            disabled={props.viewer}
            onInit={(evt, editor) => {
                if (ref) ref.current = editor;
            }}
            initialValue={props.HTMLData}
            init={{
                relative_urls: false,
                height: 500,
                menubar: false,
                plugins: [
                    'image',
                    'preview',
                    'table',
                ],
                toolbar: 'undo redo | fontselect | fontsizeselect |' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'custom_image table | preview | help',
                // font_formats: "돋움=Dotum; 돋움체=DotumChe; 굴림=Culim; 굴림체=GulimChe; 바탕=Batang; 바탕체=BatangChe;" +
                // "궁서=Gungsuh; 궁서체=GungsuhChe; 새굴림='New Gulim'; 맑은 고딕='Malgun Gothic';" +
                // "나눔 고딕='Nanum Gothic'; 나눔 명조='Nanum Myoungjo'; 주아=Jua; 독도=Dokdo; 추가='Noto Sans KR'; 나눔스퀘어=NanumSquare",
                // // content_style: "@import url('https://fonts.googleapis.com/css2?family=Dokdo&family=Jua&display=swap'); \
                // // body { font-family:DokDo; font-size:14px }",
                // content_style: "@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css'); \
                // @import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css'); \
                // body { font-family:Dotum; font-size:14px }",
                font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;' +
                    'Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;' +
                    'Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif;' +
                    'Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;' +
                    'Webdings=webdings; Wingdings=wingdings,zapf dingbats; Noto Sans="Noto Sans KR"',
                // content_style: "@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css'); \
                // body { font-family:'Noto Sans KR'; font-size:14px;}",
                setup: function(editor) {
                    // 최초 실행 시 text-align: left 으로 설정
                    editor.on('init',() => {
                        editor.execCommand('JustifyLeft', false);
                    });
                    // custom_image button 추가
                    editor.ui.registry.addButton('custom_image', {
                        title: 'image-upload',
                        icon: 'image',
                        onAction: function() {
                            setState({
                                ...state, popup: true
                            });
                        }
                    });
                },
                // 최초 로드 시 API Key를 사용하지 않아 warning message가 나오게 되는데, 이를 보이지 않게 하는 동작
                init_instance_callback : function() {
                    let freeTiny = document.querySelector('.tox-notification');
                    if (freeTiny)
                        freeTiny.style.display = 'none';
                }
            }}
        />
        </>
    );
});

export default TinyEditor;
