import React from "react";

export default function Cluster(props) {
    // cluster 클릭 시 현재 zoom을 +1 하고 cluster가 Map의 중앙이 되도록 이동
    const clusterClick = () => {
        props.map.setCenter({ lat: props.lat, lng: props.lng });
        props.map.setZoom(props.map.getZoom() + 1);
        if (props.mapChangeNavigate) props.mapChangeNavigate(props.lat, props.lng, props.map.getZoom());
    };

    return (
        <div onClick={clusterClick}
            className="cluster c-fff notosanskr-24b"
            style={{ width: props.size + "px", height: props.size + "px", fontSize: props.size / 2 + "px" }}
        >
            {props.count}
        </div>
    );
}
