import React, { useImperativeHandle, useRef, useCallback } from 'react';

const WithValueHandle = (WrapperComponent) => {
    const WithValue = ({ ...otherProps }, ref) => {
        const valueRef = useRef(null);
        const representativePicture = useRef(null);

        const setValue = useCallback((value) => {
            valueRef.current = value;
        }, []);

        const getValue = useCallback(() => {
            return valueRef.current;
        }, []);

        const setRepresentativePicture = useCallback((picture) => {
            representativePicture.current = picture;
        }, []);

        const getRepresentativePicture = useCallback(() => {
            return representativePicture.current;
        }, []);

        useImperativeHandle(ref, () => (
            { ...ref.current, setValue, getValue, setRepresentativePicture, getRepresentativePicture }
        ), [ref, setValue, getValue, setRepresentativePicture, getRepresentativePicture]);

        return (
            <WrapperComponent ref={ref} {...otherProps} />
        );
    };

    return React.forwardRef(WithValue);
};

export default WithValueHandle;
