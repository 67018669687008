import { createContext, useRef, useCallback, useMemo } from "react";
import dgLogger from "../../../common/dgLogger";

const SizeContext = createContext(null);


const useSizeContext = () => {
    const resizedUploadSize = useRef(0); // 업로드 된 파일을 resize 시킨 크기 집합
    const originalUploadedSize = useRef(0); // 업로드 된 파일 원본의 크기 집합

    const addResizedUploadSize = useCallback((value) => {
        const numberTypeValue = Number(value);
        if (!Number.isNaN(numberTypeValue)) {
            resizedUploadSize.current += numberTypeValue;
        }
        else {
            dgLogger.log(`Fail to addResizedUploadSize value = ${value}`)();
        }
    }, []);

    const subtractResizedUploadSize = useCallback((value) => {
        const numberTypeValue = Number(value);
        if (!Number.isNaN(numberTypeValue)) {
            resizedUploadSize.current -= numberTypeValue;
        }
        else {
            dgLogger.log(`Fail to subtractResizedUploadSize value = ${value}`)();
        }
    }, []);

    const setResizedUploadSize = useCallback((value) => {
        const numberTypeValue = Number(value);
        if (!Number.isNaN(numberTypeValue)) {
            resizedUploadSize.current = numberTypeValue;
        }
        else {
            dgLogger.log(`Fail to setResizedUploadSize value = ${value}`)();
        }
    }, []);

    const getResizedUploadSize = useCallback(() => {
        return resizedUploadSize.current;
    }, []);

    const addOriginalUploadedSize = useCallback((value) => {
        const numberTypeValue = Number(value);
        if (!Number.isNaN(numberTypeValue)) {
            originalUploadedSize.current += numberTypeValue;
        }
        else {
            dgLogger.log(`Fail to addOriginalUploadedSize value = ${value}`)();
        }
    }, []);

    const subtractOriginalUploadedSize = useCallback((value) => {
        const numberTypeValue = Number(value);
        if (!Number.isNaN(numberTypeValue)) {
            originalUploadedSize.current -= numberTypeValue;
        }
        else {
            dgLogger.log(`Fail to subtractOriginalUploadedSize value = ${value}`)();
        }
    }, []);

    const setOriginalUploadedSize = useCallback((value) => {
        const numberTypeValue = Number(value);
        if (!Number.isNaN(numberTypeValue)) {
            originalUploadedSize.current = numberTypeValue;
        }
        else {
            dgLogger.log(`Fail to addOriginalUploadedSize value = ${value}`)();
        }
    }, []);

    const getOriginalUploadedSize = useCallback(() => {
        return originalUploadedSize.current;
    }, []);

    const sizeContextValue = useMemo(() => ({
        addResizedUploadSize,
        subtractResizedUploadSize,
        setResizedUploadSize,
        getResizedUploadSize,
        addOriginalUploadedSize,
        subtractOriginalUploadedSize,
        setOriginalUploadedSize,
        getOriginalUploadedSize
    }), [addOriginalUploadedSize, addResizedUploadSize, getOriginalUploadedSize, getResizedUploadSize, setOriginalUploadedSize, setResizedUploadSize, subtractOriginalUploadedSize, subtractResizedUploadSize]);

    return { SizeContext, sizeContextValue };
};

export default useSizeContext;
