import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle, useRef } from 'react';
import WithOptionalHandle from "../hoc/WithOptionalHandle";
import useVendorUI4Hook from './VendorUI4Hook';
import VendorUI4View from "./VendorUI4View";
import { IsMobile } from "../../common/MediaQuery";
import common from '../../../common/common';

const VendorUI4 = forwardRef(({ _id, defaultValue, type, fields, sub_items, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const i18n_sub_items = useMemo(() => {
        const lang = common.getLang();
        if (i18n?.[lang]) {
            return sub_items.map((f) => ({ ...f, ...(i18n[lang].sub_items.find((x) => x.index == f.index) || {}) }));
        } else return sub_items;
    }, [sub_items, i18n]);
    const [value, setValue] = useState(sub_items.map((v) => ({_id: v._id, count: 0})));
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const isMobile = IsMobile();
    const {
        optionalValidation,
        setVendorUI4Value,
        getVendorUI4Value
    } = useVendorUI4Hook(ref);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        if (!getVendorUI4Value()) {
            if (defaultValue) {
                setVendorUI4Value(defaultValue);
                setValue(defaultValue);
            } else {
                setVendorUI4Value(value);
            }
        }
    }, [value, defaultValue, setVendorUI4Value, getVendorUI4Value]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = getVendorUI4Value() || [];
        const { valid, errorCollection } = optionalValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [type, required, getVendorUI4Value, optionalValidation]);

    // 현재 element에서 값이 바뀌었을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    // VendorUI4에 적용 될 handle
    const onChangeHandle = useCallback((sub_item, diff) => {
        const currentValue = getVendorUI4Value();

        // 기존 value가 있을 때
        if (currentValue) {
            const found = currentValue.find((value) => value._id == sub_item._id);
            if (found) {
                const values = currentValue.filter((value) => value._id != sub_item._id);
                const newValue = [...values, { _id: sub_item._id, count: (found.count + diff > 0) ? found.count + diff : 0}];
                setVendorUI4Value(newValue);
                setValue(newValue);
            } else {
                console.assert(false, "should have values");
            }
        }
        //기존 value가 없을 때
        else {
            const newValue = i18n_sub_items.map((v) => ({_id: v._id, count: (sub_item._id == v._id && diff > 0) ? diff : 0}));
            setVendorUI4Value(newValue);
            setValue(newValue);
        }
        validation();
    }, [i18n_sub_items, validation, setVendorUI4Value, getVendorUI4Value]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        type,
        value: value,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        isMobile,
        sub_items : i18n_sub_items,
        onChangeHandle,
    };

    return (
        <VendorUI4View {...viewProps} />
    );
});

export default WithOptionalHandle(VendorUI4);
