import React, { useEffect } from "react";
import { VIEW } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import { IsTabletOrPC, IsWebview } from "../common/MediaQuery";
import "./ReportBasedButton.css";

export default function ReportBasedButton(props) {
    const params = props.params;
    const navigate = useNavigate();
    const isWebview = IsWebview();
    const isTabletOrPC = IsTabletOrPC();

    useEffect(() => {
        document.getElementById("ListBased").classList.remove("selected");
        document.getElementById("MapBased").classList.remove("selected");
        document.getElementById("GraphBased").classList.remove("selected");
        document.getElementById("DownloadBased")?.classList.remove("selected");

        switch (parseInt(params.view)) {
            case VIEW.ListBased:
                document.getElementById("ListBased").classList.add("selected");
                break;
            case VIEW.MapBased:
                document.getElementById("MapBased").classList.add("selected");
                break;
            case VIEW.GraphBased:
                document.getElementById("GraphBased").classList.add("selected");
                break;
            case VIEW.DownloadBased:
                document.getElementById("DownloadBased")?.classList.add("selected");
                break;
            default:
        }
    }, [params.view]);

    /**
     * 사용자가 선택 한 기반 표시에 따라 navigate
     *
     * @param {*} e event
     */
    const selectBased = (e) => {
        const view = e.currentTarget.value;
        if (params.view === view) return;
        let param = props.createDefaultParam(view);

        switch (parseInt(view)) {
            case VIEW.ListBased:
                param += `/page=1`;
                break;
            case VIEW.MapBased:
                break;
            case VIEW.GraphBased:
                break;
            case VIEW.DownloadBased:
                param += `/page=1`;
                break;
            default:
        }

        navigate(param);
    };

    return (
        <span className="report-based-button">
            <button id="ListBased" onClick={selectBased} value={VIEW.ListBased} />
            <button id="MapBased" onClick={selectBased} value={VIEW.MapBased} />
            <button id="GraphBased" onClick={selectBased} value={VIEW.GraphBased} />
            {!isWebview && isTabletOrPC && common.hasSiteMemberPermission(JSON.parse(sessionStorage.getItem("permissions"))) && (
                <button className="ml-15px" id="DownloadBased" onClick={selectBased} value={VIEW.DownloadBased} />
            )}
        </span>
    );
}
