import React from "react";

const InputText = React.forwardRef((props, ref) => {
    const removeMutilpleSpace = (e) => {
        e.target.value = e.target.value.trim().replace(/ +(?= )/g, '');
    };

    return (
        <input
            type="text"
            {...props}
            ref={ref}
            onBlur={(e) => { props.onBlur && props.onBlur(e); removeMutilpleSpace(e); }}
        />
    );
});

export { InputText };
