import React, { useState, useEffect } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import ManageGroupMember from "./ManageMember/GroupMember";
import ManageSiteMember from "./ManageMember/SiteMember";
import { POPUP } from "../../common/defines";
import { useNavigate } from 'react-router-dom';
import GeneralErrorWindow from '../common/GeneralErrorWindow';
import common from "../../common/common";
import { useIsMount } from "../../common/customHook";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

export default function Member() {
    const { t } = useTranslation();
    const isMount = useIsMount();
    const navigate = useNavigate();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        user: null
    });

    useEffect(() => {
        const permissions = JSON.parse(sessionStorage.getItem("permissions"));
        const hasSiteAdminPermission = common.hasSiteAdminPermission(permissions);
        const hasGroupManagerPermission = common.hasGroupManagerPermission(permissions);
        if (!hasSiteAdminPermission && !hasGroupManagerPermission) {
            if (!isMount.current) return;
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }
        Api.getCurrentUserInfo({ optPermissions: true })
            .then((payload) => {
                common.storePermissionsInfo(payload.optPermissions);
                if (!isMount.current) return;
                setState((prev) => ({ ...prev, user: payload }));
            })
            .catch((e) => {
                dgLogger.error(e)();
                if (!isMount.current) return;
                setState(prev => ({...prev, popup: POPUP.GeneralError, popupTarget: e.toString()}));
            });
    }, [isMount, t]);

    const manageMemberView = (user) => {
        if (!user) return <React.Fragment />;
        const permissions = user.optPermissions;
        if (permissions.find((permission) => utility.hasSiteAdminPermission(permission.grade))) return <ManageSiteMember />;
        if (permissions.find((permission) => utility.hasGroupManagerPermission(permission.grade)))
            return (
                <ManageGroupMember
                    groupIds={permissions.reduce((acc, cur) => {
                        if (cur.group && utility.hasGroupManagerPermission(cur.grade)) acc.push(cur.group);
                        return acc;
                    }, [])}
                />
            );
        return <React.Fragment />;
    };

    const popups = () => {
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    return (
        <>
            {popups()}
            {manageMemberView(state.user)}
        </>
    );
}
