import React, { useEffect, useState, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";
import { InputText } from "../Input";
import { nanoid } from "nanoid";
import "./DynamicForm.css";
import { RadioCheckboxForm, FileForm, VendorUIForm, SpinnerBoxForm, VendorUI3Form, VendorUI4Form } from "./FormItem";
import Api from "../../Api";
import _ from "lodash";
import { DndContext, closestCenter, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { POPUP as GeneralPopup } from "../../common/defines";
import { DynamicFormRequest, DynamicFormRequested, DynamicFormNoModification } from "./DynamicFormWindow";
import common from "../../common/common";
import GetFormItem from "./GetFormItem";
import dgLogger from '../../common/dgLogger';
import { useIsMount } from "../../common/customHook";
import { useTranslation } from "react-i18next";
import { utility } from "@ocean-knight/shared";
import { strings } from "@ocean-knight/shared";
import { I18NTYPE } from "@ocean-knight/shared/src/utility";

const POPUP = {
    ...GeneralPopup,
    NoModification: 1004
};

/**
 * 공통적인 form item schema을 생성하는 함수, _id를 unique key로 사용, _id가 없을 때 nano를 이용하여 생성
 *
 * @param {String} type
 * @param {String} title
 * @param {String} description
 * @param {String} required
 * @param {Array} sub_items
 * @param {string} _id
 * @param {boolean} essential
 * @param {object} fields
 * @param {Array} extra_items
 * @param {boolean} built_in 기본 생성 된 item인 지 여부
 * @returns form object
 */
const createFormItem = ({
    type = "",
    title = "",
    description = "",
    required = false,
    sub_items = [],
    _id = undefined,
    essential = false,
    fields = undefined,
    extra_items = [],
    built_in = false,
    chart = null,
    i18n = {}
}) => {
    const i18nFields = fields ? common.fieldsWithoutEmptyValue(fields) : undefined ;
    const i18nSubItems = common.subItemsWithoutEmptyValue(sub_items);

    return ({
        type: type,
        fields: fields || {
            title: title,
            description: description,
            required: required,
        },
        sub_items: sub_items,
        _id: _id || `__${nanoid(32)}`,
        essential: essential,
        extra_items: extra_items,
        built_in: built_in,
        chart: chart,
        i18n: {
            en: i18n?.en || {
                ...(i18nFields && { fields: i18nFields }),
                ...(sub_items.length && { sub_items: i18nSubItems })
            },
            ko: i18n?.ko || {
                ...(i18nFields && { fields: i18nFields }),
                ...(sub_items.length && { sub_items: i18nSubItems })
            }
        }
    });
};

const TYPE = utility.FORM_ITEM_TYPE;

const MAX_COUNT = 20; // file, vendor-ui-2의 최대 개수 ( 현재 기획에서의 최대 개수 )

const DynamicForm = forwardRef((props, ref) => {
    const isMount = useIsMount();
    const { t } = useTranslation();
    const formItems = useRef([]);
    const prevFromItemCount = useRef();
    const prevFormItems = useRef();
    const activeIndex = useRef(5);
    const displayLang = useRef(common.getLang());
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
    );
    const [state, setState] = useState({
        popup: POPUP.None,
        allModels: undefined, // 등록된 모든 model
        formModels: undefined, // form 작성시 참조한 model
    });
    const [errors, setErrors] = useState({});

    const updateI18nFromFormFields = useCallback((formItems, dispLang) => {
        // 현재 form 에서 값을 displayLang 에 맞는 i18n key 에 갱신
        formItems.forEach((formItem) => {
            if (!formItem.i18n) formItem.i18n = {};
            Object.keys(I18NTYPE).forEach(lang => {
                if (!formItem.i18n[lang]) formItem.i18n[lang] = { fields: {} };
            });

            const fields = common.fieldsWithoutEmptyValue(formItem.fields);
            formItem.i18n[dispLang].fields = fields;

            // 만약 빠진 항목이 있다면, form 의 값으로 dispLang 에 맞는 i18n key 에 갱신
            // Note. 한글 Form 에만 입력하고, 자료 제출을 눌렀을때, 영문 Form 에 입력되지 않은 값이 있다면, 한글 Form 과 같은 값으로 설정되도록 처리하기 위함.
            Object.keys(formItem.i18n).forEach((lang) => {
                if (lang == dispLang) return;
                formItem.i18n[lang].fields = { ...fields, ...formItem.i18n[lang].fields };
            });

            const sub_items = common.subItemsWithoutEmptyValue(formItem.sub_items);
            if (sub_items.length) {
                formItem.i18n[dispLang].sub_items = sub_items;

                // 만약 빠진 항목이 있다면, form 의 값으로 dispLang 에 맞는 i18n key 에 갱신
                // Note. 한글 Form 에만 입력하고, 자료 제출을 눌렀을때, 영문 Form 에 입력되지 않은 값이 있다면, 한글 Form 과 같은 값으로 설정되도록 처리하기 위함.
                // sub_item 갯수가 같다면, 처리하지 않음. 만약 현재 화면에 보이는 항목 (sub_items) 이 더 많다면, 많은 갯수만큼만 append 처리
                Object.keys(I18NTYPE)
                    .filter((lang) => lang != dispLang) // 이미 처리된 lang 는 처리하지 않음
                    .forEach((lang) => {
                        const i18n = formItem.i18n[lang];
                        if (!i18n.sub_items) i18n.sub_items = [];
                        for (let i = 0; i < formItem.sub_items.length; ++i) {
                            // i18n 의 sub_item 에 있는 index 와 form_item 의 sub_items 에 있는 index 의 번호가 일치한다는
                            // 것이 확실하지 않으므로, index 는 복사하지 않음.
                            // 예를들어
                            // i18n.sub_items = [{index:0, name:a}, null, {index:2, name:c}];
                            // 이고, formItem 의 경우
                            // formItem.sub_items = [{index:1, name:a}, {index:2, name:b}]
                            // 라면, 최종 값은
                            // [{index:0, name:a}, {index:2, name:b}], {index:2 name:c}]
                            // 가 되어버릴 수 있음
                            // 또한, index key 는 createFormSubmit 에서 재 생성되므로, index key 는 우선 무시하도록 함.
                            const expectedKeys = ["name", "question"];
                            const source_sub_item = Object.keys(i18n.sub_items[i] || {}).filter((k) => expectedKeys.includes(k)).length ? i18n.sub_items[i] : formItem.sub_items[i];
                            const sub_item = Object.keys(source_sub_item)
                                .filter((k) => expectedKeys.includes(k))
                                .reduce((acc, key) => {
                                    acc[key] = source_sub_item[key];
                                    return acc;
                                }, {});
                            if (formItem.sub_items[i].sub_item_type == "custom_string" && sub_item.name) {
                                sub_item.name = strings[lang]['163'];
                            }
                            i18n.sub_items[i] = sub_item;
                        }
                    }
                );
            } else {
                Object.keys(I18NTYPE)
                    .forEach((lang) => {
                        const i18n = formItem.i18n[lang];
                        i18n.sub_items && delete i18n.sub_items;
                    }
                );
            }
        });
    }, []);

    const updateFormFieldsFromI18n = useCallback((formItems, dispLang) => {
        formItems.forEach(formItem => {
            formItem.fields = {...common.fieldsWithoutEmptyValue(formItem.fields, null), ...(formItem.i18n?.[dispLang]?.fields || {}) };
            formItem.sub_items = common.subItemsWithoutEmptyValue(formItem.sub_items, null).map((item, i) => ({...item, ...(formItem.i18n?.[dispLang]?.sub_items?.[i] || {})}));
        });
    }, []);

    // index 아래에 새로운 form item을 추가
    const addFormItem = useCallback((e, index, form = { type: TYPE.Textbox }) => {
        formItems.current[index].built_in = false;
        formItems.current.splice(index + 1, 0, createFormItem({ ...form, _id: undefined }));
        activeIndex.current = index + 1;
        const activatedElement = document.querySelector(".activated");
        if (activatedElement) {
            activatedElement.classList.remove("activated");
        }
        e && e.stopPropagation();
        forceUpdate();
    }, [forceUpdate]);

    // index 아래에 현재 선택 된 form item과 같은 item 추가
    const copyFormItem = (e, index) => {
        formItems.current[index].built_in = false;
        addFormItem(e, index, JSON.parse(JSON.stringify(formItems.current[index])));
    };

    // index의 form item 삭제
    const removeFormItem = (e, index) => {
        formItems.current.splice(index, 1);
        if (index > 5) activeIndex.current = index - 1;
        e.stopPropagation();
        const tmpErrors = errors;
        delete tmpErrors[index];
        setErrors(tmpErrors);
        forceUpdate();
    };

    // 기존 form array 획득
    const getFormArray = useCallback(async () => {
        const payload = { _id: props?.report_form_id };
        if (props.group_id) {
            const groupInfo = await Api.getGroupItem({ _id: props.group_id });
            payload._id = groupInfo.report_form_id;
        }
        try {
            const formArray = await Api.getReportFormItemArray(payload);
            updateFormFieldsFromI18n(formArray.report_form_item_ids, displayLang.current);
            updateI18nFromFormFields(formArray.report_form_item_ids, displayLang.current);
            prevFormItems.current = formArray.report_form_item_ids;
            formItems.current = JSON.parse(JSON.stringify(formArray.report_form_item_ids)); // 가리키는 주소가 같아 문제가 생기는 경우가 있어 deep copy
            // 필수 item을 제외한 item이 없는 경우 새로운 item을 추가하지 못 하므로, 기본적으로 1개의 item을 생성
            if (!formItems.current.find(item => item.essential === false)) {
                addFormItem(undefined, formItems.current.length - 2, { type: TYPE.Textbox, built_in: false });
            }

            return true;
        } catch (e) {
            dgLogger.error(e)();
            return false;
        }
    }, [addFormItem, props.group_id, props?.report_form_id, updateFormFieldsFromI18n, updateI18nFromFormFields]);

    useEffect(() => {
        const getContents = async () => {
            const r = await getFormArray();
            if (r) {
                // 만약 form item 중 vendor ui 3 type 이 있다면, 모든 model 획득
                const vendorUI3Forms = prevFormItems.current.filter((v) => v.type == "vendor-ui-3" && v.fields.object_detection_model);
                const modelList = await Api.getObjectDetectionModelList();
                const formModels = (vendorUI3Forms.length) ? modelList.models.filter(m => vendorUI3Forms.find(v => v.fields.object_detection_model == m._id)) : undefined;
                setState((prev) => ({ ...prev, allModels: modelList.models, formModels: formModels }));

                forceUpdate();
            }
        };

        if (!isMount.current) return;
        getContents();
    }, [getFormArray, forceUpdate, isMount]);

    const submitProjectForm = (project_id) => {
        createFormSubmit(undefined, project_id);
    };

    // 부모가 호출할 함수 선언
    useImperativeHandle(ref, () => {
        return {
            validate: validateForms,
            submit: submitProjectForm,
        };
    });

    useEffect(() => {
        if (activeIndex.current > 0 && prevFromItemCount.current !== formItems.current.length) {
            const activeElement = document.getElementById(formItems.current[activeIndex.current]?._id);
            common.scrollToElement(activeElement);
            prevFromItemCount.current = formItems.current.length;
        }
    });

    // form item의 type에 맞는 component 반환
    const getFormContent = (form, index, dispLang) => {
        switch (form.type) {
            case TYPE.Textbox: return <InputText key={`${form._id}-${index}`} disabled={true} />;
            case TYPE.Spinnerbox: return <SpinnerBoxForm key={`${form._id}-${index}`} fields={form.fields} dispLang={dispLang}/>;
            case TYPE.Textarea: return <textarea key={`${form._id}-${index}`} disabled={true} />;
            case TYPE.Radiobox: return <RadioCheckboxForm key={`${form._id}-${index}`} type="radio" sub_items={form.sub_items} i18n={form.i18n} index={index} dispLang={dispLang} isValid={isValid} errors={errors} />;
            case TYPE.Checkbox: return <RadioCheckboxForm key={`${form._id}-${index}`} type="checkbox" sub_items={form.sub_items} i18n={form.i18n} index={index} dispLang={dispLang} isValid={isValid} errors={errors} />;
            case TYPE.File: return <FileForm key={`${form._id}-${index}`} maxCount={MAX_COUNT} form={form} />;
            case TYPE.VendorUI: return <VendorUIForm key={`${form._id}-${index}`} maxCount={MAX_COUNT} form={form} />;
            case TYPE.VendorUI3: return <VendorUI3Form key={`${form._id}-${index}`} maxCount={MAX_COUNT} form={form} index={index} models={state.allModels || []} formModel={state.formModels?.find(v => v._id == form.fields.object_detection_model)} dispLang={dispLang} isValid={isValid} errors={errors} />;
            case TYPE.VendorUI4:
                return <VendorUI4Form key={`${form._id}-${index}`} sub_items={form.sub_items} index={index} dispLang={dispLang} isValid={isValid} errors={errors} />;
            default:
                dgLogger.error("can not handle `" + form.type + "` type form")();
        }
    };

    // form 생성 시 필요한 모든 값이 채워져 있는 지 판단
    // form 제목, radio, checkbox인 경우엔 항목의 제목
    const validateForms = () => {
        const elements = formItems.current.reduce((acc, cur, index) => {
            if (cur.essential === false && !cur.built_in) {
                acc.push(document.getElementsByName(index)[0]);
                if ([TYPE.Radiobox, TYPE.Checkbox, TYPE.VendorUI3, TYPE.VendorUI4].includes(cur.type)) {
                    acc.push(...(cur.sub_items.map((_, subIndex) =>
                        document.getElementsByName(`${index}_${subIndex}`)[0]
                    )));
                }
            };
            return acc;
        }, []);

        if (!isValid(elements)) {
            props.hideSubmit || common.scrollToInvalidElement();
            return false;
        }

        // Note. 현재 화면에 보이는 언어는 가장 마지막에 validate 를 수행하도록 순서 변경해서
        // display lang 과 실제 form 에 출력되는 값이 매치되지 않는 현상(언어 선택은 ko 인데, Form 데이터는 en 으로 출력되는 등)이 발생하지 않도록 함.
        const langs = Object.keys(I18NTYPE).filter(v => v != displayLang.current).concat(displayLang.current);

        // 현재 화면에 보이지 않는 언어의 경우, 만약 값이 비어있다면, 해당 언어로 dropbox 전환후 스크롤 되도록 처리
        const valid = langs.every(lang => {
            const elements = formItems.current.reduce((acc, cur, index) => {
                if (cur.essential === false && !cur.built_in) {
                    if (!cur.i18n?.[lang]) return acc; //if has no i18n object, then skip
                    const titleElement = document.getElementsByName(index)[0];
                    titleElement.value = cur.i18n?.[lang].fields.title;
                    acc.push(titleElement);
                    if (cur.type === TYPE.Radiobox || cur.type === TYPE.Checkbox || cur.type === TYPE.VendorUI3) {
                        acc.push(...(cur.sub_items.map((item, subIndex) => {
                                const sub_itemElement = document.getElementsByName(`${index}_${subIndex}`)[0];
                                const sub_item = cur.i18n[lang].sub_items[subIndex];
                                sub_itemElement.value = sub_item.name || sub_item.question || "";
                                return sub_itemElement;
                            }))
                        );
                    }
                };
                return acc;
            }, []);

            if (!isValid(elements)) {
                updateFormFieldsFromI18n(formItems.current, lang);
                props.hideSubmit || common.scrollToInvalidElement();

                displayLang.current = lang;
                forceUpdate();
                return false;
            }

            return true;
        });

        return valid;
    };

    const modifyMinCount = (fields) => {
        // allow_empty_item이 체크 되어 있는 지 여부
        if (!fields.allow_empty_item) {
            // allow_empty_item이 체크 되어 있지 않다면 max_count 만큼 모두 채워야 하므로 min_count를 max_count로 수정
            fields.min_item_count = fields.max_item_count;
        }
        else {
            // 필수 item이라면 1, 아니라면 0 삽입
            fields.min_item_count = (fields.required ? 1 : 0);
        }

        return fields;
    };

    /**
     * 폼이 변경이 없는지, 새로 만들어져야 하는지, 갱신되어야 하는지 를 반환합니다.
     * @param (list of dict) prevFormItems 이전 폼 정보 아이템 리스트
     * @param (list of dict) currentFormItems 현재 폼 정보 아이템 리스트
     * @returns 폼 유지 (keep), 폼 생성 (create), 폼 갱신 (update)
     */
    const evaluateFormState = useCallback((prevFormItems, currentFormItems) => {
        // 기존 form과 수정 된 form이 같은 경우 api를 호출 하지 않도록 처리 ( 같은 경우 동작이 정의 되면 동작 수정 예정 )
        if (_.isEqual(prevFormItems, currentFormItems)) {
            // no change
            return 'keep';
        }

        // if 기존 form 과 수정된 form 이 다르다면 (갯수가 다르거나, 값이 다르거나) create 로 처리
        let foundChange = prevFormItems.length != currentFormItems.length;
        if (!foundChange) {
            foundChange = currentFormItems.some((item, index) => {    // loop until true
                if (item._id.startsWith("__")) {
                    // __으로 시작 하는 경우는 기존 item이 아닌 nano로 _id를 만든 신규 item
                    return true;
                }

                // compare array items
                const prevItem = prevFormItems[index];

                // compare items and get change status
                const status = compareItemAndGetStatus(item, prevItem);
                if (status == 'change') {
                    return true;
                }

                // not found change
                return false;
            });
        }

        if (foundChange) {
            return 'create';
        }
        else {
            return 'update';
        }
    }, []);

    /**
     * 개별 아이템을 비교해서 변경된 사항이 있는지 확인합니다.
     * 추가 조건 : chart, object_detection_model, sub_item's class_number 항목은 변경 ('change') 으로 취급하지 않습니다.
     * @param (list of dict) prevItem 비교 아이템1
     * @param (list of dict) item 비교 아이템2
     * @returns 변경됨 'change', 갱신됨 'update', 변경사항 없음 'no_change'
     *
     */
    const compareItemAndGetStatus = (prevItem, item) => {
        let ret = 'no_change';

        if (item.type != "vendor-ui-3") {
            // chart, key 를 제외하고 비교.
            // 해당 값들은 바뀌어도 새로운 form item 으로 보지 않음.
            const omitFields = ['chart'];
            if (!(_.isEqual(_.omit(item, omitFields), _.omit(prevItem, omitFields)))) {
                // found change
                console.log('found change except chart and object_detection_model');
                return 'change';
            }
        }
        else {
            // chart, object_detection_model, 'sub_items' key 를 제외하고 비교.
            // 해당 값들은 바뀌어도 새로운 form item 으로 보지 않음.
            const omitFields = ['chart', 'fields.object_detection_model', 'sub_items'];
            if (!(_.isEqual(_.omit(item, omitFields), _.omit(prevItem, omitFields)))) {
                // found change
                console.log('found change except chart, object_detection_model, sub_items');
                return 'change';
            }

            // sub_items key 에서 class_number 를 제외하고 비교.
            for (let sub_item_index = 0; sub_item_index < item.sub_items.length; sub_item_index++) {
                const sub_item = item.sub_items[sub_item_index];
                const prev_sub_item = prevItem.sub_items[sub_item_index];

                const omitFields = ['class_number'];
                if (!(_.isEqual(_.omit(sub_item, omitFields), _.omit(prev_sub_item, omitFields)))) {
                    // found change
                    console.log('found sub_item change except classNumber');
                    return 'change';
                }

                // check again with 'class_number'
                if (!(_.isEqual(sub_item, prev_sub_item))) {
                    // found change in 'class_number'; update report form
                    ret = 'update';
                    break;
                }

            }
        }

        if (ret != 'change') {
            // chart, object_detection_model key 가 바뀌었다면 (새로운 form item 을 만드는 대신) DB 갱신을 수행
            if (!(_.isEqual(item?.fields?.object_detection_model, prevItem?.fields?.object_detection_model))) {
                ret = 'update';
            }
            if (!(_.isEqual(item?.chart, prevItem?.chart))) {
                ret = 'update';
            }
        }

        return ret;
    };
    /**
     * create/update report form 요청 페이로드를 생성합니다.
     * @param (list of dict) prevFormItems 이전 폼 정보 아이템 리스트
     * @param (list of dict) currentFormItems 현재 폼 정보 아이템 리스트
     * @param (string) group_id group id 값
     * @param (string) project_id project id 값
     * @returns 서버에 전달할 request paylaod
     * @see Api.createReportForm
     * @see Api.updateReportForm
     */
    const buildPayload = useCallback((prevFormItems, currentFormItems, group_id, project_id) => {
        const payload = {
            // item이 새로 추가 되어야 하는 지 flag 삽입
            formItems: currentFormItems.reduce((acc, item) => {
                // __으로 시작 하는 경우는 기존 item이 아닌 nano로 _id를 만든 item
                if (item._id.startsWith("__")) {
                    item.create = true;
                }
                else {
                    // 기존에도 존재하는 item인 경우 수정 사항이 있는 지 판단하여 flag에 boolean 삽입
                    const prevItem = prevFormItems.find(prevItem => prevItem._id === item._id);

                    // compare items and get change status
                    const status = compareItemAndGetStatus(item, prevItem);
                    if (status == 'change') {
                        item.create = true;
                    }
                    else if (status == 'update') {
                        item.update = true;
                    }
                }

                if (item.fields.title) acc.push(item);
                return acc;
            }, []),
        };

        if (group_id) payload.group_id = group_id;
        if (project_id) payload.project_id = project_id;

        dgLogger.assert(((!!group_id) ^ (!!project_id)) === 1)();

        return payload;
    }, []);

    const createFormSubmit = async (group_id, project_id) => {
        const previousFormItems = prevFormItems.current.map((formItem) => {
            const prevFormItem = {
                type: formItem.type,
                fields: formItem.fields,
                sub_items: formItem.sub_items,
                _id: formItem._id,
                essential: formItem.essential,
                extra_items: formItem.extra_items,
                ...(!formItem.essential && { chart: formItem.chart }),
                ...(formItem.i18n && { i18n: formItem.i18n }),
            };

            // i18n 의 sub_items 에도 indexing 처리
            Object.keys(I18NTYPE).forEach((lang) => {
                const i18n = prevFormItem.i18n?.[lang];
                if (!i18n?.sub_items) return ;
                i18n.sub_items = i18n.sub_items.map((item, index) => ({ ...item, index: index }));
            });

            return prevFormItem;
        });

        const currentFormItems = formItems.current.map((formItem) => {
            const updatedFormItem = {
                type: formItem.type,
                // fields를 만들 때, min_item_count가 있다면 새로운 object 반환
                fields: !formItem.essential && formItem.fields.hasOwnProperty("allow_empty_item") ? modifyMinCount(formItem.fields) : formItem.fields,
                sub_items: formItem.sub_items.map((item, index) => ({ ...item, index: index })), // index 추가
                _id: formItem._id,
                essential: formItem.essential,
                extra_items: formItem.extra_items,
                ...(!formItem.essential && { chart: formItem.chart }),
                ...(formItem.i18n && { i18n: formItem.i18n }),
            };

            // i18n 의 sub_items 에도 indexing 처리
            Object.keys(I18NTYPE).forEach((lang) => {
                const i18n = updatedFormItem.i18n?.[lang];
                if (!i18n?.sub_items) return;
                i18n.sub_items = i18n.sub_items.map((item, index) => ({ ...item, index: index }));
            });

            return updatedFormItem;
        });

        // form 생성, 실패에 따라 동작이 정의 되지 않아 동작 정의 되면 동작 수정 예정
        try {
            const formState = evaluateFormState(previousFormItems, currentFormItems);
            switch (formState) {
                case "create": {
                    const payload = buildPayload(previousFormItems, currentFormItems, group_id, project_id);
                    await Api.createReportForm(payload);
                    break;
                }
                case "update": {
                    const payload = buildPayload(previousFormItems, currentFormItems, group_id, project_id);
                    await Api.updateReportForm({ ...payload, formItems: payload.formItems.filter((x) => x.update) });
                    break;
                }
                case "keep":
                default: {
                    return setState({ ...state, popup: POPUP.NoModification });
                }
            }

            do {
                const vendorUI3Forms = currentFormItems.filter((v) => v.type == "vendor-ui-3" && v.fields.object_detection_model);
                const formModels = state.allModels.filter((m) => vendorUI3Forms.find((v) => v.fields.object_detection_model == m._id));

                if (props.hideSubmit) {
                    setState({ ...state, formModel: formModels });
                } else {
                    setState({ ...state, formModel: formModels, popup: POPUP.Requested });
                }
            } while (false);
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    // form item이 선택 되었을 때 button-group이 출력 될 수 있도록 class를 수정해주는 함수
    const switchActive = (element, index) => {
        const activatedElement = document.querySelector(".activated");
        if (activatedElement) {
            activatedElement.classList.remove("activated");
        }

        element.classList.add("activated");
        activeIndex.current = index;
        // observerElement(element);
    };

    // drag가 끝났을 때 호출되는 handler
    const handleDragEnd = (event) => {
        const { active, over } = event; // active - 현재 클릭 한 item, over - 드래그 종료 된 위치의 item

        // active를 over의 위치에 삽입
        if (active.id !== over.id) {
            const oldIndex = formItems.current.findIndex(item => item._id === active.id);
            const newIndex = formItems.current.findIndex(item => item._id === over.id);

            const item = formItems.current.splice(oldIndex, 1)[0];
            formItems.current.splice(newIndex, 0, item);
            activeIndex.current = newIndex;

            forceUpdate();
        }
    };

    // const observerElement = (element) => {
    //     if (!element) return;

    //     const io = new IntersectionObserver((entries, observer) => {
    //         entries.forEach(entry => {
    //             const buttonGroup = entry.target.querySelector(".button-group");
    //             if (!entry.isIntersecting) {
    //                 if (entry.boundingClientRect.y < 0) {
    //                     buttonGroup.classList.add("top");
    //                 }
    //             }
    //             else {
    //                 buttonGroup.classList.remove("top");
    //             }
    //         });
    //     }, { threshold: 1 });

    //     const io2 = new IntersectionObserver((entries, observer) => {
    //         entries.forEach(entry => {
    //             const buttonGroup = entry.target.querySelector(".button-group");
    //             if (!entry.isIntersecting) {
    //                 if (entry.boundingClientRect.y > 0) {
    //                     buttonGroup.classList.add("bottom");
    //                 }
    //             }
    //             else {
    //                 buttonGroup.classList.remove("bottom");
    //             }
    //         });
    //     }, { threshold: 0.05 });

    //     io.observe(element);
    //     io2.observe(element);
    // };

    const popups = () => {
        if (state.popup === POPUP.Request) return <DynamicFormRequest onConfirm={() => createFormSubmit(props.group_id, props.project_id)} onCancel={() => setState({ ...state, popup: POPUP.None })} />;
        if (state.popup === POPUP.Requested) return <DynamicFormRequested
            onConfirm={async () => {
                const r = await getFormArray();
                if (r) forceUpdate();
                setState({ ...state, popup: POPUP.None });
            }}
        />;
        if (state.popup === POPUP.NoModification) return <DynamicFormNoModification onConfirm={() => setState({ ...state, popup: POPUP.None })} />;
    };

    const isValid = (elements) => {
		const [flag, errorCollection] = common.isValid(elements);

		setErrors({ ...errors, ...errorCollection });
		return flag;
	};

    return (
        <div className="dynamic-form font-size-16">
            <Row className="gx-0 dynamic-form-title">
                <Col className="notosanskr-600 font-size-26 notosanskr-14:sm c-333 col-auto">{t("459")}</Col>
                <Col className="select col-auto">
                    <select
                        onChange={(e) => {

                            updateI18nFromFormFields(formItems.current, displayLang.current);
                            const toLang = e.target.value;
                            updateFormFieldsFromI18n(formItems.current, toLang);
                            displayLang.current = toLang;

                            setErrors({});
                        }}
                        key={displayLang.current}
                        defaultValue={displayLang.current == "ko" ? I18NTYPE.ko : I18NTYPE.en}
                    >
                        <option value={I18NTYPE.ko}>한국어</option>
                        <option value={I18NTYPE.en}>English</option>
                    </select>
                </Col>
                {formItems.current.length > 0 && !formItems.current.find((item) => item.essential === false) && (
                    <>
                        <Col>{/* empty space */}</Col>
                        <Col className="button-group col-auto">
                            <Row className="gx-0">
                                <div className="plus" onClick={(e) => addFormItem(e, formItems.current.length - 2)}>
                                    <img src={process.env.PUBLIC_URL + `/plus.png`} alt="" />
                                </div>
                            </Row>
                        </Col>
                    </>
                )}
            </Row>

            {popups()}

            <DndContext sensors={sensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={handleDragEnd}>
                <SortableContext items={formItems.current.map((form) => form._id)} strategy={verticalListSortingStrategy}>
                    {formItems.current.map((form, index) => (
                        <GetFormItem
                            dispLang={displayLang.current}
                            key={`${form._id}-${displayLang.current}`}
                            form={form}
                            index={index}
                            activeIndex={activeIndex}
                            switchActive={switchActive}
                            isValid={isValid}
                            errors={errors}
                            forceUpdate={forceUpdate}
                            models={state.allModels}
                            getFormContent={getFormContent}
                            addFormItem={addFormItem}
                            copyFormItem={copyFormItem}
                            removeFormItem={removeFormItem}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            {props.hideSubmit ||
                (formItems.current.length > 0 && ( // form item이 로드가 되었을 때부터 출력
                    <div className="submit-button-wrap notosanskr-400 font-size-18">
                        <button
                            className="submit-button"
                            onClick={() => {
                                updateI18nFromFormFields(formItems.current, displayLang.current);

                                const valid = validateForms();
                                if (valid) setState({ ...state, popup: POPUP.Request });
                            }}
                        >
                            {t("540")}
                        </button>
                    </div>
                ))}
        </div>
    );
});

export default DynamicForm;
