import "./VendorUI3.css";
import { useCallback } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageDropZone from "../../common/ImageDropZone";
import ImagePreviewWindow from "../../common/ImagePreviewWindow";
import { POPUP as GeneralPopup } from "../../../common/defines";
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ImagePreview: 1005,
};

const VendorUI3View = ({
    title,
    description,
    type,
    invalid,
    errorMessage,
    tempValue,
    max_item_count,
    popup,
    popupTarget,
    onDrop,
    removeFile,
    onClickHandle,
    onPopupConfirm,
    onPopupCancel
}) => {
    const { t } = useTranslation();
    const FileOrDropZone = useCallback(({ index }) => {
        const file = tempValue.current[index]?.file;

        return (
            <>
                <Col className="col-12">
                    <div>{t("531")}{index + 1}</div>
                </Col>
                {file ?
                    <Col className="file col-12" key={file.url + file.name + file.size}>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{file.name}</Tooltip>}>
                            <div>
                                <button className="remove-picture" onClick={() => removeFile(index)} />
                                <img
                                    src={file.thumbnail_url || file.url }
                                    alt=""
                                />
                            </div>
                        </OverlayTrigger>
                    </Col>
                    :
                    <Col className={`image-drop-zone ${invalid} col-12`}>
                        <ImageDropZone
                            name={type}
                            ref={onDrop.bind(this, index)}
                            maxFiles={1}
                            multiple={false}
                            droppableText=""
                            droppingText=""
                        />
                    </Col>
                }
            </>
        );
    }, [onDrop, removeFile, tempValue, type, invalid, t]);

    const Button = useCallback(({ index }) => {
        const value = tempValue.current[index];
        const disabled = value ? value?.file?.url === `${process.env.PUBLIC_URL}/Rolling-1.4s-200px.gif`: true;
        return (
            <button
                className="button"
                disabled={disabled}
                onClick={() => onClickHandle(index)}
            >
                {t("532").split('\n').map((v, i) => <div key={i}>{v}</div>)}
            </button>
        );
    }, [onClickHandle, tempValue, t]);

    if (popup === POPUP.ImagePreview) {
        const { questions, answers, images, index, bboxes } = popupTarget;

        return (
            <ImagePreviewWindow
                type="edit"
                questions={questions}
                answers={answers}
                images={images}
                index={index}
                bboxes={bboxes || undefined}
                onConfirm={onPopupConfirm}
                onCancel={onPopupCancel}
            />
        );
    }
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                <Row className="gx-0 w-100">
                    <label className="c-f00 top-description">
                        {t("658").split('\n').map((v, i) => <div key={i}>{v}</div>)}
                    </label>
                </Row>
                {Array(max_item_count).fill(null).map((_, i) => (
                    <Row className="gx-0 wrap" key={i}>
                        <FileOrDropZone
                            index={i}
                        />
                        <Button
                            index={i}
                        />
                    </Row>
                ))}
                <Row className="gx-0 w-100">
                    <label className="error">
                        {errorMessage}
                    </label>
                    {description && <label>{description}</label>}
                </Row>
            </Col>
        </>
    );
};

export default VendorUI3View;
