import React, { forwardRef } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const ImageDropZone2 = forwardRef(({
    maxFiles=10, // 최대 업로드 가능 파일 수
    multiple=true,
    imgId=undefined, // wrapper tag 의 id 값
    inputFileId, // file tag 의 id 값
    className,
    placeholder,
    buttonText=undefined,
    accept="image/*",
    name,
    disabled=false,
}, onDrop) => {
    const { t } = useTranslation();

    buttonText || (buttonText = t("98"));

    const options = {
        accept: accept,
        maxFiles: maxFiles,
        onDrop: onDrop,
        noClick: true,
        noKeyboard: true,
        multiple: multiple,
        disabled : disabled,
    };

    const { getRootProps, getInputProps, open } = useDropzone(options);

    return (
        <div id={imgId} className="d-flex flex-row">
            <div {...getRootProps()}>
                <input id={inputFileId} {...getInputProps()} name={name} />
                <div className={className}>
                    {placeholder}
                </div>
            </div>
            <div>
                <button htmlFor={inputFileId} className="notosanskr-15 notosanskr-12:sm c-white" onClick={open}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
});

export default ImageDropZone2;
