import { AddressSearch } from "./common/AddressSearch";

export default function PostcodeWindow(props) {
    return (
        <AddressSearch
            onComplete={props.onComplete}
            onCancel={props.onCancel}
        />
    );
}
