import "./Radio.css";
import { useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import CustomCheckbox from "../../common/CustomCheckbox";
import { InputText } from "../../Input";
import common from "../../../common/common";
import { strings } from "@ocean-knight/shared";

const RadioView = ({
    _id,
    title,
    description,
    type,
    defaultValue,
    invalid,
    errorMessage,
    sub_items,
    onChangeHandle,
    onBlurHandle,
    textDisabled,
    textDefaultValue
}) => {
    const RadioItem = useCallback(({ sub_item, isCustomString = false, invalid }) => {
        const textDefaultValueResult = textDefaultValue(sub_item._id);
        const textDisabledResult = textDisabled(sub_item._id);
        const lang = common.getLang();
        return (
            <Col className={type}>
                <CustomCheckbox
                    inputClassName={invalid}
                    type="radio"
                    name={_id}
                    defaultChecked={defaultValue.find(value => value._id === sub_item._id)}
                    onChange={onChangeHandle}
                    value={sub_item._id}
                >
                    <span>{isCustomString ? strings[lang]['163'] : sub_item.name}</span>
                </CustomCheckbox>
                {isCustomString &&
                    <InputText
                        key={textDisabledResult}
                        className={`custom-string ${invalid}`}
                        maxLength={20}
                        onBlur={(e) => onBlurHandle(e, sub_item._id)}
                        disabled={textDisabledResult}
                        defaultValue={textDefaultValueResult}
                    />
                }
            </Col>
        );
    }, [_id, type, defaultValue, onBlurHandle, onChangeHandle, textDisabled, textDefaultValue]);

    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                {description && <label className="top-description notosanskr-500 font-size-15">{description}</label>}
                <Row>
                    {sub_items.map((sub_item => (
                        <RadioItem
                            key={sub_item._id}
                            sub_item={sub_item}
                            isCustomString={sub_item.sub_item_type === "custom_string"}
                            invalid={invalid}
                        />
                    )))}
                </Row>
                <label className="error">
                    {errorMessage}
                </label>
            </Col>
        </>
    );
};

export default RadioView;
