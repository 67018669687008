import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { POPUP } from "../../common/defines";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Pagination from "../common/Pagination";
import Modal from "../common/Modal";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useNotification, useIsMount } from "../../common/customHook";
import { mutate } from "swr";
import "./ApproveDataUseRequest.css";
import CustomCheckbox from "../common/CustomCheckbox";
import {PopupRequestReportLicense} from "../common/RequestDataUseWindow";
import SiteMemberInfoWindow from "./ManageMember/SiteMemberInfoWindow";
import { IsMobile, IsPC } from "../common/MediaQuery";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import QueryString from 'qs';
import { APPROVE_DATA_USE_REQUEST_TAB_ID } from "@ocean-knight/shared/src/utility";

/**
 * 자료 사용권 거부를 눌렀을 때 호출 되는 popup
 */
export const PopupDataUseReject = ({onRequestClose, onConfirm, onCancel}) => {
    const header = <div>{i18next.t("418")}</div>;
    const body = <div>{i18next.t("419")}</div>;

    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            onCancel={onCancel}
            header={header}
            body={body}
            footer={i18next.t("415")}
            textareaId="data-use-approval-reason"
        />
    );
};

/**
 * 자료 사용권 거부를 확인 했을 때 호출 되는 popup
 */
export const PopupDataUseRejectReason = ({onRequestClose, onConfirm, reason}) => {
    const header = <div>{i18next.t("418")}</div>;
    const body = <div>{i18next.t("422")}</div>;

    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            header={header}
            body={body}
            reason={reason}
        />
    );
};

export function ApproveDataUseRequest() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing, APPROVE_DATA_USE_REQUEST_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing,
        popup: POPUP.None,
        popupTarget: null,
        groups: [],
        buttonDisabled: true,
    });
    const [reviews, setReviews] = useState([]);
    const [histories, setHistories] = useState([]);
    const isPc = IsPC();
    const { notifications } = useNotification();

    const ITEM_COUNT_PER_PAGE_ON_REVIEWING = 10;
    const ITEM_COUNT_PER_PAGE_ON_HISTORY = 10;

    const currentPage = useRef({ reviewingPage: 1, historyPage: 1,});
    const paginationOption = useRef({ itemsCountPerPage: ITEM_COUNT_PER_PAGE_ON_REVIEWING, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });

    const isMount = useIsMount();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        const hasPermission = common.hasGroupMemberPermission() | common.hasGroupManagerPermission() | common.hasSiteMemberPermission();
        if (!hasPermission) {
            if (!isMount.current) { return; }
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }

        const getContents = async () => {
            if (!isMount.current) { return; }
            try {
                const groups = await Api.getAllGroupList();
                setState((prev) => ({ ...prev, groups: groups }));
            } catch (e) {
                dgLogger.error(e)();
                setState(prev => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };

        getContents();
    }, [isMount, t]);

    useEffect(() => {
        if (!isMount.current) { return; }
        paginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        paginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        forceUpdate();
    }, [isPc, forceUpdate, isMount]);

    /**
     * 승인 혹은 거부를 눌렀을 때 자료 사용권의 상태를 갱신하는 함수
     *
     * @param {*} DataUse ??
     * @param {*} currentState 승인 혹은 거부
     * @param {*} reason 거부 사유
     */
    const updateReportUseState = async (DataUse, currentState, reason) => {
        const payload = {
            state: currentState,
            reason: reason,
            histories: [],
            lang: common.getLang(),
        };
        for (let i=0; i < reviews.length; i++){
            if (document.getElementById(`${i}`).checked)
                payload.histories.push(reviews[i]._id);
        }

        if (reviews.length === payload.histories.length) {
            if (currentPage.current.reviewingPage !== 1) {
                currentPage.current.reviewingPage--;
            }
        }

        await Api.updateReportLicenseState(payload);
        const reports = await getReportUseAll();

        const targetNotifications = notifications?.filter((notification) => payload.histories.includes(notification.item));
        if (targetNotifications) {
            await Api.removeNotifications(targetNotifications.map(notification => notification._id));
            mutate("useNotification");
        }

        return reports;
    };

    const popups = () => {
        if (state.popup === POPUP.Approve) return popupDataUseApprove();
        if (state.popup === POPUP.Reject) return <PopupDataUseReject
            onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            onConfirm={() => {
                const reason = document.getElementById("data-use-approval-reason").value;
                updateReportUseState(state.popupTarget, utility.STATE.REJECTED, reason)
                    .then(reports => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null, buttonDisabled: true });
                        state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing ?
                            setReviews(reports) :
                            setHistories(reports);
                    })
                    .catch(e => {
                        dgLogger.error(e)();
                        setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                    });
            }}
            onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
        />;
        if (state.popup === POPUP.RejectReason) return <PopupDataUseRejectReason
            onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            reason={state.popupTarget.state_reason}
        />;
        if (state.popup === POPUP.UserInfo) return popupUserInfo();
        if (state.popup === POPUP.RequestContent) return popupDataUseRequestContent();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const popupUserInfo = () => {
        const groupIds = state.popupTarget.groups.reduce((ids, cur) => {
            if (cur.group) ids.push(cur.group);
            return ids;
        }, []);

        return (
            <SiteMemberInfoWindow
                user={state.popupTarget}
                groups={state.groups.filter((group) => groupIds.includes(group._id))} // filter option 에 그룹이 빈값(전체) 라면 모든 그룹을
                onCancel={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
            />
        );
    };

    const popupDataUseRequestContent = () => {
        return (
            <PopupRequestReportLicense
                applicationInfo={state.popupTarget}
                onConfirm={() => setState({...state, popup: POPUP.None, popupTarget: null})}
            />
        );
    };

    /**
     * 자료 사용권 승인을 눌렀을 때 호출 되는 popup
     */
    const popupDataUseApprove = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_public_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_public_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_public_complete@3x.png 3x`} alt="" />;
        const header = <div>{t("416")}</div>;
        const body = <div>{t("417")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    updateReportUseState(state.popupTarget, utility.STATE.ACQUIRED, null)
                        .then(reports => {
                            setState({ ...state, popup: POPUP.None, popupTarget: null, buttonDisabled: true });
                            state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing ?
                                setReviews(reports) :
                                setHistories(reports);
                        })
                        .catch(e => {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                        });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    /**
     * 승인 내역의 처리 상태를 표시하는 함수
     *
     * @param {}} item
     * @returns
     */
    const processedState = (item) => {
        if (item.state === utility.STATE.ACQUIRED)
            return (
                <button className="notosnaskr-500 font-size-15 lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#4270e0", cursor: "default" }}>
                    {t("377")}
                </button>
            );
        else
            return (
                <button
                    className="notosnaskr-500 font-size-15 lh-1.2 ls--0.75px c-white"
                    style={{ backgroundColor: item.state === utility.STATE.REVIEWING ? "#87ac2f" : "#636363" }}
                    onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}
                >
                    {t("421")}
                </button>
            );
    };

    /**
     * 전체 checkbox를 클릭 했을 때 실행되는 함수
     */
    const allCheck = () => {
        const flag = document.getElementById("allCheckbox").checked;
        for (let i=0; i < paginationOption.current.itemsCountPerPage; i++){
            if (document.getElementById(`${i}`))
                document.getElementById(`${i}`).checked = flag;
        }

        setState({ ...state, buttonDisabled: !flag });
    };

    /**
     * 승인 대기 tab에 있는 경우 모든 checkbox를 체크 해제 하는 함수
     */
    const resetCheckbox = useCallback(() => {
        if (state.activeTab !== APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing) return;

        const allCheckbox = document.getElementById("allCheckbox");
        if (allCheckbox) allCheckbox.checked = false;

        for (let i=0; i < paginationOption.current.itemsCountPerPage; i++){
            const option = document.getElementById(`${i}`);
            if (option) option.checked = false;
        }

        setState((prev) => ({ ...prev, buttonDisabled: true }));
    }, [state.activeTab]);

    /**
    * 현재 사용자에게 자료 사용권 요청한 내역을 불러오는 함수
    */
    const getReportUseAll = useCallback((async () => {
        const page = state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing
            ? currentPage.current.reviewingPage
            : currentPage.current.historyPage;
        const payload = {
            // tab : state.activeTab,
            states: (state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing) ? [utility.STATE.REVIEWING] : [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
            page: page,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            sort: (state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing) ? { requested_date: -1 } : { state_confirmed_date: -1 },
        };

        const response = await Api.getReportLicenseAll(payload);
        paginationOption.current.totalItemsCount = response.count;
        resetCheckbox();

        return response.reports;
    }), [state.activeTab, resetCheckbox]);

    /**
    * page를 변경 할 때 호출 되는 함수
    */
    const pageChange = useCallback((page) => {
        if (state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing) {
            currentPage.current.reviewingPage = page;
            resetCheckbox();
        }
        else {
            currentPage.current.historyPage = page;
        }

        getReportUseAll()
            .then(reports => {
                if (!isMount.current) return;
                (state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing ?
                    setReviews(reports) :
                    setHistories(reports)
                );
            })
            .catch(e => {
                dgLogger.error(e)();
                setState(prev => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            });
    }, [state.activeTab, getReportUseAll, resetCheckbox, isMount]);

    useEffect(() => {
        if (!isMount.current) { return; }
        setState((prev) => ({ ...prev, buttonDisabled: true }));

        getReportUseAll()
            .then(reports => {
                if (!isMount.current) return;
                (state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing) ? setReviews(reports) : setHistories(reports);
            })
            .catch(e => {
                dgLogger.error(e)();
                setState(prev => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            });
    }, [getReportUseAll, state.activeTab, isMount]);

    return (
        <div id="approve-data-use">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("352")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("357")}</div>
                </Col>
            </Row>
            <Tabs
                activeKey={state.activeTab}
                onSelect={(k) => {
                    k === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing
                        ? (paginationOption.current.itemsCountPerPage = ITEM_COUNT_PER_PAGE_ON_REVIEWING)
                        : (paginationOption.current.itemsCountPerPage = ITEM_COUNT_PER_PAGE_ON_HISTORY);
                    setState({ ...state, activeTab: k });
                }}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
            >
                <Tab className={"reviewing"} eventKey={APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing} title={t("413")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className={`item col-auto text-center ${isPc ? "w-80px" : "w-40px"}`}>
                            <CustomCheckbox
                                id={"allCheckbox"}
                                onChange={allCheck}
                                disabled={!reviews.length}
                            />
                        </Col>
                        <Col className="item text-center">{t("374")}</Col>
                        <Col className="item text-center">{t("410")}</Col>
                        <Col className="item text-center">{t("400")}</Col>
                    </Row>
                    {reviews.map((item, i) => (
                        <Row key={item._id} className="gx-0 item-row align-items-center notosanskr-500 font-size-16">
                            <Col className={`item col-auto text-center ${isPc ? "w-80px" : "w-40px"}`}>
                                <CustomCheckbox
                                    id={i}
                                    onChange={(_) => {
                                        let checked = 0;
                                        for (let i = 0; i < reviews.length; i++) {
                                            const element = document.getElementById(`${i}`);
                                            if (element && element.checked) {
                                                checked++;
                                            }
                                        }

                                        setState({ ...state, buttonDisabled: checked === 0 });
                                        document.getElementById("allCheckbox").checked = checked === reviews.length ? true : false;
                                    }}
                                />
                            </Col>
                            <Col className="item text-center">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item text-center"><Link to={`/Report/${item.report}`}>{item.title}</Link></Col>
                            <Col className="item text-center button">
                                <button
                                    className="c-white notosanskr-500 font-size-15"
                                    onClick={() => setState({...state, popup: POPUP.RequestContent, popupTarget: {
                                        nickname: item.request_user_nickname,
                                        name: item.name,
                                        email: item.email,
                                        contact: item.contact,
                                        files: item.files,
                                        cause: item.cause,
                                    }})}
                                >
                                    {t("400")}
                                    <img src={`${process.env.PUBLIC_URL}/arrow.png`} 
                                    srcSet={`${process.env.PUBLIC_URL}/arrow@2x.png 2x, ${process.env.PUBLIC_URL}/arrow@3x.png 3x`} 
                                    alt="" />
                                </button>
                            </Col>
                        </Row>
                    ))}

                    <Row className="gx-0 button-group mt-19px">
                        <Col className="text-end">
                            <button
                                id="request-report-license-approve"
                                className="notosanskr-400 font-size-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    setState({ ...state, popup: POPUP.Approve });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("377")}
                            </button>

                            <button
                                id="request-report-license-reject"
                                className="notosanskr-400 font-size-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    setState({ ...state, popup: POPUP.Reject });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("415")}
                            </button>
                        </Col>
                    </Row>

                    <Pagination
                        key={state.activeTab}
                        ref={pageChange}
                        page={state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing ? currentPage.current.reviewingPage : currentPage.current.historyPage} // 현재 페이지
                        marginPagesDisplayed={paginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
                <Tab className={"histories"} eventKey={APPROVE_DATA_USE_REQUEST_TAB_ID.histories} title={t("414")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item text-center">{t("374")}</Col>
                        <Col className="item text-center">{t("420")}</Col>
                        <Col className="item text-center">{t("400")}</Col>
                        <Col className="item text-center">{t("410")}</Col>
                        <Col className="item text-center">{t("376")}</Col>
                    </Row>
                    {histories.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className="item c-333 text-center">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item c-333 text-center">{new Date(item.state_confirmed_date).toLocaleDateString()}</Col>
                            <Col className="item c-333 text-center button">
                                <button
                                    className="c-white notosanskr-500 font-size-15"
                                    onClick={() => setState({...state, popup: POPUP.RequestContent, popupTarget: {
                                        nickname: item.request_user_nickname,
                                        name: item.name,
                                        email: item.email,
                                        contact: item.contact,
                                        files: item.files,
                                        cause: item.cause,
                                    }})}
                                >
                                    {t("400")}
                                    <img src={`${process.env.PUBLIC_URL}/arrow.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/arrow@2x.png 2x, ${process.env.PUBLIC_URL}/arrow@3x.png 3x`}
                                    alt="" />
                                </button>
                            </Col>
                            <Col className="item text-center">
                                <Link to={`/Report/${item.report}`}>
                                    {item.title}
                                </Link>
                            </Col>

                            <Col className="item text-center process-state">
                                {processedState(item)}
                            </Col>
                        </Row>
                    ))}
                    <Pagination
                        key={state.activeTab}
                        ref={pageChange}
                        page={state.activeTab === APPROVE_DATA_USE_REQUEST_TAB_ID.reviewing ? currentPage.current.reviewingPage : currentPage.current.historyPage} // 현재 페이지
                        marginPagesDisplayed={paginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
