import React from "react";
import Modal from "../../common/Modal";
import "./WithdrawGroupMemberWindow.css";
import { WithdrawMember } from "./WithdrawMemberWindow";
import { useTranslation } from "react-i18next";

const WithdrawGroupMember = (props) => {
    const { t } = useTranslation();
    return <WithdrawMember targets={props.users} onConfirmed={props.onConfirmed} onCancel={props.onCancel} className={props.className} title={t("435")} rejoin={false} />;
};

const WithdrawGroupMemberRequest = (props) => {
    const { t } = useTranslation();
    const users = props.users;
    const group = props.group;
    const withdrawReason = props.withdrawReason;
    const icon = <img src={process.env.PUBLIC_URL + `/pop_group_withdraw2.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_withdraw2@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_withdraw2@3x.png 3x`} alt="" />;
    const header = <div>{t("435")}</div>;
    const body = <div>{t("445")}</div>;

    return (
        <Modal
            onRequestClose={props.onCancel}
            onConfirm={() => props.onConfirmed(users, group, withdrawReason)}
            onCancel={props.onCancel}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const WithdrawGroupMemberRequested = (props) => {
    const { t } = useTranslation();
    const withdrawReason = props.withdrawReason;
    const icon = <img src={process.env.PUBLIC_URL + `/pop_group_withdraw2.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_withdraw2@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_withdraw2@3x.png 3x`} alt="" />;
    const header = <div>{t("435")}</div>;
    const body = (
        <>
            <div>{t("446")}</div>
            <div className="c-4270e0">{t("447")} {withdrawReason}</div>
        </>
    );

    return (
        <Modal
            onRequestClose={props.onConfirmed}
            onConfirm={props.onConfirmed}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export { WithdrawGroupMember, WithdrawGroupMemberRequest, WithdrawGroupMemberRequested };
