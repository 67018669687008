import Api from "../Api";
import StackTrace from "stacktrace-js";

const logLevel = Object.freeze({
    log: 1,
    debug: 2,
    info: 3,
    warn: 4,
    error: 5
});

const EXPORT_LOG_LEVEL = process.env.REACT_APP_EXPORT_LOG_LEVEL || logLevel.warn;
const OUTPUT_LOG_LEVEL = process.env.REACT_APP_OUTPUT_LOG_LEVEL || logLevel.info;

const dgLogger = {
    log: (...args) => log(logLevel.log, args),
    debug: (...args) => log(logLevel.debug, args),
    info: (...args) => log(logLevel.info, args),
    warn: (...args) => log(logLevel.warn, args),
    error: (...args) => log(logLevel.error, args),
    assert: (assertion, ...args) => {
        if (!assertion) {
            if (args.length === 0) args = [ 'Assertion failed' ];
            return log(logLevel.error, args);
        }
        return () => {};
    }
};

function log(level, args) {
    let levelKey = Object.keys(logLevel).find(key => logLevel[key] === level);

    if (level >= EXPORT_LOG_LEVEL) {
        const filter = (stackframe) => {
            if (stackframe.functionName === undefined) {
                stackframe.setFunctionName('(anonymous)');
            }
            if (stackframe.fileName === undefined) {
                stackframe.setFileName('(unknown)');
            }
            return (
                stackframe.functionName.search(/StackTrace|stackMapper/g) < 0
                && stackframe.fileName.search(/dgLogger/g) < 0
            );
        };

        const stackMapper = (stackframes) => stackframes.filter(filter).map((sf) => `    ${sf.toString()}`).join('\n');
        const processStackframes = (getMessage) => (stackframes) => {
            const stack = stackMapper(stackframes);
            const message = getMessage(args);
            logRecord({ level: levelKey, message: message, stack: stack });
        };

        if (args[0] instanceof Error) {
            StackTrace.fromError(args[0], { filter: true }).then(processStackframes((args) => args[0].message));
        } else {
            StackTrace.get({ filter: filter }).then(processStackframes((args) => formatString(...args)));
        }
    }

    if (process.env.NODE_ENV === "production" && level < OUTPUT_LOG_LEVEL) return () => {};
    else return console[levelKey].bind(null, ...args);
}

function formatString() {
    // 참조: https://jsfiddle.net/B1KMusic/dx52ywo4/
    let arg = Array.prototype.slice.call(arguments);

    if (arg.length === 0) return "";
    else if (typeof arg[0] === 'object') return arg.join(', ');

    let [ str, ...argv ] = arg;

    str = str.replace(/%%/g, "<<percents>>");

    for (let arg of argv) {
        if (typeof arg === 'object') arg = JSON.stringify(arg);
        str = str.replace(/%[A-Za-z]/, arg);
    }

    str = str.replace(/<<percents>>/g, "%");

    return str;
}

function logRecord(payload) {
    Api.frontEndLogger(payload)
        .then(() => {
            console.log("log record success");
        })
        .catch(() => {
            console.log("log record fail");
        });
}

export default dgLogger;
