import { isMobile, isAndroid, isIOS } from 'react-device-detect';

let mobileAppWebviewMessageEventHandler = undefined;
let handleMobileAppWebviewMessages = {};

/**
 * React Native mobile app 으로 부터 받은 event 처리를 위한 핸들러 등록
 */
const registerMobileAppWebviewMessageHandler = () => {
    if (!mobileAppWebviewMessageEventHandler) {
        mobileAppWebviewMessageEventHandler = (event) => {
            try {
                const parsedData = JSON.parse(event.data);
                handleMobileAppWebviewMessages[parsedData?.type]?.(parsedData);
            } catch {
                return console.log("can not handle event:", event);
            }
        };
    } else {
        return console.error("there is alreayd assigned`MobileApp` Message handlers");
    }

    if (window.ReactNativeWebView) {
        if (isAndroid) {
            document.addEventListener("message", mobileAppWebviewMessageEventHandler);
        }
        if (isIOS) {
            window.addEventListener("message", mobileAppWebviewMessageEventHandler);
        }
    }
};

/**
 * React Native mobile app 으로 부터 받은 event 처리를 위해 등록했던 핸들러 해제
 */
const unregisterMobileAppWebviewMessageHandler = () => {
    if (!mobileAppWebviewMessageEventHandler) {
        return console.info("there is no registered `MobileApp` Message handlers");
    }

    if (window.ReactNativeWebView) {
        if (isAndroid) {
            document.removeEventListener("message", mobileAppWebviewMessageEventHandler);
        }
        if (isIOS) {
            window.removeEventListener("message", mobileAppWebviewMessageEventHandler);
        }
    }

    mobileAppWebviewMessageEventHandler = undefined;
};

/**
 * React Native mobile app 으로 부터 받은 event 처리
 *  examples:
 *  login({provider, email, password});
 *  signup({provider, email, realname?, password});
 *  register-push-notification({_id, deviceToken, platform});
*/
const setHandleMobileAppWebviewMessages = (key, handler) => {
    handleMobileAppWebviewMessages[key] = handler;
};

/**
 * React Native Mobile app 에 json 데이터 전송
 */
const sendMessageToMobileAppWebview = (jsonObject) => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(jsonObject));
    }
};

/**
 * 현재 web site 가 React Native Mobile app 의 webview 에서 보여지고 있는지 체크
 */
const isRunOnMobileAppWebview = () => {
    return !!window.ReactNativeWebView;
};

export {
    registerMobileAppWebviewMessageHandler,
    setHandleMobileAppWebviewMessages,
    unregisterMobileAppWebviewMessageHandler,
    sendMessageToMobileAppWebview,
    isRunOnMobileAppWebview
};
