import "./File.css";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageDropZone from "../../common/ImageDropZone";
import { useTranslation } from "react-i18next";

const FileView = ({
    title,
    description,
    type,
    invalid,
    errorMessage,
    maxFiles,
    containFiles,
    representative,
    onDrop,
    removeFile,
    changeRepresentative,
    isRepresentative
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                <Row className="gx-0 w-100">
                    <label className="c-f00 top-description">
                        {t("658").split("\n").map((v, i) => <div key={i}>{v}</div>)}
                    </label>
                </Row>
                <Row className="gx-0">
                    <Col name={type} className={`image-drop-zone ${invalid}`}>
                        <ImageDropZone
                            name={type}
                            ref={onDrop}
                            maxFiles={maxFiles}
                            multiple={true}
                            droppableText=""
                            droppingText=""
                        />
                    </Col>
                    {containFiles.map((file) => (
                        <Col className="file" key={file.url + file.name + file.size}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{file.name}</Tooltip>}>
                                <div>
                                    {isRepresentative(file) && <button disabled className="representative">{t("149")}</button>}
                                    <button className="remove-picture" onClick={() => removeFile(file)} />
                                    <img
                                        src={file.thumbnail_url || file.url}
                                        alt=""
                                        onClick={() => changeRepresentative(file)}
                                    />
                                </div>
                            </OverlayTrigger>
                        </Col>
                    ))}
                </Row>
                <Row className="gx-0 w-100">
                    <label className="error">
                        {errorMessage}
                    </label>
                    {description && <label className="notosanskr-500 font-size-15">{description}</label>}
                </Row>
            </Col>
        </>
    );
};

export default FileView;
