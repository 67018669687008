import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const pathLength = pathname.split("/").length;
        if (pathLength < 4) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return null;
}
