import React, { useState, useEffect, useRef } from "react";
import Api from "../../../Api";
import { utility } from "@ocean-knight/shared";
import { POPUP as GeneralPopup } from "../../../common/defines";
import { useIsMount } from "../../../common/customHook";
import { MailTransferWindow, MailTransferCompletedWindow, MailTransferFailureWindow } from "../MailTransferWindow";
import SiteMemberInfoWindow from "./SiteMemberInfoWindow";
import Pagination from "./../../common/Pagination";
import { Row, Col } from "react-bootstrap";
import { WithdrawSiteMember, WithdrawSiteMemberRequest, WithdrawSiteMemberRequested } from "./WithdrawSiteMemberWindow";
import { MultiSelect } from "react-multi-select-component";
import CustomCheckbox from './../../common/CustomCheckbox';
import "./SiteMember.css";
import SortIcon from "../../common/SortIcon";
import { InputText } from "../../Input";
import DateBox from "../../common/DateBox";
import common from "../../../common/common";
import dgLogger from "../../../common/dgLogger";
import { useTranslation } from "react-i18next";

const ID = {
    filterName: "site-member-manage-filter-name",
    filterGroup: "site-member-manage-filter-group",
    filterGrade: "site-member-manage-filter-grade",
    filterOrg: "site-member-manage-filter-organization",
    // withdrawReason: "site-member-manage-withdraw-reason",
    // withdrawWithBan: "site-member-manage-withdraw-with-ban",


    checkAll: "site-member-manage-items-check-all",
    resultName: "site-member-manage-filter-result-name",
    resultGrade: "site-member-manage-filter-result-grade",
    resultOrganization: "site-member-manage-filter-result-organization",
    resultRegisterDate: "site-member-manage-filter-result-register-date",
    resultLatestVisit: "site-member-manage-filter-result-latest-visit",
    resultNumberOfComments: "site-member-manage-filter-result-number-of-comments",
    resultNumberOfReports: "site-member-manage-filter-result-number-of-reports",
};

const POPUP = {
    ...GeneralPopup,
    WithdrawGroupMemberRequest: 1038,
    WithdrawGroupMemberRequested: 1039,
    WithdrawSiteMemberRequest: 1044,
    WithdrawSiteMemberRequested: 1045,
    UserInfoUpdated: 1048,
};

export default function ManageSiteMember() {
    const { t } = useTranslation();
    const isMount = useIsMount();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        filteredOptions: {
            // 검색에 사용한 옵션 (사용자 정보를 변경후에 기존 옵션으로 다시 서버에서 정보를 얻어올 필요가 있을 수 있음)
            name: null, // string
            groups: [], // [_id]
            startDate: "",
            endDate: "",
            userTypes: [], // [utility.USER_MANAGE_GRADE]
            currentPage: 1, // 현재 보고있는 페이지
            sort: { register_date: -1 } // 정렬 기준 (등록일)
        },
        groups: [], // 모든 (active) 그룹 목록
        users: [], // 검색된 사용자 목록
        enableButtons: false // '회원 정지', '메일 발송' 등의 버튼 활성/비활성화 상태
    });
    const paginationOption = useRef({ itemsCountPerPage: 10, pageRangeDisplayed: 10, totalItemsCount: 0 });

    const [prevDate, setPrevDate] = useState({startDate: state.filteredOptions.startDate, endDate: state.filteredOptions.endDate, date: t("60")});

    useEffect(() => {
        document.getElementById(ID.checkAll).disabled = true;
    }, []);

    useEffect(() => {
        Api.getAllGroupList()
            .then((payload) => {
                if (!isMount.current) return;
                setState((prev) => ({ ...prev, groups: payload }));
            })
            .catch((e) => dgLogger.error(e)());
    }, [isMount]);

    /**
     * 현재 웹 페이지의 filter 항목에서 선택된 값들을 획득하여 반환합니다.
     *
     * @returns 현재 웹 페이지에서 선택된 값들
     */
    const getFilterOptions = () => {
        const name = document.getElementById(ID.filterName).value;
        const groups = state.filteredOptions.groups;
        const userTypes = state.filteredOptions.userTypes;
        const startDate = document.getElementById("startDate").value;
        const endDate = document.getElementById("endDate").value;

        return [name, groups, userTypes, startDate, endDate];
    };

    /**
     * parameter로 전달된 filter 에 매칭되는 사용자 정보를 획득하여 state 를 갱신합니다.
     *
     * @param {string} name 검색할 그룹원 이름
     * @param {string[]} groups 검색할 그룹 목록
     * @param {string[]} userTypes 검색할 일반 회원 / 그룹 매니저
     * @param {string} startDate 자료 등록 시작일 filter
     * @param {string} endDate 자료 등록 종료일 filter
     * @param {number} page pagination 시 보는 페이지 번호
     * @param {Object} sort 정렬 기준
     * @param {Object} extraState state 를 갱신할때 spread 로 추가할 state
     */
    const getFilteredUserInfoSummaryInDateRange = (name, groups, userTypes, startDate, endDate, page, sort, extraState = {}) => {
        const newStartDate = startDate && common.convertToIsoDate(startDate);
        const newEndDate = endDate && common.convertToIsoDate(endDate, 1);

        Api.getFilteredUser({
            name: name,
            groups: groups,
            userTypes: userTypes,
            startDate: newStartDate,
            endDate: newEndDate,
            currentPage: `${page}`,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            sort: sort
        })
            .then((payload) => {
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                if (paginationOption.current.totalItemsCount) document.getElementById(ID.checkAll).disabled = false;
                setState({
                    ...state,
                    users: payload.users,
                    filteredOptions: {
                        name: name,
                        groups: groups,
                        userTypes: userTypes,
                        startDate: newStartDate,
                        endDate: newEndDate,
                        currentPage: page,
                        sort: sort,
                    },
                    ...extraState,
                });
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    const getGroupMemberGrade = (user) => {
        if (user.groups.length === 0) return t("493");
        const listOfGradeDesc = user.groups.reduce((gradeDesc, group) => {
            const permission = user.permissions.find((permission) => permission.group === group._id);
            if (!permission) return gradeDesc;
            gradeDesc.push(`${common.i18nGroupName(group.name)}(${permission.grade_name})`);
            return gradeDesc;
        }, []);
        return listOfGradeDesc.map((desc, i) => (
            <React.Fragment key={i}>
                {desc}
                <br />
            </React.Fragment>
        ));
    };

    const popupUserInfo = () => {
        const groupIds = state.popupTarget.groups.reduce((ids, cur) => {
            if (cur.group) ids.push(cur.group);
            return ids;
        }, []);
        return (
            <SiteMemberInfoWindow
                user={state.popupTarget}
                groups={state.groups.filter((group) => groupIds.includes(group._id))} // filter option 에 그룹이 빈값(전체) 라면 모든 그룹을
                onSave={(updateUserInfoBody) => {
                    if (Object.keys(updateUserInfoBody).length === 0) { // 변경사항 없이 확인 저장을 누른경우. (다만, 그룹등이 삭제되었을 수 있으므로, filter 결과를 갱신할 필요가 있습니다.)
                        const filteredOptions = state.filteredOptions;
                        const page = filteredOptions.currentPage;
                        getFilteredUserInfoSummaryInDateRange(
                            filteredOptions.name,
                            filteredOptions.groups,
                            filteredOptions.userTypes,
                            filteredOptions.startDate,
                            filteredOptions.endDate,
                            page, filteredOptions.sort,
                            {
                                popup: POPUP.None, popupTarget: null
                            }
                        );
                        return;
                    }

                    Api.updateUserInfo(updateUserInfoBody)
                        .then(() => {
                            const filteredOptions = state.filteredOptions;
                            const page = filteredOptions.currentPage;
                            getFilteredUserInfoSummaryInDateRange(
                                filteredOptions.name,
                                filteredOptions.groups,
                                filteredOptions.userTypes,
                                filteredOptions.startDate,
                                filteredOptions.endDate,
                                page,
                                filteredOptions.sort,
                                {
                                    popup: POPUP.None, popupTarget: null
                                }
                            );
                        })
                        .catch((e) => {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        });
                }}
                onCancel={() => {
                    const filteredOptions = state.filteredOptions;
                    const page = filteredOptions.currentPage;
                    getFilteredUserInfoSummaryInDateRange(
                        filteredOptions.name,
                        filteredOptions.groups,
                        filteredOptions.userTypes,
                        filteredOptions.startDate,
                        filteredOptions.endDate,
                        page,
                        filteredOptions.sort,
                        {
                            popup: POPUP.None, popupTarget: null
                        }
                    );
                }}
                onWithdrawn={() => {
                    const filteredOptions = state.filteredOptions;
                    const needToMovePreviousPage = filteredOptions.currentPage > 1 && state.users.length - 1 === 0; // has no item to display. then move previous page
                    const page = needToMovePreviousPage ? filteredOptions.currentPage - 1 : filteredOptions.currentPage;
                    getFilteredUserInfoSummaryInDateRange(
                        filteredOptions.name,
                        filteredOptions.groups,
                        filteredOptions.userTypes,
                        filteredOptions.startDate,
                        filteredOptions.endDate,
                        page,
                        filteredOptions.sort,
                        {
                            popup: POPUP.None, popupTarget: null
                        }
                    );
                }}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.UserInfo) return popupUserInfo();
        if (state.popup === POPUP.WithdrawSiteMember) {
            return (
                <WithdrawSiteMember
                    users={state.popupTarget}
                    onConfirmed={(withdrawReason, banned) => {
                        setState({
                            ...state,
                            popup: POPUP.WithdrawSiteMemberRequest,
                            popupTarget: {
                                users: state.popupTarget,
                                reason: withdrawReason,
                                banned: banned,
                            },
                        });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawSiteMemberRequest) {
            return (
                <WithdrawSiteMemberRequest
                    onConfirmed={() => {
                        dgLogger.assert(state.popupTarget.users.length >= 1)();
                        dgLogger.assert(state.popupTarget.reason)();

                        Api.withdrawSiteMembers({
                            users: state.popupTarget.users.map((user) => user._id), // _id
                            reason: state.popupTarget.reason, // string (withdraw reason)
                            // banned: state.popupTarget.banned, // boolean (banned? or not)
                            banned: true,
                            lang: common.getLang()
                        })
                            .then(() => {
                                const filteredOptions = state.filteredOptions;
                                const needToMovePreviousPage = filteredOptions.currentPage > 1 && state.users.length - state.popupTarget.users.length === 0; // has no item to display. then move previous page
                                const page = needToMovePreviousPage ? filteredOptions.currentPage - 1 : filteredOptions.currentPage;
                                getFilteredUserInfoSummaryInDateRange(
                                    filteredOptions.name,
                                    filteredOptions.groups,
                                    filteredOptions.userTypes,
                                    filteredOptions.startDate,
                                    filteredOptions.endDate,
                                    page,
                                    filteredOptions.sort,
                                    {
                                        popup: POPUP.WithdrawSiteMemberRequested,
                                    }
                                );
                            })
                            .catch((e) => dgLogger.error(e)());
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawSiteMemberRequested) {
            return (
                <WithdrawSiteMemberRequested
                    reason={state.popupTarget.reason}
                    onConfirmed={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.MailTransfer) {
            return (
                <MailTransferWindow
                    users={state.popupTarget}
                    onSend={(data) => {
                        Api.sendMailToGroupMembers(data)
                            .then(() => {
                                setState({ ...state, popup: POPUP.MailTransferCompleted, popupTarget: null });
                            })
                            .catch((err) => {
                                dgLogger.error(err)();
                                setState({ ...state, popup: POPUP.MailTransferFailure, popupTarget: err.toString() });
                            });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onClose={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.MailTransferCompleted) {
            return (
                <MailTransferCompletedWindow
                    onClose={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.MailTransferFailure) {
            return (
                <MailTransferFailureWindow
                    onClose={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
    };

    const resetSortFlag = (except = null) => {
        const resultName = document.getElementById(ID.resultName);
        if (!except || resultName !== except) resultName.asc = null;
        const resultGrade = document.getElementById(ID.resultGrade);
        if (!except || resultGrade !== except) resultGrade.asc = null;
        // const resultOrganization = document.getElementById(ID.resultOrganization);
        // if (!except || resultOrganization !== except) resultOrganization.asc = null;
        const resultRegisterDate = document.getElementById(ID.resultRegisterDate);
        if (!except || resultRegisterDate !== except) resultRegisterDate.asc = null;
        const resultLatestVisit = document.getElementById(ID.resultLatestVisit);
        if (!except || resultLatestVisit !== except) resultLatestVisit.asc = null;
        const resultNumberOfComments = document.getElementById(ID.resultNumberOfComments);
        if (!except || resultNumberOfComments !== except) resultNumberOfComments.asc = null;
        const resultNumberOfReports = document.getElementById(ID.resultNumberOfReports);
        if (!except || resultNumberOfReports !== except) resultNumberOfReports.asc = null;
    };

    const clearCheckboxes = () => {
        document.getElementById(ID.checkAll).checked = false;
        state.users.forEach((user) => {
            document.getElementById(user._id).checked = false;
        });
    };

    const handleSort = (e, sortKey) => {
        if (!state.users || state.users.length === 0) return;
        clearCheckboxes();
        resetSortFlag(e.target);
        e.target.asc = !e.target.asc;
        const filteredOptions = state.filteredOptions;
        const sort = {};
        sort[sortKey] = e.target.asc ? 1 : -1;
        getFilteredUserInfoSummaryInDateRange(
            filteredOptions.name,
            filteredOptions.groups,
            filteredOptions.userTypes,
            filteredOptions.startDate,
            filteredOptions.endDate,
            filteredOptions.currentPage,
            sort
        );
    };

    const findFilteredMember = () => {
        clearCheckboxes();
        resetSortFlag();
        const [name, groups, userTypes, startDate, endDate] = getFilterOptions();
        getFilteredUserInfoSummaryInDateRange(
            name,
            groups,
            userTypes,
            startDate,
            endDate,
            1 /*first page*/,
            { register_date: -1 }
        );
    };

    const USER_TYPES = [
        { label: t("493"), value: utility.USER_MANAGE_GRADE.SITE_MEMBER },
        { label: t("464"), value: utility.USER_MANAGE_GRADE.GROUP_MANAGER },
    ];

    return (
        <div id="manage-site-member">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("350")}</Col>
            </Row>

            <div className="filter-wrap">
                <Row className="gx-0 notosanskr-400 font-size-14">
                    <Col className="filter-inputs-wrap">
                        <Row className="gx-0">
                            <Col className="group">
                                <MultiSelect
                                    id={ID.filterGroup}
                                    options={state.groups.map((group) => ({ label: common.i18nGroupName(group.name), value: group._id }))}
                                    value={state.groups.reduce((acc, cur) => {
                                        if (state.filteredOptions.groups.includes(cur._id)) acc.push({ label: common.i18nGroupName(cur.name), value: cur._id });
                                        return acc;
                                    }, [])}
                                    onChange={(values) => {
                                        setState({ ...state, filteredOptions: { ...state.filteredOptions, groups: values.map((item) => item.value) } });
                                    }}
                                    disableSearch={true}
                                    overrideStrings={{ selectSomeItems: t("38"), selectAll: t("404"), selectAllFiltered: t("404"), allItemsAreSelected: t("71") }}
                                />
                            </Col>
                            <Col className="grade">
                                <MultiSelect
                                    id={ID.filterGrade}
                                    options={USER_TYPES}
                                    value={USER_TYPES.reduce((acc, cur) => {
                                        if (state.filteredOptions.userTypes.includes(cur.value)) acc.push(cur);
                                        return acc;
                                    }, [])}
                                    onChange={(values) => {
                                        setState({ ...state, filteredOptions: { ...state.filteredOptions, userTypes: values.map((item) => item.value) } });
                                    }}
                                    disableSearch={true}
                                    overrideStrings={{ selectSomeItems: t("688"), selectAll: t("404"), selectAllFiltered: t("404"), allItemsAreSelected: t("71") }}
                                />
                            </Col>
                            <Col className="date col">
                                <DateBox
                                    startDate={prevDate.startDate}
                                    endDate={prevDate.endDate}
                                    date={prevDate.date}
                                    onChange={(startDate, endDate, date) => setPrevDate({ startDate, endDate, date })}
                                />
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="name col mt-7px">
                                <InputText id={ID.filterName} placeholder={t("90")} onKeyPress={(e) => e.key === "Enter" && findFilteredMember()} />
                            </Col>
                            <Col className="col mt-7px mr-7px"></Col>
                            <Col className="reset col mt-7px notosanskr-400 font-size-16 text-end">
                                <button
                                    className="reset lh-1.5 ls--0.8px"
                                    onClick={() => {
                                        document.getElementById(ID.filterName).value = "";
                                        setState({ ...state, filteredOptions: { ...state.filteredOptions, groups: [], userTypes: [] } });
                                    }}
                                >
                                    {t("69")}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="find col-auto notosanskr-400 font-size-16">
                        <button
                            className="find lh-1.5 ls--0.8px"
                            onClick={findFilteredMember}
                        >
                            {t("70")}
                        </button>
                    </Col>
                </Row>
            </div>

            <div>
                <table className="result">
                    <thead>
                        <tr className="notosanskr-500 font-size-18 lh-1.33 ls--0.9px">
                            <th className="checkbox">
                                <CustomCheckbox
                                    id={ID.checkAll}
                                    onChange={(e) => {
                                        state.users.forEach((user) => {
                                            document.getElementById(user._id).checked = e.target.checked;
                                        });
                                        setState({ ...state, enableButtons: e.target.checked });
                                    }}
                                />
                            </th>
                            <th
                                id={ID.resultName}
                                className="name"
                                onClick={(e) => {
                                    handleSort(e, "name");
                                }}
                            >
                                {t("90")} <SortIcon/>
                            </th>
                            <th
                                id={ID.resultGrade}
                                className="grade maxw-400px text-start ps-2"
                                onClick={(e) => {
                                    handleSort(e, "hightestGrade");
                                }}
                            >
                                {t("492")} <SortIcon/>
                            </th>
                            {/* <th
                                id={ID.resultOrganization}
                                className="organization"
                                onClick={(e) => {
                                    handleSort(e, "organization");
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <div>
                                        자료활용
                                        <br />
                                        공익단체
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th> */}
                            <th
                                id={ID.resultRegisterDate}
                                className="registered-date"
                                onClick={(e) => {
                                    handleSort(e, "register_date");
                                }}
                            >
                                {t("430")} <SortIcon/>
                            </th>
                            <th
                                id={ID.resultLatestVisit}
                                className="last-visit"
                                onClick={(e) => {
                                    handleSort(e, "latest_visit");
                                }}
                            >
                                {t("431")} <SortIcon/>
                            </th>
                            <th
                                id={ID.resultNumberOfComments}
                                className="report-count"
                                onClick={(e) => {
                                    handleSort(e, "numberOfReports");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("432").split('\n').map((v, i) => <div key={i}>{v}</div>)}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                            <th
                                id={ID.resultNumberOfReports}
                                className="comment-count"
                                onClick={(e) => {
                                    handleSort(e, "numberOfComments");
                                }}
                            >
                                <div className="d-inline-flex align-items-center">
                                    <div>
                                        {t("433").split('\n').map((v, i) => <div key={i}>{v}</div>)}
                                    </div>
                                    <div>
                                        <SortIcon />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.users.map((user) => (
                            <tr key={user._id} className="notosanskr-500 font-size-16">
                                <td className="checkbox align-top">
                                    <CustomCheckbox
                                        id={user._id}
                                        onChange={(_) => {
                                            const checked = state.users.some((user) => document.getElementById(user._id).checked);
                                            if (!checked) document.getElementById(ID.checkAll).checked = false;
                                            setState({ ...state, enableButtons: checked });
                                        }}
                                    />
                                </td>
                                <td className="name align-top">
                                    <button
                                        onClick={() => {
                                            Api.getUserInfo({ _id: user._id })
                                                .then((payload) => setState({ ...state, popup: POPUP.UserInfo, popupTarget: payload }))
                                                .catch((e) => dgLogger.error(e)());
                                        }}
                                    >
                                        {user.name}
                                    </button>
                                </td>
                                <td className="grade align-top maxw-400px text-start ps-2">{getGroupMemberGrade(user)}</td>
                                {/* <td className="organization align-top">{user.organization?.length ? "O" : "X"}</td> */}
                                <td className="registered-date align-top">{new Date(user.register_date).toLocaleDateString()}</td>
                                <td className="last-visit align-top">{user.latest_visit ? new Date(user.latest_visit).toLocaleDateString() : new Date(user.register_date).toLocaleDateString()}</td>
                                <td className="report-count align-top">{user.numberOfReports}</td>
                                <td className="comment-count align-top">{parseInt(user.numberOfComments)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="text-end button-group notosanskr-400 font-size-16">
                    <button
                        className="withdraw"
                        disabled={!state.enableButtons}
                        onClick={() => {
                            const users = state.users.filter((user) => document.getElementById(user._id).checked);
                            if (users.length === 0) {
                                return dgLogger.warn("has no selected user")();
                            }
                            setState({ ...state, popup: POPUP.WithdrawSiteMember, popupTarget: users });
                        }}
                    >
                        {t("495")}
                    </button>
                    <button
                        className="send-mail"
                        disabled={!state.enableButtons}
                        onClick={() => {
                            const users = state.users.filter((user) => document.getElementById(user._id).checked);
                            if (users.length === 0) {
                                return dgLogger.warn("has no selected user")();
                            }
                            setState({ ...state, popup: POPUP.MailTransfer, popupTarget: users });
                        }}
                    >
                        {t("436")}
                    </button>
                    <button
                        className="send-mail"
                        disabled={state.users.length === 0}
                        onClick={() => {
                            const filteredOptions = state.filteredOptions;
                            Api.getFilteredUser({
                                name: filteredOptions.name,
                                groups: filteredOptions.groups,
                                userTypes: filteredOptions.userTypes,
                            }).then((payload) => {
                                setState({ ...state, popup: POPUP.MailTransfer, popupTarget: payload.users });
                            }).catch(e => dgLogger.error(e)());
                        }}
                    >
                        {t("437")}
                    </button>
                </div>

                <Pagination
                    ref={(newPage) => {
                        clearCheckboxes();
                        const filteredOptions = state.filteredOptions;
                        getFilteredUserInfoSummaryInDateRange(
                            filteredOptions.name,
                            filteredOptions.groups,
                            filteredOptions.userTypes,
                            filteredOptions.startDate,
                            filteredOptions.endDate,
                            newPage,
                            filteredOptions.sort);
                    }}
                    page={state.filteredOptions.currentPage}
                    itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                    totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                    pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                />
            </div>
        </div>
    );
}
