import React, { useState } from "react";
import Api from "../../../Api";
import { utility, dummy } from "@ocean-knight/shared";
import { POPUP as GeneralPopup } from "../../../common/defines";
import PostcodeWindow from "../../PostcodeWindow";
import { WithdrawGroupMember, WithdrawGroupMemberRequest, WithdrawGroupMemberRequested } from "./WithdrawGroupMemberWindow";
import PostcodeForm from "../../common/PostcodeForm";
import PhoneForm from "../../common/PhoneForm";
import { WithdrawSiteMember, WithdrawSiteMemberRequest, WithdrawSiteMemberRequested } from "./WithdrawSiteMemberWindow";
import { UserInfoUpdateWindow } from "../../common/UserInfoUpdateWindow";
import ReactModal from "react-modal";
import ProfileEmblem from "../../common/ProfileEmblem";
import { Button, Row, Col } from "react-bootstrap";
import "./SiteMemberInfoWindow.css";
import { InputText } from "../../Input";
import dgLogger from '../../../common/dgLogger';
import { useTranslation } from "react-i18next";
import common from "../../../common/common";

const ID = {
    profile: "site-member-manage-member-profile",
    mail: "site-member-manage-member-email",
    realname: "site-member-manage-member-realname",
    phone: "site-member-manage-member-phone",
    address1: "site-member-manage-member-address1",
    address2: "site-member-manage-member-address2",
    about: "site-member-manage-member-about",
    sns: "site-member-manage-member-sns",
    name: "site-member-manage-member-name",
    organization: "site-member-manage-member-organization",
    permission: "site-member-manage-grade-target",
};

const POPUP = {
    ...GeneralPopup,
    WithdrawGroupMemberRequest: 1038,
    WithdrawGroupMemberRequested: 1039,

    WithdrawSiteMemberRequest: 1044,
    WithdrawSiteMemberRequested: 1045,
};

/**
 * 회원 정보를 출력/갱신 합니다.
 *
 * @param {*} props properties
 *  이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  {
 *      user: user 정보,
 *      groups: 현재 화면에 표시되는 (유저가 속한) group(s) 정보,
 *      onSave: '저장' 버튼의 callback, // 만약 undefined 라면, 저장 버튼을 출력하지 않고, 개인 정보 수정할수 없음.
 *      onCancel: '취소' 버튼의 callback, // 만약 onSave 가 없다면, '닫기' 버튼으로 출력됨
 *      onWithdrawn: '탈퇴' 버튼의 후처리 callback, // 만약 undefined 라면, 탈퇴 버튼을 출력하지 않음.
 *  }
 *  ```
 * @returns 화면에 출력할 html
 */
export default function SiteMemberInfoWindow(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        user: props.user,
        popup: POPUP.None,
        popupTarget: null,
        avatarUrl: props.user.profile_picture ? props.user.profile_picture.url : null,
    });

    const isGroupManager = (user, group) => {
        dgLogger.assert(user)();
        dgLogger.assert(group)();
        dgLogger.assert(user.groups.length > 0)();
        const item = user.groups.find((item) => item.group === group._id);
        dgLogger.assert(item)();
        const permission = group.permissions.find((permission) => permission._id === item.permission);
        if (!permission) {
            dgLogger.error("failed to find proper grade as group member. something goes wrong. ignored.")();
            return false;
        }

        return utility.hasGroupManagerPermission(permission.grade);
    };

    const popupPostcode = () => {
        return (
            <div id={ID.postcode_popup}>
                <PostcodeWindow
                    onComplete={(address1, address2) => {
                        document.getElementById(ID.address1).value = address1;
                        document.getElementById(ID.address2).value = address2;
                        if (address1) document.getElementById(ID.address2).removeAttribute("disabled");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={() =>
                        setState({ ...state, popup: POPUP.None, popupTarget: null })
                    }
                />
            </div>
        );
    };

    const popupUserInfoUpdate = () => {
        return (
            <UserInfoUpdateWindow
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    props.onSave({
                        _id: state.user._id,
                        phone: state.popupTarget.phone,
                        address1: state.popupTarget.address1,
                        address2: state.popupTarget.address2,
                        about: state.popupTarget.about,
                        sns: state.popupTarget.sns,
                        profile_picture: state.popupTarget.profile_picture,
                        permission: state.popupTarget.permission,
                    });

                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Postcode) return popupPostcode();
        if (state.popup === POPUP.UserInfoUpdate) return popupUserInfoUpdate();
        if (state.popup === POPUP.WithdrawGroupMember) {
            return (
                <WithdrawGroupMember
                    users={[state.popupTarget.user]}
                    onConfirmed={(withdrawReason) => {
                        setState({ ...state, popup: POPUP.WithdrawGroupMemberRequest, popupTarget: { ...state.popupTarget, withdrawReason: withdrawReason } });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawGroupMemberRequest) {
            return (
                <WithdrawGroupMemberRequest
                    users={[state.popupTarget.user]}
                    group={state.popupTarget.group}
                    withdrawReason={state.popupTarget.withdrawReason}
                    onConfirmed={(users, group, withdrawReason) => {
                        Api.withdrawGroupMembers({
                            users: users.map((user) => user._id), // _id
                            group: group._id,
                            reason: withdrawReason, // string (withdraw reason)
                            lang: common.getLang()
                        })
                            .then(() => {
                                const user = state.user;
                                user.groups = user.groups.filter((item) => item.group !== group._id);
                                setState({ ...state, user: user, popup: POPUP.WithdrawGroupMemberRequested });
                            })
                            .catch((e) => dgLogger.error(e)());
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawGroupMemberRequested) {
            return (
                <WithdrawGroupMemberRequested
                    withdrawReason={state.popupTarget.withdrawReason}
                    onConfirmed={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawSiteMember) {
            return (
                <WithdrawSiteMember
                    users={state.popupTarget}
                    onConfirmed={(withdrawReason, banned) => {
                        setState({
                            ...state,
                            popup: POPUP.WithdrawSiteMemberRequest,
                            popupTarget: {
                                users: state.popupTarget,
                                reason: withdrawReason,
                                banned: banned,
                            },
                        });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawSiteMemberRequest) {
            return (
                <WithdrawSiteMemberRequest
                    onConfirmed={() => {
                        dgLogger.assert(state.popupTarget.users.length >= 1)();
                        dgLogger.assert(state.popupTarget.reason)();
                        Api.withdrawSiteMembers({
                            users: state.popupTarget.users.map((user) => user._id), // _id
                            reason: state.popupTarget.reason, // string (withdraw reason)
                            // banned: state.popupTarget.banned, // boolean (banned? or not)
                            banned: true,
                            lang: common.getLang()
                        })
                            .then(() => {
                                setState({ ...state, popup: POPUP.WithdrawSiteMemberRequested });
                            })
                            .catch((e) => dgLogger.error(e)());
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.WithdrawSiteMemberRequested) {
            return (
                <WithdrawSiteMemberRequested
                    reason={state.popupTarget.reason}
                    onConfirmed={() => {
                        props.onWithdrawn();
                    }}
                />
            );
        }
    };

    const onSave = async () => {
        const phone = document.getElementById(ID.phone).value;
        const address1 = document.getElementById(ID.address1).value;
        const address2 = document.getElementById(ID.address2).value;
        const about = document.getElementById(ID.about).value;
        const sns = document.getElementById(ID.sns).value;
        const permissions = state.user.groups // 바뀐 권한이 있을경우, [{group:`group._id`, permission: `permission._id`}] 형태로 반환받습니다.
            .reduce((acc, cur) => {
                acc.push({
                    group: props.groups.find((group) => group._id === cur.group),
                    userPermission: cur.permission,
                });
                return acc;
            }, [])
            .filter((item) => item.group)
            .map((item) => {
                // [origin, current] value pair
                const selectedOption = document.getElementById(item.group._id);
                const grade = selectedOption.options[selectedOption.selectedIndex].value;
                return [
                    {
                        // original
                        group: item.group._id,
                        isGroupManager: isGroupManager(state.user, item.group),
                    },
                    {
                        // current
                        group: item.group._id,
                        isGroupManager: grade === "manager" ? true : false,
                    },
                ];
            })
            .filter((pair) => pair[0].isGroupManager !== pair[1].isGroupManager) // origin 과 current 가 다른 항목들만 filter
            .map((pair) => {
                // current 에 해당하는 값으로 group 및 permission id 값을 dictionary list 로 만들어 반환
                const group = props.groups.find((group) => group._id === pair[1].group);
                let permission;
                if (pair[1].isGroupManager) {
                    permission = group.permissions.find((permission) => utility.hasGroupManagerPermission(permission.grade));
                    permission = permission._id;
                } else {
                    permission = group.default_permission;
                }
                return { group: pair[1].group, permission: permission };
            });

        do {
            if (state.user.phone !== phone) break;
            if (state.user.address1 !== address1) break;
            if (state.user.address2 !== address2) break;
            if (state.user.about !== about) break;
            if (state.user.sns !== sns) break;
            if (permissions.length > 0) break;

            return props.onSave({});
        } while (false);

        try {
            const data = {
                _id: state.user._id,
                phone: phone,
                address1: address1,
                address2: address2,
                about: about,
                sns: sns,
            };
            if (permissions.length > 0) data.permission = permissions; // [{group: `group._id`, permission: `permission._id`}]

            setState({ ...state, popup: POPUP.UserInfoUpdate, popupTarget: data });
            // dgLogger.info("prepared to update user info")();
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={props.onCancel}
            className={props.className ? props.className : "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"site-member-info"}
        >
            {popups()}
            <div className="title nanumsquareeb-18 nanumsquareeb-16:sm">{t("565")}
                {process.env.NODE_ENV !== "production" && (
                    <Button className="px-2 btn-secondary"
                        onClick={() => {
                            let r = dummy.randomSignupInfo();
                            document.getElementById(ID.phone).value = r.phone;
                            document.getElementById(ID.address1).value = r.address1;
                            document.getElementById(ID.address2).value = r.address2;
                            document.getElementById(ID.about).value = r.about;
                            document.getElementById(ID.sns).value = r.sns;
                        }}
                    >
                        Auto Randomize
                    </Button>)}
                <div>
                    <img src={process.env.PUBLIC_URL + `/icon_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`} onClick={props.onCancel} style={{ cursor: "pointer" }} alt="" />
                </div>
            </div>
            <div className="content">
                <Row>
                    <Col className="col-lg" sm={12}>
                        <div className="profile">
                            <ProfileEmblem
                                width={"121px"}
                                height={"121px"}
                                url={state.user && state.avatarUrl ? state.avatarUrl : undefined}
                                urlClass={"avatar"}
                                char={state.user && !state.avatarUrl ? state.user.name : undefined}
                                charClass={"avatar avatar-121 bg-secondary"}
                                profileId={ID.profile}
                            />
                            <div className="profile-name nanumsquareeb-24 text-break text-center">{state.user.name}</div>
                        </div>
                    </Col>
                    <Col className="col-lg" sm={12}>
                        <table className="site-info-table">
                            <tbody>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("284")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <InputText id={ID.mail} name="email" defaultValue={state.user.email} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("896")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <InputText id={ID.realname} name="realname" defaultValue={state.user.realname} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("327")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <PhoneForm phoneId={ID.phone} defaultValue={state.user.phone} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("329")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <PostcodeForm address1Id={ID.address1} address1DefaultValue={state.user.address1} address2Id={ID.address2} address2DefaultValue={state.user.address2} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("340")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <textarea id={ID.about} name="about" placeholder={t("341")} defaultValue={state.user.about} maxLength={50} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("342")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <InputText id={ID.sns} name="sns" placeholder={t("343")} defaultValue={state.user.sns} disabled={true} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquareb-18 nanumsquareb-14:sm">
                                        <div>
                                            {t("238")}
                                        </div>
                                    </td>
                                    <td className="notosanskr-14 notosanskr-13:sm">
                                        <div className="site-member-belong-group">
                                            <table>
                                                <tbody>
                                                    {state.user.groups
                                                        .filter((item) => item.group)
                                                        .reduce((acc, cur) => {
                                                            const group = props.groups.find((group) => group._id === cur.group);
                                                            if (group) {
                                                                acc.push({
                                                                    group: group,
                                                                    userPermission: cur.permission,
                                                                });
                                                            }
                                                            return acc;
                                                        }, [])
                                                        .map((item) => (
                                                            <tr key={item.group._id}>
                                                                <td>{common.i18nGroupName(item.group.name)}</td>
                                                                <td>
                                                                    <select className="site-member-belong-group-grade" id={item.group._id} name="grade" disabled={!props.onSave}>
                                                                        <option value="manager" selected={isGroupManager(state.user, item.group)}>
                                                                            {t("464")}
                                                                        </option>
                                                                        <option value="member" selected={!isGroupManager(state.user, item.group)}>
                                                                            {t("356")}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                {(props.onSave && !item.group?.built_in) &&
                                                                    <td>
                                                                        <button
                                                                            onClick={() => {
                                                                                setState({ ...state, popup: POPUP.WithdrawGroupMember, popupTarget: { user: state.user, group: item.group } });
                                                                            }}
                                                                        >
                                                                            <img src={process.env.PUBLIC_URL + `/icon_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`} alt=""/>
                                                                        </button>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))}
                                                    {/* {state.user.organization ? (
                                                <tr>
                                                    <td>
                                                    {state.user.organization.name}
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        {props.onSave ? (
                                                            <button
                                                                onClick={() => {
                                                                    let payload = {
                                                                        _id: state.user._id,
                                                                        organization: {
                                                                            exist: false,
                                                                        }, // remove organization
                                                                    };
                                                                    Api.updateUserInfo(payload)
                                                                        .then(() => {
                                                                            const user = state.user;
                                                                            user.organization = undefined;
                                                                            setState({ ...state, user: user });
                                                                        })
                                                                        .catch((e) => dgLogger.error(e)());
                                                                }}
                                                            >
                                                                <img src={process.env.PUBLIC_URL + `/icon_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`} />
                                                            </button>
                                                        ) : undefined}
                                                    </td>
                                                </tr>
                                            ) : undefined} */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className="button-group">
                    <Col>
                    </Col>
                    <Col className="text-center condition-button col-md" xs={12}>
                        {props.onSave &&
                            <button className="save nanumsquareb-14" onClick={onSave}>
                                {t("265")}
                                <img src={process.env.PUBLIC_URL + `/icon_redcheck.png`} srcSet={`${process.env.PUBLIC_URL}/icon_redcheck@2x.png 2x, ${process.env.PUBLIC_URL}/icon_redcheck@3x.png 3x`} alt="" />
                            </button>}
                        <button
                            className="cancel nanumsquareb-14"
                            onClick={() => {
                                props.onCancel();
                            }}
                        >
                            {props.onSave ? t("80") : t("566")}
                        </button>
                    </Col>
                    <Col className="text-end">
                        {props.onWithdrawn &&
                            <button
                                className="withdraw nanumsquareb-14"
                                onClick={() => {
                                    setState({ ...state, popup: POPUP.WithdrawSiteMember, popupTarget: [props.user] });
                                }}
                            >
                                {t("495")}
                                <img src={process.env.PUBLIC_URL + `/icon_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/icon_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/icon_withdraw@3x.png 3x`} alt="" />
                            </button>
                        }
                    </Col>
                </Row>
            </div>
        </ReactModal>
    );
}
