import React, { useState, useEffect, useRef, useCallback } from "react";
import Api from "../../Api";
import { POPUP as GeneralPopup } from "../../common/defines";
import "./RequestGroupCreation.css";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageDropZone from "../common/ImageDropZone";
import ImageDropZone2 from "../common/ImageDropZone2";
import PhoneForm from "../common/PhoneForm";
import { useIsMount } from "../../common/customHook";
import "./RequestGroupCreation.css";
import { InputText } from "../Input";
import { PopupValidateFile, PopupValidateImage } from "../common/ValidateModal";
import CustomCheckbox from "../common/CustomCheckbox";
import Timer from "../common/Timer";
import dgLogger from '../../common/dgLogger';
import { Trans, useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
};

const DUP_STATE = {
    NONE: 0,
    UNKNOWN: 1,
    DUPLICATED: 2,
    UNIQUE: 3,
};

const MAX_FILES = 10;
const MAX_SIZE = (1024 * 1024) * 200; // 200MB

export default function RequestGroupCreationContent({
    ID, // html element's ID
    onSubmit, // 신청하기 버튼을 눌렀을때의 callback
    applicationInfo, // 정보를 보여줄 group request 신청서 내용
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState({
        userInfo: {},
        certificateFiles: null,
        pictures: null, /*{ url: null, file: null, id: null, }*/
        representativePicture: null,

        popup: POPUP.None,
        popupTarget: null,
    });
    const isMount = useIsMount();
    const groupNameDuplicateState = useRef(DUP_STATE.NONE);
    const [errors, setErrors] = useState({ groupName: "", pictures: "", userName: "", phone: "", research: "", link: "", files: "", about: "" });

    useEffect(() => {
        const getContents = async () => {
            try {
                const userInfo = await (applicationInfo ? Api.getUserInfo({_id: applicationInfo.owner}): Api.getCurrentUserInfo());

                if (!isMount.current) return;
                setState((prev) => ({ ...prev, userInfo: userInfo }));
            } catch (e) {
                if (!isMount.current) return;
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };
        getContents();
    }, [isMount, applicationInfo]);

    // '소속' 파일이 첨부(변경)될 경우
    useEffect(() => {
        if (state.certificateFiles) {
            const errorCollection = {};
            const files = document.getElementsByClassName("create-group-files")[0];

            if (!state.certificateFiles.length) {
                errorCollection.files = { state: false, message: t("15") };
                files.classList.add("invalid");
            }
            else {
                errorCollection.files = { state: false, message: "" };
                files.classList.remove("invalid");
            }

            setErrors((prev) => ({ ...prev, ...errorCollection }));
        }
    }, [state.certificateFiles, t]);

    // '사진' 파일이 첨부(변경)될 경우
    useEffect(() => {
        if (state.pictures) {
            const errorCollection = {};
            const pictures = document.getElementsByClassName("create-group-pictures")[0];

            if (!state.pictures.length) {
                errorCollection.pictures = { state: false, message: t("15") };
                pictures.classList.add("invalid");
            }
            else {
                errorCollection.pictures = { state: false, message: "" };
                pictures.classList.remove("invalid");
            }
            setErrors((prev) => ({ ...prev, ...errorCollection }));
        }
    }, [state.pictures, t]);

    const verifyGroupNameDuplicateState = () => {
        if (groupNameDuplicateState.current !== DUP_STATE.UNIQUE) {
            if (groupNameDuplicateState.current === DUP_STATE.DUPLICATED) {
                return { state: false, message: t("385") };
            }
            else if (groupNameDuplicateState.current === DUP_STATE.UNKNOWN) {
                return { state: false, message: t("567") };
            }
            else {
                return { state: false, message: t("15") };
            }
        } else {
            return { state: true, message: t("568") };
        }
    };

    const validateFields = async () => {
        let flag = true;
        const errorCollection = {};
        const groupName = document.getElementById(ID.createGroupName);
        const userName = document.getElementById(ID.createGroupUserName);
        const contact = document.getElementById(ID.createGroupContact);
        const research = document.getElementById(ID.createGroupResearch);
        const link = document.getElementById(ID.createGroupRelLink);
        const pictures = document.getElementsByClassName("create-group-pictures")[0];
        const files = document.getElementsByClassName("create-group-files")[0];
        const about = document.getElementById(ID.createGroupAbout);

        errorCollection.groupName = await onDuplicateGroupName();
        if (groupNameDuplicateState.current !== DUP_STATE.UNIQUE ) {
            groupName.classList.add("invalid");
            flag = false;
        }
        else {
            groupName.classList.remove("invalid");
        }

        common.validateInputField(errorCollection, 'userName', userName) || (flag = false);
        common.validateInputField(errorCollection, 'phone', contact) || (flag = false);
        common.validateInputField(errorCollection, 'research', research) || (flag = false);
        common.validateInputField(errorCollection, 'link', link) || (flag = false);
        common.validateFileField(errorCollection, 'files', files, state.certificateFiles) || (flag = false);
        common.validateFileField(errorCollection, 'pictures', pictures, state.pictures) || (flag = false);
        common.validateInputField(errorCollection, 'about', about) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const onDuplicateGroupName = async () => {
        try {
            const name = document.getElementById(ID.createGroupName).value.trim();
            if (!name) {
                groupNameDuplicateState.current = DUP_STATE.NONE;
            }
            else {
                await Api.isDuplicatedGroupName({ name: name });
                groupNameDuplicateState.current = DUP_STATE.UNIQUE;
            }
        } catch (e) {
            groupNameDuplicateState.current = DUP_STATE.DUPLICATED;
        } finally {
            return verifyGroupNameDuplicateState();
        }
    };

    const onDropCertificateFiles = (acceptedFiles, fileRejections) => {
        // 모든 파일 업로드 가능
        const [, allFiles, result] = common.validateOnDrop(acceptedFiles, fileRejections, state.certificateFiles || [], MAX_FILES, MAX_SIZE);
        if (!result.valid) {
            return setState(prev => ({...prev,
                popup: POPUP.ValidateFileField,
                popupTarget: { // files 및 size 는 0 이하의 값일 경우, modal (popup) 에 에러메세지가 출력되지 않음.
                    MAX_FILES: (!result.maxFiles) ? MAX_FILES : -1,
                    MAX_SIZE: (!result.maxSize) ? MAX_SIZE / (1024 * 1024) : -1,
                } }));
        }

        setState({...state, certificateFiles:allFiles});
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const removeFileFromFileList = (elementId, targetFile) => {
        const dt = new DataTransfer();
        const input = document.getElementById(elementId);
        const { files } = input;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file !== targetFile)
            dt.items.add(file); // here you exclude the file. thus removing it.
        }

        input.files = dt.files; // Assign the updates list
    };

    const onDropRepresentativePicture = useCallback((acceptedFiles, fileRejections) => {
        // 이미지 파일 업로드 가능
        const [newFiles, , result] = common.validateOnDrop(
            acceptedFiles,
            fileRejections,
            state.pictures?.map(picture => picture.file) || [],
            MAX_FILES,
            MAX_SIZE,
            ["image/"]);
        if (!result.valid) {
            return setState(prev => ({...prev,
                popup: POPUP.ValidateFileField,
                popupTarget: { // files 및 size 는 0 이하의 값일 경우, modal (popup) 에 에러메세지가 출력되지 않음.
                    MAX_FILES: MAX_FILES,
                    MAX_SIZE: MAX_SIZE,
                    MIME_TYPE: (!result.mimeType) ? "image/*" : null
                }
            }));
        }

        const readFile = (files, pictures) => {
            if (files.length === 0) {
                // 만약 대표(representative_picture) 가 없다면, 현재 업로드된 이미지중, 첫번째 이미지를 대표 이미지로 설정함.
                setState((prev) => ({ ...prev, pictures: state.pictures?.concat(pictures) || pictures, representativePicture: state.representativePicture || pictures[0] }));

                return;
            }
            const file = files.shift();
            const reader = new FileReader();
            reader.onloadend = () => {
                const newPictures = [...pictures, { url: reader.result, file: file, }];
                readFile(files, newPictures);
            };
            reader.readAsDataURL(file);
        };
        (newFiles && newFiles.length > 0) && readFile(newFiles, []);
    }, [state.pictures, state.representativePicture]);

    /**
     *
     * @returns popup 상태에 따라 popup 호출
     */
    const popups = () => {
        if (state.popup === POPUP.ValidateFileField) {
            const target = state.popupTarget;
            if (target.MIME_TYPE) {
                return (
                    <PopupValidateImage
                        onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                    />
                );
            } else {
                return (
                    <PopupValidateFile
                        maxFiles={MAX_FILES}
                        maxSizeInMB={MAX_SIZE / (1024 * 1024)}
                        onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                    />
                );
            }
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    return (
        <>
            {popups()}
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("90")}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>{state.userInfo.name && <InputText id={ID.nickName} disabled={true} defaultValue={state.userInfo?.name} placeholder={t("90")} className="w-75"/>}</Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("370")}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                    <div>
                        <InputText
                            id={ID.createGroupName}
                            name="groupName"
                            defaultValue={applicationInfo?.name || ""}
                            disabled={!onSubmit}
                            required={true}
                            onChange={() => {
                                groupNameDuplicateState.current = DUP_STATE.NONE;
                            }}
                            onBlur={async (e) => {
                                if (groupNameDuplicateState.current === DUP_STATE.NONE && isValid([e.target])) {
                                    const errorCollection = {};
                                    errorCollection.groupName = await onDuplicateGroupName();
                                    if (groupNameDuplicateState.current !== DUP_STATE.UNIQUE) {
                                        e.target.classList.add("invalid");
                                    } else {
                                        e.target.classList.remove("invalid");
                                    }
                                    setErrors({ ...errors, ...errorCollection });
                                }
                            }}
                            maxLength={20}
                            className="w-75"
                        />
                    </div>
                    {errors?.groupName.message &&
                        (errors?.groupName?.state ? (
                            <div className="notosanskr-400 font-size-14 c-ccc mt-10px" id={ID.duplicatedName}>
                                {errors?.groupName?.message}
                            </div>
                        ) : (
                            <div className="notosanskr-400 font-size-14 c-f00 mt-10px" id={ID.duplicatedName}>
                                {errors?.groupName?.message}
                            </div>
                        ))}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("386")}</Col>
                    </Row>
                </Col>
                <Col className="item col-sm" xs={12}>
                    <Row className={`gx-0 align-items-top ${applicationInfo && "maxh-122px"}`} style={{ overflow: "auto" }}>
                        {applicationInfo ? (
                            applicationInfo.pictures.map((picture, idx) => (
                                <Col className="upload-images col-auto mb-12px" key={picture.url}>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{picture.name}</Tooltip>}>
                                        <div className="each-image">
                                            <div className="upload-image-wrap">
                                                {picture?.url === applicationInfo.representative_picture?.url && (
                                                    <button disabled className="representative notosanskr-400 font-size-14 c-white">
                                                        {t("149")}
                                                    </button>
                                                )}
                                                <a href={picture?.url} target="_blank" rel="noopener noreferrer">
                                                    <img id={picture?.url} src={picture?.url} alt="Representative" />
                                                </a>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                            ))
                        ) : (
                            <React.Fragment>
                                <Col className="upload-zone col-auto gx-0 mb-12px">
                                    <ImageDropZone
                                        accept={"image/*"}
                                        droppableText=""
                                        droppingText=""
                                        disabled={state.pictures?.length >= MAX_FILES}
                                        // maxFiles={MAX_FILES}
                                        imgId={ID.pictureDrop}
                                        inputFileId={ID.picturesInput}
                                        dropzoneClassName="create-group-pictures"
                                        ref={onDropRepresentativePicture}
                                    />
                                </Col>
                                <Col>
                                    <Row className="gx-0">
                                        {state.pictures?.map((picture, idx) => (
                                            <Col className="upload-images col-auto gx-0 mb-12px" key={picture.url}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{picture.file.name}</Tooltip>}>
                                                    <div className="each-image">
                                                        <div className="upload-image-wrap">
                                                            {picture.file === state.representativePicture?.file && (
                                                                <button disabled className="representative notosanskr-400 font-size-14 c-white">
                                                                    {t("149")}
                                                                </button>
                                                            )}

                                                            <button
                                                                className="remove-picture c-white"
                                                                onClick={() => {
                                                                    const pictures = state.pictures.reduce((acc, cur) => {
                                                                        if (cur.file !== picture.file) acc.push(cur);
                                                                        return acc;
                                                                    }, []);

                                                                    const representative_picture = pictures.find((picture) => picture.file === state.representativePicture.file) || (pictures?.length && pictures[0]);
                                                                    setState({ ...state, pictures: pictures, representativePicture: representative_picture });
                                                                }}
                                                            />
                                                            <img
                                                                src={picture?.url}
                                                                alt="Representative"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setState({ ...state, representativePicture: picture });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                    {errors?.pictures.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.pictures?.message}</div>}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="applicant col-lg-6">
                    <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000 col-sm-12">
                        <Row className="gx-0 align-items-center h-100">
                            <Col>{t("387")}</Col>
                        </Row>
                    </Col>
                    <Col className={`item notosanskr-400 font-size-14 col-sm align-items-center ${errors?.userName?.message ? "d-block" : "d-flex"}`} xs={12}>
                        <InputText id={ID.createGroupUserName}
                            name="userName"
                            disabled={true}
                            defaultValue={applicationInfo?.user_name || state.userInfo.realname}
                            onBlur={(e) => isValid([e.target])}
                        />
                        {errors?.userName?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.userName?.message}</div>}
                    </Col>
                </Col>
                <Col className="applicant" sm={6}>
                    <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                        <Row className="gx-0 align-items-center h-100">
                            <Col>{t("388")}</Col>
                        </Row>
                    </Col>
                    <Col className={`item notosanskr-400 font-size-14 col-sm align-items-center ${errors?.phone?.message ? "d-block" : "d-flex"}`} xs={12}>
                        <PhoneForm phoneId={ID.createGroupContact} disabled={!onSubmit} defaultValue={applicationInfo?.contact || ""} isValid={isValid} />
                        {errors?.phone?.message && <div className={`notosanskr-400 font-size-14 c-f00 mt-10px`}>{errors?.phone?.message}</div>}
                    </Col>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("232")}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                    <InputText
                        id={ID.createGroupResearch}
                        name="research"
                        className="w-75"
                        disabled={!onSubmit}
                        defaultValue={applicationInfo?.research || ""}
                        onBlur={(e) => isValid([e.target])}
                    />
                    {errors?.research?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.research?.message}</div>}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("231")}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                    <InputText
                        id={ID.createGroupRelLink}
                        name="link"
                        className="w-75"
                        disabled={!onSubmit}
                        defaultValue={applicationInfo?.rel_link || ""}
                        onBlur={(e) => isValid([e.target])}
                    />
                    {errors?.link?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.link?.message}</div>}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("389")}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                    {applicationInfo ? (
                        <div className="maxh-70px" style={{ overflow: "auto" }}>
                            {applicationInfo.certificate_files.map((file, i) => (
                                <div className="files-wrap align-middle" key={file.url}>
                                    <a href={common.getStorageUrl(file.key, process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET, 600)} target="_blank" rel="noopener noreferrer">
                                        {common.convertFileName(file.name, 40)}
                                    </a>
                                    <Timer time={600} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="caution notosanskr-400 font-size-14 c-f00">{t("96")}</div>
                            <div className="file-group">
                                <ImageDropZone2
                                    className="create-group-files notosanskr-400 font-size-14 c-999"
                                    inputFileId={ID.createGroupFilesInput}
                                    imgId={ID.createGroupFiles}
                                    disabled={state.certificateFiles?.length >= MAX_FILES}
                                    // maxFiles={MAX_FILES}
                                    accept=""
                                    ref={onDropCertificateFiles}
                                    multiple={true}
                                    placeholder={t("97")}
                                />
                            </div>
                            <div className="notosanskr-400 font-size-14 c-f00 mt-10px" display={errors?.files?.message ? "block" : "none"}>
                                {errors?.files?.message}
                            </div>
                            {state.certificateFiles?.map((file, i) => (
                                <div className="files-wrap align-middle" key={i}>
                                    <span className="file-name notosanskr-400 font-size-14 lh-1.43 ls--0.7px c-333 mr-15px">{common.convertFileName(file.name)}</span>
                                    <button
                                        className="remove-file"
                                        onClick={(e) => {
                                            removeFileFromFileList(ID.createGroupFilesInput, file);
                                            setState({ ...state, certificateFiles: state.certificateFiles?.filter((certFile) => certFile !== file) || [] });
                                        }}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/19171.png`}
                                            srcSet={`${process.env.PUBLIC_URL}/19171@2x.png 2x,
                                            ${process.env.PUBLIC_URL}/19171@3x.png 3x`}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            {t("390").split('\n').map((v, i) => <div key={i}>{v}</div>)}
                        </Col>
                    </Row>
                </Col>
                <Col as="div" id={ID.createGroupRequireJoinConfirm} className="item permission d-flex col-sm align-items-center d-flex" xs={12} defaultValue={applicationInfo?.require_join_confirm || false}>
                    <div className="radio-wrap me-4">
                        <CustomCheckbox
                            type="radio"
                            id={`${ID.createGroupRequireJoinConfirm}-required`}
                            disabled={!onSubmit}
                            name={`${ID.createGroupRequireJoinConfirm}-radio`}
                            defaultChecked={applicationInfo?.require_join_confirm}
                            value={true}
                        >
                            <label htmlFor={`${ID.createGroupRequireJoinConfirm}-required`} className="notosanskr-15 c-333">
                                {t("391")}
                            </label>
                        </CustomCheckbox>
                    </div>
                    <div className="radio-wrap">
                        <CustomCheckbox
                            type="radio"
                            id={`${ID.createGroupRequireJoinConfirm}-pass`}
                            disabled={!onSubmit}
                            name={`${ID.createGroupRequireJoinConfirm}-radio`}
                            value={false}
                            defaultChecked={!applicationInfo?.require_join_confirm}
                        >
                            <label htmlFor={`${ID.createGroupRequireJoinConfirm}-pass`} className="notosanskr-15 c-333">
                                {t("392")}
                            </label>
                        </CustomCheckbox>
                    </div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                        <Trans i18nKey={'214'} components={{tag: <span></span>}}/>
                        </Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                    <textarea
                        id={ID.createGroupAbout}
                        name="about"
                        disabled={!onSubmit}
                        defaultValue={applicationInfo?.about || ""}
                        className="w-75"
                        onBlur={(e) => isValid([e.target])}
                        placeholder={t("393")}
                        maxLength={50}
                    ></textarea>
                    {errors?.about?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.about?.message}</div>}
                </Col>
            </Row>
            {onSubmit && (
                <Row className="gx-0 confirm-btn-group" style={{ textAlign: "right" }}>
                    <Col>
                        <button
                            id={"create-group-submit"}
                            className="save-btn notosanskr-400 font-size-18 c-white"
                            onClick={async () => {
                                if (!(await validateFields())) {
                                    common.scrollToInvalidElement();
                                    return;
                                }
                                onSubmit &&
                                    onSubmit(
                                        state.certificateFiles,
                                        state.pictures.map((picture) => picture.file),
                                        state.representativePicture
                                    );
                            }}
                        >
                            {t("99")}
                        </button>
                    </Col>
                </Row>
            )}
        </>
    );
}
