import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import WithTextHandle from "../hoc/WithTextHandle";
import useTagHook from './TagHook';
import TagView from "./TagView";
import common from '../../../common/common';

const Tag = forwardRef(({ defaultValue, type, fields, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const {
        textValidation,
        setTagValue,
        getTagValue
    } = useTagHook(ref);

    const formatTag = useCallback((value) => {
        return value.map((item) => "#" + item).join(" ").trim();
    }, []);

    // 저장 된 value는 array 형태이므로, element에 표시 될 수 있도록 string으로 formatting
    const formattedDefaultValue = useMemo(() => (
        defaultValue && formatTag(defaultValue)
    ), [defaultValue, formatTag]);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        setTagValue(formattedDefaultValue);
    }, [formattedDefaultValue, setTagValue]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = formatTag(getTagValue());
        const { valid, errorCollection } = textValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [type, required, formatTag, getTagValue, textValidation]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    const onBlurHandle = useCallback((e) => {
        setTagValue(e.target.value);
        validation();
    }, [validation, setTagValue]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        type,
        defaultValue : formattedDefaultValue,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        onBlurHandle
    };

    return (
        <TagView {...viewProps} />
    );
});

export default WithTextHandle(Tag);
