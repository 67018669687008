import React, { useImperativeHandle, useCallback } from 'react';
import WithValueHandle from "./WithValueHandle";
import i18n from 'i18next';

const WithTextHandle = (WrapperComponent) => {
    const WithText = ({ ...otherProps }, ref) => {
        const textValidation = useCallback(({ value, required, errorKey }) => {
            let errorCollection = { [errorKey]: { state: true, message: "" } };
            let valid = true;

            if (required) {
                valid = Boolean(value);
                errorCollection = { [errorKey]: { state: valid, message: valid ? "" : i18n.t("15") } };
            }

            // valid가 true이고, 하위 컴포넌트의 validation function 이 있는 경우
            if (valid && ref.current.childValidation) {
                ({ valid, errorCollection } = ref.current.childValidation({ value, errorKey }));
            }

            return { valid, errorCollection };
        }, [ref]);

        useImperativeHandle(ref, () => (
            { ...ref.current, textValidation }
        ), [ref, textValidation]);

        return (
            <WrapperComponent ref={ref} {...otherProps} />
        );
    };

    return WithValueHandle(React.forwardRef(WithText));
};

export default WithTextHandle;
