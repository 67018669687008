import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle, useRef, useContext } from 'react';
import WithImageHandle from "../hoc/WithImageHandle";
import useVendorUI3Hook from './VendorUI3Hook';
import VendorUI3View from "./VendorUI3View";
import useSizeContext from "../Context/SizeContext";
import { POPUP as GeneralPopup } from "../../../common/defines";
import dgLogger from '../../../common/dgLogger';
import common from '../../../common/common';

const POPUP = {
    ...GeneralPopup,
    ImagePreview: 1005,
};

const VendorUI3 = forwardRef(({ defaultValue, type, fields, sub_items, i18n }, ref) => {
    const { title, description, required, min_item_count, max_item_count } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const i18n_sub_items = useMemo(() => {
        const lang = common.getLang();
        if (i18n?.[lang]) {
            return sub_items.map((f) => ({ ...f, ...(i18n[lang].sub_items.find((x) => x.index == f.index) || {}) }));
        } else return sub_items;
    }, [sub_items, i18n]);
    const tempValue = useRef([]);
    const [popup, setPopup] = useState(POPUP.None);
    const [popupTarget, setPopupTarget] = useState(null);
    const [pendingUploadFiles, setPendingUploadFiles] = useState([]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const fileListLength = useRef(0);
    const {
        imageValidation,
        pushDeletedFilesKey,
        pushAddedFilesKey,
        setVendorUI3Value,
        getVendorUI3Value,
        processUploadedFiles,
        uploadImage
    } = useVendorUI3Hook(ref);

    const { SizeContext } = useSizeContext();
    const sizeContext = useContext(SizeContext);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        if (defaultValue && !getVendorUI3Value()) {
            setVendorUI3Value(defaultValue);
            tempValue.current = defaultValue;
            fileListLength.current = defaultValue.filter(item => item).length;

            // 기존에 저장 되어있던 파일들의 size를 합산하여 context에 추가
            const originalSize = defaultValue.reduce((acc, item) => acc += item?.file ? item.file.size : 0, 0);
            sizeContext.addOriginalUploadedSize(originalSize);

            forceUpdate();
        }
    }, [defaultValue, sizeContext, setVendorUI3Value, getVendorUI3Value, forceUpdate]);

    // cloud에 업로드 되어야 할 파일이 생겼을 때 실행
    useEffect(() => {
        if (pendingUploadFiles.length) {
            Promise.all(pendingUploadFiles.map(async (file, index) => (
                uploadImage(file).then(fileInfo => {
                    if (fileInfo.key) pushAddedFilesKey(fileInfo.key); // file key가 있는 경우 추가 된 key 목록에 추가
                    tempValue.current[file.index].file = fileInfo;
                })
            ))).then(() => {
                setVendorUI3Value(tempValue.current);
                setPendingUploadFiles([]);
            });
        }
    }, [pendingUploadFiles, uploadImage, pushAddedFilesKey, setVendorUI3Value]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const { valid, errorCollection } = imageValidation({ required, errorKey: type, min_item_count, fileListLength: fileListLength.current });
        setErrors(errorCollection);

        return valid;
    }, [type, required, min_item_count, imageValidation]);

    // 사진이 업로드 됐을 때의 handler
    const onDrop = useCallback(async (index, acceptedFiles, fileRejections) => {
        try {
            const { newFileList } = await processUploadedFiles({
                acceptedFiles,
                fileRejections,
                max_item_count: 1, // vendor-ui-2의 file은 개별 항목이기 때문에 1개만 진행
            });
            if (!newFileList.length) return validation();

            fileListLength.current++;
            validation();

            const file = newFileList[0];
            file.index = index;

            const updatedFiles = {
                lat: file.lat,
                lng: file.lng,
                dateTime: file.dateTime,
                name: file.name,
                size: file.size,
                url: `${process.env.PUBLIC_URL}/Rolling-1.4s-200px.gif`
            };

            // DB에 저장 될 value를 만들기 위해 sub_item의 id를 key로 하는 object에 값 저장
            // file, textbox에도 id가 존재하므로 각각의 id를 key로 사용하여 value 저장
            // 사진만 등록한 경우에도 제출이 되어야 하므로 기본 값 저장
            tempValue.current[index] = {
                file: updatedFiles,
                answers: i18n_sub_items.map(item => ({
                    _id: item._id,
                    answer: 0
                })),
                sum: 0
            };

            setVendorUI3Value(tempValue.current);
            setPendingUploadFiles(newFileList);
        }
        catch (e) {
            dgLogger.info(e)();
        }
    }, [i18n_sub_items, processUploadedFiles, validation, setVendorUI3Value]);

    // remove button을 눌렀을 때의 handler
    const removeFile = useCallback((index) => {
        const file = tempValue.current[index].file;
        sizeContext.subtractOriginalUploadedSize(file.size);
        if (file.key) pushDeletedFilesKey(file.key); // file key가 있는 경우 삭제 할 key 목록에 추가

        delete tempValue.current[index];
        fileListLength.current--;
         validation();

        setVendorUI3Value(tempValue.current);
    }, [sizeContext, pushDeletedFilesKey, validation, setVendorUI3Value]);

    // imagePreview popup에서 사용 될 confirm function
    const onPopupConfirm = useCallback((answers, sum) => {
        const updatedTempValue = tempValue.current
            .map((value, index) => (
                !value ? null : {
                    ...value,
                    answers: answers[index],
                    sum: sum[index],
                }
            ));
        tempValue.current = updatedTempValue;
        setVendorUI3Value(tempValue.current);
    }, [setVendorUI3Value]);

    // imagePreview popup에서 사용 될 cancel function
    const onPopupCancel = useCallback(() => {
        setPopup(POPUP.None);
        setPopupTarget(null);
    }, []);

    // 개수 입력 버튼을 눌렀을 때 사용 될 function
    const onClickHandle = useCallback((index) => {
        // 업로드 중인 이미지는 포함이 되지 않도록 array 생성
        const images = tempValue.current.map(value => {
            if (value?.file != null && value?.file?.url !== `${process.env.PUBLIC_URL}/Rolling-1.4s-200px.gif`) {
                return ({
                    watermark: value.file?.resize_url || value.file?.watermark_url || value.file?.url,
                    thumbnail: value.file?.thumbnail_url || value.file?.url
                });
            }
            else {
                return undefined;
            }
        });

        const answers = tempValue.current.map(value => value?.answers);
        const bboxes = tempValue.current.map(data => data && ({imageSize: data.image_size, boundingBox: data.bounding_box}));
        const questions = i18n_sub_items;

        setPopup(POPUP.ImagePreview);
        setPopupTarget({ index: index, images: images, questions: questions, answers: answers, bboxes: bboxes });
    }, [i18n_sub_items]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        type,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        tempValue,
        max_item_count,
        popup,
        popupTarget,
        onDrop,
        removeFile,
        onClickHandle,
        onPopupConfirm,
        onPopupCancel
    };

    return (
        <VendorUI3View {...viewProps} />
    );
});

export default WithImageHandle(VendorUI3);
