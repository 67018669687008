import "./Emblem.css";

/**
 *
 * @param {*} props 다음의 구조를 갖습니다. (url 이나 char 둘중 하나의 값을 반드시 있어야 합니다.)
 * ```
 * {
 * url: image url (optional),
 * urlClass: custom css class (optional),
 * char: string (optional) // text 의 첫글자를 출력
 * charClass: custom css class (optional)
 * button: sub item type (optional) // 없을경우 sub item 을 출력하지 않음
 * onClick: emblem 의 sub item 을 클릭했을때 불리는 callback (optional. depend on `button`)
 * }
 * ```
 * @returns HTMLElement
 */
export default function Emblem(props) {
    return (
        <div
            className="emblem-container"
            style={{
                width: props.width ? props.width : "151px",
                height: props.height ? props.height : "151px",
            }}
        >
            <div className="emblem-outer">
                {props.url ? <img src={props.url} alt="" className={`emblem ${props.urlClass ? props.urlClass : ""}`} /> : undefined}
                {!props.url && props.char ? <span className={`text-white rounded-circle ${props.charClass ? props.charClass : ""}`}>{props.char.charAt(0).toUpperCase()}</span> : undefined}

                {props.button ? <div className="emblem-inner">{props.button === "sns" ? <label className="emblem-button-sns" onClick={props.onClick} /> : undefined}</div> : undefined}
            </div>
        </div>
    );
}
