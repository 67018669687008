import { useCallback } from "react";
import Api from "../../Api";
import dgLogger from "../../common/dgLogger";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import common from "../../common/common";
import { strings } from "@ocean-knight/shared";

export default function useCreateReportHook(t) {
    // 현재 로그인 된 사용자가 가입 되어 있는 그룹 목록 획득하여 반환
    const getGroupList = useCallback(async () => {
        try {
            const { groups } = await Api.getMemberedGroupList();
            return groups || [];
        }
        catch (e) {
            dgLogger.error(e)();
            return [];
        }
    }, []);

    // 그룹 id에 해당하는 그룹 정보 획득하여 반환
    const getGroupItem = useCallback(async (groupId) => {
        try {
            const groupInfo = await Api.getGroupItem({ _id: groupId, optActive: false });
            // 그룹 정보는 array 형태로 사용 되기에 array로 바꾸어 반환
            return [groupInfo];
        }
        catch (e) {
            dgLogger.error(e)();
            return [];
        }
    }, []);

    // 그룹 id에 해당하는 그룹에 소속 되어 있는 프로젝트 목록을 획득하여 반환
    const getProjectList = useCallback(async (groupId) => {
        try {
            const { projects } = await Api.getActiveProjectListAll({ group: groupId, withHidden: true });
            // 프로젝트명이 '없음' 이라면, 언어에 따라 '없음' 혹은 'None' 으로 처리되도록 함.
            return projects.map((v) => ({ ...v, name: v.name == strings.ko["188"] ? strings[common.getLang()]["188"] : v.name })) || [];
        }
        catch (e) {
            dgLogger.error(e)();
            return [];
        }
    }, []);

    // report form의 id을 이용하여 form item 목록을 획득하여 저장
    const getReportForm = useCallback(async (reportFormId) => {
        try {
            const { report_form_item_ids } = await Api.getReportFormItemArray({ _id: reportFormId });
            return report_form_item_ids || [];
        }
        catch (e) {
            dgLogger.error(e)();
            return [];
        }
    }, []);

    // back end로 전송 할 report form item result의 구조 생성
    const createReportFromItemResult = useCallback((refList, reportFormItemList) => {
        const reportFromItemResult = refList.current.reduce((acc, child, index) => {
            const reportFromItem = reportFormItemList[index];
            const type = reportFromItem.type;
            const value = child.current?.getValue();

            // value에 어떠한 타입이 들어올 지 모르는 상황
            // value가 array라면 length가 있는 지 확인하여 값이 비어있는 지 확인
            // value가 array가 아니라면 현재 value의 값이 비어있는 지 확인
            if (Array.isArray(value) ? Boolean(value.length) : Boolean(value)) {
                acc.push({
                    representative_picture: child.current?.getRepresentativePicture(),
                    extra: null,
                    report_form_item_id: reportFromItem._id,
                    type,
                    value
                });
            }

            return acc;
        }, []);

        return reportFromItemResult;
    }, []);

    // context는 render를 위한 용도이기 때문에 watermark에 삽입 할 현재 date 값이 필요
    // refList와 reportFormItemList의 같은 index는 같은 item type을 가지는 것을 이용해 date 값을 찾아 반환
    const getCurrentReportDateValue = useCallback((refList, reportFormItemList) => {
        const dateIndex = reportFormItemList.findIndex(item => item.type === "date");
        const date = refList.current[dateIndex].current?.getValue();
        return date;
    }, []);

    // GPS를 이용하여 나라 이름을 획득하여 반환
    // 나라 이름 획득 실패 시 "기타" 반환
    const reverseGeocode = useCallback(async (latitude, longitude) => {
        try {
            const geocode = await Api.reverseGeocode(latitude + "," + longitude);
            return geocode;
        }
        catch (e) {
            dgLogger.error(e)();
            return i18n.t("59");
        }
    }, []);

    // 현재 location으로 geocode의 위치를 찾아와 저장
    const updateLocationResult = useCallback(async (item) => {
        const value = item.value;
        const geocode = await reverseGeocode(value.latitude, value.longitude);
        value.geocode = geocode;
    }, [reverseGeocode]);

    const getWatermarkText = useCallback(({ email, isoDateString }) => {
        return [`OCEAN Cloud | ${email.trim()} | ${new Date(isoDateString).toLocaleDateString().trim()}`];
    }, []);

    // 아직 cloud에 upload 되지 않은 파일이 있는 지 확인
    const isUploadPending = useCallback((fileList) => {
        if (fileList.find(file => file?.url === `${process.env.PUBLIC_URL}/Rolling-1.4s-200px.gif`)) {
            return true;
        }
        else {
            return false;
        }
    }, []);

    // 값이 undeinfed 인 key는 request 시 사라지게 되므로 없애고 싶은 key에 대해 undeinfed로 설정
    const transformAndCleanFile = useCallback((file) => ({
        ...file,
        resize_url: undefined,
        resizeImageSize: undefined,
        url: undefined,
        watermarkText: undefined,
        no_preview: undefined
    }), []);

    const uploadWatermarkImage = (async ({ imageInfos, progress }) => {
        const size = imageInfos.length ? await Api.uploadWatermarkImage(imageInfos, progress) : 0;
        return size;
    });

    // resize_url이 있는 객체만 새롭게 업로드 된(수정 버튼을 통해 들어온 값이 아닌) 파일이므로 나누어 저장
    const separateImageFiles = useCallback((fileList, watermarkText) => {
        const [newImageInfos, originalImageInfos] = fileList.reduce((acc, cur) => {
            if (cur?.resize_url && !cur?.no_preview) {
                acc[0].push({ ...cur, watermarkText: watermarkText });
            }
            else {
                acc[1].push(cur);
            }
            return acc;
        }, [[], []]);

        return { newImageInfos, originalImageInfos };
    }, []);

    // back-end로 file 객체를 전달하기 전 update 진행
    // watermark file을 upload하고, file object를 update
    const updateFileReuslt = useCallback(async ({ value, representative_picture, email, date, watermarkUploadedSize, progress }) => {
        const filePromise = [];
        const watermarkText = getWatermarkText({
            email: email,
            isoDateString: date
        });

        // resize_url이 있는 객체만 새롭게 업로드 된(수정 버튼을 통해 들어온 값이 아닌) 파일이므로 나누어 저장
        const { newImageInfos, originalImageInfos } = separateImageFiles(value, watermarkText);

        // 새롭게 업로드 된 파일의 watermark image file을 upload 진행
        const size = await uploadWatermarkImage({ imageInfos: newImageInfos, progress });
        watermarkUploadedSize.current += size;

        // 사용하지 않을 key를 삭제 (undefined로 대체)
        const transformRepresentativePicture = representative_picture && transformAndCleanFile(representative_picture);
        const transformValue = [...originalImageInfos, ...newImageInfos.map(transformAndCleanFile)];

        return { filePromise, transformRepresentativePicture, transformValue };

    }, [getWatermarkText, separateImageFiles, transformAndCleanFile]);

    // 2개의 파일이 같은 지 판단하는 함수
    // 파일의 이름과 크기와 key가 같다면 같은 것으로 판단
    // 기존 file 컴포넌트의 compare와 다르게 특정 컴포넌트는 같은 파일이 업로드 되나, key가 다르므로 key도 함께 판단
    const compareFile = useCallback((source, target) => {
        if (source && target && source?.name === target?.name && source?.size === target?.size && source?.key === target?.key) {
            return true;
        }
        return false;
    }, []);

    // report 생성 api 호출
    const createReportUsingResult = useCallback((payload) => {
        return Api.createReportUsingResult(payload);
    }, []);

    // 자식 컴포넌트들에게서 모아둔 deletedFileKey 획득
    const getDeletedFilesKey = useCallback((refList) => {
        const deletedFilesKey = refList.current.reduce((acc, child) => {
            const keyList = child.current?.getDeletedFilesKey?.();
            if (keyList?.length) {
                acc.push(...keyList);
            }

            return acc;
        }, []);

        return deletedFilesKey;
    }, []);

    // 자식 컴포넌트들에게서 모아둔 deletedFileKey 획득
    const getAddedFilesKey = useCallback((refList) => {
        const addedFilesKey = refList.current.reduce((acc, child) => {
            const keyList = child.current?.getAddedFilesKey?.();
            if (keyList?.length) {
                acc.push(...keyList);
            }

            return acc;
        }, []);

        return addedFilesKey;
    }, []);

    // 삭제 할 file key 모음이 있다면, api 호출
    const deleteImages = useCallback((deletedFilesKey) => {
        if (deletedFilesKey.length) {
            Api.deleteImages(deletedFilesKey);
        }
    }, []);

    const upsertReportSnapshot = useCallback(({ childRefList, reportFormItemList, tempReportResult, storedReport, tempReport, selectedGroupId, selectedProjectId, currentUserInfo, reportFormId }) => {
        try {
            const currentReportFromItemResult = createReportFromItemResult(childRefList, reportFormItemList);
            // 기존에 저장 된 데이터와 현재 데이터가 같지 않은 경우
            // 프로젝트는 변경이 가능하여 report_form_item_result만으로 판단하지 않고, DB schema 자체와 비교
            const payload = {
                report_id: storedReport?._id || tempReport?.report_id,
                participating_group: selectedGroupId.current,
                participating_project: selectedProjectId.current,
                registered_by: currentUserInfo?._id,
                report_form_result: {
                    report_form_id: reportFormId.current,
                    report_form_item_result: currentReportFromItemResult,
                },
                added_files_key: [...(tempReport?.added_files_key || []), ...getAddedFilesKey(childRefList)],
                deleted_files_key: [...(tempReport?.deleted_files_key || []), ...getDeletedFilesKey(childRefList)],
                is_modifying: Boolean(storedReport || tempReport?.is_modifying),
            };
            if (!_.isEqual(tempReportResult.current, payload)) {
                Api.upsertReportSnapshot(payload)
                    .then((response) => {
                        tempReportResult.current = payload;
                        localStorage.setItem(currentUserInfo?._id, response?._id);
                    });
            }
        }
        catch (e) {
            dgLogger.error(e)();
        }
    }, [createReportFromItemResult, getAddedFilesKey, getDeletedFilesKey]);

    const deleteReportSnapshot = useCallback((userId) => {
        try {
            Api.deleteReportSnapshot({ registered_by: userId });
            localStorage.removeItem(userId);
        }
        catch (e) {
            dgLogger.error(e)();
        }
    }, []);

    const hasReportSnapshotLocalStorage = useCallback((userId) => {
        return Boolean(localStorage.getItem(userId));
    }, []);

    return {
        getGroupList,
        getGroupItem,
        getProjectList,
        getReportForm,
        createReportFromItemResult,
        getCurrentReportDateValue,
        updateLocationResult,
        isUploadPending,
        updateFileReuslt,
        compareFile,
        createReportUsingResult,
        getDeletedFilesKey,
        getAddedFilesKey,
        deleteImages,
        upsertReportSnapshot,
        deleteReportSnapshot,
        hasReportSnapshotLocalStorage,
    };
}
