import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import WithDateHandle from "../hoc/WithDateHandle";
import useCustomDateHook from './CustomDateHook';
import CustomDateView from "./CustomDateView";
import common from '../../../common/common';

// 컴포넌트 이름을 Date로 하면 기존의 window Date와 충돌이 생겨 다른 컴포넌트 이름과 패턴이 다르게 명명
const CustomDate = forwardRef(({ defaultValue, type, fields, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const {
        dateValidation,
        setDateValue,
        getDateValue,
        getContextDatetimeValue,
        dateObjectToString,
        convertToDate,
        convertToIsoDate
    } = useCustomDateHook(ref);
    const [date, setDate] = useState(getContextDatetimeValue());


    // context에 저장 되어 있는 datetime이 바뀌었을 때 호출
    // ref.current.value에는 iso date가 저장 되므로 render에 필요한 값은 따로 저장
    useEffect(() => {
        const currentDatetimeValue = getContextDatetimeValue(); // YYYY:MM:DD hh:mm:ss
        let currentDateValue = '';
        // context에 저장 되어 있는 값이 있는 경우 YYYY-MM-DD로 변환
        if (currentDatetimeValue) {
            const [date] = currentDatetimeValue.split(" ");
            currentDateValue = date.replace(/:/gi, "-");
        }

        setDateValue(currentDateValue);
        setDate(currentDateValue);
        // validation은 무조건 true가 되는 것이 기존 동작
        setErrors({ [type]: { state: true, message: '' } });
    }, [type, getContextDatetimeValue, setDateValue]);

    // 저장 된 value는 iso date 형태이므로, element에 표시 될 수 있도록 convert
    const formattedDefaultValue = useMemo(() => (
        defaultValue && convertToDate(new Date(defaultValue))
    ), [convertToDate, defaultValue]);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        if (formattedDefaultValue) {
            setDateValue(formattedDefaultValue);
            setDate(formattedDefaultValue);
        }
    }, [formattedDefaultValue, setDateValue]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = getDateValue();
        const { valid, errorCollection } = dateValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [type, required, getDateValue, dateValidation]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    const onBlurHandle = useCallback((e) => {
        setDateValue(e.target.value);
        validation();
        setDate(e.target.value);
    }, [validation, setDateValue]);

    // 현재 element에서 수정이 발생 했을 때 호출 되는 handler
    const onChangeHandle = useCallback((e) => {
        // 사용자가 키보드 입력 등을 통해 max date를 넘은 경우
        // invalid 처리를 하지 않고 값을 보정
        if (e.target.validity.rangeOverflow) {
            e.target.value = e.target.max;
        }
    }, []);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    // 현재 날짜보다 늦은 날짜는 선택할 수 없도록 설정
    // useMemo 등을 사용하면 00시를 지났음에도 갱신이 되지 않아 사용 하지 않음
    const max = dateObjectToString(new Date());

    const viewProps = {
        title,
        description,
        type,
        defaultValue: date,
        max,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        onBlurHandle,
        onChangeHandle,
    };

    return (
        <CustomDateView {...viewProps} />
    );
});

export default WithDateHandle(CustomDate);
