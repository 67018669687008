import React, { useCallback, useEffect } from "react";
import "./ReportTable.css";
import { ReportCard, updateGroupNameRowHeight } from "./ReportCard";
import { IsMobile, IsTablet } from "../common/MediaQuery";
import common from "../../common/common";

export default function ReportTable(props) {
    const reportLength = props.reports?.length;
    const isMobile = IsMobile();
    const isTablet = IsTablet();
    const tdCount = isMobile ? 2 : isTablet ? 3 : 4;

    const createDummyTd = useCallback((lineReport) => {
        const dummy = [];
        if (lineReport < tdCount) {
            while (dummy.length + lineReport < tdCount) {
                dummy.push(
                    <td key={tdCount - dummy.length + lineReport}></td>
                );
            }
        }
        return dummy;
    }, [tdCount]);

    const getTableComponent = useCallback(() => {
        const tableComponent = [];
        for (let i = 0; i < reportLength; i += tdCount) {
            const lineReport = props.reports.slice(i, i + tdCount);
            tableComponent.push(
                <tr key={i}>
                    {lineReport.map((report, index) => {
                        return (
                            <td key={report._id} >
                                <ReportCard
                                    checkbox={props.checkbox && report.no_request_history}
                                    report_id={report._id}
                                    registered_by={report.registered_by}
                                    date={report.date}
                                    register_date={report.register_date}
                                    group_name={common.i18nGroupName(report.group_name)}
                                    title={report.title}
                                    index={i + index}
                                    id_prefix={"report-card-checkbox"}
                                    button_id={"request-report-license"}
                                    reportLength={reportLength}
                                    report_file_url={report.report_file_url}
                                />
                            </td>
                        );
                    })}
                    {createDummyTd(lineReport.length)}
                </tr>
            );
        }
        return tableComponent;
    }, [createDummyTd, props.checkbox, props.reports, reportLength, tdCount]);

    useEffect(() => {
        updateGroupNameRowHeight();
    }, []);

    return (
        <>
        <table className={props.className? props.className : "report-table"}
        >
            <tbody>
                {getTableComponent()}
            </tbody>
        </table>
        </>
    );
}
