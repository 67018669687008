import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import { POPUP as GeneralPopup } from "../../common/defines";
import { useLocation, useNavigate } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Pagination from "../common/Pagination";
import Modal from "../common/Modal";
import { useIsMount, useNotification } from "../../common/customHook";
import { mutate } from "swr";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReportCard } from './../Report/ReportCard';
import "./RequestDataUse.css";
import CustomCheckbox from "../common/CustomCheckbox";
import { IsPC, IsTablet } from "../common/MediaQuery";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";
import QueryString from 'qs';
import { REQUEST_DATA_USE_TAB_ID } from "@ocean-knight/shared/src/utility";

const POPUP = {
    ...GeneralPopup,
    Notification: 1010,
};

export default function RequestDataUse() {
    const { t } = useTranslation();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [REQUEST_DATA_USE_TAB_ID.reviewing, REQUEST_DATA_USE_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : REQUEST_DATA_USE_TAB_ID.reviewing,
        popup: POPUP.None,
        popupTarget: null,
        buttonDisabled: true,
    });
    const isMount = useIsMount();
    const [reviews, setReviews] = useState([]);
    const [histories, setHistories] = useState([]);
    const isPc = IsPC();
    const isTablet = IsTablet();
    const REPORT_CARD_ID_PREFIX = 'report-card-checkbox';
    const ITEM_COUNT_PER_PAGE_ON_REVIEWING = 16;
    const ITEM_COUNT_PER_PAGE_ON_HISTORY = 10;


    const currentPage = useRef({ reviewingPage: 1, historyPage: 1,});
    const paginationOption = useRef({ itemsCountPerPage: ITEM_COUNT_PER_PAGE_ON_REVIEWING, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const navigate = useNavigate();
    const { notifications } = useNotification();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        const hasPermission = common.hasSiteMemberPermission(JSON.parse(sessionStorage.getItem("permissions")));
        if (!hasPermission) {
            if (!isMount.current) { return; }
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }
    }, [t, isMount]);

    /**
     * 승인 대기 tab에 있는 경우 모든 checkbox를 체크 해제 하는 함수
     */
    const resetCheckbox = useCallback(() => {
        if (state.activeTab !== REQUEST_DATA_USE_TAB_ID.reviewing) return;
        document.getElementById("allCheckbox").checked = false;
        for (let i = 0; i < paginationOption.current.itemsCountPerPage; i++) {
            if (document.getElementById(`${REPORT_CARD_ID_PREFIX}${i}`))
                document.getElementById(`${REPORT_CARD_ID_PREFIX}${i}`).checked = false;
        }

        setState((prev) => ({ ...prev, buttonDisabled: true }));
    }, [state.activeTab]);

    const getDataUseHistoryMine = useCallback(async () => {
        const page = state.activeTab === REQUEST_DATA_USE_TAB_ID.reviewing
        ? currentPage.current.reviewingPage
        : currentPage.current.historyPage;

        try {
            const payload = await Api.getRequestedReportLicenseAll({
                states: state.activeTab === REQUEST_DATA_USE_TAB_ID.reviewing ? [utility.STATE.REVIEWING] : [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                page: page,
                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            });

            paginationOption.current.totalItemsCount = payload.count;
            resetCheckbox();
            if (!isMount.current) { return; }
            state.activeTab === REQUEST_DATA_USE_TAB_ID.reviewing ? setReviews(payload.reports) : setHistories(payload.reports);
        } catch (e) {
            dgLogger.error(e)();
        }
    }, [state.activeTab, resetCheckbox, isMount]);

    useEffect(() => {
        if (!isMount.current) { return; }
        getDataUseHistoryMine();
    }, [getDataUseHistoryMine, isMount]);

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            const item = notifications.find(notification => notification.from === "report.approval.histories");
            if (!item) return;
            Api.getNotificationDetail(item)
            .then(payload => {
                if (payload) {
                    setState((prev) => ({ ...prev, popup: POPUP.Notification, popupTarget: payload }));
                }
            })
            .catch(e => {
                dgLogger.error(e)();
                setState((prev) => ({
                    ...prev,
                    popup: POPUP.GeneralError,
                    popupTarget: t("673"),
                }));
            });
        }
    }, [notifications, t]);

    useEffect(() => {
        paginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        paginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        forceUpdate();
    }, [isPc, forceUpdate]);

    const removeRequestedReportLicense = () => {
        const payload = [];
        for (let i=0; i < reviews.length; i++){
            if (document.getElementById(`${REPORT_CARD_ID_PREFIX}${i}`).checked)
                payload.push(reviews[i]._id);
        }

        if (reviews.length === payload.length) {
            if (currentPage.current.reviewingPage !== 1) {
                currentPage.current.reviewingPage--;
            }
        }

        Api.removeRequestedReportLicense(payload)
            .then(async () => {
                await getDataUseHistoryMine();
                setState({ ...state, popup: POPUP.Requested, popupTarget: null, buttonDisabled: true });
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    const popupDataUseRequest = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/icon_cancel_data_use_request.png`} srcSet={`${process.env.PUBLIC_URL}/icon_cancel_data_use_request@2x.png 2x, ${process.env.PUBLIC_URL}/icon_cancel_data_use_request@3x.png 3x`} alt="" />;
        const header = <div>{t("406")}</div>;
        const body = <div>{t("407")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    removeRequestedReportLicense();
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupDataUseRequested = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/icon_cancel_data_use_request2.png`} srcSet={`${process.env.PUBLIC_URL}/icon_cancel_data_use_request2@2x.png 2x, ${process.env.PUBLIC_URL}/icon_cancel_data_use_request2@3x.png 3x`} alt="" />;
        const header = <div>{t("408")}</div>;
        const body = <div>{t("409")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupDataUseRejectReason = (onClicked = undefined) => {
        const header = <div>{t("411")}</div>;
        const body = <div>{t("412")}</div>;

        return (
            <Modal
                onRequestClose={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                header={header}
                body={body}
                reason={state.popupTarget.state_reason}
            />
        );
    };

    const popupDataUseApproved = (onClicked = undefined) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_public_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_public_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_public_complete@3x.png 3x`} alt="" />;
        const header = <div>{t("579")}</div>;
        const body = <div>{t("580")}</div>;

        return (
            <Modal
                onRequestClose={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const processedState = (item) => {
        if (item.state === utility.STATE.REVIEWING)
            return (
                <button className="nanumsquare-15b lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#87ac2f", cursor: "default" }}>
                    {t("378")}
                </button>
            );
        if (item.state === utility.STATE.ACQUIRED)
            return (
                <button className="nanumsquare-15b lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#4270e0", cursor: "default" }}>
                    {t("377")}
                </button>
            );
        if (item.state === utility.STATE.REJECTED)
            return (
                <button
                    className="nanumsquare-15b lh-1.2 ls--0.75px c-white"
                    style={{ backgroundColor: "#636363" }}
                    onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}
                >
                    {t("379")}
                </button>
            );
    };

    const popups = () => {
        if (state.popup === POPUP.Request) return popupDataUseRequest();
        if (state.popup === POPUP.Requested) return popupDataUseRequested();
        if (state.popup === POPUP.RejectReason) return popupDataUseRejectReason();
        if (state.popup === POPUP.Notification) {
            if (state.popupTarget.state === utility.STATE.ACQUIRED) return popupDataUseApproved(() => {
                Api.removeNotification(state.popupTarget.notificationId)
                .then(() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                    mutate("useNotification");
                })
                .catch(e => dgLogger.error(e)());
            });
            if (state.popupTarget.state === utility.STATE.REJECTED) return popupDataUseRejectReason(() => {
                Api.removeNotification(state.popupTarget.notificationId)
                .then(() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                    mutate("useNotification");
                })
                .catch(e => dgLogger.error(e)());
            });
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    /**
     * 전체 checkbox를 클릭 했을 때 실행되는 함수
     */
     const allCheck = () => {
        const flag = document.getElementById("allCheckbox").checked;
        for (let i=0; i < paginationOption.current.itemsCountPerPage; i++){
            if (document.getElementById(`${REPORT_CARD_ID_PREFIX}${i}`))
                document.getElementById(`${REPORT_CARD_ID_PREFIX}${i}`).checked = flag;
        }

         setState({ ...state, buttonDisabled: !flag });
    };

    /**
     * page를 변경 할 때 호출 되는 함수
     */
    const pageChange = useCallback((page) => {
        if (state.activeTab === REQUEST_DATA_USE_TAB_ID.reviewing) {
            currentPage.current.reviewingPage = page;
            resetCheckbox();
        }
        else {
            currentPage.current.historyPage = page;
        }
        getDataUseHistoryMine();
    }, [state.activeTab, resetCheckbox, getDataUseHistoryMine]);

    const listOfItems = [];
    const itemCount = isPc ? 4 : isTablet ? 3 : 2;
    for (let i = 0; i < reviews.length; i += itemCount) {
        listOfItems.push(reviews.slice(i, i + itemCount));
    }

    return (
        <div id="request-data-use">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("358")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("357")}</div>
                </Col>
            </Row>
            <Tabs
                activeKey={state.activeTab}
                onSelect={(k) => {
                    k === REQUEST_DATA_USE_TAB_ID.reviewing
                        ? (paginationOption.current.itemsCountPerPage = ITEM_COUNT_PER_PAGE_ON_REVIEWING)
                        : (paginationOption.current.itemsCountPerPage = ITEM_COUNT_PER_PAGE_ON_HISTORY);
                    setState({ ...state, activeTab: k });
                }}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
            >
                <Tab className={"reviewing"} eventKey={REQUEST_DATA_USE_TAB_ID.reviewing} title={t("674")}>
                    <Row className="gx-0 reviewing-form-title notosanskr-400 font-size-15 lh-1.4 ls--0.75px c-333">
                        <Col className="text-start">
                            <CustomCheckbox id={`allCheckbox`} onChange={allCheck} disabled={!reviews.length} className="d-flex align-items-center mr-12px:sm"> {t("404")}</CustomCheckbox>
                        </Col>
                    </Row>
                    {listOfItems.map((items, i) => (
                        <Row key={i} className="gx-0 item-row">
                            {items.map((item, index) => (
                                <Col key={item._id} className="report-card-wrap col-auto">
                                    <ReportCard
                                        checkbox={true}
                                        report_id={item.report}
                                        registered_by={item.registered_by}
                                        date={item.date}
                                        register_date={item.register_date}
                                        group_name={common.i18nGroupName(item.group_name)}
                                        title={item.title}
                                        index={i * (isPc ? 4 : isTablet ? 3 : 2) + index}
                                        id_prefix={REPORT_CARD_ID_PREFIX}
                                        button_id={"request-report-license"}
                                        reportLength={reviews.length}
                                        report_file_url={item.report_file_url}
                                        onClick={() => {
                                            let checked = 0;
                                            for (let i = 0; i < reviews.length; i++) {
                                                const element = document.getElementById(`${REPORT_CARD_ID_PREFIX}${i}`);
                                                if (element && element.checked) {
                                                    checked++;
                                                }
                                            }
                                            document.getElementById("allCheckbox").checked = checked === reviews.length ? true : false;
                                            setState({ ...state, buttonDisabled: checked === 0 });
                                        }}
                                    />
                                </Col>
                            ))}
                        </Row>
                    ))}

                    <Row className={`gx-0 ${isPc ? "mt-42px" : "mt-21px"}`}>
                        <Col className="text-end">
                            <button
                                id="request-report-license"
                                className="notosanskr-400 font-size-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    setState({ ...state, popup: POPUP.Request });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("405")}
                            </button>
                        </Col>
                    </Row>

                    <Pagination
                        key={state.activeTab}
                        ref={pageChange}
                        page={state.activeTab === REQUEST_DATA_USE_TAB_ID.reviewing ? currentPage.current.reviewingPage : currentPage.current.historyPage} // 현재 페이지
                        marginPagesDisplayed={paginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
                <Tab className={"histories"} eventKey={REQUEST_DATA_USE_TAB_ID.histories} title={t("373")}>
                    <Row className="item-row gx-0 align-items-center text-center notosanskr-500 font-size-18">
                        <Col className="item first">{t("374")}</Col>
                        <Col className="item second">{t("410")}</Col>
                        <Col className="item third">{t("376")}</Col>
                    </Row>
                    {histories.map((item) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center text-center notosanskr-500 font-size-16">
                            <Col className="item first c-333">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item second notosanskr-500 font-size-16 lh-1.25 ls--0.8px">
                                <Link to={`/Report/${item.report}`}>{item.title}</Link>
                            </Col>
                            <Col className="item third process-state">
                                {processedState(item)}
                            </Col>
                        </Row>
                    ))}
                    <Pagination
                        key={state.activeTab}
                        ref={pageChange}
                        page={state.activeTab === REQUEST_DATA_USE_TAB_ID.reviewing ? currentPage.current.reviewingPage : currentPage.current.historyPage} // 현재 페이지
                        marginPagesDisplayed={paginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
