import React, { useEffect, useState, useCallback, useRef } from "react";
import Api from "../../Api";
import { useIsMount } from "../../common/customHook";
import { VIEW } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import Map from "../common/Map";
import * as Config from "../common/MapConfig";
import common from "../../common/common";
import LoadingSpinner from "../common/LoadingSpinner";
import dgLogger from "../../common/dgLogger";

export default function ReportMapBased(props) {
    const [data, setData] = useState([]);
    const [state, setState] = useState({ isLoading: false });
    const filterOption = props.filterOption;
    const basedOption = props.basedOption;
    const navigate = useNavigate();
    const isMount = useIsMount();
    const prevFilterOption = useRef({});

    // Map 표현에 필요한 data를 받아와 state에 저장
    const getMapBasedData = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));

        // update startDate
        let newStartDate = null;
        if (filterOption.startDate) {
            // update date into local one
            newStartDate = common.convertToIsoDate(filterOption.startDate);
        }

        // update endDate
        let newEndDate = null;
        if (filterOption.endDate) {
            // update date into local one
            newEndDate = common.convertToIsoDate(filterOption.endDate, 1);
        }

        // 선택된 항목들을 구분자(|)로 나누어 array로 생성
        const groupsArray = filterOption.groups ? filterOption.groups.split("|") : undefined;
        const projectsArray = filterOption.projects ? filterOption.projects.split("|") : undefined;
        const locationsArray = filterOption.locations ? filterOption.locations.split("|") : undefined;

        const payload = {
            groups: groupsArray,
            projects: projectsArray,
            locations: locationsArray,
            startDate: filterOption.startDate ? newStartDate : undefined,
            endDate: filterOption.endDate ? newEndDate : undefined,
            registered_by: filterOption.name,
            keyword: filterOption.keyword,
            user_id: filterOption.myReport === "true" ? sessionStorage.getItem("_id") : undefined,
        };

        Api.getReportsMap(payload)
            .then((payload) => {
                setData(payload);
            })
            .catch((e) => {
                dgLogger.error(e)();
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isLoading: false }));
            });
    }, [filterOption]);

    // filterOption이 바뀌었을 때 현재 filterOption에 따라 map에서 사용 할 data 요청
    useEffect(() => {
        if (isMount.current && JSON.stringify(prevFilterOption.current) !== JSON.stringify(filterOption)) {
            getMapBasedData();
            prevFilterOption.current = filterOption;
        }
    }, [filterOption, getMapBasedData, isMount]);

    useEffect(() => {
        if (!Object.keys(basedOption).length && data.length) {
            const tmpbasedOption = { lat: Config.lat, lng: Config.lng, zoom: Config.zoom };
            let param = props.createDefaultParam(VIEW.MapBased);
            param += `/lat=${tmpbasedOption.lat}&lng=${tmpbasedOption.lng}&z=${tmpbasedOption.zoom}`;
            navigate(param, { replace: true });
        }
    }, [data, basedOption, navigate, props]);

    /**
     * Map에 조작이 있을 때 마다 호출 되는 함수
     * 조작이 되어 이동 된 현재의 lat, lng, zoom을 받아와 navigate
     *
     * @param {number} lat 위도
     * @param {number} lng 경도
     * @param {number} z zoom
     */
    const mapChangeNavigate = useCallback((lat, lng, z) => {
        if (!Object.keys(basedOption).length || ((Math.abs(parseFloat(basedOption.lat) - lat) + Math.abs(parseFloat(basedOption.lng) - lng) < 0.000001) && parseInt(basedOption.z) === z)) return;
        let param = props.createDefaultParam(VIEW.MapBased);
        param += `/lat=${lat}&lng=${lng}&z=${z}`;
        navigate(param);
    }, [basedOption, navigate, props]);

    if (state.isLoading) return <LoadingSpinner isOpen={true} />;

    return (
        <div className="report-map-based">
            <Map
                data={data ? data : undefined}
                center={{ lat: basedOption.lat ? parseFloat(basedOption.lat) : Config.lat, lng: basedOption.lng ? parseFloat(basedOption.lng) : Config.lng }}
                zoom={basedOption.z ? parseInt(basedOption.z) : Config.zoom}
                mapChangeNavigate={mapChangeNavigate}
            />
        </div>
    );
}
