import React, { useState, useContext, useCallback, useEffect } from "react";

import { Accordion, Container, Row, Col } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { Link, Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "../AppContext";

import ApproveOrganization from "./Mypage/ApproveOrganization";
import { ApproveGroupCreation } from "./Mypage/ApproveGroupCreation";
import { ApproveGroupMember } from "./Mypage/ApproveGroupMember";
import { ApproveDataUseRequest } from "./Mypage/ApproveDataUseRequest";

import Group from "./Mypage/Group";
// import GroupMember from "./Group/GroupMember";
import Member from "./Mypage/ManageMember";
import ObjectDetectionModel from "./Mypage/ObjectDetectionModel";
import { MyInfo } from "./Mypage/MyInfo";

import { RequestGroupCreation } from "./Mypage/RequestGroupCreation";
import RequestGroupMember from "./Mypage/RequestGroupMember";
import RequestOrganization from "./Mypage/RequestOrganization";
import RequestDataUse from "./Mypage/RequestDataUse";

import common from "../common/common";
import "./Mypage.css";
import Api from "../Api";
import GeneralErrorWindow from "./common/GeneralErrorWindow";

import { useNotification } from "../common/customHook";
import { PC, MobileOrTablet, IsPC, IsTablet } from "./common/MediaQuery";
import dgLogger from "../common/dgLogger";

import { Trans, useTranslation } from "react-i18next";

import Maintenance from "./Mypage/Maintenance";
import { isRunOnMobileAppWebview, sendMessageToMobileAppWebview } from "./common/RNBridge";

function ContextAwareToggle({ children, eventKey, className, callback, showIcon = true }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(isCurrentEventKey, eventKey));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Row className={`gx-0 ${className} ${isCurrentEventKey ? "toggle-enabled" : ""}`} onClick={decoratedOnClick}>
            <Col className={`col-auto ${showIcon && "col-11"}`}>
                <span className="notosanskr-600 font-size-19 c-333">{children}</span>
            </Col>
            {showIcon ? (
                <Col className="col-1 d-flex align-items-center">
                    <img className="collapsable-icon text-end" src={isCurrentEventKey ? `${process.env.PUBLIC_URL}/leftmenu-iconminus@3x.png` : `${process.env.PUBLIC_URL}/leftmenu-iconplus.svg`} alt="" />
                </Col>
            ) : undefined}
        </Row>
    );
}

export default function MyPage(props) {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const { notifications } = useNotification();
    const [, updateState] = useState();
    const [state, setState] = useState({
        user: {},
        avatarUrl: null,
        toggleBottom: false, // set style to default
        selectItemIndex: "",

        isLoading: true,
        isSiteMember: false,
        isSiteAdmin: false,
        isGroupManager: false,
        isGroupMember: false,
    });
    const isPc = IsPC();
    const isTablet = IsTablet();

    const forceUpdate = useCallback(() => updateState({}), []);
    const [organization, setOrganization] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const getPermissions = async () => {
            try {
                const permList = JSON.parse(sessionStorage.getItem("permissions"));
                if (!permList) {
                    const payload = await Api.getCurrentUserInfo({ optPermissions: true });
                    common.storePermissionsInfo(payload.optPermissions);
                }

                const isSiteMember = common.hasSiteMemberPermission();
                const isSiteAdmin = common.hasSiteAdminPermission();
                const isGroupManager = common.hasGroupManagerPermission();
                const isGroupMember = common.hasGroupMemberPermission();
                // console.log(`isSiteMember ${isSiteMember}, isSiteAdmin ${isSiteAdmin} isGroupManager ${isGroupManager} isGroupMember ${isGroupMember}`);
                setState(prev => ({...prev, isLoading: false, isSiteMember, isSiteAdmin, isGroupManager, isGroupMember}));
            } catch (e) {
                setState(prev => ({...prev, isLoading: false}));
            }
        };
        getPermissions();
    }, []);

    useEffect(() => {
        document.getElementById("App").className = "my-page";
        if (context.loggedIn) {
            getOrganizationPermission();
            Api.getCurrentUserInfo()
                .then((payload) => {
                    setState((prev) => ({ ...prev, user: payload, avatarUrl: payload.profile_picture ? payload.profile_picture?.url : null }));
                })
                .catch((e) => dgLogger.error(e)());
        }
    }, [context.loggedIn]);

    useEffect(() => {
        location.pathname === "/Mypage/MyInfo" && setState((prev) => ({ ...prev, selectItemIndex: "" }));
    }, [location.pathname]);

    const getOrganizationPermission = async () => {
        setOrganization(await Api.getOrganizationPermission());
    };

    /**
     * session storage permission을 갱신하는 함수
     */
    const renewPermissions = useCallback((runUpdate = true) => {
        Api.getCurrentUserInfo({ optPermissions: true })
            .then((payload) => {
                // dgLogger.info("renew permissions success")();
                common.storePermissionsInfo(payload.optPermissions);
                runUpdate && forceUpdate();
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [forceUpdate]);

    const onUpdate = () => {
        Api.getCurrentUserInfo()
            .then((payload) => {
                setState({ ...state, user: payload, avatarUrl: payload.profile_picture ? payload.profile_picture?.url : null });
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    const getAvatarCircle = (user) => {
        if (!user || !user.name) return <div />;
        if (state.avatarUrl) return <img src={state.avatarUrl} alt="Profile" className="avatar" />;
        return <span className={`avatar ${isPc ? "avatar-54" : "avatar-70"} bg-secondary text-white rounded-circle`}>{state.user.name.charAt(0).toUpperCase()}</span>;
    };

    const mobileMenuItemClick = (e) => {
        setState({ ...state, selectItemIndex: e.target.dataset.index });
    };

    const getSubMenuItem = () => {
        const Approve = (
            <>
                {state.isSiteAdmin && (
                    <Col className={`col-auto${location.pathname === "/Mypage/ApproveGroupCreation" ? " toggle-enabled" : ""}`}>
                        <Link to={`/Mypage/ApproveGroupCreation`}>
                            {t("353")}
                        </Link>
                    </Col>
                )}
                {state.isGroupManager && (
                    <Col className={`col-auto${location.pathname === "/Mypage/ApproveGroupMember" ? " toggle-enabled" : ""}`}>
                        <Link to={`/Mypage/ApproveGroupMember`}>
                            {t("356")}
                        </Link>
                    </Col>
                )}
                {/* {isGroupManager && (
                    <Col className={`col-auto${location.pathname === "/Mypage/ApproveOrganization"? " toggle-enabled" : ""}`}>
                        <Link to={`/Mypage/ApproveOrganization`}>자료활용 공익단체</Link>
                    </Col>
                )} */}
                {(state.isGroupManager || state.isGroupMember || state.isSiteMember) && (
                    <Col className={`col-auto${location.pathname === "/Mypage/ApproveDataUseRequest" ? " toggle-enabled" : ""}`}>
                        <Link to={`/Mypage/ApproveDataUseRequest`}>
                            {t("357")}
                        </Link>
                    </Col>
                )}
            </>
        );
        const Request = (
            <>
                <Col className={`col-auto${location.pathname === "/Mypage/RequestGroupMember" ? " toggle-enabled" : ""}`}>
                    <Link to={`/Mypage/RequestGroupMember`}>
                        {t("359")}
                    </Link>
                </Col>
                <Col className={`col-auto${location.pathname === "/Mypage/RequestGroupCreation" ? " toggle-enabled" : ""}`}>
                    <Link to={`/Mypage/RequestGroupCreation`}>
                        {t("353")}
                    </Link>
                </Col>
                {/* <Col className={`col-auto${location.pathname === "/Mypage/RequestOrganization"? " toggle-enabled" : ""}`}>
                    <Link to={`/Mypage/RequestOrganization`}>자료활용 공익단체</Link>
                </Col> */}
                {organization && (
                    <Col className={`col-auto${location.pathname === "/Mypage/RequestDataUse" ? " toggle-enabled" : ""}`}>
                        <Link to={`/Mypage/RequestDataUse`}>
                            {t("357")}
                        </Link>
                    </Col>
                )}
            </>
        );

        switch (state.selectItemIndex) {
            case "3":
                return Approve;
            case "4":
                return Request;
            default:
        }
    };

    return (
        <Container>
            <span className="my-page-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff my-page">My page</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">
                    <Trans i18nKey={"10"} components={{tag: <></>}} />
                </span>
            </span>
            { (state.isSiteMember || state.isSiteAdmin) ? (
                <Row id="my-page" className="gx-0">
                    <Col className="sidebar" xs="auto">
                        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${isPc ? "leftmenu_bg.png" : "img_leftmenu_bg@3x.png"})` }} className="sidebar-top c-white">
                            <PC>
                                <Row className="gx-0">
                                    <Col className="notosanskr-24 sidebar-top-item">
                                        <Trans i18nKey={"10"} components={{tag: <b className="notosanskr-24b"/>}}/>
                                    </Col>
                                </Row>
                            </PC>
                            <Row className="sidebar-top-item gx-0 align-items-center">
                                <Col className="col-auto" style={{ width: `${isPc ? "54px" : "70px"}`, height: `${isPc ? "54px" : "70px"}`, marginRight: "18px" }}>
                                    {getAvatarCircle(state.user)}
                                </Col>
                                <Col>
                                    <div className="notosanskr-16b notosanskr-18b:sm text-break user-name">{state.user.name}</div>
                                    {/* <div className="notosanskr-15-500">&nbsp;</div> */}
                                </Col>
                            </Row>
                            <MobileOrTablet>
                                <Row className="sidebar-body-item gx-0 text-center">
                                    <Link to={`/Mypage/MyInfo`}>
                                        <Col className="notosanskr-15 c-white btn-detail">
                                            <span data-index="" onClick={(e) => mobileMenuItemClick(e)}>
                                                {t("349")}
                                            </span>
                                        </Col>
                                    </Link>
                                    {/* <Link to={`/Mypage/MyInfo`}>
                                    <Col className="notosanskr-14 c-white btn-edit">
                                        <span>수정하기</span>
                                    </Col>
                                </Link> */}
                                </Row>
                            </MobileOrTablet>
                        </div>
                        <PC>
                            <div className="sidebar-body c-white">
                                {/* <Row className="sidebar-body-item gx-0">
                                    <Col>
                                        <div className="notosanskr-15-500 c-91b2fd">E-mail</div>
                                        <div className="notosanskr-15">{state.user.email}</div>
                                    </Col>
                                </Row>
                                <Row className="sidebar-body-item gx-0">
                                    <Col>
                                        <div className="notosanskr-15-500 c-91b2fd">Contact number</div>
                                        <div className="notosanskr-15">{state.user.phone}</div>
                                    </Col>
                                </Row>
                                <Row className="sidebar-body-item gx-0">
                                    <Col>
                                        <div className="notosanskr-15-500 c-91b2fd">Address</div>
                                        <div className="notosanskr-15">{state.user.address1}</div>
                                    </Col>
                                </Row> */}
                                <Row className="sidebar-body-item gx-0 text-center">
                                    <Link to={`/Mypage/MyInfo`}>
                                        <Col className="notosanskr-14 c-white btn-detail">
                                            <span>{t("349")}</span>
                                        </Col>
                                    </Link>
                                    {/* <Link to={`/Mypage/MyInfo`}>
                                        <Col className="notosanskr-14 c-white btn-edit">
                                            <span>수정하기</span>
                                        </Col>
                                    </Link> */}
                                </Row>
                            </div>

                            <div className="sidebar-menu">
                                <Accordion>
                                    {state.isSiteAdmin ? (
                                        <>
                                            <div id="accordion-approval">
                                                <ContextAwareToggle
                                                    eventKey="-4"
                                                    className="sidebar-menu-item border-b1-ccc"
                                                    callback={(isClosed, key) => {
                                                        setState({ ...state, toggleBottom: false });
                                                    }}
                                                >
                                                <div className="position-relative">
                                                    {t("875")}
                                                </div>
                                                </ContextAwareToggle>
                                                <Accordion.Collapse eventKey="-4">
                                                    <div className="notosanskr-15 c-999">
                                                        {state.isSiteAdmin && (
                                                            <>
                                                            <div>
                                                                <Link to={`/Mypage/ObjectDetectionModel`}>{t("876")}</Link>
                                                            </div>
                                                            <div>
                                                                <Link to={`/MyPage/Maintenance`}>{t("889")}</Link>
                                                            </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                            <Link to={`/Mypage/Member`} className="py-0 px-0">
                                                <ContextAwareToggle eventKey="-3" className="sidebar-menu-item border-b1-ccc" callback={(isClosed, key) => {}} showIcon={false}>
                                                    {t("350")}
                                                </ContextAwareToggle>
                                            </Link>
                                        </>
                                    ) : state.isGroupManager ? (
                                        <Link to={`/Mypage/Member`} className="py-0 px-0">
                                            <ContextAwareToggle eventKey="-2" className="sidebar-menu-item border-b1-ccc" callback={(isClosed, key) => {}} showIcon={false}>
                                                {t("355")}
                                            </ContextAwareToggle>
                                        </Link>
                                    ) : undefined}

                                    {state.isSiteAdmin || state.isGroupManager ? (
                                        <Link to={`/Mypage/Group`} className="py-0 px-0">
                                            <ContextAwareToggle
                                                eventKey="-1"
                                                className="sidebar-menu-item border-b1-ccc"
                                                callback={(isClosed, key) => {
                                                    setState({ ...state, toggleBottom: false });
                                                }}
                                                showIcon={false}
                                            >
                                                {t("351")}
                                            </ContextAwareToggle>
                                        </Link>
                                    ) : undefined}

                                    {state.isSiteAdmin || state.isGroupManager || state.isGroupMember || state.isSiteMember ? (
                                        <div id="accordion-approval">
                                            <ContextAwareToggle
                                                eventKey="0"
                                                className="sidebar-menu-item border-b1-ccc"
                                                callback={(isClosed, key) => {
                                                    setState({ ...state, toggleBottom: false });
                                                }}
                                            >
                                                <div className="position-relative">
                                                    {t("352")}
                                                    {notifications && notifications.length > 0 && context.loggedIn ? (
                                                        <span className="c-fff notosanskr-14 position-absolute top-0 ms-2 bg-f4685e notification-badge">
                                                            {notifications.length > 9 ? "9+" : notifications.length}
                                                        </span>
                                                    ) : undefined}
                                                </div>
                                            </ContextAwareToggle>
                                            <Accordion.Collapse eventKey="0">
                                                <div className="notosanskr-15 c-999">
                                                    {state.isSiteAdmin && (
                                                        <div>
                                                            <Link to={`/Mypage/ApproveGroupCreation`}>{t("353")}</Link>
                                                        </div>
                                                    )}
                                                    {state.isGroupManager && (
                                                        <div>
                                                            <Link to={`/Mypage/ApproveGroupMember`}>{t("356")}</Link>
                                                        </div>
                                                    )}
                                                    {/*
                                                    {isGroupManager && (
                                                        <div>
                                                            <Link to={`/Mypage/ApproveOrganization`}>자료활용 공익단체</Link>
                                                        </div>
                                                    )}
                                                    */}
                                                    {(state.isGroupManager || state.isGroupMember || state.isSiteMember) && (
                                                        <div>
                                                            <Link to={`/Mypage/ApproveDataUseRequest`}>{t("357")}</Link>
                                                        </div>
                                                    )}
                                                </div>
                                            </Accordion.Collapse>
                                        </div>
                                    ) : undefined}

                                    {state.isSiteMember ? (
                                        <div id="accordion-request" className="bottom">
                                            <ContextAwareToggle
                                                eventKey="1"
                                                className="sidebar-menu-item border-b1-ccc"
                                                callback={(isClosed, key) => {
                                                    setState({ ...state, toggleBottom: false });
                                                }}
                                            >
                                                {t("358")}
                                            </ContextAwareToggle>
                                            <Accordion.Collapse eventKey="1">
                                                <div className="notosanskr-15 c-999">
                                                    <div>
                                                        <Link to={`/Mypage/RequestGroupMember`}>{t("359")}</Link>
                                                    </div>
                                                    <div>
                                                        <Link to={`/Mypage/RequestGroupCreation`}>{t("353")}</Link>
                                                    </div>
                                                    {/*
                                                    <div>
                                                        <Link to={`/Mypage/RequestOrganization`}>자료활용 공익단체</Link>
                                                    </div>
                                                    */}
                                                    {organization ? (
                                                        <div>
                                                            <Link to={`/Mypage/RequestDataUse`}>{t("357")}</Link>
                                                        </div>
                                                    ) : undefined}
                                                </div>
                                            </Accordion.Collapse>
                                        </div>
                                    ) : undefined}
                                    <Link to={`#`} className="py-0 px-0">
                                        <ContextAwareToggle
                                            eventKey="2"
                                            className="sidebar-menu-item border-b1-ccc"
                                            callback={(isClosed, key) => {
                                                setState({ ...state, toggleBottom: !isClosed });

                                                if (isRunOnMobileAppWebview()) {
                                                    sendMessageToMobileAppWebview({
                                                        type: "logout",
                                                        social: (context.loginProvider && context.loginProvider != "email"),
                                                        provider: context.loginProvider
                                                    });
                                                }

                                                Api.logout().finally(() => {
                                                    localStorage.removeItem("autoLogin");
                                                    context.setLoggedOut();
                                                    navigate("/");
                                                });
                                            }}
                                            showIcon={false}
                                        >
                                            {t("354")}
                                        </ContextAwareToggle>
                                    </Link>
                                </Accordion>
                            </div>
                            <div id="sidebar-bottom" className={state.toggleBottom ? "sidebar-bottom toggle-enabled" : "sidebar-bottom"} />
                        </PC>
                    </Col>
                    <MobileOrTablet>
                        <Col sm={12} key={location.pathname}>
                            <Row className="gx-0 sidebar-menu notosanskr-600 font-size-18 align-items-center text-center">
                                {state.isSiteAdmin && isTablet ? (
                                    <Col className={`sidebar-menu-item col-auto${state.selectItemIndex === "0" ? " toggle-enabled" : ""}`}>
                                        <Link to={`/Mypage/Member`} data-index="0" onClick={(e) => mobileMenuItemClick(e)}>
                                            {t("350")}
                                        </Link>
                                    </Col>
                                ) : (
                                    state.isGroupManager && isTablet && (
                                        <Col className={`sidebar-menu-item col-auto${state.selectItemIndex === "1" ? " toggle-enabled" : ""}`}>
                                            <Link to={`/Mypage/Member`} data-index="1" onClick={(e) => mobileMenuItemClick(e)}>
                                                {t("355")}
                                            </Link>
                                        </Col>
                                    )
                                )}

                                {(state.isSiteAdmin || state.isGroupManager) && isTablet && (
                                    <Col className={`sidebar-menu-item col-auto${state.selectItemIndex === "2" ? " toggle-enabled" : ""}`}>
                                        <Link to={`/Mypage/Group`} data-index="2" onClick={(e) => mobileMenuItemClick(e)}>
                                            {t("351")}
                                        </Link>
                                    </Col>
                                )}
                                {(state.isSiteMember || state.isSiteAdmin) && (
                                    <Col className={`sidebar-menu-item col-auto${state.selectItemIndex === "3" ? " toggle-enabled" : ""}`}>
                                        <div data-index="3" onClick={(e) => mobileMenuItemClick(e)}>
                                            {notifications && notifications.length > 0 && context.loggedIn ? (
                                                <span className="c-fff notosanskr-400 font-size-15 position-absolute top-0 start-100 bg-f4685e notification-badge">
                                                    {notifications.length > 9 ? "9+" : notifications.length}
                                                </span>
                                            ) : undefined}
                                            {t("352")}
                                        </div>
                                    </Col>
                                )}
                                {state.isSiteMember && (
                                    <Col className={`sidebar-menu-item col-auto${state.selectItemIndex === "4" ? " toggle-enabled" : ""}`}>
                                        <div data-index="4" onClick={(e) => mobileMenuItemClick(e)}>
                                            {t("358")}
                                        </div>
                                    </Col>
                                )}
                                {isTablet && (
                                    <Col className={`sidebar-menu-item col-auto`}>
                                        <div
                                            onClick={() => {
                                                if (isRunOnMobileAppWebview()) {
                                                    sendMessageToMobileAppWebview({
                                                        type: "logout",
                                                        social: (context.loginProvider && context.loginProvider != "email"),
                                                        provider: context.loginProvider
                                                    });
                                                }

                                                Api.logout().finally(() => {
                                                    localStorage.removeItem("autoLogin");
                                                    context.setLoggedOut();
                                                    navigate("/");
                                                });
                                            }}
                                        >
                                            {t("354")}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                            {state.selectItemIndex && <Row className="gx-0 sidebar-sub-menu notosanskr-400 font-size-17 align-items-center">{getSubMenuItem()}</Row>}
                        </Col>
                    </MobileOrTablet>
                    <Col className="content col-md" sm={12}>
                        <Routes>
                            <Route path={`/Member`} element={<Member key={location.key} />}></Route>
                            <Route path={`/Group`} element={<Group key={location.key} />}></Route>
                            <Route path={`/ObjectDetectionModel`} element={<ObjectDetectionModel key={location.key} />}></Route>
                            {/* <Route path={`/GroupMember`} element={<GroupMember key={location.key}/>}></Route> */}
                            <Route path={`/Maintenance`} element={<Maintenance key={location.key} />}></Route>
                            <Route path={`/ApproveOrganization`} element={<ApproveOrganization key={location.key} />}></Route>
                            <Route path={`/ApproveGroupCreation`} element={<ApproveGroupCreation key={location.key} />}></Route>
                            <Route path={`/ApproveGroupMember`} element={<ApproveGroupMember key={location.key} />}></Route>
                            <Route path={`/ApproveDataUseRequest`} element={<ApproveDataUseRequest key={location.key} />}></Route>
                            <Route path={`/RequestGroupCreation`} element={<RequestGroupCreation renewPermissions={renewPermissions} />}></Route>
                            <Route path={`/RequestGroupMember`} element={<RequestGroupMember renewPermissions={renewPermissions} />}></Route>
                            <Route path={`/RequestOrganization`} element={<RequestOrganization getOrganizationPermission={getOrganizationPermission} />}></Route>
                            <Route path={`/RequestDataUse`} element={<RequestDataUse key={location.key} />}></Route>
                            <Route path={`/MyInfo`} element={<MyInfo renewPermissions={renewPermissions} onUpdate={onUpdate} />}></Route>
                            <Route path={`*`} element={<div id="my-page">
                                <GeneralErrorWindow
                                    message={t("539")}
                                    onClick={() => {
                                        // redirecto to my info page
                                        navigate("/MyPage/MyInfo");
                                    }}
                                />
                            </div>}></Route>
                        </Routes>
                    </Col>
                </Row>
            ) : (
                state.isLoading ? <></> : <div id="my-page">
                    <GeneralErrorWindow
                        message={t("539")}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                </div>
            )}
        </Container>
    );
}
