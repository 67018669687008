import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const DynamicFormRequest = (props) => {
    const { t } = useTranslation();
    const icon = (
        <img src={process.env.PUBLIC_URL + `/pop_data_change.png`} srcSet={`${process.env.PUBLIC_URL}/pop_data_change@2x.png 2x, ${process.env.PUBLIC_URL}/pop_data_change@3x.png 3x`} alt="" />
    );
    const header = <div>{t("459")}</div>;
    const body = <div>{t("483")}</div>;

    return <Modal onRequestClose={props.onCancel} onConfirm={props.onConfirm} onCancel={props.onCancel} icon={icon} header={header} body={body} />;
};

const DynamicFormRequested = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/icon_accept_terms.png`} srcSet={`${process.env.PUBLIC_URL}/icon_accept_terms@2x.png 2x, ${process.env.PUBLIC_URL}/icon_accept_terms@3x.png 3x`} alt="" />;
    const header = <div>{t("459")}</div>;
    const body = <div>{t("484")}</div>;

    return (
        <Modal
            onRequestClose={props.onConfirm}
            onConfirm={props.onConfirm}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const DynamicFormNoModification = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_password_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_password_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_password_error@3x.png 3x`} alt="" />;
    const header = <div>{t("459")}</div>;
    const body = <div>{t("485")}</div>;

    return (
        <Modal
            onRequestClose={props.onConfirm}
            onConfirm={props.onConfirm}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export {
    DynamicFormRequest,
    DynamicFormRequested,
    DynamicFormNoModification,
};
