import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AppContext } from "../../AppContext";
import common from "../../common/common";
import "./Filter.css";
import { InputText } from "../Input";
import { Row, Col } from "react-bootstrap";
import CustomCheckbox from "../common/CustomCheckbox";
import { Mobile, PC, Tablet } from "../common/MediaQuery";
import Api from "../../Api";
import { useIsMount, useStateWithCallback } from "../../common/customHook";
import DateBox from "../common/DateBox";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";
import { LOCATION2STRTABLEMAP } from "@ocean-knight/shared/src/utility";

export default function Filter(props) {
    const { t } = useTranslation();
    const [locations, setLocations] = useStateWithCallback([]);
    const [groups, setGroups] = useStateWithCallback([]);
    const [projects, setProjects] = useStateWithCallback([]);
    const [selectProjects, setSelectProjects] = useState([]);
    const context = useContext(AppContext);
    const isMount = useIsMount();
    const [keyword, setKeyword] = useState("");
    const [name, setName] = useState("");
    const [prevDate, setPrevDate] = useStateWithCallback({startDate:"", endDate:"", date: ""});
    const resettingStep = useRef(0);

    const getLocationOptions = useCallback(() => {
        return Object.keys(LOCATION2STRTABLEMAP).map(v => ({value: v, label: t(`${LOCATION2STRTABLEMAP[v]}`) }));
    }, [t]);

    const locationOptions = getLocationOptions();

    /**
    * params로 넘어온 데이터가 있다면 배열로 만들어 반환
    *
    * @returns MultiSelect에서 사용 할 배열
    */
    const setListFromFilterOption = useCallback((options = []) => {
        return options.map((option) => (getLocationOptions().find(v => v.value == option) || { value: option, label: option}));
    }, [getLocationOptions]);

    /**
     * params로 넘어온 그룹 데이터가 있다면 배열로 만들어 반환
     *
     * @returns MultiSelect에서 사용 할 배열
     */
    const setListFromFilterGroupOption = useCallback((options = []) => {
        return options.reduce((acc, option) => {
            const group = props.groups.find(group => group._id === option);
            if (group) acc.push({ value: group?._id, label: common.i18nGroupName(group?.name) });
            return acc;
        }, []);
    }, [props.groups]);

    useEffect(() => {
        setPrevDate({
            startDate: props.filterOption.startDate,
            endDate: props.filterOption.endDate,
            date: props.filterOption.date || ""
        });
    }, [props.filterOption.startDate, props.filterOption.endDate, props.filterOption.date, setPrevDate]);

    // 전달 된 filterOption이 있다면 해당 값으로 filter의 값 설정
    useEffect(() => {
        if (Object.keys(props.filterOption).length) {
            try {
                document.getElementById("startDate").value = props.filterOption.startDate;
                document.getElementById("endDate").value = props.filterOption.endDate;
                document.getElementById("date").innerHTML = props.filterOption.date || document.getElementById("date").innerHTML;

                document.getElementById("name").value = props.filterOption.name;
                document.getElementById("myReport").checked = JSON.parse(props.filterOption.myReport);
                document.getElementById("name").disabled = JSON.parse(props.filterOption.myReport);
                document.getElementById("keyword").value = props.filterOption.keyword;
                props.filterOption.groups && props.groups.length && setGroups(setListFromFilterGroupOption(props.filterOption.groups.split("|")));
                props.filterOption.locations && setLocations(setListFromFilterOption(props.filterOption.locations.split("|")));
            }
            catch (e) {
                dgLogger.error(e)();
            }
        }
    }, [props.filterOption, setListFromFilterGroupOption, setListFromFilterOption, props.groups.length, setGroups, setLocations]);

    /**
     * params로 넘어온 프로젝트 id가 있다면 배열로 만들어 반환
     *
     * @returns MultiSelect에서 사용 할 배열
     */
    const setListFromFilterProjectOption = useCallback((options) => {
        const selectProject = props.filterOption.projects?.split("|");
        return options.reduce((acc, option) => {
            const project = selectProject?.find(project => project === option?._id);
            if (project) acc.push({ value: option?._id, label: option?.name });
            return acc;
        }, []);
    }, [props.filterOption.projects]);

    useEffect(() => {
        if (groups.length) {
            const payload = {
                groups: groups.map(group => group.value),
                sort: { register_date: -1 },
                withHidden: false,
            };

            Api.getProjectListAll(payload)
                .then((payload) => {
                    if (!isMount.current) return;
                    setSelectProjects(setListFromFilterProjectOption(payload));
                    setProjects(payload);
                })
                .catch((e) => {
                    dgLogger.error(e)();
                });
        }
        else {
            setSelectProjects(setListFromFilterProjectOption([]));
            setProjects([]);
        }
    }, [groups, props.filterOption.projects, isMount, setListFromFilterProjectOption, setProjects]);

    /**
     * 내 자료를 눌렀을 때 등록자에 현재 로그인 된 사용자의 이름 삽입
     *
     * @param {Event} e
     */
    const myReportClick = (e) => {
        if (e.target.checked) {
            document.getElementById("name").value = sessionStorage.getItem('name');
            document.getElementById("name").disabled = true;
        }
        else {
            document.getElementById("name").value = "";
            document.getElementById("name").disabled = false;
        }
    };

    /**
     * 필터 초기화를 눌렀을 때 현재 filter를 초기화 하는 함수
     */
    const resetFilter = () => {
        const searchButton = document.getElementById("search");
        searchButton.disabled = true;

        document.getElementById("startDate").value = "";
        document.getElementById("endDate").value = "";
        document.getElementById("date").innerHTML = t("60");
        document.getElementById("date").style.color = "#aaa";

        document.getElementById("name").value = "";
        document.getElementById("name").disabled = false;
        document.getElementById("myReport").checked = false;
        document.getElementById("keyword").value = "";

        resettingStep.current = 0;
        const callback = (_ /*newState*/) => {
            resettingStep.current ++;
            if (resettingStep.current >= 4) {
                resettingStep.current = 0;

                const searchButton = document.getElementById("search");
                searchButton.disabled = false;
            }
        };

        setPrevDate({startDate:"", endDate:"", date: ""}, callback);
        setGroups([], callback);
        setProjects([], callback);
        setLocations([], callback);
    };

    const getReportFilterUrl = useCallback((param) => {
        return common.getReportFilterUrl(param);
    }, []);

    /**
     * 검색 버튼을 누르면 현재 filter의 값으로 url을 생성하여 해당 url로 navigate
     */
    const searchClick = (e) => {
        const filter = getReportFilterUrl({
            groups,
            selectProjects,
            locations,
            startDate: document.getElementById("startDate").value,
            endDate: document.getElementById("endDate").value,
            date: encodeURIComponent(prevDate.date),
            name: encodeURIComponent(document.getElementById("name").value),
            myReport: document.getElementById("myReport").checked,
            keyword: encodeURIComponent(document.getElementById("keyword").value)
        });
        props.searchButtonClick(filter);
    };

    // option으로 사용 할 array를 multi-select library에 맞게 object 생성
    const getOptions = (array = []) => {
        return array.reduce((acc, cur) => {
            acc.push({ value: cur._id, label: common.i18nGroupName(cur.name) });
            return acc;
        }, []);
    };

    return (
        <>
            <PC>
                <Row className="filter gx-0 align-items-center">
                    <Col>
                        <Row className="gx-0 align-items-center">
                            <Col className="notosanskr-700 font-size-18 title col-sm-auto">
                                {t("34")}
                            </Col>
                            <Col>
                                <InputText
                                    className="c-333 notosanskr-400 font-size-14"
                                    id="keyword"
                                    onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                    defaultValue={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                            </Col>
                            <Col className="col-auto text-end align-items-center" style={{ width: "auto", marginRight: "20px" }}>
                                <CustomCheckbox id="myReport" onChange={myReportClick} disabled={!context.loggedIn || common.hasSiteAdminPermission()}>
                                    <span className="c-333 notosanskr-700 font-size-14 ml-18px text-center">
                                        {t("37")}
                                    </span>
                                </CustomCheckbox>
                            </Col>
                            <Col className="col-sm-auto text-start">
                                <span className="filter-and-my-report">
                                    <button className="reset-filter notosanskr-400 font-size-16 c-fff" onClick={resetFilter}>
                                        <img src={process.env.PUBLIC_URL + `/icon_refresh.png`} srcSet={`${process.env.PUBLIC_URL}/icon_refresh@2x.png 2x`} alt="" />
                                        {t("69")}
                                    </button>
                                </span>
                            </Col>
                        </Row>
                        <Row className="gx-0 align-items-center">
                            <Col className="notosanskr-700 font-size-18 title col-sm-auto mt-12px">
                                {t("35")}
                            </Col>
                            <Col>
                                <Row className="gx-3">
                                    <Col className="filter-option">
                                        <MultiSelect
                                            className="c-333 notosanskr-400 font-size-14 multi-select-group"
                                            options={getOptions(props?.groups)}
                                            value={groups}
                                            onChange={setGroups}
                                            disableSearch={true}
                                            overrideStrings={{ selectSomeItems: t("38"), selectAll: t("39"), selectAllFiltered: t("39"), allItemsAreSelected: t("39") }}
                                        />
                                    </Col>
                                    <Col className="filter-option">
                                        <MultiSelect
                                            className="c-333 notosanskr-400 font-size-14 multi-select-project"
                                            options={getOptions(projects)}
                                            value={selectProjects}
                                            onChange={setSelectProjects}
                                            disableSearch={true}
                                            overrideStrings={{ selectSomeItems: t("3"), selectAll: t("40"), selectAllFiltered: t("40"), allItemsAreSelected: t("40") }}
                                            disabled={!projects.length}
                                        />
                                    </Col>
                                    <Col className="filter-option">
                                        <MultiSelect
                                            className="c-333 notosanskr-400 font-size-14 multi-select"
                                            options={locationOptions}
                                            value={locations}
                                            onChange={setLocations}
                                            disableSearch={true}
                                            overrideStrings={{ selectSomeItems: t("41"), selectAll: t("42"), selectAllFiltered: t("42"), allItemsAreSelected: t("42") }}
                                        />
                                    </Col>
                                    <Col className="filter-option">
                                        <DateBox
                                            startDate={prevDate.startDate}
                                            endDate={prevDate.endDate}
                                            date={prevDate.date}
                                            onChange={(startDate, endDate, date) => setPrevDate({startDate, endDate, date})}/>
                                    </Col>
                                    <Col className="filter-option">
                                        <InputText
                                            className="c-333 notosanskr-400 font-size-14"
                                            id="name"
                                            placeholder={t("26")}
                                            onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                            defaultValue={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-sm-auto text-center" style={{ width: "200px" }}>
                        <button id="search" onClick={(e) => searchClick(e)}>
                            <span className="c-fff notosanskr-400 font-size-16">
                                {t("70")}
                            </span>
                        </button>
                    </Col>
                </Row>
            </PC>

            <Tablet>
                <Row className="filter gx-0 align-items-center">
                    <Col>
                        <Row className="gx-0 align-items-center">
                            <Col className="notosanskr-700 font-size-18 title col-sm-auto">
                                {t("34")}
                            </Col>
                            <Col>
                                <InputText
                                    className="c-333 notosanskr-400 font-size-14"
                                    id="keyword"
                                    onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                    defaultValue={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="gx-0 align-items-center">
                            <Col className="notosanskr-700 font-size-18 title col-sm-auto mt-12px">
                                {t("35")}
                            </Col>
                            <Col>
                                <Row className="gx-3">
                                    <Col className="filter-option">
                                        <MultiSelect
                                            className="c-333 notosanskr-400 font-size-14 multi-select-group text-wrap text-break"
                                            options={getOptions(props?.groups)}
                                            value={groups}
                                            onChange={setGroups}
                                            disableSearch={true}
                                            overrideStrings={{ selectSomeItems: t("38"), selectAll: t("39"), selectAllFiltered: t("39"), allItemsAreSelected: t("39") }}
                                        />
                                    </Col>
                                    <Col className="filter-option">
                                        <MultiSelect
                                            className="c-333 notosanskr-400 font-size-14 multi-select-project text-wrap text-break"
                                            options={getOptions(projects)}
                                            value={selectProjects}
                                            onChange={setSelectProjects}
                                            disableSearch={true}
                                            overrideStrings={{ selectSomeItems: t("3"), selectAll: t("40"), selectAllFiltered: t("40"), allItemsAreSelected: t("40") }}
                                            disabled={!projects.length}
                                        />
                                    </Col>
                                    <Col className="filter-option">
                                        <MultiSelect
                                            className="c-333 notosanskr-400 font-size-14 multi-select text-wrap text-break"
                                            options={locationOptions}
                                            value={locations}
                                            onChange={setLocations}
                                            disableSearch={true}
                                            overrideStrings={{ selectSomeItems: t("41"), selectAll: t("42"), selectAllFiltered: t("42"), allItemsAreSelected: t("42") }}
                                        />
                                    </Col>
                                    <Col className="filter-option">
                                        <DateBox
                                            startDate={prevDate.startDate}
                                            endDate={prevDate.endDate}
                                            date={prevDate.date}
                                            onChange={(startDate, endDate, date) => setPrevDate({startDate, endDate, date})}/>
                                    </Col>
                                    <Col className="filter-option">
                                        <InputText
                                            className="c-333 notosanskr-400 font-size-14"
                                            id="name"
                                            placeholder={t("26")}
                                            onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                            defaultValue={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Col>
                                    <Col className="col-sm-auto text-end filter-option d-flex align-items-center" style={{ justifyContent: "end" }}>
                                        <CustomCheckbox id="myReport" onChange={myReportClick} disabled={!context.loggedIn || common.hasSiteAdminPermission()}>
                                            <span className="c-333 notosanskr-700 font-size-14 ml-18px">
                                                {t("37")}
                                            </span>
                                        </CustomCheckbox>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-sm-auto text-center" style={{ width: "200px", marginLeft: "2rem", paddingLeft: "2rem" }}>
                        <Row className="gx-0">
                            <Col className="col-sm-auto text-start">
                                <span className="filter-and-my-report">
                                    <button className="reset-filter notosanskr-400 font-size-16 c-fff" onClick={resetFilter}>
                                        <img src={process.env.PUBLIC_URL + `/icon_refresh.png`} srcSet={`${process.env.PUBLIC_URL}/icon_refresh@2x.png 2x`} alt="" />
                                        {t("69")}
                                    </button>
                                </span>
                            </Col>
                        </Row>
                        <Row className="gx-0 mt-12px">
                            <button id="search" onClick={(e) => searchClick(e)}>
                                <span className="c-fff notosanskr-400 font-size-16">
                                    {t("70")}
                                </span>
                            </button>
                        </Row>
                    </Col>
                </Row>
            </Tablet>

            <Mobile>
                <div className="filter gx-0 align-items-center">
                    <Row className="gx-0">
                        <Col></Col>
                        <Col className="notosanskr-700 font-size-18 title text-center">
                            {t("34")}
                        </Col>
                        <Col className="filter-title d-flex" style={{ justifyContent: "end" }}>
                            <CustomCheckbox id="myReport" onChange={myReportClick} disabled={!context.loggedIn || common.hasSiteAdminPermission()}>
                                <span className="c-333 notosanskr-400 font-size-14" style={{ paddingRight: "0.5rem" }}>
                                    {t("37")}
                                </span>
                            </CustomCheckbox>
                        </Col>
                    </Row>
                    <Row className="gx-0">
                        <Col>
                            <InputText
                                className="c-333 notosanskr-400 font-size-18"
                                id="keyword"
                                onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                defaultValue={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="gx-0">
                        <Col className="notosanskr-700 font-size-18 title text-center">
                            {t("35")}
                        </Col>
                    </Row>
                    <Row className="gx-0">
                        <Col className="filter-title">
                            <MultiSelect
                                className="c-333 notosanskr-400 font-size-14 multi-select-group"
                                options={getOptions(props?.groups)}
                                value={groups}
                                onChange={setGroups}
                                disableSearch={true}
                                overrideStrings={{ selectSomeItems: t("38"), selectAll: t("39"), selectAllFiltered: t("39"), allItemsAreSelected: t("39") }}
                            />
                        </Col>
                        <Col className="col-auto" style={{ width: "2%" }} />
                        <Col className="filter-title">
                            <MultiSelect
                                className="c-333 notosanskr-400 font-size-14 multi-select-project"
                                options={getOptions(projects)}
                                value={selectProjects}
                                onChange={setSelectProjects}
                                disableSearch={true}
                                overrideStrings={{ selectSomeItems: t("3"), selectAll: t("40"), selectAllFiltered: t("40"), allItemsAreSelected: t("40") }}
                                disabled={!projects.length}
                            />
                        </Col>
                    </Row>
                    <Row className="gx-0 maxh-45px">
                        <Col className="filter-title">
                            <MultiSelect
                                className="c-333 notosanskr-400 font-size-14 multi-select"
                                options={locationOptions}
                                value={locations}
                                onChange={setLocations}
                                disableSearch={true}
                                overrideStrings={{ selectSomeItems: t("41"), selectAll: t("42"), selectAllFiltered: t("42"), allItemsAreSelected: t("42") }}
                            />
                        </Col>
                        <Col className="col-auto" style={{ width: "2%" }} />
                        <Col className="filter-title">
                            <DateBox
                                startDate={prevDate.startDate}
                                endDate={prevDate.endDate}
                                date={prevDate.date}
                                onChange={(startDate, endDate, date) => setPrevDate({startDate, endDate, date})}/>
                        </Col>
                    </Row>
                    <Row className="gx-0 align-items-center">
                        <Col className="filter-title">
                            <InputText
                                className="c-333 notosanskr-400 font-size-14"
                                id="name"
                                placeholder={t("26")}
                                onKeyPress={(e) => e.key === "Enter" && searchClick()}
                                defaultValue={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Col>
                        <Col className="col-auto" style={{ width: "2%" }} />
                        <Col className="filter-title">
                            <span className="filter-and-my-report">
                                <button className="reset-filter notosanskr-400 font-size-16 c-fff" onClick={resetFilter}>
                                    <img src={process.env.PUBLIC_URL + `/icon_refresh.png`} srcSet={`${process.env.PUBLIC_URL}/icon_refresh@2x.png 2x`} alt="" />
                                    {t("69")}
                                </button>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button id="search" onClick={(e) => searchClick(e)}>
                                <span className="c-fff notosanskr-400 font-size-16">
                                    {t("70")}
                                </span>
                            </button>
                        </Col>
                    </Row>
                </div>
            </Mobile>
        </>
    );
}
