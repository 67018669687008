import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from "react";
import ReactModal from "react-modal";
import ImageGallery from 'react-image-gallery';
import "./ImagePreviewWindow.css";
import ImagePreviewTable from "./ImagePreviewTable";
import { IsMobile, useMobile } from "./MediaQuery";
import { POPUP as GeneralPopup } from "../../common/defines";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import common from "../../common/common";

const POPUP = {
    ...GeneralPopup,
    CancelEdit: 1001,
};

export default function ImagePreviewWindow(props) {
    const { t } = useTranslation();
    const images = props.images.map((image, index) => {
        if (image) {
            return ({
                index: index,
                original: image.watermark,
                thumbnail: image.thumbnail,
                originalClass: "images-original",
                thumbnailClass: "images-thumbnail",
                imageSize: props.bboxes?.[index]?.imageSize || undefined,
                boundingBox: props.bboxes?.[index]?.boundingBox || undefined
            });
        }
        else {
            return undefined;
        }
    }).filter(image => image);
    const [index, setIndex] = useState(props.index);
    const [displayTable, setDisplayTable] = useState(true);
    const tmpAnswers = useRef(JSON.parse(JSON.stringify(props.answers || [])));
    const imageGalleryRef = useRef(null);
    const isMobile = IsMobile();
    const [popup, setPopup] = useState(POPUP.None);
    const getNthBoundingBoxColor = common.useGetNthBoundingBoxColor();

    const cancelEditPopup = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/icon_cancel_data_use_request.png`} srcSet={`${process.env.PUBLIC_URL}/icon_cancel_data_use_request@2x.png 2x, ${process.env.PUBLIC_URL}/icon_cancel_data_use_request@3x.png 3x`} alt="" />;
        const header = <div>
            {t("514")}
        </div>;
        const body = <>{t("515").split("\n").map((v, i) => <div key={i}>{v}</div>)}</>;

        return (
            <Modal
                onRequestClose={() => setPopup(POPUP.None)}
                onConfirm={props.onCancel}
                onCancel={() => setPopup(POPUP.None)}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const onCancel = () => {
        if (!props.answers) {
            props.onCancel();
            return;
        }
        const prevAnswers = props.answers.reduce((acc, cur, rowIndex) => {
            const list = !cur ? [] : cur.filter(data => data?.answer)
                .map((data, colIndex) => (
                    {
                        [`${rowIndex}${colIndex}`]: data.answer 
                    }
                ));
            acc.push(...list);
            return acc;
        }, []);
        const currentAnswers = tmpAnswers.current.reduce((acc, cur, rowIndex) => {
            acc.push(...cur.filter(data => data?.answer).map((data, colIndex) => ({ [`${rowIndex}${colIndex}`]: data.answer })));
            return acc;
        }, []);

        if (JSON.stringify(prevAnswers) === JSON.stringify(currentAnswers)) {
            props.onCancel();
        }
        else {
            setPopup(POPUP.CancelEdit);
        }
    };

    const renderImageGallaryItem = (item) => {
        return (<>
            {/* to calculate canvas content scale using resized (under browser) image size */}
            <img src={item.original} className="image-gallery-image d-none position-absolute" alt=""
                id={`hidden-image-for-image-index-${item.index}`}
            />
            {/* to draw image and bounding boxes on canvas */}
            <canvas className="image-gallery-image"
                id={`overlay-canvas-for-image-index-${item.index}`}
            />
            {/* bounding box labels to calculate size of fonts */}
            {item.boundingBox && <div className="d-none">
                {item.boundingBox.map((v, i) =>
                    <span
                        key={i}
                        id={`image-gallery-image-${item.index}-texts-${i}`}>
                            {`${String.fromCharCode(65/*A*/ + v.class_number)} : ${(v.conf < 0) ? "custom" : v.conf.toFixed(2)}`}
                    </span>
                )}
            </div> }
        </>);
    };

    /**
     * RGB 색상의 상대적 명도를 계산합니다.
     * 명도는 sRGB 명도 공식을 사용하여 계산됩니다.
     *
     * sRGB 변환 공식: (kotlin `RGBToXYZ` 함수 구현 참고)
     * - 값이 0.04045 이하인 경우: 값 / 12.92
     * - 값이 0.04045 초과인 경우: ((값 + 0.055) / 1.055) ^ 2.4
     *
     * @param {number} r - 색상의 빨간색 구성 요소 (0-255).
     * @param {number} g - 색상의 녹색 구성 요소 (0-255).
     * @param {number} b - 색상의 파란색 구성 요소 (0-255).
     * @returns {number} - 색상의 상대적 명도 (0.0 - 1.0).
     */
    const calculateLuminance = useCallback((r, g, b) => {
        // RGB 값을 0-255 범위에서 0-1 범위로 변환
        const [rs, gs, bs] = [r / 255, g / 255, b / 255].map(value => {
            // sRGB 변환 공식 적용
            // 값이 0.04045 이하인 경우 12.92로 나누는 이유:
            // 작은 값일수록 감마 보정 적용 시 명도가 더 작아지므로 이를 보정하기 위함
            // 값이 0.04045 이하인 경우: 값 / 12.92
            // 값이 0.04045 초과인 경우: ((값 + 0.055) / 1.055) ^ 2.4
            return value <= 0.04045 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
        });

        // sRGB 명도 공식을 사용하여 상대적 명도 계산
        // 명도 = 0.2126 * R_s + 0.7152 * G_s + 0.0722 * B_s
        return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
    }, []);

    const renderImageOnly = useCallback((image, canvas) => {
        image.classList.remove("d-none");
        image.classList.remove("position-absolute");
        canvas.classList.add("d-none");
    }, []);

    const renderCanvas = useCallback((image, canvas) => {
        image.classList.add("d-none");
        image.classList.add("position-absolute");
        canvas.classList.remove("d-none");
    }, []);

    const tryRenderBoundingBoxItems = useCallback((currentIndex) => {
        if (!images[currentIndex]) return console.log(`can not found ${currentIndex}th target image on image list`);

        // mobile size 에서 Image Preview Window 를 띄울때, useEffect 에 의해 즉시 호출될 수 있음.
        // 이 경우 html 초기화 되기 전에 불리는 상황이 발생하기도 함. 따라서, element 를 찾지 못하면, return 하도록 처리
        const image = document.getElementById(`hidden-image-for-image-index-${images[currentIndex].index}`);
        if (!image) return console.log("can not found matching image tag");

        const canvasID = `overlay-canvas-for-image-index-${images[currentIndex].index}`;
        const canvas = document.getElementById(canvasID);

        // props.type 이 null 이라면, 이는 단순 이미지(has no bounding boxes) 를 의미함.
        // imageSize 가 null 이거나, boundingBox 가 null 이라면, label 이 없거나, 아직 제출되지 않은 데이터를 의미함.
        // 따라서, canvas 를 감추고, image 만 출력
        let bboxRenderable = !!props.type && !!(images[currentIndex]?.imageSize) && !!(images[currentIndex]?.boundingBox);
        if (!bboxRenderable) return renderImageOnly(image, canvas);
        // 반대의 경우라면, bounding box 가 있다는 의미이므로,
        // image 를 감추고 canvas 에 이미지 및 bounding box 를 draw 하도록 준비
        else { renderCanvas(image, canvas); }

        canvas.width  = images[currentIndex].imageSize.width;
        canvas.height  = images[currentIndex].imageSize.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(image, 0, 0, images[currentIndex].imageSize.width, images[currentIndex].imageSize.height);

        const baseScale = isMobile ? 3 : 1;
        const scale = (images[currentIndex].imageSize.width / image.width) * baseScale;
        // console.log(`(${images[currentIndex].imageSize.width} / ${image.width}) * ${baseScale}`);
        // console.log("scale : ", scale);
        const fontSize = 32 * scale;

        ctx.beginPath();
        ctx.lineWidth = 2 * scale;
        ctx.font = `${fontSize}px arial`;

        const textPadding = 14/*px*/ * scale;

        images[currentIndex].boundingBox.map((element, i) => {
            if (element.class_number < 0) {
                console.info("class number is not valid.");
                return undefined;
            }

            const [r, g, b] = getNthBoundingBoxColor(element.class_number);
            const labelColor = `rgba(${r}, ${g}, ${b}, 1.0)`;
            ctx.strokeStyle = labelColor;

            const sx = element.coord.sx;
            const sy = element.coord.sy;
            const ex = element.coord.ex;
            const ey = element.coord.ey;
            const w = ex - sx;
            const h = ey - sy;
            ctx.beginPath(); // Start a new path
            ctx.rect(sx, sy, w, h);
            ctx.stroke();

            const text = document.getElementById(`image-gallery-image-${images[currentIndex].index}-texts-${i}`);
            const textContent = text.textContent;

            // 텍스트 크기 측정
            var textMetrics = ctx.measureText(textContent);
            var textWidth = textMetrics.width + (textPadding*2);
            var textHeight = fontSize + textPadding; // 폰트 사이즈를 텍스트 높이로 사용

            // 텍스트를 그릴 위치 (왼쪽 상단 정렬)
            var fx = sx - ctx.lineWidth / 2; // bbox left
            var fy = sy - textHeight + ctx.lineWidth; // bbox top

            // 만약 text 가 오른쪽 영역 밖으로 나갔다면, text 출력을 left align 처리
            if (fx + textWidth > canvas.width) fx = ex - textWidth; // bbox right - text width
            // 만약 text 가 상단 영역 밖으로 나갔다면, text 출력을 bottom 에 출력 처리
            if (fy < 0) fy = ey; // bbox bottom

            // 배경색 그리기 (텍스트 영역만큼)
            ctx.fillStyle = labelColor;
            ctx.fillRect(fx, fy, textWidth, textHeight - ctx.lineWidth);

            // 텍스트 그리기
            ctx.fillStyle = calculateLuminance(r, g, b) < 0.5 ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)';
            ctx.fillText(textContent, fx + textPadding, fy + textHeight - (ctx.lineWidth * 2) - (textPadding/2));

            return undefined;
        });
    }, [isMobile, images, getNthBoundingBoxColor, calculateLuminance, props.type, renderCanvas, renderImageOnly]);

    // ??? why this useEffect called 3 times ???
    useEffect(() => {
        if (imageGalleryRef.current) {
            tryRenderBoundingBoxItems(index);
        }
    }, [index, isMobile, tryRenderBoundingBoxItems]);

    return (
        <ReactModal
            isOpen={true}
            className={props.className ? props.className : "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"image-preview"}
        >
            {popup === POPUP.CancelEdit && cancelEditPopup()}
            <div className="image-preview-button-group" style={{border: "solid 0px red"}}>
                {props.type &&
                    <>
                        <div className="info">
                            <img src={process.env.PUBLIC_URL + `/i.png`} onClick={() => {
                                setDisplayTable(!displayTable);
                            }}
                                alt=""
                            />
                        </div>
                        <div style={{ width: "2rem" }}></div>
                    </>
                }
                <div className="close">
                    <img src={process.env.PUBLIC_URL + `/x.png`} onClick={onCancel}
                        alt=""
                    />
                </div>
            </div>
            <div className={props.type && displayTable ? "display-table" : ""}
                onLoad={() => {
                    const currentIndex = images.findIndex(image => image.index === props.index);
                    tryRenderBoundingBoxItems(currentIndex);
                }}
            >
                <ImageGallery
                    ref={imageGalleryRef}
                    items={images}
                    lazyLoad={true}
                    renderItem = {renderImageGallaryItem}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showThumbnails={!isMobile}
                    thumbnailPosition={"left"}
                    startIndex={images.findIndex(image => image.index === props.index)}
                    onSlide={(currentIndex) => {
                        if (!props.type) return ;

                        setIndex(images[currentIndex].index);
                    }}
                    onBeforeSlide={(currentIndex) => {
                        tryRenderBoundingBoxItems(currentIndex);

                        if (!props.type) return ;

                        const table = document.getElementsByClassName("image-preview-table");
                        if (table[table.length - 1]) table[table.length - 1].style.display = "none";
                        if (props.type !== "edit") return ;
                        const items_list = document.getElementsByClassName("answers");
                        const items_array = Array.prototype.slice.call(items_list);
                        const answers = items_array.map(item => {
                            return ({
                                _id: item.id,
                                answer : parseInt(item.value) || 0
                            });
                        });
                        tmpAnswers.current[index] = answers;
                    }}
                />
                {props.type && displayTable && (
                    <ImagePreviewTable
                        ref={tmpAnswers}
                        index={index}
                        type={props.type}
                        questions={props.questions}
                        onConfirm={(answers, sum) => {
                            props.onConfirm(answers, sum);
                            props.onCancel();
                        }}
                        onCancel={onCancel}
                        rowColor={true}
                    />
                )}
            </div>
        </ReactModal>
    );
}
