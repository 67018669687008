import React from "react";
import { useMediaQuery } from "react-responsive";

const IsMobile = () => {
    return useMediaQuery({
        query: "(max-width:923px)"
    });
};

const useMobile = () => {
    return useMediaQuery({
        query: "(max-width:923px)"
    });
};

const IsMobileOrTablet = () => {
    return useMediaQuery({
        query: "(max-width:1519px)"
    });
};

const IsTablet = () => {
    return useMediaQuery({
        query: "(min-width:924px) and (max-width:1519px)"
    });
};

const useTablet = () => {
    return useMediaQuery({
        query: "(min-width:924px) and (max-width:1519px)"
    });
};

const IsTabletOrPC = () => {
    return useMediaQuery({
        query: "(min-width:924px)"
    });
};

const IsPC = () => {
    return useMediaQuery({
        query: "(min-width:1520px)"
    });
};

const usePC = () => {
    return useMediaQuery({
        query: "(min-width:1520px)"
    });
};

const Mobile = ({ children }) => {
    return <>{IsMobile() && children}</>;
};

const Tablet = ({ children }) => {
    return <>{IsTablet() && children}</>;
};

const MobileOrTablet = ({ children }) => {
    return <>{IsMobileOrTablet() && children}</>;
};

const PC = ({ children }) => {
    return <>{IsPC() && children}</>;
};

const TabletOrPC = ({ children }) => {
    return <>{IsTabletOrPC() && children}</>;
};

const IsWebview = () =>{
    // ref
    // 1. https://github.com/Radicis/react-open-in-app-banner
    // 2. https://github.com/atomantic/is-ua-webview
    const userAgentStrings = [
        'oceancloud-mobile-app',
        'webview',
        '(iphone|ipod|ipad)(?!.*safari).*mobile(\\/[a-z0-9]+)?$',
        'android.*(;\\s+wv|version/\\d.\\d\\s+chrome/\\d+(\\.0){3}).*safari(\\/[a-z0-9.]+)?$',
        'linux; u; android'
    ];

    const webviewRegExp = new RegExp('(' + userAgentStrings.join('|') + ')', 'ig');
    return !!navigator.userAgent.match(webviewRegExp);
};

export { Mobile, PC, IsMobile, IsPC, Tablet, IsTablet, IsTabletOrPC, TabletOrPC, IsMobileOrTablet, MobileOrTablet, IsWebview, usePC, useTablet, useMobile };
