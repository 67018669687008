import React, { useState, useRef, useEffect } from "react";
import { Mobile, PC, Tablet } from "./MediaQuery";
import common from "../../common/common";
import "./DateBox.css";
import { useTranslation } from "react-i18next";

const DateBox = (props) => {
    const { t } = useTranslation();
    const DATE = {
        EntirePeriod: "entire-period",
        LastDay: "last-day",
        LastWeek: "last-week",
        LastMonth: "last-month",
        LastHalfYear: "last-half-year",
        LastYear: "last-year",
    };

    // Note. tag의 id 값은 props 로 넘겨서 기본값을 override 할 수 있습니다.
    const ID = {
        startDate: props.startDateID || "startDate",
        endDate: props.endDateID || "endDate",
        date: props.dateID || "date",
        dateBox: props.dateBoxID || "date-box",
    };

    const [date, setDate] = useState(props.date);

    useEffect(() => {
        setDate(props.date);
    }, [props.date]);

    const prevDate = useRef({ startDate: props.startDate || "", endDate: props.endDate || "" });
    const firefoxVersion = useRef(window.navigator.userAgent.match(/Firefox\/(\d+)/));

    // userAgent 를 통한 동작 환경 체크
    const isIphoneEnv = () => {
        // ref: https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
        const user_agent = window.navigator.userAgent;
        if (user_agent.indexOf('iPhone') !== -1 && user_agent.indexOf('Safari') !== -1) {
            // iphone safari
            return true;
        }
        else if (user_agent.indexOf('iPhone') !== -1 && user_agent.indexOf('AppleWebKit') !== -1) {
            // iphone safari + iphone app (webview)
            return true;
        }
        else {
            return false;
        }
    };

    const selectPeriod = (e) => {
        const now = new Date();
        const startDate = document.getElementById(ID.startDate);
        const endDate = document.getElementById(ID.endDate);

        if (e.target.id === DATE.EntirePeriod) {
            startDate.value = "";
            endDate.value = "";
            endDate.min = "";
            startDate.max = "9999-12-31";
        }
        else {
            endDate.value = common.convertToDate(now);
            switch (e.target.id) {
                case DATE.LastDay:
                    now.setDate(now.getDate() - 1); //1일 전
                    break;
                case DATE.LastWeek:
                    now.setDate(now.getDate() - 7); //7일 전
                    break;
                case DATE.LastMonth:
                    now.setMonth(now.getMonth() - 1); //1달 전
                    break;
                case DATE.LastHalfYear:
                    now.setMonth(now.getMonth() - 6); //6개월 전
                    break;
                case DATE.LastYear:
                    now.setFullYear(now.getFullYear() - 1); //1년 전
                    break;
                default:
            }
            startDate.value = common.convertToDate(now);
            endDate.min = startDate.value;
            startDate.max = endDate.value;
        }

        prevDate.current.startDate = startDate.value;
        prevDate.current.endDate = endDate.value;
        props.onChange && props.onChange(startDate.value, endDate.value, e.target.innerHTML);
        document.getElementById(ID.date).innerHTML = e.target.innerHTML;
        document.getElementById(ID.date).style.color = "#333";
        document.getElementById(ID.dateBox).style.display = "none";
        setDate(e.target.innerHTML);
    };

    return (
        <div className="datebox-component">
            <PC>
                <span className="c-333 notosanskr-400 font-size-14 date">
                    <span
                        id={ID.date}
                        tabIndex="1"
                        onClick={(e) => {
                            const dateBox = document.getElementById(ID.dateBox);
                            if (dateBox.style.display === "none" || dateBox.style.display === "") {
                                dateBox.style.display = "block";
                                dateBox.focus();
                            } else {
                                dateBox.style.display = "";
                            }
                        }}
                        style={{ color: `${date !== "" ? "#333" : "#aaa"}` }}
                    >
                        {date || t("60")}
                    </span>
                    <span
                        id={ID.dateBox}
                        tabIndex="0"
                        onBlur={(e) => {
                            // notes, redkur;
                            // ref: https://barker.codes/blog/the-focusevent-relatedtarget-property-in-vanilla-js/#:~:text=The%20FocusEvent.relatedTarget%20property%20refers%20to%20the%20the%20secondary,the%20element%20receiving%20focus%20%28for%20blur%20and%20focusout%29.
                            // FocusEvent, blur(focusout) event data is,
                            // target        : the element loses focus
                            // relatedTarget : the element receiving focus
                            if (!e.relatedTarget && firefoxVersion.current && parseInt(firefoxVersion.current[1]) >= 109) {
                                if (document.activeElement.localName !== "input") {
                                    document.getElementById("date-box").style.display = "none";
                                    document.getElementById("startDate").value = prevDate.current.startDate;
                                    document.getElementById("endDate").value = prevDate.current.endDate;
                                }
                            }
                            else if (!e.relatedTarget || (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget.id !== ID.date)) {
                                document.getElementById(ID.dateBox).style.display = "none";
                                document.getElementById(ID.startDate).value = prevDate.current.startDate;
                                document.getElementById(ID.endDate).value = prevDate.current.endDate;
                            }
                        }}
                    >
                        <div className="pointer" onClick={selectPeriod} id={DATE.EntirePeriod}>
                            {t("61")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastDay}>
                        {t("62")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastWeek}>
                        {t("63")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastMonth}>
                        {t("64")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastHalfYear}>
                        {t("65")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastYear}>
                        {t("66")}
                        </div>
                        <hr />
                        <div>
                        {t("67")}
                            </div>
                        <input
                            type="date"
                            id={ID.startDate}
                            defaultValue={props.startDate || ""}
                            max="9999-12-31"
                            onChange={(e) => common.setDateRange(e, ID.startDate, ID.endDate)}
                            onBlur={(e) => common.dateVerify(e, ID.startDate, ID.endDate)}
                        />
                        <input
                            type="date"
                            id={ID.endDate}
                            defaultValue={props.endDate || ""}
                            max="9999-12-31"
                            onChange={(e) => common.setDateRange(e, ID.startDate, ID.endDate)}
                            onBlur={(e) => common.dateVerify(e, ID.startDate, ID.endDate)}
                        />
                        <div className="apply">
                            <button
                                className="c-fff nanumsquareb-14"
                                onClick={() => {
                                    const startDateValue = document.getElementById(ID.startDate).value;
                                    const endDateValue = document.getElementById(ID.endDate).value;
                                    if (!startDateValue || !endDateValue) return;
                                    prevDate.current.startDate = startDateValue;
                                    prevDate.current.endDate = endDateValue;
                                    props.onChange && props.onChange(startDateValue, endDateValue, `${startDateValue}~${endDateValue}`);
                                    document.getElementById(ID.date).innerHTML = `${startDateValue}~${endDateValue}`;
                                    document.getElementById(ID.date).style.color = "#333";
                                    document.getElementById(ID.dateBox).style.display = "none";
                                    setDate(`${startDateValue}~${endDateValue}`);
                                }}
                            >
                                {t("68")}
                            </button>
                        </div>
                    </span>
                </span>
            </PC>
            <Tablet>
                <span className="c-333 notosanskr-400 font-size-14 date">
                    <span
                        id={ID.date}
                        tabIndex="1"
                        onClick={(e) => {
                            const dateBox = document.getElementById(ID.dateBox);
                            if (dateBox.style.display === "none" || dateBox.style.display === "") {
                                dateBox.style.display = "block";
                                dateBox.focus();
                            } else {
                                dateBox.style.display = "";
                            }
                        }}
                        style={{ color: `${date !== "" ? "#333" : "#aaa"}` }}
                    >
                        {date || t("60")}
                    </span>
                    <span
                        id={ID.dateBox}
                        tabIndex="0"
                        onBlur={(e) => {
                            if (!e.relatedTarget && firefoxVersion.current && parseInt(firefoxVersion.current[1]) >= 109) {
                                if (document.activeElement.localName !== "input") {
                                    document.getElementById("date-box").style.display = "none";
                                    document.getElementById("startDate").value = prevDate.current.startDate;
                                    document.getElementById("endDate").value = prevDate.current.endDate;
                                }
                            }
                            else if (!e.relatedTarget || (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget.id !== ID.date)) {
                                document.getElementById(ID.dateBox).style.display = "none";
                                document.getElementById(ID.startDate).value = prevDate.current.startDate;
                                document.getElementById(ID.endDate).value = prevDate.current.endDate;
                            }
                        }}
                    >
                        <div className="pointer" onClick={selectPeriod} id={DATE.EntirePeriod}>
                        {t("61")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastDay}>
                        {t("62")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastWeek}>
                        {t("63")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastMonth}>
                        {t("64")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastHalfYear}>
                        {t("65")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastYear}>
                        {t("66")}
                        </div>
                        <hr />
                        <div>
                        {t("67")}
                            </div>
                        <input
                            type="date"
                            id={ID.startDate}
                            defaultValue={props.startDate || ""}
                            max="9999-12-31"
                            onChange={(e) => common.setDateRange(e, ID.startDate, ID.endDate)}
                            onBlur={(e) => common.dateVerify(e, ID.startDate, ID.endDate)}
                        />
                        <input
                            type="date"
                            id={ID.endDate}
                            defaultValue={props.endDate || ""}
                            max="9999-12-31"
                            onChange={(e) => common.setDateRange(e, ID.startDate, ID.endDate)}
                            onBlur={(e) => common.dateVerify(e, ID.startDate, ID.endDate)}
                        />
                        <div className="apply">
                            <button
                                className="c-fff nanumsquareb-14 text-wrap text-break"
                                onClick={() => {
                                    const startDateValue = document.getElementById(ID.startDate).value;
                                    const endDateValue = document.getElementById(ID.endDate).value;
                                    if (!startDateValue || !endDateValue) return;
                                    prevDate.current.startDate = startDateValue;
                                    prevDate.current.endDate = endDateValue;
                                    props.onChange && props.onChange(startDateValue, endDateValue, `${startDateValue}~${endDateValue}`);
                                    document.getElementById(ID.date).innerHTML = `${startDateValue}~${endDateValue}`;
                                    document.getElementById(ID.date).style.color = "#333";
                                    document.getElementById(ID.dateBox).style.display = "none";
                                    setDate(`${startDateValue}~${endDateValue}`);
                                }}
                            >
                                {t("68")}
                            </button>
                        </div>
                    </span>
                </span>
            </Tablet>
            <Mobile>
                <span className="c-333 notosanskr-400 font-size-14 date">
                    <span
                        className="pointer"
                        id={ID.date}
                        tabIndex="1"
                        onClick={(e) => {
                            const dateBox = document.getElementById(ID.dateBox);
                            if (dateBox.style.display === "none" || dateBox.style.display === "") {
                                dateBox.style.display = "block";
                                dateBox.focus();
                            } else {
                                dateBox.style.display = "";
                            }
                        }}
                        style={{ color: `${date !== "" ? "#333" : "#aaa"}` }}
                    >
                        {date || t("60")}
                    </span>
                    <span
                        id={ID.dateBox}
                        tabIndex="0"
                        onBlur={(e) => {
                            if (isIphoneEnv()) {
                                // quick hack to fix the iphone safari's input date problem
                                if (e.target.id === ID.startDate || e.target.id === ID.endDate) {
                                    // event triggered from child date control (date dismissed)
                                    // we'll add mouseleave event listener to dismiss date-box properly
                                    const date_box = document.getElementById(ID.dateBox);
                                    const mouseLeaveCallback = (ev2) => {
                                        // dismiss date-box
                                        document.getElementById(ID.dateBox).style.display = "none";
                                        document.getElementById(ID.startDate).value = prevDate.current.startDate;
                                        document.getElementById(ID.endDate).value = prevDate.current.endDate;

                                        // remove callback
                                        date_box.removeEventListener("mouseleave", mouseLeaveCallback);
                                    };
                                    date_box.addEventListener("mouseleave", mouseLeaveCallback);
                                    return;
                                }
                            }
                            else if (!e.relatedTarget && firefoxVersion.current && parseInt(firefoxVersion.current[1]) >= 109) {
                                if (document.activeElement.localName !== "input") {
                                    document.getElementById("date-box").style.display = "none";
                                    document.getElementById("startDate").value = prevDate.current.startDate;
                                    document.getElementById("endDate").value = prevDate.current.endDate;
                                }
                            }
                            else if (!e.relatedTarget || (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget.id !== ID.date)) {
                                document.getElementById(ID.dateBox).style.display = "none";
                                document.getElementById(ID.startDate).value = prevDate.current.startDate;
                                document.getElementById(ID.endDate).value = prevDate.current.endDate;
                            }
                        }}
                    >
                        <div className="pointer" onClick={selectPeriod} id={DATE.EntirePeriod}>
                        {t("61")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastDay}>
                        {t("62")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastWeek}>
                        {t("63")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastMonth}>
                        {t("64")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastHalfYear}>
                        {t("65")}
                        </div>
                        <div className="pointer" onClick={selectPeriod} id={DATE.LastYear}>
                        {t("66")}
                        </div>
                        <hr />
                        <div>
                        {t("67")}
                            </div>
                        <input
                            type="date"
                            id={ID.startDate}
                            defaultValue={props.startDate || ""}
                            max="9999-12-31"
                            onChange={(e) => common.setDateRange(e, ID.startDate, ID.endDate)}
                            onBlur={(e) => common.dateVerify(e, ID.startDate, ID.endDate)}
                        />
                        <input
                            type="date"
                            id={ID.endDate}
                            defaultValue={props.endDate || ""}
                            max="9999-12-31"
                            onChange={(e) => common.setDateRange(e, ID.startDate, ID.endDate)}
                            onBlur={(e) => common.dateVerify(e, ID.startDate, ID.endDate)}
                        />
                        <div className="apply">
                            <button
                                className="c-fff nanumsquareb-14 notosanskr-12:sm"
                                onClick={() => {
                                    const startDateValue = document.getElementById(ID.startDate).value;
                                    const endDateValue = document.getElementById(ID.endDate).value;
                                    if (!startDateValue || !endDateValue) return;
                                    prevDate.current.startDate = startDateValue;
                                    prevDate.current.endDate = endDateValue;
                                    props.onChange && props.onChange(startDateValue, endDateValue, `${startDateValue}~${endDateValue}`);
                                    document.getElementById(ID.date).innerHTML = `${startDateValue}~${endDateValue}`;
                                    document.getElementById(ID.date).style.color = "#333";
                                    document.getElementById(ID.dateBox).style.display = "none";
                                    setDate(`${startDateValue}~${endDateValue}`);
                                }}
                            >
{t("68")}
                            </button>
                        </div>
                    </span>
                </span>
            </Mobile>
        </div>
    );
};

export default DateBox;
