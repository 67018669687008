import { useImperativeHandle, useState, useEffect, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import Api from "../../../Api";
import dgLogger from "../../../common/dgLogger";

const useImperativeFunctions = (ref, { ...functions }) => {
    useImperativeHandle(ref, () => ({
        ...ref.current,
        ...functions
    }), [ref, functions]);
};

const useGetCurrentUserInfo = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const userInfo = await Api.getCurrentUserInfo({ optPermissions: true, optGroups: true });
                setUser(userInfo);
            }
            catch (e) {
                dgLogger.error(e)();
            }
        })();
    }, []);

    return user;
};

// 수정하기 버튼으로 넘어온 값 획득
const useGetStoredReport = () => {
    const location = useLocation();
    const storedReport = useMemo(() => (
        location.state?.report || null
    ), [location.state?.report]);

    return storedReport;
};

// 임시 저장 된 자료의 값 획득
const useGetTempReport = () => {
    const location = useLocation();
    const tempReport = useMemo(() => (
        location.state?.tempReport || null
    ), [location.state?.tempReport]);

    return tempReport;
};


const validateFloat = (event, pasteData = "") => {
    let value = event.target.value;
    let data = event.nativeEvent.data; // 키보드로 입력 한 값
    let firstWordReg = /[-0-9]/; // first word = - or number
    let validNumberFormatReg = /^-?([0-9])*\.?([0-9])*$/;

    // 붙여넣은 데이터가, 숫자 포멧에 맞지 않는 경우에 대한 처리
    if (!validNumberFormatReg.test(value) && pasteData) {
        let cursor = event.target.selectionStart;
        let valueLeftSlice = value.slice(0, cursor); // value를 cursor 기준 slice 하여 left value 저장
        let valueRightSlice = value.slice(cursor); // value를 cursor 기준 slice 하여 right value 저장
        // value = valueLeftSlice.slice(0, valueLeftSlice.length - pasteData.length) + valueRightSlice; // slice된 left value에서 붙여 넣기 된 data만 제외 한 후에 right와 합쳐 저장
        value = valueLeftSlice.replace(pasteData, "") + valueRightSlice;
    }
    // 키보드로 입력한 값을 포함한 데이터가, 숫자 포멧에 맞지 않는 경우에 대한 처리
    else {
        // (['-', '.'].includes(data) && value.split(data).length > 2) => 입력 한 data가 - 또는 . 이고 현재 입력 된 input text에 입력 한 data가 2개 이상 있을 때 true
        const has2OrMoreSign = ["-", "."].includes(data) && value.split(data).length > 2;
        // (((!validNumberFormatReg.test(value) && value.length > 1) => 입력 된 input text가 2글자 이상이고, 부동소수점 포맷에 맞지 않을 때 true (.이 2개거나 -가 가장 앞에 없거나 등)
        const hasWrongFloatFormat = !validNumberFormatReg.test(value) && value.length > 1;
        // (!firstWordReg.test(data) && value.length === 1) => 입력 된 input text가 1글자이고, 입력 된 input text가 - 또는 숫자가 아닐 때 true
        const notStartCharacter = data && !firstWordReg.test(data) && value.length === 1;

        // dgLogger.log(`${has2OrMoreSign}, ${hasWrongFloatFormat}, ${notStartCharacter}`)();

        if (has2OrMoreSign || hasWrongFloatFormat || notStartCharacter) {
            let cursor = event.target.selectionStart;
            let valueLeftSlice = value.slice(0, cursor); // value를 cursor 기준 slice 하여 left value 저장
            let valueRightSlice = value.slice(cursor); // value를 cursor 기준 slice 하여 right value 저장
            value = valueLeftSlice.replace(data, "") + valueRightSlice;
        }
    }

    return value;
};

export {
    useImperativeFunctions,
    useGetCurrentUserInfo,
    useGetStoredReport,
    useGetTempReport,
    validateFloat,
};
