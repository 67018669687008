import "./ProfileEmblem.css";

export default function ProfileEmblem(props) {
    const accept = props?.accept || "image/*";

    return (
        <div className="avatar-form">
            <div
                className={`avatar-container`}
                style={{
                    width: `${props.width ? props.width : `151px`}`,
                    height: `${props.height ? props.height : `151px`}`,
                }}
            >
                <div className="outer">
                    {props.url && <img src={props.url} alt="Profile" className={props.urlClass} />}
                    {!props.url && props.char && <span className={`${props.charClass} text-white rounded-circle`}>{props.char.charAt(0).toUpperCase()}</span>}
                    {props.onChange && (
                        <div className="inner">
                            <input className="input-file" type="file" id={props.profileId} name="picture" accept={accept} defaultValue="" onChange={props.onChange} />
                            <label className="avatar-change-button" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
