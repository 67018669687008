import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

export default function GroupParticipationWindow ({onConfirm, onCancel}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const icon = <img src={process.env.PUBLIC_URL + `/icon_group.png`} srcSet={`${process.env.PUBLIC_URL}/icon_group@2x.png 2x, ${process.env.PUBLIC_URL}/icon_group@3x.png 3x`} alt="" />;
    const header = <div>{t("359")}</div>;
    const body = (
        <>
            <div>{t("523")}</div>
            <div>{t("524")}</div>
        </>
    );

    return (
        <Modal
            onRequestClose={() => {
                if (onCancel) onCancel();
                else {
                    const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                    const navigatePathname = prevPathname.length > 1 ? prevPathname.splice(-2)[0] : "/";
                    sessionStorage.setItem("prevPathname", prevPathname);
                    navigate(navigatePathname || "/", { replace: true });
                }
            }}
            onConfirm={onConfirm || (() =>{
                navigate(`/Project`);
            })}
            onCancel={() => {
                if (onCancel) onCancel();
                else {
                    const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                    const navigatePathname = prevPathname.length > 1 ? prevPathname.splice(-2)[0] : "/";
                    sessionStorage.setItem("prevPathname", prevPathname);
                    navigate(navigatePathname || "/", { replace: true });
                }
            }}
            icon={icon}
            header={header}
            body={body}
        />
    );
}
