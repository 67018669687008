import React, { useImperativeHandle } from 'react';
import WithValueHandle from "./WithValueHandle";
import { useCallback } from 'react';
import i18n from 'i18next';

const WithAutocompleteHandle = (WrapperComponent) => {
    const WithAutocomplete = ({ ...otherProps }, ref) => {
        const autocompleteValidation = useCallback(({ value, required, errorKey }) => {
            let errorCollection = { [errorKey]: { state: true, message: "" } };
            let valid = true;

            if (required) {
                valid = Boolean(value.length);
                errorCollection = { [errorKey]: { state: valid, message: valid ? "" : i18n.t("15") } };
            }

            return { valid, errorCollection };
        }, []);

        useImperativeHandle(ref, () => (
            { ...ref.current, autocompleteValidation }
        ), [ref, autocompleteValidation]);

        return (
            <WrapperComponent ref={ref} {...otherProps} />
        );
    };

    return WithValueHandle(React.forwardRef(WithAutocomplete));
};

export default WithAutocompleteHandle;
