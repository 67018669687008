import React, { useImperativeHandle, useCallback } from 'react';
import WithValueHandle from "./WithValueHandle";
import i18n from 'i18next';

const WithOptionalHandle = (WrapperComponent) => {
    const WithOptional = ({ ...otherProps }, ref) => {
        const optionalValidation = useCallback(({ value, required, errorKey }) => {
            let valid = true;
            let errorCollection = { [errorKey]: { state: valid, message: "" } };

            if (required) {
                valid = Boolean(value.length);
                errorCollection = { [errorKey]: { state: valid, message: valid ? "" : i18n.t("15") } };
            }

            // valid true이고, 하위 컴포넌트의 validation function 이 있는 경우
            if (valid && ref.current.childValidation) {
                ({ valid, errorCollection } = ref.current.childValidation({ value, errorKey }));
            }

            return { valid, errorCollection };
        }, [ref]);

        useImperativeHandle(ref, () => (
            { ...ref.current, optionalValidation }
        ), [ref, optionalValidation]);

        return (
            <WrapperComponent ref={ref} {...otherProps} />
        );
    };

    return WithValueHandle(React.forwardRef(WithOptional));
};

export default WithOptionalHandle;
