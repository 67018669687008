export default function Conditional({ condition, children, elem, ...props }) {
    const Element = elem;

    if (condition) {
        return (
            <Element {...props}>
                {children}
            </Element>
        );
    } else {
        return children;
    }
}