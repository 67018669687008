import React, { useMemo } from "react";
import common from "../../common/common";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import "./CheckForTempReportWindow.css";
import Api from "../../Api";
import { useTranslation } from "react-i18next";

export default function CheckForTempReportWindow({ onClose, onCancel, report, overrideOnClick /*for testing purpose*/ }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userId = useMemo(() => sessionStorage.getItem("_id"), []);
    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_error@3x.png 3x`} alt="" />;
    const header = <div>{t("650")}</div>;
    const body = <div>{t("651")}</div>;

    return (
        <ReactModal isOpen={true} className={"default"} style={{ overlay: {}, content: {} }} portalClassName={"custom-modal-no-title-temp-report"}>
            <div style={{ textAlign: "right" }}>
                <img
                    className="close-icon"
                    src={process.env.PUBLIC_URL + `/popup_close.png`}
                    srcSet={`${process.env.PUBLIC_URL}/popup_close@2x.png 2x, ${process.env.PUBLIC_URL}/popup_close@3x.png 3x`}
                    onClick={onCancel || (() => {
                        common.navigateBack(navigate);
                    })}
                    alt=""
                />
            </div>
            <div className="custom-modal-content">
                <div className="custom-modal-icon">{icon}</div>
                <div className="custom-modal-header notosanskr-24b">{header}</div>
                <div className="custom-modal-body nanumsquarer-15">{body}</div>
            </div>
            <div className="custom-modal-footer" style={{ textAlign: "right" }}>
                <button
                    className="remove-report nanumsquareb-14"
                    onClick={overrideOnClick || (async () => {
                        const payload = {
                            registered_by: userId,
                        };
                        const snapshotReport = await Api.deleteReportSnapshot(payload);
                        Api.deleteImages(snapshotReport.added_files_key);
                        localStorage.removeItem(userId);
                        onClose();
                        navigate("/CreateReport", { state: { report } });
                    })}
                >
                    {t("85")}
                </button>
                <button
                    className={`isCancel confirm nanumsquareb-14`}
                    onClick={overrideOnClick || (async () => {
                        const payload = {
                            registered_by: userId,
                        };
                        const tempReport = await Api.fetchReportSnapshot(payload);
                        onClose();
                        navigate("/CreateReport", { state: { tempReport } });
                    })}
                >
                    {t("79")}
                </button>
                <button
                    className="cancel nanumsquareb-14"
                    onClick={onCancel || (() => {
                        common.navigateBack(navigate);
                    })}
                >
                    {t("80")}
                </button>
            </div>
        </ReactModal>
    );
}
