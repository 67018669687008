import React, { useEffect, useState, useRef } from "react";
import Api from "../../../Api";
import { utility, error as E } from "@ocean-knight/shared";
import { POPUP as GeneralPopup } from "../../../common/defines";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, closestCenter, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { Row, Col } from "react-bootstrap";
import Modal from "../../common/Modal";
import "./GroupMemberGrade.css";
import { InputText } from "../../Input";
import common from "../../../common/common";
import CustomCheckbox from "../../common/CustomCheckbox";
import dgLogger from '../../../common/dgLogger';
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    DuplicatedGradeName : 1009,
    GroupGradeUpdated: 1010,
};

function SortableItem({ id, permission, errors, children }) {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ disabled: permission.readonly, id: id });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <>
            {permission.readonly ? (
                <div
                    name="group-user-grade"
                    className={`row gx-0 item align-items-center notosanskr-500 font-size-16 ${utility.hasGroupManagerPermission(permission.grade) ? "group-manager" : "site-member"}`}
                >
                    <Col className="col-auto">
                        <div className={`draggable-col`}>&nbsp;</div>
                    </Col>
                    {children}
                </div>
            ) : (
                <div
                    name="group-user-grade"
                    ref={setNodeRef}
                    style={style}
                    className={`row gx-0 item align-items-center notosanskr-500 font-size-16 bg-white ${utility.hasGroupManagerPermission(permission.grade) ? "group-manager" : "site-member"}`}
                >
                    <Col className="col-auto">
                        <div className={`draggable-col draggable`} ref={setActivatorNodeRef} {...attributes} {...listeners} style={{ "touchAction": "none" }}>
                            &nbsp;
                        </div>
                    </Col>
                    {children}
                </div>
            )}
        </>
    );
}

export default function GroupMemberGrade(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        permissions: [], // for refresh render
        originalPermissions: [], // 변경사항 적용될 목록과 비교하기 위함
    });
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
      );
    const [errors, setErrors] = useState({});

    const permissions = useRef([]);

    useEffect(() => {
        Api.post("/v1/group/permissions", { _id: props._id })
            .then((res) => {
                if (res.data.code !== E.FOUND) {
                    // dgLogger.warn(res.data.payload.message)();
                    return;
                }

                permissions.current = res.data.payload.map((item, index) => ({
                    _id: item._id,
                    grade: item.grade,
                    grade_name: item.grade_name,
                    grade_description: item.grade_description,
                    order: item.order,
                    readonly: item.readonly,
                    can_create_project: item.can_create_project,
                    mapKey: Math.random().toString(36),
                }));
                permissions.current.sort((a, b) => a.order - b.order);
                setState((prev) => ({ ...prev, permissions: permissions.current, originalPermissions: permissions.current.slice() }));
            })
            .catch((err) => dgLogger.error(err)());
    }, [props._id]);

    const actionColumn = (permission) => {
        if (permission.readonly) {
            if (utility.hasGroupManagerPermission(permission.grade)) return <button className="notosanskr-500 font-size-18 c-4270e0 group-mananger" disabled={true}>{t("464")}</button>;
            else return <button className="group-member-bare notosanskr-500 font-size-15 c-333" disabled/>; // 다른 아이템들과 width 를 맞추기 위해 넣은 dummy button
        } else {
            return (
                <button className="group-member notosanskr-500 font-size-15 c-333"
                    onClick={() => {
                        setState({ ...state, popup: POPUP.GroupRemoveUserPermission, popupTarget: permission });
                    }}
                >
                    {t("465")}
                </button>
            );
        }
    };

    const validateFields = () => {
        let flag = true;
        const errorCollection = {};
        permissions.current.forEach(permission => {
            common.validateInputField(errorCollection, permission.mapKey, document.getElementById(permission.mapKey)) || (flag = false);
        });

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const onSubmit = async () => {
        if (!validateFields()) {
            common.scrollToInvalidElement();
            return;
        }
        const gradeNames = permissions.current.map(item => item.grade_name);
        const hasDup = gradeNames.some((item, i) => gradeNames.indexOf(item) !== i );
        if (hasDup) {
            setState({ ...state, popup: POPUP.DuplicatedGradeName, popupTarget: null });
            return;
        }

        try {
            const permissionIDs = permissions.current.filter(item => item._id).map(item => item._id);

            await Api.removeGroupPermissions({
                _id: props._id, // group id
                permissions: state.originalPermissions.reduce((acc, cur) => {
                    if (-1 === permissionIDs.indexOf(cur._id)) acc.push(cur._id); // 현재 UI 상에 표시되는 목록에 들어있지 않다면, 삭제된 등급입니다.
                    return acc;
                }, []),
            });
            const payload = await Api.updateGroupPermissions({
                _id: props._id, // group id
                permissions: permissions.current.reduce((acc, cur) => {
                    acc.push({
                        _id: cur._id,
                        grade_name: cur.grade_name,
                        grade_description: cur.grade_description,
                        order: cur.order,
                        can_create_project: cur.can_create_project || false,
                    });
                    return acc;
                }, []),
            });

            permissions.current = payload.map((item, index) => ({
                _id: item._id,
                grade: item.grade,
                grade_name: item.grade_name,
                grade_description: item.grade_description,
                order: item.order,
                readonly: item.readonly,
                mapKey: Math.random().toString(36),
                can_create_project: item.can_create_project || false,
            }));
            permissions.current.sort((a, b) => a.order - b.order);
            setState({ ...state,
                popup: POPUP.GroupGradeUpdated,
                popupTarget: null,
                permissions: permissions.current,
                originalPermissions: permissions.current.slice()
            });
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    const addPermissionRow = () => {
        permissions.current.push({
            _id: undefined,
            grade_name: "",
            grade_description: "",
            order: permissions.current.length,
            mapKey: Math.random().toString(36),
        });
        setState({ ...state });
    };

    const popups = () => {
        if (state.popup === POPUP.GroupRemoveUserPermission) return popupRemoveGroupUserPermission(
            () => {
                // 서버에서 얻어온 데이터가 아니라면, 현재 자료구조에서 삭제하고 종료
                permissions.current = permissions.current.reduce((acc, cur) => {
                    if (cur.order === state.popupTarget.order && cur.mapKey === state.popupTarget.mapKey) return acc;
                    cur.order = acc.length;
                    acc.push(cur);
                    return acc;
                }, []);

                setState({
                    ...state,
                    popup: POPUP.None,
                    popupTarget: null,
                    permissions: permissions.current,
                });
            },
            () => {
                setState({ ...state, popup: POPUP.None, popupTarget: null });
            }
        );
        if (state.popup === POPUP.DuplicatedGradeName) return popupDuplicatedGradeName(() => {
            setState({ ...state, popup: POPUP.None, popupTarget: null });
        });
        if (state.popup === POPUP.GroupGradeUpdated) return popupGradeUpdateRequested(() => {
            setState({ ...state, popup: POPUP.None, popupTarget: null });
        });
    };

    const popupDuplicatedGradeName = (onConfirm) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_grade.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_grade@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_grade@3x.png 3x`} alt=""/>;
        const header = <div>{t("472")}</div>;
        const body = <div>{t("473")}</div>;

        return (
            <Modal
                onRequestClose={onConfirm}
                onConfirm={onConfirm}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupGradeUpdateRequested = (onConfirm) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_grade.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_grade@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_grade@3x.png 3x`} alt=""/>;
        const header = <div>{t("470")}</div>;
        const body = <div>{t("471")}</div>;

        return (
            <Modal
                onRequestClose={onConfirm}
                onConfirm={onConfirm}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupRemoveGroupUserPermission = (onConfirm, onCancel) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_grade.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_grade@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_grade@3x.png 3x`} alt=""/>;
        const header = <div>{t("468")}</div>;
        const body = <div>{t("469")}</div>;

        return (
            <Modal
                onRequestClose={onCancel}
                onConfirm={onConfirm}
                onCancel={onCancel}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const handleDragEnd = (event) => {
        const {active, over} = event;

        if (active.id !== over.id) {

            const oldIndex = permissions.current.findIndex(permission => permission.mapKey === active.id);
            const newIndex = permissions.current.findIndex(permission => permission.mapKey === over.id);

            const item = permissions.current.splice(oldIndex, 1)[0];
            permissions.current.splice(newIndex, 0, item);
            permissions.current.forEach((item, i) => item.order = i); // re-indexing order
            setState({...state, permissions: permissions.current, });
        }
    };

    return (
        <div id="group-member-grade">
            <Row className="gx-0 update-group-picture-title">
                <Col className="notosanskr-600 font-size-26 notosanskr-14:sm c-333">{t("458")}</Col>
            </Row>

            {popups()}

            <div className="grades">
                <Row className="gx-0 title align-items-center notosanskr-500 font-size-18">
                    <Col className="col-auto">
                        <div className={`draggable-col`}>&nbsp;</div>
                    </Col>
                    <Col className="grade-name col-auto">
                        {t("462")}
                    </Col>
                    <Col className="grade-desc col-auto">
                        {t("463")}
                    </Col>
                    <Col className="can-create-project">
                        {t("687").split('\n').map((v, i) => <span key={i}>{v}&nbsp;</span>)}
                    </Col>
                </Row>
                <DndContext sensors={sensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={handleDragEnd}>
                    <SortableContext items={permissions.current.filter((permission) => !permission.readonly).map((permission) => permission.mapKey)} strategy={verticalListSortingStrategy}>
                        {permissions.current.map(permission => (
                            <SortableItem key={permission.mapKey} id={permission.mapKey} permission={permission} errors={errors}>
                                <Col className="grade-name col-auto">
                                    <InputText
                                        id={permission.mapKey}
                                        name="grade-name"
                                        defaultValue={permission.grade_name}
                                        className={`w-100`}
                                        onChange={(e) => {
                                            permission.grade_name = e.target.value;
                                        }}
                                        placeholder={t("467")}
                                        onBlur={(e) => {
                                            const errorCollection = {};
                                            common.validateInputField(errorCollection, permission.mapKey, e.target);
                                            setErrors({ ...errors, ...errorCollection });
                                        }}
                                        maxLength={10}
                                    />
                                    {errors[permission.mapKey]?.message && <div className="c-f00">{errors[permission.mapKey]?.message}</div>}
                                </Col>
                                <Col className="grade-desc col-auto">
                                    <Row className={`gx-0 align-items-center`}>
                                        <Col className="input">
                                            <InputText
                                                name="grade-description"
                                                className={`w-100`}
                                                defaultValue={permission.grade_description}
                                                onChange={(e) => {
                                                    permission.grade_description = e.target.value;
                                                }}
                                                maxLength={50}
                                            />
                                            {errors[permission.mapKey]?.message && <div className="c-f00">&nbsp;</div>}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="can-create-project col-auto">
                                    <CustomCheckbox
                                        disabled={permission.order === 0}
                                        className="d-flex align-items-center justify-content-center"
                                        defaultChecked={permission.can_create_project}
                                        onChange={(e) => permission.can_create_project = e.target.checked}
                                    >
                                    </CustomCheckbox>
                                </Col>
                                <Col className="grade-desc">
                                    <Row className="gx-0 justify-content-end">
                                        <Col className="button col-auto">
                                            {actionColumn(permission)}
                                            {errors[permission.mapKey]?.message && <div className="c-f00">&nbsp;</div>}
                                        </Col>
                                    </Row>
                                </Col>
                            </SortableItem>
                        ))}
                    </SortableContext>
                </DndContext>

                <Row className="item gx-0 align-items-center">
                    <Col className="col-auto">
                        <div className={`draggable-col`}>&nbsp;</div>
                    </Col>
                    <Col className="grade-name col-auto">
                        <InputText id="group-grade-manage-grade-name" disabled />
                    </Col>
                    <Col className="grade-desc col-auto">
                        <Row className="gx-0 align-items-center">
                            <Col className="input">
                                <InputText id="group-grade-manage-description" disabled />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="grade-desc">
                        <Row className="gx-0 justify-content-end">
                            <Col className="button col-auto">
                                <button
                                    className="new-grade notosanskr-500 font-size-15 c-white"
                                    onClick={() => {
                                        addPermissionRow();
                                    }}
                                >
                                    {t("466")}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <Row className="confirm-btn-group" style={{ textAlign: "right" }}>
                <Col>
                    <button className="save-btn notosanskr-400 font-size-18 c-white" onClick={onSubmit}>
                        {t("265")}
                    </button>
                </Col>
            </Row>
        </div>
    );
}
