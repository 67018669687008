import { useCallback } from "react";

export default function useTitleHook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const textValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.textValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    // element에서 반환 되는 value는 string이므로 DB에 저장 될 수 있는 형태로 formatting
    const setTagValue = useCallback((value) => {
        // '#' 단위로 분리된 값을 저장, 빈값 ('') 은 무시
        const formattedValue = value.split("#").reduce((acc, cur) => {
            const item = cur.trim();
            if (!item) return acc;
            if (acc.includes(item)) return acc;
            acc.push(item);
            return acc;
        }, []);
        ref.current?.setValue?.(formattedValue);
    }, [ref]);

    const getTagValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    return {
        textValidation,
        setTagValue,
        getTagValue
    };
};
