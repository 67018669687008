import React, { useImperativeHandle, useCallback } from 'react';
import WithValueHandle from "./WithValueHandle";
import i18n from 'i18next';

const WithLocationHandle = (WrapperComponent) => {
    const WithLocation = ({ ...otherProps }, ref) => {
        const locationValidation = useCallback(({ latitude, longitude, required }) => {
            let valid = true;
            let errorCollection = { latitude: { state: valid, message: "" }, longitude: { state: valid, message: "" } };
            if (required) {
                if (!latitude) {
                    valid = false;
                    errorCollection['latitude'] = { state: valid, message: i18n.t("15") };
                }

                if (!longitude) {
                    valid = false;
                    errorCollection["longitude"] = { state: valid, message: i18n.t("15") };
                }
            }

            // flag가 true이고, 하위 컴포넌트의 validation function 이 있는 경우
            if (valid && ref.current.childValidation) {
                ({ valid, errorCollection } = ref.current.childValidation({ latitude, longitude }));
            }

            return { valid, errorCollection };
        }, [ref]);

        useImperativeHandle(ref, () => (
            { ...ref.current, locationValidation }
        ), [ref, locationValidation]);

        return (
            <WrapperComponent ref={ref} {...otherProps} />
        );
    };

    return WithValueHandle(React.forwardRef(WithLocation));
};

export default WithLocationHandle;
