import React, { useEffect } from "react";
import "./Footer.css";
import { Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { MobileView } from 'react-device-detect';

import SuggestMobileApp from './SuggestMobileApp';
import { useTranslation } from "react-i18next";

export default function Footer() {
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
        if (prevPathname[prevPathname.length - 1] !== location.pathname) {
            prevPathname.push(location.pathname);
            if (prevPathname.length > 10) prevPathname.splice(0, 1);
            sessionStorage.setItem("prevPathname", prevPathname);
        }
    }, [location]);

    return (
        <div className="footer">
            <footer>
                <Container>
                    {process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET != "oceancloud.public" && (
                        <div className="footer-content notosanskr-14 notosanskr-13:sm">
                            <div className="mb-4">
                                <Link to="/PrivacyPolicy" className="me-3">
                                    {t("6")}
                                </Link>
                                {"|"}
                                <Link to="/antiemail" className="mx-3">
                                    {t("11")}
                                </Link>
                                {"|"}
                                <a href="https://oceanknights.net" target="_self" className="mx-3">
                                    {t("12")}
                                </a>
                                {"|"}
                                <a href={t("643")} target="_self" className="ms-3">
                                    {t("13")}
                                </a>
                            </div>
                            <div>
                                {t("14")
                                    .split("\n")
                                    .map((v, i) => (
                                        <div key={i}>{v}</div>
                                    ))}
                                <div style={{ fontSize: "12px" }}>Copyright ⓒ 2022 DEVGURU All Rights Reserved.</div>
                            </div>
                        </div>
                    )}
                </Container>
            </footer>
            {/* <MobileView>
               <SuggestMobileApp />
            </MobileView> */}
        </div>
    );
}
