import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle, useRef, useContext } from 'react';
import WithImageHandle from "../hoc/WithImageHandle";
import useVendorUI2Hook from './VendorUI2Hook';
import VendorUI2View from "./VendorUI2View";
import useSizeContext from "../Context/SizeContext";
import { validateFloat } from "../common/common";
import dgLogger from '../../../common/dgLogger';
import common from '../../../common/common';

const VendorUI2 = forwardRef(({ defaultValue, type, fields, sub_items, i18n }, ref) => {
    const { title, description, required, min_item_count } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const i18n_sub_items = useMemo(() => {
        const lang = common.getLang();
        if (i18n?.[lang]) {
            return sub_items.map((f) => ({ ...f, ...(i18n[lang].sub_items.find((x) => x.index == f.index) || {}) }));
        } else return sub_items;
    }, [sub_items, i18n]);
    const pasteData = useRef("");
    const tempValue = useRef({});

    // sub_items의 각 item의 _id를 key로 사용하는 object 생성
    // 초기화의 개념, key가 한 개라도 존재하면 실행하지 않도록 조건식 처리
    useEffect(() => {
        if (!Object.keys(tempValue.current).length) {
            i18n_sub_items.forEach(subItem => {
                tempValue.current[subItem._id] = {};
            });
        }
    }, [i18n_sub_items]);

    const [pendingUploadFiles, setPendingUploadFiles] = useState([]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const fileListLength = useRef(0);
    const {
        imageValidation,
        pushDeletedFilesKey,
        pushAddedFilesKey,
        setVendorUI2Value,
        setVendorUI2DefaultValue,
        getVendorUI2Value,
        processUploadedFiles,
        uploadImage
    } = useVendorUI2Hook(ref);

    const { SizeContext } = useSizeContext();
    const sizeContext = useContext(SizeContext);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        if (defaultValue && !getVendorUI2Value() && Object.keys(tempValue.current).length) {
            setVendorUI2DefaultValue(defaultValue || []);
            fileListLength.current = defaultValue.length;

            // 기존에 저장 되어있던 DB 구조를 컴포넌트에 알맞게 변환
            defaultValue.forEach(sub_item => {
                sub_item.value.forEach(item => {
                    tempValue.current[sub_item._id][item._id] = item.type === 'file' ? item.value[0] : item.value;
                });
            });

            // 기존에 저장 되어있던 파일들의 size를 합산하여 context에 추가
            const originalSize = defaultValue.reduce((acc, items) =>
                acc += items.value.filter(item => item.type === 'file')[0].value[0].size ,0);
            sizeContext.addOriginalUploadedSize(originalSize);

            forceUpdate();
        }
    }, [defaultValue, sizeContext, setVendorUI2DefaultValue, getVendorUI2Value, forceUpdate]);

    // cloud에 업로드 되어야 할 파일이 생겼을 때 실행
    useEffect(() => {
        if (pendingUploadFiles.length) {
            Promise.all(pendingUploadFiles.map(async (file, index) => (
                uploadImage(file).then(fileInfo => {
                    if (fileInfo.key) pushAddedFilesKey(fileInfo.key); // file key가 있는 경우 추가 된 key 목록에 추가
                    tempValue.current[file.subItemId][file.fileTypeId] = fileInfo;
                })
            ))).then(() => {
                setVendorUI2Value(tempValue.current);
                setPendingUploadFiles([]);
            });
        }
    }, [pendingUploadFiles, uploadImage, pushAddedFilesKey, setVendorUI2Value]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const { valid, errorCollection } = imageValidation({ required, errorKey: type, min_item_count, fileListLength: fileListLength.current });
        setErrors(errorCollection);

        return valid;
    }, [type, required, min_item_count, imageValidation]);

    // 사진이 업로드 됐을 때의 handler
    const onDrop = useCallback(async (subItemId, fileTypeId, textboxTypeId, acceptedFiles, fileRejections) => {
        try {
            const { newFileList } = await processUploadedFiles({
                acceptedFiles,
                fileRejections,
                max_item_count: 1, // vendor-ui-2의 file은 개별 항목이기 때문에 1개만 진행
            });
            if (!newFileList.length) return validation();

            fileListLength.current++;
            validation();

            const file = newFileList[0];
            file.subItemId = subItemId;
            file.fileTypeId = fileTypeId;

            const updatedFiles = {
                lat: file.lat,
                lng: file.lng,
                dateTime: file.dateTime,
                name: file.name,
                size: file.size,
                url: `${process.env.PUBLIC_URL}/Rolling-1.4s-200px.gif`
            };

            // DB에 저장 될 value를 만들기 위해 sub_item의 id를 key로 하는 object에 값 저장
            // file, textbox에도 id가 존재하므로 각각의 id를 key로 사용하여 value 저장
            // 사진만 등록한 경우에도 제출이 되어야 하므로 기본 값 저장
            tempValue.current[subItemId][fileTypeId] = updatedFiles;
            tempValue.current[subItemId][textboxTypeId] = "";

            setVendorUI2Value(tempValue.current);
            setPendingUploadFiles(newFileList);
        }
        catch (e) {
            dgLogger.info(e)();
        }
    }, [processUploadedFiles, validation, setVendorUI2Value]);

    // remove button을 눌렀을 때의 handler
    const removeFile = useCallback((subItemId, fileItemId) => {
        const file = tempValue.current[subItemId][fileItemId];
        sizeContext.subtractOriginalUploadedSize(file.size);
        if (file.key) pushDeletedFilesKey(file.key); // file key가 있는 경우 삭제 할 key 목록에 추가

        tempValue.current[subItemId] = {};
        fileListLength.current--;
        validation();

        setVendorUI2Value(tempValue.current);
    }, [sizeContext, pushDeletedFilesKey, validation, setVendorUI2Value]);

    // 입력 된 값이 바뀌었을 떄 호출 되는 handler
    // 입력 된 값이 float 형태에 맞지 않을 때 형태를 보정하여 value 수정
    const onChangeHandle = useCallback((e) => {
        const value = validateFloat(e, pasteData.current);
        e.target.value = value;
        pasteData.current = "";
    }, []);

    // 복사 붙여 넣기로 입력 된 값이 있다면 해당 값 저장
    const onPasteHandle = useCallback((e) => {
        pasteData.current = e.clipboardData.getData("Text");
    }, []);

    const onBlurHandle = useCallback((e, subItemId, textboxTypeId) => {
        // DB에 저장 될 value를 만들기 위해 sub_item의 id를 key로 하는 object에 값 저장
        // textbox에도 id가 존재하므로 id를 key로 사용하여 value 저장
        tempValue.current[subItemId][textboxTypeId] = e.target.value;
        setVendorUI2Value(tempValue.current);
    }, [setVendorUI2Value]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        type,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        sub_items: i18n_sub_items,
        tempValue,
        onDrop,
        removeFile,
        onChangeHandle,
        onPasteHandle,
        onBlurHandle,
    };

    return (
        <VendorUI2View {...viewProps} />
    );
});

export default WithImageHandle(VendorUI2);
