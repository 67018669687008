import React, { useState, useEffect } from "react";

export default function Timer(props) {
    const [minutes, setMinutes] = useState(parseInt(props.time / 60));
    const [seconds, setSeconds] = useState(props.time % 60);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(parseInt(minutes) - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    return (
        <span className={props.className || "Timer"}>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
    );
}
