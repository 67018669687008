import { createContext, useState, useCallback, useMemo } from "react";

const GPSContext = createContext(null);

const useGPSContext = () => {
    const [gps, setGPS] = useState({ latitude: null, longitude: null });

    const getGPS = useCallback(() => {
        return gps;
    }, [gps]);

    const GPSContextValue = useMemo(() => ({
        setGPS,
        getGPS
    }), [setGPS, getGPS]);

    return {
        GPSContext,
        GPSContextValue
    };
};

export default useGPSContext;
