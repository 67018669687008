import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import WithTimeRangeHandle from "../hoc/WithTimeRangeHandle";
import useTimeRangeHook from './TimeRangeHook';
import TimeRangeView from "./TimeRangeView";
import common from '../../../common/common';

const TimeRange = forwardRef(({ defaultValue, type, fields, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const [errors, setErrors] = useState({ start: { state: true, message: '' }, end: { state: true, message: '' } });
    const {
        timeRangeValidation,
        setTimeRangeValue,
        getTimeRangeValue,
        getContextDatetimeValue,
        add30MinutesInTimeRange
    } = useTimeRangeHook(ref);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");


    // context에 저장 되어 있는 datetime이 바뀌었을 때 호출
    useEffect(() => {
        const currentDatetimeValue = getContextDatetimeValue(); // YYYY:MM:DD hh:mm:ss
        let startTime = '';
        let endTime = '';
        // context에 저장 되어 있는 값이 있는 경우 hh:mm:ss로 변환
        if (currentDatetimeValue) {
            const [, time] = currentDatetimeValue.split(" ");
            startTime = time.slice(0, 5);
            endTime = add30MinutesInTimeRange(time.slice(0, 5));
        }
        setStart(startTime);
        setEnd(endTime);
        setTimeRangeValue(startTime, endTime);
        // validation은 무조건 true가 되는 것이 기존 동작
        setErrors({ start: { state: true, message: '' }, end: { state: true, message: '' } });
    }, [type, getContextDatetimeValue, add30MinutesInTimeRange, setTimeRangeValue]);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        if (defaultValue) {
            setStart(defaultValue.start);
            setEnd(defaultValue.end);
            setTimeRangeValue(defaultValue.start, defaultValue.end);
        }
    }, [defaultValue, setTimeRangeValue]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const { start, end } = getTimeRangeValue();
        const { valid, errorCollection } = timeRangeValidation({ start, end, required });
        setErrors(errorCollection);

        return valid;
    }, [required, getTimeRangeValue, timeRangeValidation]);


    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    // 시작 시간 input에 적용
    const onBlurHandleOfStart = useCallback((e) => {
        const currentValue = getTimeRangeValue();
        setStart(e.target.value);
        setEnd(currentValue?.end);
        setTimeRangeValue(e.target.value, currentValue?.end);
        validation();
    }, [getTimeRangeValue, setTimeRangeValue, validation]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    // 종료 시간 input에 적용
    const onBlurHandleOfEnd = useCallback((e) => {
        const currentValue = getTimeRangeValue();
        setStart(currentValue?.start);
        setEnd(e.target.value);
        setTimeRangeValue(currentValue?.start, e.target.value);
        validation();
    }, [getTimeRangeValue, setTimeRangeValue, validation]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);


    const viewProps = {
        title,
        description,
        type,
        start,
        end,
        startInvalid: errors["start"]?.state ? "" : "invalid",
        startErrorMessage: errors["start"]?.message,
        endInvalid: errors["end"]?.state ? "" : "invalid",
        endErrorMessage: errors["end"]?.message,
        onBlurHandleOfStart,
        onBlurHandleOfEnd
    };

    return (
        <TimeRangeView {...viewProps} />
    );
});

export default WithTimeRangeHandle(TimeRange);
