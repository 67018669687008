import { Link } from "react-router-dom";

export default function CustomButtonLink(props) {
    return (
        <Link to={props.to} state={props.state}>
            <button className={`${props.className} custom-button-link`} onClick={props.onClick}>
                {props.children}
            </button>
        </Link >
    );
}
