const PermissionEnum = {
    MANAGE_SITE:               0b00000000000010000000000000000000, // 사이트 관리
    CREATE_DELETE_GROUP:       0b00000000000001000000000000000000, // 그룹 생성 승인 및 그룹 삭제
    PERMIT_ORGANIZATION:       0b00000000000000100000000000000000, // 자료활용 공익단체 승인 및 삭제
    REMOVE_ANY_MEMBER:         0b00000000000000010000000000000000, // 일반 회원 삭제
    REMOVE_ANY_DATA:           0b00000000000000001000000000000000, // 모든 자료 삭제
    MODIFY_GROUP_INFO:         0b00000000000000000100000000000000, // 그룹 정보 변경 - 그룹명, 소개, 로고
    MODIFY_GROUP_FORM:         0b00000000000000000010000000000000, // 입력폼 변경
    MANAGE_GROUP_MEMBER:       0b00000000000000000001000000000000, // 그룹원 관리
    PERMIT_GROUP_SIGNUP:       0b00000000000000000000100000000000, // 그룹원 가입 승인
    REMOVE_GROUP_DATA:         0b00000000000000000000010000000000, // 관리하고 있는 소속 그룹 자료 삭제
    REMOVE_GROUP_COMMENT:      0b00000000000000000000001000000000, // 관리하고 있는 소속 그룹 댓글 삭제
    SUBMIT_MY_DATA:            0b00000000000000000000000100000000, // 자료 등록
    MODIFY_MY_DATA:            0b00000000000000000000000010000000, // 내 자료 수정
    REMOVE_MY_DATA:            0b00000000000000000000000001000000, // 내 자료 삭제
    REMOVE_MY_PROJECT:         0b00000000000000000000000000100000, // 내 프로젝트 삭제
    PERMIT_DATA_LICENSE:       0b00000000000000000000000000010000, // 내 자료 사용권 승인
    SUBMIT_COMMENT:            0b00000000000000000000000000000100, // 댓글 등록 및 내 댓글 관리
    VIEW_DATA:                 0b00000000000000000000000000000010, // 자료 및 프로젝트 보기
    SEARCH_DATA:               0b00000000000000000000000000000001, // 자료 검색
};

const SiteAdminPermission    = 0b00000000000001111111001100000111;
const GroupManagerPermission = 0b00000000000000000011111111111111;
const GroupMemberPermission  = 0b00000000000000000000000011111111;
const MemberPermission       = 0b00000000000000000000000000000111;

const permissionGradeToString = (bits) => {
    // return 64 bit length string
    return bits.toString(2).padStart(32, "0");
};

const permissionGradeToNumber = (str) => {
    return parseInt(str, 2);
};

/**
 * 사이트 관리자 권한인지 여부를 반환합니다.
 * 
 * @param {*} perm 퍼미션(grade) 값
 * @returns Site 관리자 권한인지 여부 (T/F)
 */
const hasSiteAdminPermission = (perm) => {
    if ((typeof perm) === 'string') {
        perm = permissionGradeToNumber(perm)
    }

    return perm === SiteAdminPermission;
};

/**
 * 그룹 매니저 권한인지 여부를 반환합니다.
 * 
 * @param {*} perm 퍼미션(grade) 값
 * @returns Group 관리자 권한인지 여부 (T/F)
 */
 const hasGroupManagerPermission = (perm) => {
    if ((typeof perm) === 'string') {
        perm = permissionGradeToNumber(perm)
    }

    return perm === GroupManagerPermission;
};

/**
 * parameter로 전달된 포트 valid 한지 확인후, 반환합니다.
 *
 * @param val {number | string} 포트 번호 | 파이프 이름
 * @returns 입력값이 숫자라면, 포트 번호 | 입력값이 문자라면, 파이프 이름 | 입력값이 음수라면 false 를 반환
 */
const normalizePort = (val) => {
    let port = parseInt(val, 10);

    if (isNaN(port)) {
        // named pipe
        return val;
    }

    if (port >= 0) {
        // port number
        return port;
    }

    return false;
};

const STATE = {
    ACQUIRED: "acquired",
    REJECTED: "rejected",
    REVIEWING: "reviewing",
    REMOVE: "remove",
};

const CHART_TYPE = {
    None: "none",
    Pie: "pie",
    Doughnut: "doughnut",
    Horizontal: "horizontal",
    Vertical: "vertical"
};

const FORM_ITEM_TYPE = {
    Textbox: "textbox", // 단답형
    Textarea: "ml_textbox", // 장문형
    Label: "label",
    Spinnerbox: "spinnerbox", // 단답형 (숫자)
    File: "file", // 이미지 업로드
    Radiobox: "radiobox", // 객관식 질문
    Checkbox: "checkbox", // 체크박스
    VendorUI: "vendor-ui-2", // 쓰레기 양 평가
    VendorUI3: "vendor-ui-3", // 조사 결과 입력
    VendorUI4: "vendor-ui-4", // 조사 개수 버튼 입력
};

const I18NTYPE = {
    ko: "ko",
    en: "en"
};

const LOCATION2STRTABLEMAP = {
    // location name : string table index
    강원도: 43,
    경기도: 44,
    경상남도: 45,
    경상북도: 46,
    광주광역시: 47,
    대구광역시: 48,
    대전광역시: 49,
    부산광역시: 50,
    서울특별시: 51,
    세종특별자치시: 872,
    울산광역시: 52,
    인천광역시: 53,
    전라남도: 54,
    전라북도: 55,
    제주특별자치도: 56,
    충청남도: 57,
    충청북도: 58,
    기타: 59,
};

// Note. `[회원 관리]의 등급은 일반 회원, 그룹 매니저 두 개 고정`
const USER_MANAGE_GRADE = {
    SITE_MEMBER: "site-member",
    GROUP_MANAGER: "group-manager",
};

const APPROVE_GROUP_CREATION_TAB_ID = {
    reviews: "reviews",
    histories: "histories",
};
const APPROVE_DATA_USE_REQUEST_TAB_ID = {
    reviewing: "reviewing",
    histories: "histories",
};
const APPROVE_GROUP_MEMBER_TAB_ID = {
    reviews: "reviews",
    histories: "histories",
};
const APPROVE_ORGANIZATION_TAB_ID = {
    reviews: "reviews",
    histories: "histories",
};
const REQUEST_DATA_USE_TAB_ID = {
    reviewing: "reviewing",
    histories: "histories",
};

const REQUEST_GROUP_CREATION_TAB_ID = {
    request: "request",
    histories: "histories",
};
const REQUEST_GROUP_MEMBER_TAB_ID = {
    request: "request",
    histories: "histories",
};
const REQUEST_ORGANIZATION_TAB_ID = {
    request: "request",
    histories: "histories",
};

module.exports = {
    permissionGradeToString,
    permissionGradeToNumber,
    normalizePort,
    hasSiteAdminPermission,
    hasGroupManagerPermission,

    // types
    PermissionEnum,
    SiteAdminPermission,
    GroupManagerPermission,
    GroupMemberPermission,
    MemberPermission,

    // state enum
    STATE,
    USER_MANAGE_GRADE,
    CHART_TYPE,
    FORM_ITEM_TYPE,
    I18NTYPE,
    LOCATION2STRTABLEMAP,

    // tab ids
    APPROVE_GROUP_CREATION_TAB_ID,
    APPROVE_DATA_USE_REQUEST_TAB_ID,
    APPROVE_GROUP_MEMBER_TAB_ID,
    APPROVE_ORGANIZATION_TAB_ID,
    REQUEST_DATA_USE_TAB_ID,
    REQUEST_GROUP_CREATION_TAB_ID,
    REQUEST_GROUP_MEMBER_TAB_ID,
    REQUEST_ORGANIZATION_TAB_ID
};
