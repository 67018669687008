import React from "react";
import ReactModal from "react-modal";
import { ListGroup } from "react-bootstrap";
import "./UpdateMemberGradeWindow.css";
import { useTranslation } from "react-i18next";

const ID = {
    updateTarget: "member-manage-targets",
    updatePermissionTarget: "member-manage-grade-target",
};

const UpdateMemberGrade = ({
    users,
    grades,
    onConfirmed,
    onCancel,
    className,
    title = "그룹원 등급 변경"
}) => {
    const { t } = useTranslation();

    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={onCancel}
            className={className ? className : "default"}
            style={{
                overlay: {},
                content: {
                    // top: "50%",
                    // left: "50%",
                    // right: "auto",
                    // bottom: "auto",
                    // marginRight: "-50%",
                    // transform: "translate(-50%, -50%)",
                },
            }}
            portalClassName={"member-manage-grade"}
        >
            <div className="title nanumsquareeb-18">
                {title}
                <div style={{ position: "absolute", right: "32px" }}>
                    <img
                        src={process.env.PUBLIC_URL + `/icon_close.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`}
                        onClick={onCancel}
                        alt=""
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>
            <table className="member-manage-grade-table">
                <thead className="nanumsquare-16">
                    <tr>
                        <td>
                            {t("438")}
                        </td>
                        <td>
                            {t("423")}
                        </td>
                    </tr>
                </thead>
                <tbody className="notosanskr-14">
                    <tr>
                        <td className="align-top">
                            <ListGroup
                                id={ID.updateTarget}
                                style={{
                                    overflowY: "auto",
                                    maxHeight: "200px",
                                }}
                                disabled
                            >
                                {users.map((user, i) => (
                                    <ListGroup.Item key={user._id} className={i % 2 !== 0 ? "bg-white" : "bg-f2f4f7"}>
                                        {user.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </td>
                        <td className="align-top">
                            <select id={ID.updatePermissionTarget}>
                                {grades.map((grade) => (
                                    <option key={grade._id} value={grade._id}>
                                        {grade.grade_name}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="text-end button-group">
                <button
                    className="confirm nanumsquareb-14"
                    onClick={() => {
                        if (!onConfirmed) return;
                        const selectedOption = document.getElementById(ID.updatePermissionTarget);
                        const permission = selectedOption.options[selectedOption.selectedIndex].value;
                        onConfirmed(users, permission);
                    }}
                >
                    {t("79")}
                </button>
                <button className="cancel nanumsquareb-14" onClick={onCancel}>
                    {t("80")}
                </button>
            </div>
        </ReactModal>
    );
};

export { UpdateMemberGrade };
