import React, { useState, useEffect, useRef, useContext } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import { dummy } from "@ocean-knight/shared";
import { POPUP as GeneralPopup } from "../../common/defines";
import "./RequestGroupCreation.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Button, Tabs, Tab, Row, Col } from "react-bootstrap";
import Modal from "../common/Modal";
import { useNotification } from "../../common/customHook";
import { mutate } from "swr";
import Pagination from "../common/Pagination";
import RequestGroupCreationContent from './RequestGroupCreationContent';
import GroupInfoApplicationWindow from "../common/GroupInfoApplicationWindow";
import LoadingSpinner from "../common/LoadingSpinner";
import dgLogger from '../../common/dgLogger';
import { useTranslation, Trans } from "react-i18next";
import i18next from "i18next";
import QueryString from 'qs';
import { REQUEST_GROUP_CREATION_TAB_ID } from "@ocean-knight/shared/src/utility";

const POPUP = {
    ...GeneralPopup,
    Notification: 1010,
};

const ID = {
    createGroupRequireJoinConfirm: "create-group-require-join-confirm",
    createGroupName: "create-group-name",
    createGroupUserName: "create-group-username",
    createGroupContact: "create-group-contact",
    createGroupResearch: "create-group-research",
    createGroupRelLink: "create-group-link",
    createGroupAbout: "create-group-about",
    pictureDrop: "create-group-representative-picture-drop",
    picturesInput: "create-group-pictures-input",
    createGroupFilesInput: "create-group-files-input",
    createGroupFiles: "create-group-files",
};

const DUP_STATE = {
    NONE: 0,
    UNKNOWN: 1,
    DUPLICATED: 2,
    UNIQUE: 3,
};

export const PopupRequestedCreateGroup = ({onClose}) => {
    const icon = <img src={process.env.PUBLIC_URL + `/pop_group_apply.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_apply@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_apply@3x.png 3x`} alt="" />;
    const header = <div>{i18next.t("398")}</div>;
    const body = <div>{i18next.t("399").split('\n').map((v, i) => <div key={i}>{v}</div>)}</div>;

    return (
        <Modal
            onRequestClose={onClose}
            onConfirm={onClose}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export function RequestGroupCreation(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [REQUEST_GROUP_CREATION_TAB_ID.request, REQUEST_GROUP_CREATION_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : REQUEST_GROUP_CREATION_TAB_ID.request,
        popup: POPUP.None,
        popupTarget: null,
        certificateFiles: [],
        pictures: [
            /*{ url: null, file: null, id: null, }*/
        ],
        representativePicture: null,
        reRenderKey: false, // RequestGroupCreation 를 re-render 할 toggle 변수 (toggle 시 re-render 수행함)
        isLoading: false,
    });
    const [reviews, setReviews] = useState([]);
    const { notifications } = useNotification();
    const groupNameDuplicateState = useRef(DUP_STATE.NONE);
    const paginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: 10, totalItemsCount: 0 });

    useEffect(() => {
        const hasPermission = common.hasSiteMemberPermission(JSON.parse(sessionStorage.getItem("permissions")));
        if (!hasPermission) {
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }

        const getContents = async () => {
            try {
                const payload = await Api.getGroupHistoryMine({
                    currentPage: paginationOption.current.currentPage,
                    itemsCountPerPage: paginationOption.current.itemsCountPerPage,
                });
                paginationOption.current.totalItemsCount = payload.totalItemsCount;

                setReviews(payload.histories);
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };
        getContents();
    }, [t]);

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            const item = notifications.find(notification => notification.from === "group.approval.histories");
            if (!item) return;
            Api.getNotificationDetail(item)
            .then(payload => {
                setState((prev) => ({ ...prev, popup: POPUP.Notification, popupTarget: payload }));
            })
            .catch(e => {
                dgLogger.error(e)();
                setState((prev) => ({
                    ...prev,
                    popup: POPUP.GeneralError,
                    popupTarget: t("673"),
                }));
            });
        }
    }, [notifications, t]);


    const onSubmit = async () => {
        setState({ ...state, isLoading: true });
        const groupName = document.getElementById(ID.createGroupName).value.trim();
        const contact = document.getElementById(ID.createGroupContact).value.trim();
        const userName = document.getElementById(ID.createGroupUserName).value.trim();
        const research = document.getElementById(ID.createGroupResearch).value.trim();
        const rel_link = document.getElementById(ID.createGroupRelLink).value.trim();
        const require_join_confirm = document.querySelector(`input[name="${ID.createGroupRequireJoinConfirm}-radio"]:checked`).value.trim();
        const about = document.getElementById(ID.createGroupAbout).value.trim();

        try {
            const payload = {
                name: groupName,
                userName: userName,
                contact: contact,
                research: research,
                rel_link: rel_link,
                require_join_confirm: require_join_confirm,
                about: about,
                lang: common.getLang()
            };

            if (state.certificateFiles) payload.certificate_files = await Api.uploadFiles(state.certificateFiles, false);

            if (state.pictures.length) {
                const index = state.pictures.indexOf(state.representativePicture.file);
                payload.pictures = await Api.uploadFiles(state.pictures, true);
                payload.representative_picture = payload.pictures[index];
            }

            await Api.requestGroupCreation(payload);
            // dgLogger.info("request group creation success")();

            const response = await Api.getGroupHistoryMine({
                currentPage: paginationOption.current.currentPage,
                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            });
            paginationOption.current.totalItemsCount = response.totalItemsCount;
            setReviews(response.histories);
            setState({ ...state, isLoading: false });

            // dgLogger.info("get my group creation request history success")();
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    const popupRequestCreateGroup = (e) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_new.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_new@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_new@3x.png 3x`} alt="" />;
        const header = <div>{t("396")}</div>;
        const name = document.getElementById(ID.createGroupName).value.trim();
        const body = <div><LoadingSpinner isOpen={state.isLoading} /><Trans i18nKey={'397'} values={{"%s": name}} components={{tag: <span className="c-4270e0"></span>}} /></div>;

        return (
            <Modal
                onRequestClose={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={async () => {
                    await onSubmit();
                    setState({ ...state, popup: POPUP.Requested, popupTarget: null });
                }}
                onCancel={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupGroupCreationRejectReason = (onClicked = undefined) => {
        const header = <div>{t("401")}</div>;
        const body = <div>{t("402")}</div>;

        return (
            <Modal
                onRequestClose={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                header={header}
                body={body}
                reason={state.popupTarget.state_reason}
            />
        );
    };

    const popupGroupCreationApproved = (onClicked = undefined) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_apply.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_apply@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_apply@3x.png 3x`} alt="" />;
        const header = <div>{t("675")}</div>;
        const body = <div>{t("583")}</div>;

        return (
            <Modal
                onRequestClose={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupGroupCreationRequestContent = () => {
        return <GroupInfoApplicationWindow
            group={state.popupTarget}
            onClick={() => {
                setState({ ...state, popup: POPUP.None, popupTarget: null });
            }}
        />;
    };

    const popups = () => {
        if (state.popup === POPUP.Request) return popupRequestCreateGroup();
        if (state.popup === POPUP.Requested) return <PopupRequestedCreateGroup onClose={() => {
                groupNameDuplicateState.current = DUP_STATE.UNKNOWN;

                // clear fields
                document.getElementById(ID.createGroupName).value = "";
                document.getElementById(ID.createGroupUserName).value = "";
                document.getElementById(ID.createGroupContact).value = "";
                document.getElementById(ID.createGroupResearch).value = "";
                document.getElementById(ID.createGroupRelLink).value = "";
                document.querySelector(`input#${ID.picturesInput}`).value = "";
                document.querySelector(`input#${ID.createGroupFilesInput}`).value = "";
                document.getElementById(ID.createGroupAbout).value = "";
                const radio = document.querySelector(`input[name="${ID.createGroupRequireJoinConfirm}-radio"][value="false"`);
                if (radio) radio.checked = true;

                // open `history` tab
                setState({ ...state, certificateFiles: [], pictures: [], popup: POPUP.None, popupTarget: null, activeTab: REQUEST_GROUP_CREATION_TAB_ID.histories, reRenderKey: !state.reRenderKey });
        }}/>;
        if (state.popup === POPUP.RejectReason) return popupGroupCreationRejectReason();
        if (state.popup === POPUP.RequestContent) return popupGroupCreationRequestContent();
        if (state.popup === POPUP.Notification) {
            if (state.popupTarget.state === utility.STATE.ACQUIRED) return popupGroupCreationApproved(() => {
                props.renewPermissions();
                Api.removeNotification(state.popupTarget.notificationId)
                .then(() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                    mutate("useNotification");
                })
                .catch(e => dgLogger.error(e)());
            });
            if (state.popupTarget.state === utility.STATE.REJECTED) return popupGroupCreationRejectReason(() => {
                Api.removeNotification(state.popupTarget.notificationId)
                .then(() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                    mutate("useNotification");
                })
                .catch(e => dgLogger.error(e)());
            });
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const processedState = (item) => {
        if (item.state === utility.STATE.REVIEWING) return <span className="reviewing-state c-white notosanskr-500 font-size-15">{t("378")}</span>;
        if (item.state === utility.STATE.ACQUIRED) return <span className="acquired-state c-white notosanskr-500 font-size-15">{t("377")}</span>;
        if (item.state === utility.STATE.REJECTED)
            return (
                <button className="rejected-state c-white notosanskr-500 font-size-15" onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}>
                    {t("379")}
                </button>
            );
    };

    return (
        <div id="request-group-creation">
            {popups()}
            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("358")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("353")}</div>
                </Col>
            </Row>
            <Tabs activeKey={state.activeTab} onSelect={(k) => setState({ ...state, activeTab: k })} id="uncontrolled-tab" className="uncontrolled-tab">
                <Tab className={"request"} eventKey={REQUEST_GROUP_CREATION_TAB_ID.request} title={t("358")}>
                    <Row className="gx-0 request-form-title notosanskr-500 font-size-26 c-333">
                        <Col className="col-auto">{t("384")}</Col>
                        {process.env.NODE_ENV !== "production" && (
                            <Col>
                                &nbsp;
                                <Button
                                    onClick={() => {
                                        let r = dummy.randomGroupInfo();
                                        document.getElementById(ID.createGroupName).value = r.name;
                                        document.getElementById(ID.createGroupUserName).value = r.userName;
                                        document.getElementById(ID.createGroupContact).value = r.contact;
                                        document.getElementById(ID.createGroupResearch).value = r.research;
                                        document.getElementById(ID.createGroupRelLink).value = r.rel_link;
                                        document.getElementById(ID.createGroupAbout).value = r.about;
                                        const radio = document.querySelector(`input[name="${ID.createGroupRequireJoinConfirm}-radio"][value="${r.require_join_confirm}"`);
                                        if (radio) radio.checked = true;
                                    }}
                                >
                                    Auto Fill
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <RequestGroupCreationContent
                        key={state.reRenderKey}
                        ID={ID}
                        onSubmit={async (certificateFiles, pictures, representative_picture) => {
                            setState({ ...state, popup: POPUP.Request, popupTarget: null, certificateFiles: certificateFiles, pictures: pictures, representativePicture: representative_picture });
                        }}
                    />
                </Tab>
                <Tab className={"histories"} eventKey={REQUEST_GROUP_CREATION_TAB_ID.histories} title={t("373")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item first">{t("374")}</Col>
                        <Col className="item second">{t("375")}</Col>
                        <Col className="item third">{t("376")}</Col>
                        <Col className="item fourth">{t("400")}</Col>
                    </Row>
                    {reviews.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className="item first c-333">{new Date(item.register_date).toLocaleDateString()}</Col>
                            <Col className="item second c-333">{item.state === utility.STATE.ACQUIRED && item.group_state === "active" ? <Link to={`/Group/${item.group_id}`}>{common.i18nGroupName(item.name)}</Link> : <>{common.i18nGroupName(item.name)}</>}</Col>
                            <Col className="item third">{processedState(item)}</Col>
                            <Col className="item fourth col-lg-auto button">
                                <button
                                    className="c-white notosanskr-500 font-size-15"
                                    onClick={() => {
                                        Api.getGroupHistoryItem(item._id)
                                            .then((payload) => {
                                                setState({ ...state, popup: POPUP.RequestContent, popupTarget: payload });
                                            })
                                            .catch((e) => {
                                                dgLogger.error(e)();
                                            });
                                    }}
                                >
                                    {t("400")}
                                    <img src={`${process.env.PUBLIC_URL}/arrow.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/arrow@2x.png 2x, ${process.env.PUBLIC_URL}/arrow@3x.png 3x`}
                                    alt="" />
                                </button>
                            </Col>
                        </Row>
                    ))}
                    <Pagination
                        ref={(newPage) => {
                            Api.getGroupHistoryMine({
                                currentPage: newPage,
                                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
                            })
                                .then((payload) => {
                                    paginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    paginationOption.current.currentPage = newPage;
                                    setReviews(payload.histories);
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                                });
                        }}
                        page={paginationOption.current.currentPage}
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
