import { useCallback } from "react";

export default function useFileHook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const imageValidation = useCallback(({ required, errorKey, min_item_count, fileListLength }) => {
        return ref.current?.imageValidation?.({ required, errorKey, min_item_count, fileListLength });
    }, [ref]);

    const pushDeletedFilesKey = useCallback((value) => {
        ref.current?.pushDeletedFilesKey?.(value);
    }, [ref]);

    const pushAddedFilesKey = useCallback((value) => {
        ref.current?.pushAddedFilesKey?.(value);
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setFileValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    // 현재 저장 된 value 반환
    const getFileValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    const setRepresentativePicture = useCallback((value) => {
        ref.current?.setRepresentativePicture?.(value);
    }, [ref]);

    // upload 된 file의 유효성을 검사하고 문제가 없다면 사용 할 수 있도록 file list 반환
    const processUploadedFiles = useCallback(async ({ acceptedFiles, fileRejections, containFiles = [], max_item_count }) => {
        return await ref.current?.processUploadedFiles({
            acceptedFiles,
            fileRejections,
            containFiles,
            max_item_count
        });
    }, [ref]);

    // 2개의 파일이 같은 지 판단하는 함수
    // 파일의 이름과 크기가 같다면 같은 것으로 판단
    const compareFile = useCallback((source, target) => {
        if (source && target && source?.name === target?.name && source?.size === target?.size) {
            return true;
        }
        return false;
    }, []);

    const uploadImage = useCallback((file) => {
        return ref.current?.uploadImage?.(file);
    }, [ref]);

    return {
        imageValidation,
        pushDeletedFilesKey,
        pushAddedFilesKey,
        setFileValue,
        getFileValue,
        setRepresentativePicture,
        processUploadedFiles,
        compareFile,
        uploadImage,
    };
};
