import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api";
import { Row, Col } from "react-bootstrap";
import { useIsMount } from "../../common/customHook";
import ProjectsSection, { PROJECT_STATE } from "./ProjectsSection";
import "./PlannedProjectsSection.css";
import { IsPC } from "../common/MediaQuery";
import dgLogger from '../../common/dgLogger';
import { useTranslation, Trans } from "react-i18next";

export default function PlannedProjectsSection(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        projects: [], // number of projects
        filterOption: {
            currentPage: 1,
        },
    });
    const isPc = IsPC();
    const isMount = useIsMount();
    const paginationOption = useRef({ itemsCountPerPage: props.itemsCountPerPage ? props.itemsCountPerPage : 4, pageRangeDisplayed: null, totalItemsCount: 0 }); // 추후 동작에 따라 ref가 아니라 일반 변수로 수정 할 수도 있음

    useEffect(() => {
        Api.getPlannedProjectListAll({
            group: props.group,
            optGroup: true,
            currentPage: 1,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            sort: { register_date: -1 },
        })
            .then((payload) => {
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    projects: payload.projects,
                }));
            })
            .catch((e) => dgLogger.error(e)());
    }, [isMount, props.group]);

    return (
        <React.Fragment>
            <div id="planned-project">
                {state.projects?.length > 0 && (
                    <div>
                        <Row className={`gx-0 align-items-center top-bar ${isPc ? "mb-26px" : "mb-12px"}`}>
                            <Col className="col-auto notosanskr-300 font-size-30 title">
                                <Trans i18nKey={'216'} components={{tag: <span className="notosanskr-600 font-size-30"></span>}}/>
                            </Col>
                            <Col>
                                <div className="line" />
                            </Col>
                            <Col className="col-auto">
                                <div className="dot" />
                            </Col>
                        </Row>

                        <div className="planned-project-group">
                            <Row className="gx-0">
                                <Col>
                                    <ProjectsSection paginationOption={paginationOption.current} projects={state.projects} state={PROJECT_STATE.PLANNED} />
                                </Col>
                            </Row>
                            {paginationOption.current.totalItemsCount > state.projects.length && (
                                <Row key={state.projects.length} className="view-more-row gx-0 align-items-center">
                                    <Col>
                                        <div className="line" />
                                    </Col>
                                    <Col className="col-auto">
                                        <button
                                            className="more notosanskr-16 c-white"
                                            onClick={() => {
                                                const page = state.filterOption.currentPage + 1;
                                                Api.getPlannedProjectListAll({
                                                    group: props.group,
                                                    optGroup: true,
                                                    currentPage: page,
                                                    itemsCountPerPage: paginationOption.current.itemsCountPerPage,
                                                    sort: { register_date: -1 },
                                                })
                                                    .then((payload) => {
                                                        paginationOption.current.totalItemsCount = payload.totalItemsCount;
                                                        const project = state.projects.concat(payload.projects);
                                                        setState({ ...state, filterOption: { currentPage: page }, projects: project });
                                                    })
                                                    .catch((e) => dgLogger.error(e)());
                                            }}
                                        >
                                            {t("4")}
                                        </button>
                                    </Col>
                                    <Col>
                                        <div className="line" />
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
