import React, { useState, useRef } from "react";
import { useIsMount } from "../../common/customHook";
import '@toast-ui/chart/dist/toastui-chart.min.css';
import { BarChart, ColumnChart } from '@toast-ui/react-chart';
import { useTranslation } from "react-i18next";

/**
 * Bar 차트를 출력
 * @param {*} props {categories, series, title, yAxisTitle, xAxisTitle, legendVisible, interval}
 * @returns Bar Chart
 */
export default function ReportGraphBarChart(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        minScale: 'month',
        labels: props.categories,
        datasets: props.series,
        options: {
            usageStatistics: false,
            exportMenu: {
                visible: false
            },
            chart: {
                width: 'auto',
                height: 'auto',
                title: {
                    text: props.title,
                    position: 'left',
                    offsetY: -10,
                }
            },
            tooltip: {
                template: (model, _/*defaultTooltipTemplate*/, theme) => {
                  const { background } = theme;
            
                  return `<div style="
                    background: ${background};
                    min-width: 140px;
                    color: white;
                    border-radius: 3px;
                  " class="d-flex">
                      <div class="p-4" style="color: ${model.data[0].color}">■</div>
                      <div class="py-4">${model.data[0].category}</div>
                      <div class="p-4" style="margin-left: auto !important">${model.data[0].formattedValue}</div>
              </div>`;
                }
            },
            yAxis: {
                title: props?.yAxisTitle,
                min: 1
            },
            xAxis: {
                title: props?.xAxisTitle,
                tick: (props.interval || (props.vertical && props.categories.length <= 17)) && { interval: 1 },
                height: 150,
            },
            legend: {
                align: 'bottom',
                visible: props.legendVisible,
            },
            lang: {  // 빈 데이터 (series 이 빈 배열일때) 일때 출력할 메세지
                noData: t("690"),
            },
            theme: {
                chart: {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    fontFamily: "Noto Sans KR",
                    fontWeight: "400",
                },
                xAxis: {
                    title: {
                        fontSize: 15,
                        fontWeight: 400,
                    },
                    label: {
                        fontSize: 11,
                        fontWeight: 700,
                    },
                },
                yAxis:
                {
                    title: {
                        fontSize: 17,
                        fontWeight: 400,
                    },
                    label: {
                        fontSize: 11,
                        fontWeight: 700,
                    },
                },
                series: {
                    barWidth: props.vertical && props.categories.length < 10 && "100" ,
                },
                legend: {
                    label: {
                        fontSize: 14,
                        fontWeight: 400,
                    }
                },
                noData: { // 빈 데이터 (series 이 빈 배열일때) 일때 출력할 폰트 스타일
                    fontSize: 24,
                    fontFamily: "Noto Sans KR",
                    fontWeight: 'bold',
                    color: '#3ee',
                },
            }
        }
    });
    const isMount = useIsMount();
    if (!isMount) return <></>;

    const sum = (array) => array.reduce((acc, cur) => acc + cur, 0);
    const datasetsSum = sum(state.datasets.map(x => sum(x.data)));

    return (<div>
        {props.showScaleDropbox && <div className="report-graph-based-scale-level-wrap">
            <select
                className="report-graph-based-combobox notosanskr-500 font-size-14 form-select form-select-sm"
                defaultValue={state.minScale}
                onChange={(e) => {
                    props.onChangeScale(e.target.value).then(termChart => {
                        // vertical bar 출력이고, 출력할 항목이 10개 미만이라면, bar 의 width 를 좁게 조절하여 출력합니다.
                        const options = state.options;
                        options.theme.series.barWidth = props.vertical && termChart.categories.length < 10 ? "100" : undefined;
                        setState({ ...state, options: options, labels: termChart.categories, datasets: termChart.series });
                    });
                }}
                disabled={!props.categories?.length}
            >
                <option value="month">per Month</option>
                <option value="year">per Year</option>
            </select>
        </div>}
        <div className="position-relative" style={{ width: "100%", minHeight: "400px", maxHeight: "unset" }}>
            {props.vertical ?
                <ColumnChart
                    key={state.labels}
                    data={{
                        categories: state.labels,
                        series: datasetsSum > 0 ? state.datasets.map(x => ({ ...x, colorByCategories: props.colorByCategories })) : []
                    }}
                    options={state.options}
                    style={
                        {
                            width: '100%',
                            height: '500px',
                        }
                    }
                />
                :
                <BarChart
                    key={state.labels}
                    data={{
                        categories: state.labels,
                        series: datasetsSum > 0 ? state.datasets.map(x => ({ ...x, colorByCategories: true })) : [],
                    }}
                    options={state.options}
                    style={{
                        width: '100%',
                        height: '500px',
                    }} />
            }
        </div>
    </div>);
}