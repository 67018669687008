import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { strings } from "@ocean-knight/shared";
import common from "./common/common";

const getNavigatorLang = () => {
    return (window.navigator.language == "ko" || window.navigator.language.startsWith("ko-")) ? "ko" : "en";
};

i18n
// .use(LanguageDetector)
.use(initReactI18next)
.init({
    debug: process.env.NODE_ENV !== "production",
    // Note. webview 의 경우, LanguageDetector 를 통하는 경우, 'en' 으로만 나오게 됨.
    // 따라서, userAgent 가 mobile app (mobile app 에서 append 함) 일 경우, LanguageDetector 의 값을 override 하도록 처리
    lng: getNavigatorLang(),
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    saveMissing: true,
    resources: {
        en: {
            translation: strings.en
        },
        ko: {
            translation: strings.ko
        }
    }
});
