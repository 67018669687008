import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import WithAutocompleteHandle from "../hoc/WithAutocompleteHandle";
import useTypeaheadHook from './TypeaheadHook';
import TypeaheadView from "./TypeaheadView";
import dgLogger from '../../../common/dgLogger';
import common from '../../../common/common';

const Typeahead = forwardRef(({ defaultValue, type, fields, _id, currentUserInfo, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);

    const [errors, setErrors] = useState({ [type]: { state: true, message: "" } });
    const {
        autocompleteValidation,
        setTypeaheadValue,
        getTypeaheadValue,
        searchUserName
    } = useTypeaheadHook(ref);

    const [options, setOptions] = useState([]); // 현재 표시중인 선택 가능한 항목 리스트

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        setTypeaheadValue(defaultValue);
    }, [defaultValue, setTypeaheadValue]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = getTypeaheadValue();
        const { valid, errorCollection } = autocompleteValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [required, type, autocompleteValidation, getTypeaheadValue]);

    // 선택한 항목 목록이 변경되었을 때 호출되는 handler
    const onChangeHandle = useCallback((selected) => {
        setTypeaheadValue(selected);
        validation();
    }, [validation, setTypeaheadValue]);

    // 검색어를 입력했을 때 표시하는 handler
    // parameter로 전달된 name이 포함되는 사용자 정보 목록을 받아와서, 해당 정보를 options에 저장하는 handler
    const onSearch = useCallback(async (name) => {
        try {
            const users = await searchUserName(name);
            setOptions(users);
        }
        catch (err) {
            dgLogger.info(err)();
            setOptions([]);
        }
    }, [searchUserName]);

    const filterBy = useCallback((option, props) => (
        !props.selected.map(select => select._id).includes(option._id)
        && option._id !== currentUserInfo._id
    ), [currentUserInfo]);

    // autocompleteValidation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        _id,
        title,
        description,
        type,
        defaultValue,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        options,
        filterBy,
        onChangeHandle,
        onSearch
    };

    return (
        <TypeaheadView {...viewProps} />
    );
});

export default WithAutocompleteHandle(Typeahead);
