import "./Typeahead.css";
import { Col } from "react-bootstrap";
import { AsyncTypeahead } from "../../common/Typeahead";

const TypeaheadView = ({
    _id,
    title,
    description,
    type,
    defaultValue,
    invalid,
    errorMessage,
    options,
    filterBy,
    onChangeHandle,
    onSearch
}) => {
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                <AsyncTypeahead
                    id={"typeahead-" + _id}
                    className={invalid}
                    labelKey="name"
                    multiple
                    onChange={onChangeHandle}
                    minLength={1}
                    onSearch={onSearch}
                    options={options}
                    placeholder={description}
                    defaultSelected={defaultValue || []}
                    filterBy={filterBy}
                />
                <label className="error">
                    {errorMessage}
                </label>
            </Col>
        </>
    );
};

export default TypeaheadView;
