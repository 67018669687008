import { useCallback } from "react";

export default function useCheckboxHook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const optionalValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.optionalValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setCheckboxValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    // 현재 저장 되어 있는 value return
    const getCheckboxValue = useCallback(() => {
            return ref.current?.getValue?.();
    }, [ref]);

    return {
        optionalValidation,
        setCheckboxValue,
        getCheckboxValue,
    };
};
