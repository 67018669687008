import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import { POPUP } from "../../common/defines";
import { useLocation, useNavigate } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Modal from "../common/Modal";
import Pagination from '../common/Pagination';
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useNotification } from "../../common/customHook";
import { mutate } from "swr";
import SiteMemberInfoWindow from "./ManageMember/SiteMemberInfoWindow";
import "./ApproveGroupCreation.css";
import GroupInfoApplicationWindow from './../common/GroupInfoApplicationWindow';
import CustomCheckbox from './../common/CustomCheckbox';
import { IsMobile, IsPC } from "../common/MediaQuery";
import { useIsMount } from "../../common/customHook";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import QueryString from 'qs';
import { APPROVE_GROUP_CREATION_TAB_ID } from "@ocean-knight/shared/src/utility";

const ID = {
    rejectReason: "group-creation-approval-reason",

    checkAll: "group-creation-approval-check-all",
    buttonApprove: "group-creation-button-approve",
    buttonReject: "group-creation-button-reject",
};

export const PopupGroupCreationReject = ({onRequestClose, onConfirm, onCancel}) => {
    const header = <div>{i18next.t("503")}</div>;
    const body = <div>{i18next.t("489")}</div>;

    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            onCancel={onCancel}
            header={header}
            body={body}
            textareaId="group-creation-approval-reason"
        />
    );
};

export const PopupGroupCreationRejectReason = ({onRequestClose, onConfirm, reason}) => {
    const header = <div>{i18next.t("503")}</div>;
    const body = <div>{i18next.t("490")}</div>;


    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            header={header}
            body={body}
            reason={reason}
        />
    );
};

export function ApproveGroupCreation() {
    const { t } = useTranslation();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [APPROVE_GROUP_CREATION_TAB_ID.reviews, APPROVE_GROUP_CREATION_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : APPROVE_GROUP_CREATION_TAB_ID.reviews, // 현재 tab
        groups: [], // group information
        popup: POPUP.None,
        popupTarget: null,
        buttonDisabled: true,
    });
    const [reviews, setReviews] = useState([]);
    const [histories, setHistories] = useState([]);
    const navigate = useNavigate();
    const { notifications } = useNotification();
    const isPc = IsPC();
    const reviewsPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const historiesPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const isMount = useIsMount();

    const updateCheckboxesStatus = useCallback((reviews) => {
        let checked = 0;
        reviews.forEach((review) => {
            if (document.getElementById(review._id)?.checked) checked++;
        });

        document.getElementById(ID.checkAll).checked = checked > 0 && checked === reviews.length;
        document.getElementById(ID.checkAll).disabled = !(reviews?.length);
        setState((prev) => ({ ...prev, buttonDisabled: checked === 0 }));
    }, []);

    // TODO: ApproveDataUseRequest.js 의 방식을 따라서 (유사 페이지들)코드를 개선합니다.
    //  구성: state.activeTab 을 감시하고 있다가, tab chage 시 useEffect 에서 자료 갱신 함수 호출. pageChange 쪽은 함수 호출
    // 변경사항이 많아지므로, 추후 개별 이슈를 만들어 진행합니다.
    useEffect(() => {
        const hasPermission = common.hasSiteAdminPermission(JSON.parse(sessionStorage.getItem("permissions")));
        if (!hasPermission) {
            if (!isMount.current) return;
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }
        const getContents = async () => {
            if (!isMount.current) { return; }
            try {
                const groups = await Api.getAllGroupList();
                const reviewsPayload = await Api.getGroupHistoryAll({
                    states: [utility.STATE.REVIEWING],
                    currentPage: reviewsPaginationOption.current.currentPage,
                    itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                    sort: { register_date: -1 },
                });
                reviewsPaginationOption.current.totalItemsCount = reviewsPayload.totalItemsCount;

                const resultsPayload = await Api.getGroupHistoryAll({
                    states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                    currentPage: historiesPaginationOption.current.currentPage,
                    itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                    sort: { state_confirmed_date: -1 },
                });
                historiesPaginationOption.current.totalItemsCount = resultsPayload.totalItemsCount;

                setReviews(reviewsPayload.histories);
                setHistories(resultsPayload.histories);
                updateCheckboxesStatus(reviewsPayload.histories);
                setState((prev) => ({ ...prev, groups: groups }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };
        getContents();
    }, [isMount, updateCheckboxesStatus, t]);

    useEffect(() => {
        reviewsPaginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        reviewsPaginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        historiesPaginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        historiesPaginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;

        if (!isMount.current) return;
        forceUpdate();
    }, [isPc, isMount, forceUpdate]);

    const updateGroupState = async (groupIDs, state_, reason) => {
        await Api.updateGroupHistory({
            ids: groupIDs,
            state: state_,
            state_reason: reason,
            lang: common.getLang()
        });

        let page = reviewsPaginationOption.current.currentPage;
        if (page > 1 && reviews.length === 1) page = page - 1;
        reviewsPaginationOption.current.currentPage = page;

        const payload = await Api.getGroupHistoryAll({
            states: [utility.STATE.REVIEWING],
            currentPage: reviewsPaginationOption.current.currentPage,
            itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
            sort: { register_date: -1 },
        });

        reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;

        let mutated = false;
        for (const _id of groupIDs) {
            const notification = notifications?.find((notification) => notification.item === _id);
            if (notification) {
                await Api.removeNotification(notification._id);
                mutated = true;
            }
        }
        mutated && mutate("useNotification");

        return payload.histories;
    };

    const getUserInfo = async (_id) => {
        try {
            const payload = await Api.getUserInfo({ _id: _id });
            return payload;
        } catch (e) {
            dgLogger.error(e)();
            return null;
        }
    };

    const popups = () => {
        if (state.popup === POPUP.Approve) return popupGroupCreationApprove();
        if (state.popup === POPUP.Reject) return <PopupGroupCreationReject
            onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            onConfirm={() => {
                const reason = document.getElementById("group-creation-approval-reason").value;
                updateGroupState(state.popupTarget, utility.STATE.REJECTED, reason)
                .then((reviews) => {
                    updateCheckboxesStatus(reviews);
                    setReviews(reviews);
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                })
                .catch(e => {
                    dgLogger.error(e)();
                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                });
            }}
            onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
        />;
        if (state.popup === POPUP.RejectReason) return <PopupGroupCreationRejectReason
            onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            reason={state.popupTarget.state_reason}
        />;
        if (state.popup === POPUP.UserInfo) return popupUserInfo();
        if (state.popup === POPUP.RequestContent) return popupGroupCreationRequestContent();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const popupGroupCreationRequestContent = () => {
        return (
            <GroupInfoApplicationWindow
                group={state.popupTarget}
                onClick={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            />
        );
    };

    const popupGroupCreationApprove = () => {
        dgLogger.assert(state.popupTarget != null)();
        const group = state.popupTarget;
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_apply.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_apply@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_apply@3x.png 3x`} alt=""/>;
        const header = <div>{t("501")}</div>;
        const body = <div><span className="c-4270e0">{group.name}</span> {t("502")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    updateGroupState(state.popupTarget, utility.STATE.ACQUIRED, null)
                    .then((reviews) => {
                        updateCheckboxesStatus(reviews);
                        setReviews(reviews);
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    })
                    .catch(e => {
                        dgLogger.error(e)();
                        setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                    });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupUserInfo = () => {
        const groupIds = state.popupTarget.groups.reduce((ids, cur) => {
            if (cur.group) ids.push(cur.group);
            return ids;
        }, []);

        return (
            <SiteMemberInfoWindow
                user={state.popupTarget}
                groups={state.groups.filter((group) => groupIds.includes(group._id))} // filter option 에 그룹이 빈값(전체) 라면 모든 그룹을
                onCancel={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
            />
        );
    };

    const processedState = (item) => {
        if (item.state === utility.STATE.ACQUIRED)
            return (
                <button className="nanumsquare-15b lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#4270e0", cursor: "default" }}>
                    {t("377")}
                </button>
            );
        else
            return (
                <button
                    className="nanumsquare-15b lh-1.2 ls--0.75px c-white"
                    style={{ backgroundColor: item.state === utility.STATE.REVIEWING ? "#87ac2f" : "#636363" }}
                    onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}
                >
                    {t("421")}
                </button>
            );
    };

    return (
        <div id="approve-group-creation">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("352")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("353")}</div>
                </Col>
            </Row>

            <Tabs
                activeKey={state.activeTab}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
                onSelect={(key) => {
                    if (state.activeTab === key) return;

                    switch (key) {
                        case APPROVE_GROUP_CREATION_TAB_ID.reviews:
                            return Api.getGroupHistoryAll({
                                states: [utility.STATE.REVIEWING],
                                currentPage: reviewsPaginationOption.current.currentPage,
                                itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                                sort: { register_date: -1 },
                            })
                                .then((payload) => {
                                    reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: APPROVE_GROUP_CREATION_TAB_ID.reviews });
                                    setReviews(payload.histories);
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });

                        case APPROVE_GROUP_CREATION_TAB_ID.histories:
                            return Api.getGroupHistoryAll({
                                states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                                currentPage: historiesPaginationOption.current.currentPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                                sort: { state_confirmed_date: -1 },
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: APPROVE_GROUP_CREATION_TAB_ID.histories });
                                    setHistories(payload.histories);
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        default:
                            return;
                    }
                }}
            >
                <Tab className={"reviews"} eventKey={APPROVE_GROUP_CREATION_TAB_ID.reviews} title={t("413")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18 lh-1.33 ls--0.9px">
                        <Col className="item col-auto text-center w-40px">
                            <CustomCheckbox
                                id={ID.checkAll}
                                onChange={(e) => {
                                    reviews.forEach((review) => {
                                        document.getElementById(review._id).checked = e.target.checked;
                                    });

                                    setState({ ...state, buttonDisabled: !e.target.checked });
                                }}
                                disabled={!reviews.length}
                            />
                        </Col>
                        <Col className="item">{t("374")}</Col>
                        <Col className="item">{t("491")}</Col>
                        <Col className="item">{t("400")}</Col>
                    </Row>
                    {reviews.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16 lh-1.25 ls--0.8px">
                            <Col className="item col-auto text-center w-40px">
                                <CustomCheckbox
                                    id={item._id}
                                    onChange={(_) => {
                                        updateCheckboxesStatus(reviews);
                                    }}
                                />
                            </Col>
                            <Col className="item text-center">{new Date(item.register_date).toLocaleDateString()}</Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="border-0"
                                    role="link"
                                    onClick={async () => {
                                        const info = await getUserInfo(item.owner);
                                        if (info) {
                                            setState({ ...state, popup: POPUP.UserInfo, popupTarget: info });
                                        }
                                    }}
                                >
                                    {item.user_name}
                                </span>
                            </Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="border-0"
                                    role="link"
                                    onClick={() => {
                                        Api.getGroupHistoryItem(item._id)
                                            .then((payload) => {
                                                setState({ ...state, popup: POPUP.RequestContent, popupTarget: payload });
                                            })
                                            .catch((e) => {
                                                dgLogger.error(e)();
                                            });
                                    }}
                                >
                                    {item.name}
                                </span>
                            </Col>
                        </Row>
                    ))}

                    <Row className="gx-0 button-group mt-30px">
                        <Col className="text-end">
                            <button
                                id={ID.buttonApprove}
                                className="approve notosanskr-400 font-size-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    const approveIDs = [];
                                    reviews.forEach((review) => {
                                        if (document.getElementById(review._id).checked) approveIDs.push(review._id);
                                    });
                                    setState({ ...state, popup: POPUP.Approve, popupTarget: approveIDs });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("377")}
                            </button>

                            <button
                                id={ID.buttonReject}
                                className="reject notosanskr-400 font-size-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    const rejectIDs = [];
                                    reviews.forEach((review) => {
                                        if (document.getElementById(review._id).checked) rejectIDs.push(review._id);
                                    });
                                    setState({ ...state, popup: POPUP.Reject, popupTarget: rejectIDs });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("421")}
                            </button>
                        </Col>
                    </Row>

                    <Pagination
                        ref={(newPage) => {
                            Api.getGroupHistoryAll({
                                states: [utility.STATE.REVIEWING],
                                currentPage: newPage,
                                itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                                sort: { register_date: -1 },
                            })
                                .then((payload) => {
                                    reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    reviewsPaginationOption.current.currentPage = newPage;
                                    setReviews(payload.histories);
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                                });
                        }}
                        page={reviewsPaginationOption.current.currentPage}
                        marginPagesDisplayed={reviewsPaginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={reviewsPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={reviewsPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={reviewsPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
                <Tab className={"histories"} eventKey={APPROVE_GROUP_CREATION_TAB_ID.histories} title={t("414")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item text-center">{t("374")}</Col>
                        <Col className="item text-center">{t("420")}</Col>
                        <Col className="item text-center">{t("665")}</Col>
                        <Col className="item text-center">{t("491")}</Col>
                        <Col className="item text-center">{t("376")}</Col>
                    </Row>
                    {histories.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className="item text-center">{new Date(item.register_date).toLocaleDateString()}</Col>
                            <Col className="item text-center">{new Date(item.state_confirmed_date).toLocaleDateString()}</Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="border-0"
                                    role="link"
                                    onClick={() => {
                                        Api.getGroupHistoryItem(item._id)
                                            .then((payload) => {
                                                setState({ ...state, popup: POPUP.RequestContent, popupTarget: payload });
                                            })
                                            .catch((e) => {
                                                dgLogger.error(e)();
                                            });
                                    }}
                                >
                                    {item.name}
                                </span>
                            </Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="border-0"
                                    role="link"
                                    onClick={async () => {
                                        const info = await getUserInfo(item.owner);
                                        if (info) {
                                            setState({ ...state, popup: POPUP.UserInfo, popupTarget: info });
                                        }
                                    }}
                                >
                                    {item.user_name}
                                </span>
                            </Col>
                            <Col className="item text-center process-state">{processedState(item)}</Col>
                        </Row>
                    ))}
                    <Pagination
                        ref={(newPage) => {
                            Api.getGroupHistoryAll({
                                states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                                currentPage: newPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                                sort: { state_confirmed_date: -1 },
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    historiesPaginationOption.current.currentPage = newPage;
                                    setHistories(payload.histories);
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                                });
                        }}
                        page={historiesPaginationOption.current.currentPage}
                        marginPagesDisplayed={historiesPaginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={historiesPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={historiesPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={historiesPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
