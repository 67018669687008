import React, { useImperativeHandle, useState, useContext, useCallback, useRef } from 'react';
import WithValueHandle from "./WithValueHandle";
import common from '../../../common/common';
import { POPUP as GeneralPopup } from "../../../common/defines";

import ExifReader from "exifreader";
import { PopupValidateFile, PopupValidateImage, PopupValidateResolution } from "../../common/ValidateModal";
import Api from '../../../Api';
import useSizeContext from "../Context/SizeContext";
import i18n from 'i18next';
import canvasSize, { maxWidth } from 'canvas-size';

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
    ImageUploadPending: 1006,
};

const MAX_SIZE = 1024 * 1024 * 1000; // 1GB

const WithImageHandle = (WrapperComponent) => {
    const WithImage = ({ ...otherProps }, ref) => {
        const [popup, setPopup] = useState(POPUP.None);
        const [popupTarget, setPopupTarget] = useState(null);
        // 삭제 된 파일들의 키
        const deletedFilesKey = useRef([]);
        // 추가 된 파일들의 키
        const addedFilesKey = useRef([]);

        const { SizeContext } = useSizeContext();
        const sizeContext = useContext(SizeContext);

        const pushDeletedFilesKey = useCallback((key) => {
            deletedFilesKey.current.push(key);
        }, []);

        const getDeletedFilesKey = useCallback(() => {
            return deletedFilesKey.current;
        }, []);

        const pushAddedFilesKey = useCallback((key) => {
            addedFilesKey.current.push(key);
        }, []);

        const getAddedFilesKey = useCallback(() => {
            return addedFilesKey.current;
        }, []);

        /**
         * 전달받은 파일로부터 exif 정보를 취득하여 반환
         * @param {*} file exif 정보를 취득할 타겟 파일
         * @returns 획득한 exif 정보에 있는 { latitude, longitude, 생성시간 }
         */
        const getExif = useCallback(async (file) => {
            const tags = await ExifReader.load(file, { expanded: true });

            return {
                lat: tags.gps?.Latitude,
                lng: tags.gps?.Longitude,
                dateTime: tags.exif?.DateTimeOriginal?.description || tags.exif?.DateTime?.description,
            };
        }, []);

        const canvasLimit = async () => {
            let canvasMaxWidth;
            let canvasMaxHeight;
            let canvasMaxArea;
            try {
                const { width : _maxW } = await canvasSize.maxWidth({ usePromise: true });
                canvasMaxWidth = _maxW;
                const { height : _maxH } = await canvasSize.maxHeight({ usePromise: true });
                canvasMaxHeight = _maxH;
                const { width, height } = await canvasSize.maxArea({ usePromise: true });
                canvasMaxArea = width * height;
                // console.log(`max w : ${canvasMaxWidth}, max h : ${canvasMaxHeight}, max area : ${canvasMaxArea}`);
            } catch (e) {
                // see. https://jhildenbiddle.github.io/canvas-size/#/?id=test-results
                canvasMaxWidth = canvasMaxHeight = 16384;
                canvasMaxArea = 16384 * 16384;
            }

            return { maxWidth: canvasMaxWidth, maxHeight: canvasMaxHeight, maxArea: canvasMaxArea};
        };

        const checkTooHighResolutionFileIndex = async(fileList, {maxWidth, maxHeight, maxArea}, type="image/") => {
            const _URL = window.URL || window.webkitURL;

            const filePromises = fileList.map(async (file, index) => {
                if (!file.type.startsWith(type)) {
                    return { index, skip: true };
                }

                const url = _URL.createObjectURL(file);
                const img = new Image();
                const promise = new Promise((resolve, reject) => {
                    img.onload = () => {
                        // console.log(`{w: ${img.width}}, h: ${img.height}, area: ${img.width*img.height}`);
                        resolve({ index, file, width: img.width, height: img.height });
                    };
                    img.onerror = (e) => reject({ index, file, error: e });
                });
                img.src = url;

                try {
                    return await promise;
                } catch (error) {
                    return { index, error };
                } finally {
                    _URL.revokeObjectURL(url);
                }
            });

            const results = await Promise.all(filePromises);

            const invalidFile = results.find(r => !r.skip && (r.error || maxWidth < r.width || maxHeight < r.height || maxArea < r.width * r.height));
            if (invalidFile) {
                return invalidFile.index;
            }

            return -1;
        };

        // upload 된 file의 유효성을 검사하고 문제가 없다면 사용 할 수 있도록 file list 반환
        const processUploadedFiles = useCallback(async ({ acceptedFiles, fileRejections, containFiles = [], max_item_count }) => {
            const [newFileList, fileList, result] = common.validateOnDrop(
                acceptedFiles,
                fileRejections,
                containFiles,
                max_item_count,
                null,
                ["image/"]
            );
            if (!result.valid) {
                setPopup(POPUP.ValidateFileField);
                setPopupTarget({ // files 및 size 는 0 이하의 값일 경우, modal (popup) 에 에러메세지가 출력되지 않음.
                    MAX_FILES: max_item_count,
                    MAX_SIZE: -1,
                    MIME_TYPE: (!result.mimeType) ? "image/*" : null
                });

                return { newFileList:[], fileList: containFiles };
            }

            for (const file of newFileList) {
                // 전체 첨부 파일 용량이 1GB 를 넘는다면, 에러 팝업
                if (file.size + sizeContext.getOriginalUploadedSize() > MAX_SIZE) {
                    setPopup(POPUP.ValidateFileField);
                    setPopupTarget({
                        MAX_FILES: -1,
                        MAX_SIZE: MAX_SIZE
                    });

                    return { newFileList:[], fileList: containFiles };
                }
                else {
                    sizeContext.addOriginalUploadedSize(file.size);
                }
            }

            // 업로드 된 파일의 exif 정보를 각각 저장 후 rolling image를 출력할 수 있도록 item 추가
            for (const file of newFileList) {
                try {
                    const exif = await getExif(file);
                    file.latitude = exif.lat;
                    file.longitude = exif.lng;
                    file.dateTime = exif.dateTime;
                }
                catch (e) { }
            };

            const {maxWidth, maxHeight, maxArea} = await canvasLimit();
            let foundIndex = await checkTooHighResolutionFileIndex(newFileList, {maxWidth, maxHeight, maxArea}, "image/");
            let highReolutionFile = undefined;
            if (foundIndex >= 0) {
                const removed = newFileList.splice(foundIndex);
                highReolutionFile = removed[0];

                foundIndex = fileList.findIndex(v => common.isEqualFile(v,highReolutionFile));
                fileList.splice(foundIndex);
            }

            if (highReolutionFile) {
                setPopup(POPUP.ValidateFileField);
                setPopupTarget({ // files 및 size 는 0 이하의 값일 경우, modal (popup) 에 에러메세지가 출력되지 않음.
                    EXCEED_RESOLUTION_FILE: highReolutionFile.name
                });
            }

            return { newFileList, fileList };
        }, [sizeContext, getExif]);

        // image upload 중 문제가 있을 때 발생하는 popup
        const Popups = useCallback(() => {
            if (popup === POPUP.ValidateFileField) {
                if (popupTarget?.MIME_TYPE) {
                    return (
                        <PopupValidateImage
                            onCancel={() => {
                                setPopup(POPUP.None);
                                setPopupTarget(null);
                            }}
                        />
                    );
                } else if (popupTarget?.EXCEED_RESOLUTION_FILE) {
                    return (
                        <PopupValidateResolution
                            fileName={popupTarget?.EXCEED_RESOLUTION_FILE}
                            onCancel={() => {
                                setPopup(POPUP.None);
                                setPopupTarget(null);
                            }}
                        />
                    );
                }
                else {
                    return (
                        <PopupValidateFile
                            maxFiles={popupTarget?.MAX_FILES ? popupTarget?.MAX_FILES : -1}
                            maxSizeInMB={popupTarget?.MAX_SIZE ? popupTarget?.MAX_SIZE / (1024 * 1024) : -1}
                            onCancel={() => {
                                setPopup(POPUP.None);
                                setPopupTarget(null);
                            }}
                        />
                    );
                }
            }
            else return <></>;
        }, [popup, popupTarget]);

        // image의 validation
        // report item에 명시 되어 있는 min_item_count를 이용하여 부분 입력 등의 validation 처리
        const imageValidation = useCallback(({required, errorKey, min_item_count, fileListLength }) => {
            let valid = true;
            const errorCollection = {
                [errorKey]: {
                    state: true,
                    message: ''
                }
            };

            if (required) {
                if (fileListLength < min_item_count) {
                    errorCollection[errorKey] = {
                        state: false,
                        message: i18n.t("15")
                    };
                    valid = false;
                }
            }
            else {
                if (fileListLength > 0 && fileListLength < min_item_count) {
                    errorCollection[errorKey] = {
                        state: false,
                        message: i18n.t("528")
                    };
                    valid = false;
                }
            }
            return { valid, errorCollection };
        }, []);

        // image를 cloud 의 임시 bucket에 저장하는 api 호출
        // 저장 된 image의 size를 context에 저장
        const uploadImage = useCallback(async (file) => {
            const fileInfo = await Api.uploadImage({ imageFile: file });
            sizeContext.addResizedUploadSize((fileInfo.no_preview ? 0 : fileInfo.resizeImageSize));

            return fileInfo;
        }, [sizeContext]);

        // 값이 undeinfed 인 key는 request 시 사라지게 되므로 없애고 싶은 key에 대해 undeinfed로 설정
        const transformItem = useCallback((item) => ({
            ...item,
            resize_url: undefined,
            resizeImageSize: undefined,
            url: undefined,
            watermarkText: undefined
        }), []);

        useImperativeHandle(ref, () => (
            { ...ref.current, pushDeletedFilesKey, getDeletedFilesKey, pushAddedFilesKey, getAddedFilesKey, imageValidation, processUploadedFiles, uploadImage, transformItem }
        ), [ref, pushDeletedFilesKey, getDeletedFilesKey, pushAddedFilesKey, getAddedFilesKey, imageValidation, processUploadedFiles, uploadImage, transformItem]);

        return (
            <>
                <Popups />
                <WrapperComponent ref={ref} {...otherProps} />
            </>
        );
    };

    return WithValueHandle(React.forwardRef(WithImage));
};

export default WithImageHandle;
