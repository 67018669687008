import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { POPUP } from "../../common/defines";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Modal from "../common/Modal";
import Pagination from '../common/Pagination';
import { useNotification } from "../../common/customHook";
import { mutate } from "swr";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import "./ApproveGroupMember.css";
import SiteMemberInfoWindow from "./ManageMember/SiteMemberInfoWindow";
import CustomCheckbox from './../common/CustomCheckbox';
import { IsPC } from "../common/MediaQuery";
import dgLogger from '../../common/dgLogger';
import { useIsMount } from "../../common/customHook";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import QueryString from 'qs';
import { APPROVE_GROUP_MEMBER_TAB_ID } from "@ocean-knight/shared/src/utility";

const ID = {
    acquired: "group-join-approval-acquired",
    rejected: "group-join-approval-rejected",
    rejectReason: "group-join-approval-reject-reason",
    history: "history",
    reviewing: "reviewing",

    checkAll: "group-join-approval-check-all",
    buttonApprove: "group-join-button-approve",
    buttonReject: "group-join-button-reject",
};

export const PopupGroupJoinReject = ({onRequestClose, onConfirm, onCancel}) => {
    const header = <div>{i18next.t("488")}</div>;
    const body = <div>{i18next.t("489")}</div>;

    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            onCancel={onCancel}
            header={header}
            body={body}
            textareaId={ID.rejectReason}
        />
    );
};

export const PopupGroupJoinRejectReason = ({onRequestClose, onConfirm, reason}) => {
    const header = <div>{i18next.t("488")}</div>;
    const body = <div>{i18next.t("490")}</div>;

    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            header={header}
            body={body}
            reason={reason}
        />
    );
};


export function ApproveGroupMember() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [APPROVE_GROUP_MEMBER_TAB_ID.reviews, APPROVE_GROUP_MEMBER_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : APPROVE_GROUP_MEMBER_TAB_ID.reviews, // 현재 tab
        popup: POPUP.None,
        popupTarget: null,
        groups: [], // group information
        reviews: [],
        histories: [],
        buttonDisabled: true,
    });
    const isPc = IsPC();
    const { notifications } = useNotification();
    const reviewsPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const historiesPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const isMount = useIsMount();

    const updateCheckboxesStatus = useCallback((reviews) => {
        let checked = 0;
        const checkAll = document.getElementById(ID.checkAll);
        if (!checkAll) {
            dgLogger.error("element not found")();
            return;
        }
        reviews.forEach((review) => {
            if (document.getElementById(review._id)?.checked) checked++;
        });

        checkAll.checked = checked > 0 && checked === reviews.length;
        checkAll.disabled = !(reviews?.length);
        setState((prev) => ({ ...prev, buttonDisabled: checked === 0 }));
    }, []);

    useEffect(() => {
        const hasPermission = common.hasGroupManagerPermission();
        if (!hasPermission) {
            if (!isMount.current) { return; }
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }

        const getContents = async () => {
            if (!isMount.current) { return; }
            try {
                const groups = await Api.getAllGroupList();
                const reviewsPayload = await Api.getGroupMemberHistoryAll({
                    states: [utility.STATE.REVIEWING],
                    currentPage: reviewsPaginationOption.current.currentPage,
                    itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                    sortKey: "lookupApproval.requested_date",
                });
                reviewsPaginationOption.current.totalItemsCount = reviewsPayload.totalItemsCount;

                const resultsPayload = await Api.getGroupMemberHistoryAll({
                    states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                    currentPage: historiesPaginationOption.current.currentPage,
                    itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                    sortKey: "lookupApproval.state_confirmed_date",
                });
                historiesPaginationOption.current.totalItemsCount = resultsPayload.totalItemsCount;

                updateCheckboxesStatus(reviewsPayload.histories);
                setState((prev) => ({ ...prev, groups: groups, reviews: reviewsPayload.histories, histories: resultsPayload.histories }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };
        getContents();
    }, [updateCheckboxesStatus, isMount, t]);

    useEffect(() => {
        reviewsPaginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        reviewsPaginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        historiesPaginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        historiesPaginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        forceUpdate();
    }, [isPc, forceUpdate]);

    const updateGroupMemberState = async (requestIDs, state_, reason, extra_state) => {
        await Api.updateGroupMemberHistory({
            ids: requestIDs,
            state: state_,
            state_reason: reason,
            lang: common.getLang()
        });

        let page = reviewsPaginationOption.current.currentPage;
        if (page > 1 && state.reviews.length === 1) page = page -1;
        reviewsPaginationOption.current.currentPage = page;

        const payload = await Api.getGroupMemberHistoryAll({
            states: [utility.STATE.REVIEWING],
            currentPage: reviewsPaginationOption.current.currentPage,
            itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
            sortKey: "lookupApproval.requested_date",
        });
        reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;

        let mutated = false;
        for (const _id of requestIDs) {
            const notification = notifications?.find((notification) => notification.item === _id);
            if (notification) {
                await Api.removeMatchedNotification({ from: notification.from, item: notification.item });
                mutated = true;
            }
        }
        mutated && mutate("useNotification");


        return payload.histories;
    };

    const getUserInfo = async (_id) => {
        try {
            const payload = await Api.getUserInfo({ _id: _id });
            return payload;
        } catch (e) {
            dgLogger.error(e)();
            return null;
        }
    };

    const popups = () => {
        if (state.popup === POPUP.Approve) return popupGroupJoinApprove();
        if (state.popup === POPUP.Reject) {
            dgLogger.assert(state.popupTarget != null)();
            return <PopupGroupJoinReject
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    const reason = document.getElementById(ID.rejectReason).value;
                    updateGroupMemberState(state.popupTarget, utility.STATE.REJECTED, reason)
                        .then((histories) => {
                            updateCheckboxesStatus(histories);
                            setState({ ...state, reviews: histories, popup: POPUP.None, popupTarget: null, buttonDisabled: true });
                        })
                        .catch((e) => {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                        });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            />;
        }
        if (state.popup === POPUP.RejectReason) return <PopupGroupJoinRejectReason
            onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            reason={state.popupTarget.state_reason}
        />;
        if (state.popup === POPUP.UserInfo) return popupUserInfo();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const popupUserInfo = () => {
        const groupIds = state.popupTarget.groups.reduce((ids, cur) => {
            if (cur.group) ids.push(cur.group);
            return ids;
        }, []);

        return (
            <SiteMemberInfoWindow
                user={state.popupTarget}
                groups={state.groups.filter((group) => groupIds.includes(group._id))} // filter option 에 그룹이 빈값(전체) 라면 모든 그룹을
                onCancel={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
            />
        );

    };

    const popupGroupJoinApprove = () => {
        dgLogger.assert(state.popupTarget != null)();
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_apply.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_apply@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_apply@3x.png 3x`} alt=""/>;
        const header = <div>{t("486")}</div>;
        const body = <div>{t("487")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() =>
                    updateGroupMemberState(state.popupTarget, utility.STATE.ACQUIRED, null)
                        .then((histories) => {
                            updateCheckboxesStatus(histories);
                            setState({ ...state, reviews: histories, popup: POPUP.None, popupTarget: null, buttonDisabled: true });
                        })
                        .catch((e) => {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                        })
                }
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const processedState = (item) => {
        if (item.state === utility.STATE.ACQUIRED)
            return (
                <button className="nanumsquare-15b lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#4270e0", cursor: "default" }}>
                    {t("377")}
                </button>
            );
        else
            return (
                <button
                    className="nanumsquare-15b lh-1.2 ls--0.75px c-white"
                    style={{ backgroundColor: item.state === utility.STATE.REVIEWING ? "#87ac2f" : "#636363" }}
                    onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}
                >
                    {t("421")}
                </button>
            );
    };

    return (
        <div id="approve-group-member">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("352")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("356")}</div>
                </Col>
            </Row>

            <Tabs
                activeKey={state.activeTab}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
                onSelect={(key) => {
                    if (state.activeTab === key) return;

                    switch (key) {
                        case APPROVE_GROUP_MEMBER_TAB_ID.reviews:
                            return Api.getGroupMemberHistoryAll({
                                states: [utility.STATE.REVIEWING],
                                currentPage: reviewsPaginationOption.current.currentPage,
                                itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                                sortKey: "lookupApproval.requested_date",
                            })
                                .then((payload) => {
                                    reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: APPROVE_GROUP_MEMBER_TAB_ID.reviews, reviews: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });

                        case APPROVE_GROUP_MEMBER_TAB_ID.histories:
                            return Api.getGroupMemberHistoryAll({
                                states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                                currentPage: historiesPaginationOption.current.currentPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                                sortKey: "lookupApproval.state_confirmed_date",
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: APPROVE_GROUP_MEMBER_TAB_ID.histories, histories: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        default:
                            return;
                    }
                }}
            >
                <Tab className={"reviews"} eventKey={APPROVE_GROUP_MEMBER_TAB_ID.reviews} title={t("413")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className={`item col-auto text-center ${isPc ? "w-80px" : "w-40px"}`}>
                            <CustomCheckbox
                                id={ID.checkAll}
                                onChange={(e) => {
                                    state.reviews.forEach((review) => {
                                        document.getElementById(review._id).checked = e.target.checked;
                                    });

                                    setState({ ...state, buttonDisabled: !e.target.checked });
                                }}
                                disabled={!state.reviews.length}
                            />
                        </Col>
                        <Col className="item text-center">{t("374")}</Col>
                        <Col className="item text-center">{t("491")}</Col>
                    </Row>
                    {state.reviews.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className={`item col-auto text-center ${isPc ? "w-80px" : "w-40px"}`}>
                                <CustomCheckbox
                                    id={item._id}
                                    onChange={(_) => {
                                        updateCheckboxesStatus(state.reviews);
                                    }}
                                />
                            </Col>
                            <Col className="item text-center">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="notosanskr-500 font-size-15"
                                    role="link"
                                    onClick={async () => {
                                        const info = await getUserInfo(item.user);
                                        if (info) {
                                            setState({ ...state, popup: POPUP.UserInfo, popupTarget: info });
                                        }
                                    }}
                                >
                                    {item.userName}
                                </span>
                            </Col>
                        </Row>
                    ))}

                    <Row className="gx-0 button-group mt-19px">
                        <Col className="text-end">
                            <button
                                id={ID.buttonApprove}
                                className="approve notosanskr-400 font-size-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    const approveIDs = [];
                                    state.reviews.forEach((review) => {
                                        if (document.getElementById(review._id).checked) approveIDs.push(review._id);
                                    });
                                    setState({ ...state, popup: POPUP.Approve, popupTarget: approveIDs });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("377")}
                            </button>

                            <button
                                id={ID.buttonReject}
                                className="reject notosanskr-400 font-size-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    const rejectIDs = [];
                                    state.reviews.forEach((review) => {
                                        if (document.getElementById(review._id).checked) rejectIDs.push(review._id);
                                    });
                                    setState({ ...state, popup: POPUP.Reject, popupTarget: rejectIDs });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("421")}
                            </button>
                        </Col>
                    </Row>

                    <Pagination
                        ref={(newPage) => {
                            Api.getGroupMemberHistoryAll({
                                states: [utility.STATE.REVIEWING],
                                currentPage: newPage,
                                itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                                sortKey: "lookupApproval.requested_date",
                            })
                                .then((payload) => {
                                    reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    reviewsPaginationOption.current.currentPage = newPage;
                                    setState({ ...state, reviews: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        }}
                        page={reviewsPaginationOption.current.currentPage}
                        marginPagesDisplayed={reviewsPaginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={reviewsPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={reviewsPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={reviewsPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>

                <Tab className={"histories"} eventKey={APPROVE_GROUP_MEMBER_TAB_ID.histories} title={t("414")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item text-center">{t("374")}</Col>
                        <Col className="item text-center">{t("420")}</Col>
                        <Col className="item text-center">{t("491")}</Col>
                        <Col className="item text-center">{t("376")}</Col>
                    </Row>

                    {state.histories.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className="item text-center">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item text-center">{new Date(item.state_confirmed_date).toLocaleDateString()}</Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="notosanskr-500 font-size-15"
                                    role="link"
                                    onClick={async () => {
                                        const info = await getUserInfo(item.user);
                                        if (info) {
                                            setState({ ...state, popup: POPUP.UserInfo, popupTarget: info });
                                        }
                                    }}
                                >
                                    {item.userName}
                                </span>
                            </Col>
                            <Col className="item text-center process-state">{processedState(item)}</Col>
                        </Row>
                    ))}
                    <Pagination
                        ref={(newPage) => {
                            Api.getGroupMemberHistoryAll({
                                states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                                currentPage: newPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                                sortKey: "lookupApproval.state_confirmed_date",
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    historiesPaginationOption.current.currentPage = newPage;
                                    setState({ ...state, histories: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        }}
                        page={historiesPaginationOption.current.currentPage}
                        marginPagesDisplayed={historiesPaginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={historiesPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={historiesPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={historiesPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
