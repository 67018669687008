const randomSignupInfo = () => {
    const prefix = ["jane", "john", "joseph", "rex", "darlene", "roger", "leo", "carly", "shana", "vince", "baz", "magnus", "julilliard", "katherine", "sienna", "rachel", "francis", "kay", "evan", "conner", "joshua", "jayden", "anthony", "harvey", "james", "adam", "viktor", "benjamin", "enji", "leo", "buck", "dynne", "jeffrey"];
    const suffix = ["@gmail.fake.com", "@naver.fake.com", "@daum.fake.net", "@nate.fake.com", "@yahoo.fake.com", "@msn.fake.com", "@hotmail.fake.com", "@outlook.fake.com", "@kakao.fake.com", "@facebook.fake.com", "@twitter.fake.com", "@instagram.fake.com"];
    let email = `${prefix[Math.floor(Math.random() * prefix.length)]}${suffix[Math.floor(Math.random() * suffix.length)]}`;

    const fname = ["Carly", "Vince", "Baz", "Magnus", "Leo", "Darlene", "Rex", "Joseph", "John", "Jane"];
    const lname = ["Smith", "Johnson", "Williams", "Jones", "Brown", "Davis", "Miller", "Wilson", "Moore", "Taylor"];
    let name = `${fname[Math.floor(Math.random() * fname.length)]} ${lname[Math.floor(Math.random() * lname.length)]}`;

    let password = "1";

    let phone = `010${Math.floor(Math.random() * 100000000)}`;

    const address1list = [
        "서울특별시",
        "경기도",
        "인천광역시",
        "부산광역시",
        "대구광역시",
        "대전광역시",
        "광주광역시",
        "울산광역시",
        "세종특별자치시",
        "경상북도",
        "경상남도",
        "충청북도",
        "충청남도",
        "전라북도",
        "전라남도",
        "강원도",
        "제주특별자치도",
    ];
    const address2list = [
        "중구",
        "서구",
        "동구",
        "영도구",
        "부산진구",
        "동래구",
        "남구",
        "북구",
        "사하구",
        "금정구",
        "강서구",
        "연제구",
        "수영구",
        "사상구",
        "기장군",
        "중구",
        "서구",
        "동구",
        "영도구",
        "부산진구",
        "동래구",
        "남구",
        "북구",
        "사하구",
        "금정구",
        "강서구",
        "연제구",
        "수영구",
        "사상구",
        "기장군",
    ];
    let address1 = address1list[Math.floor(Math.random() * address1list.length)];
    let address2 = address2list[Math.floor(Math.random() * address2list.length)];

    let about = `안녕하세요. 저는 ${name} 입니다. 이메일 주소는 ${email} 입니다.`;

    let snses = ["https://www.facebook.com/", "https://www.twitter.com/", "https://www.instagram.com/"];
    let sns = snses[Math.floor(Math.random() * snses.length)];

    return { email: email, nickname: name, phone: phone, password: password, address1: address1, address2: address2, about: about, sns: sns, addressList: address1list };
};

function __randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const randomOrgInfo = () => {
    const namePrefix = ["김", "이", "박", "송", "오", "최", "유", "임", "조", "전", "안", "지"];
    const namePostfix = ["지호", "상훈", "세령", "선아", "현주", "정현", "경호", "형진", "종화", "용태", "한준", "윤병", "진호", "진환", "현우", "예준", "봉규", "승례", "현욱", "태용", "수미", "찬욱"];

    const name = `${namePrefix[Math.floor(Math.random() * namePrefix.length)]} ${namePostfix[Math.floor(Math.random() * namePostfix.length)]}`;

    const belongsToRc = [
        "스펙터",
        "아트록스 군단",
        "은의 현자",
        "지하드",
        "용감한 형제단",
        "어둠의 설원",
        "지구교",
        "리벨리온",
        "로젠크로이츠 올덴",
        "블레이즈 원",
        "죽음을 먹는자",
        "HYDRA",
        "위키드",
        "에테온",
        "시스",
        "디셉티콘",
        "그림자 동맹",
        "템플 기사단",
        "제레",
        "백선교",
        "신라 컴퍼니", 
        "번스타인",
    ];
    const affiliation = belongsToRc[Math.floor(Math.random() * belongsToRc.length)]

    const positionRc = ["사원", "주임", "대리", "과장", "차장", "사원", "조장", "반장", "직장", "연구원", "선임 연구원", "책임 연구원", "이사", "상무", "전무", "부사장", "사장", "부장", "공장장", "수석 연구원"];
    const position = positionRc[Math.floor(Math.random() * positionRc.length)];
    const cause = "그룹내 자료를 열람하고 싶습니다.";
    const contact = "010-123-4567";

    return { name, affiliation, position, cause, contact};
};

const randomGroupInfo = () => {
    const namePrefix = ["", "하얀", "흰", "노란", "회색의", "붉은", "불타는", "잿빛의", "검은", "푸른", "창백한", "맑은", "흐린", "정겨운", "따듯한", "뜨거운", "식어가는", "마지막", "시작의"];
    const namePostfix = ["", "Mk II", "Co LTD", "Inc", "TM", "Corp ", "PLC", "LLC"]
    const nameRc = [
        "스펙터",
        "아트록스 군단",
        "은의 현자",
        "지하드",
        "용감한 형제단",
        "어둠의 설원",
        "지구교",
        "리벨리온",
        "로젠크로이츠 올덴",
        "블레이즈 원",
        "죽음을 먹는자",
        "HYDRA",
        "위키드",
        "에테온",
        "시스",
        "디셉티콘",
        "그림자 동맹",
        "템플 기사단",
        "제레",
        "백선교",
        "신라 컴퍼니", 
        "번스타인",
    ];
    let name = `${namePrefix[Math.floor(Math.random() * namePrefix.length)]} ${nameRc[Math.floor(Math.random() * nameRc.length)]} ${namePostfix[Math.floor(Math.random() * namePostfix.length)]}`;
    let contact = `010${Math.floor(Math.random() * 100000000)}`;
    let start_date = __randomDate(new Date(1982, 1, 1), new Date()).toISOString().substring(0, 10);
    let end_date = __randomDate(new Date(), new Date(7654, 3, 21)).toISOString().substring(0, 10);
    const researchRc = [
        "지구공동설",
        "지적설계",
        "다우징 로드",
        "지진운",
        "젋은 지구설",
        "컴트레일",
        "지구 평면설",
        "천동설",
        "프랙탈 우주론",
        "형태형성장 가설",
        "삼등분가",
        "저마늄",
        "생광석",
        "물은 답을 알고있다",
        "영구기관",
        "카르노 효율",
        "원적외선",
        "수소수",
        "육각수",
        "피라미드 파워",
        "감응유전설",
    ];
    let research = researchRc[Math.floor(Math.random() * researchRc.length)];
    const rel_link_rc = ["https://www.facebook.com/", "https://www.twitter.com/", "https://www.instagram.com/"];
    let rel_link = rel_link_rc[Math.floor(Math.random() * rel_link_rc.length)];
    let require_join_confirm = Math.random() < 0.5;

    const userNamePrefix = ["김", "이", "박", "송", "오", "최", "유", "임", "조", "전", "안", "지"];
    const userNamePostfix = ["지호", "상훈", "세령", "선아", "현주", "정현", "경호", "형진", "종화", "용태", "한준", "윤병", "진호", "진환", "현우", "예준", "봉규", "승례", "현욱", "태용", "수미", "찬욱"];

    const userName = `${userNamePrefix[Math.floor(Math.random() * userNamePrefix.length)]} ${userNamePostfix[Math.floor(Math.random() * userNamePostfix.length)]}`;

    let about = `안녕하세요. 이 그룹은 ${name} 입니다. 관심사는 ${research} 입니다. 연락은 ${contact} 로 주세요.`;
    return {
        name: name,
        userName: userName,
        contact: contact,
        start_date: start_date,
        end_date: end_date,
        research: research,
        rel_link: rel_link,
        require_join_confirm: require_join_confirm,
        about: about,
        namerc: nameRc,
    };
};


const randomProjectInfo = () => {
    const namePrefix = ["", "하얀", "흰", "노란", "회색의", "붉은", "불타는", "잿빛의", "검은", "푸른", "창백한", "맑은", "흐린", "정겨운", "따듯한", "뜨거운", "식어가는", "마지막", "시작의"];
    const nameRc = [
        "모질라 파이어폭스",
        "모질라 썬더버드",
        "아이스위즐",
        "미도리",
        "실피드",
        "아파치 HTTP 서버",
        "WebKit - 블링크",
        "자테온",
        "체로키",
        "크로뮴",
        "파일질라",
        "NGINX",
        "Netty",
        "Tor",
        "Signal",
        "OpenVPN",
        "WireGuard",
        "ProtonVPN",
        "ProtonMail,",
        "루비",
        "부스트",
        "지니",
        "파이썬",
        "펄",
        "Emacs",
        "이클립스",
        "넷빈즈",
        "Git",
        "Node.js",
        "PHP",
        "Qt",
        "Rust",
        "vi (vim)",
        "Swift",
        "R",
        "Kotlin",
        ".NET",
    ];
    let name = `${namePrefix[Math.floor(Math.random() * namePrefix.length)]} ${nameRc[Math.floor(Math.random() * nameRc.length)]}`;
    let start_date = __randomDate(new Date(1982, 1, 1), new Date()).toISOString().substring(0, 10);
    let end_date = __randomDate(new Date(2020, 1, 1), new Date(7654, 3, 21)).toISOString().substring(0, 10);
    const locationRc = [
        "서울특별시",
        "경기도",
        "인천광역시",
        "부산광역시",
        "대구광역시",
        "대전광역시",
        "광주광역시",
        "울산광역시",
        "세종특별자치시",
        "경상북도",
        "경상남도",
        "충청북도",
        "충청남도",
        "전라북도",
        "전라남도",
        "강원도",
        "제주특별자치도",
    ];

    let location = locationRc[Math.floor(Math.random() * locationRc.length)];
    let about = `안녕하세요. 이 프로젝트는 ${name} 입니다. 프로젝트 지역은 ${location} 입니다.`;
    return { name: name, start_date: start_date, end_date: end_date, location: location, about: about };
};

module.exports = {
    randomSignupInfo,
    randomGroupInfo,
    randomProjectInfo,
    randomOrgInfo,
};
