import React, {useState, useRef} from "react";
import ManualInputMap from "./common/ManualInputMap";
import * as Config from "./common/MapConfig";
import Api from "../Api";
import { error as E } from "@ocean-knight/shared";
import KakaoShareButton from "./common/KakaoShareButton";
import LoadingSpinner from "./common/LoadingSpinner";
import { logEvent } from "../common/fbase";
import { getUA, isMobile } from 'react-device-detect';
import { IsWebview } from "./common/MediaQuery";

// TODO: separate into another file
import { useIsMount } from "../common/customHook";
import common from "../common/common";
function GetGraph() {
    const [data, setData] = useState([]);
    const isMount = useIsMount();
    const dataLength = useRef(-1);
    const [state, setState] = useState({ isLoading: false });
    const paginationOption = useRef({ itemsCountPerPage: 16, pageRangeDisplayed: isMobile ? 5 : 10, totalItemsCount: 0 });

    const getGraphBasedData = () => {
        setState({ ...state, isLoading: true });

        const payload = {
            groups: undefined,
            projects: undefined,
            locations: undefined,
            startDate: undefined,
            endDate: undefined,
            registered_by: undefined,
            keyword: '',
            page: 1,
            field: '',
            user_id: undefined,
        };
    
        Api.getReportsGraph(payload)
            .then((payload) => {
                if (!isMount.current) return;
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                dataLength.current = payload.reports.length;
                setData(payload.reports);
                console.log(JSON.stringify(payload.reports, null, 4));
            })
            .catch((e) => {
                console.log(e);
                // show empty data
                paginationOption.current.totalItemsCount = 0;
                dataLength.current = 0;
                setData([]);
            })
            .finally(() => {
                setState({ ...state, isLoading: false });
            });
    };
    
    useState(() => {
        getGraphBasedData();
    }, [paginationOption]);

    return <></>;
}

export default function TestComponent() {
    const [state, setState] = useState({
        popup: false,
        topReporterProjectId: ""
    });
    const [loading, setLoading] = useState({isOpen:false});
    const interval = useRef();
    const progress = useRef(0);
    const progressbar = useRef();

    const gps = useRef({lat: undefined, lng: undefined});


    const confirm = () => {
        console.log(gps.current); //gps 값 확인 가능
        setState({
            ...state, popup: false
        });
    };

    const cancel = () => {
        setState({
            ...state, popup: false
        });
    };

    const popup = () => {
        if (state.popup){
            return (
                <>
                <ManualInputMap
                center={Config.defaultCenter}
                zoom={Config.zoom}
                ref={gps}
                ></ManualInputMap>
                <button onClick={confirm}>확인</button>
                <button onClick={cancel}>취소</button>
                </>
            );
        }
    };

    const setBucketACL = () => {
        Api.setBucketACL({ bucketName: document.getElementById("set-bucket-acl").value });
    };

    // Note. check develop env: process.env.NODE_ENV !== "production"
    return (
        <React.Fragment>
        {popup()}

        <div>
            <div style={{border: "1px solid"}}>
                <div>결과 로그는 console (f12) 에서 확인</div>
                <div>
                    <button
                        onClick={() => {
                            Api.devAutoGenerateUser()
                                .then(() => {
                                    console.info("random user creation success");
                                })
                                .catch((e) => console.dir(e));
                        }}
                    >
                        Auto Generate User
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => {
                            Api.devMakeUser2RandomGroupManager()
                                .then(() => {
                                    console.info("make random group manager success");
                                })
                                .catch((e) => console.error(e.toString()));
                        }}
                    >
                        (현재 로그인된 사용자를) Make random Group manager
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => {
                            Api.devMakeUser2RandomGroupMember()
                                .then(() => {
                                    console.info("make random group member success");
                                })
                                .catch((e) => console.error(e.toString()));
                        }}
                    >
                        (현재 로그인된 사용자를) Make random Group member
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => {
                            Api.devMakeRandomOrganization()
                                .then(() => {
                                    console.info("make random organization success");
                                })
                                .catch((e) => console.error(e.toString()));
                        }}
                    >
                        (현재 로그인 된 사용자로) Make random organization
                    </button>
                </div>
            </div>
            <div style={{border: "1px solid"}}>
                <div>
                <button onClick={() => {
                    Api.post("/v1/group/dev/auto-generate-requests")
                        .then((res) => {
                            if (res.data.code !== E.CREATED) {
                                console.warn('failed to request create random group');
                                return;
                            }

                            console.info("request group creation success");
                        })
                        .catch((e) => {
                            console.dir(e);
                        });
                }}>Auto Request Group Creation</button>
                </div>
                <div>
                <button onClick={async () => {
                    try {
                    const reports = await Api.getProjectRegister({ // 등록된 자료 획득
                        itemsCountPerPage: 100,
                        sort: { register_date: -1 },
                    });
                    const [name, data] = await Api.downloadReports({
                        ids: reports.register.map(report => report._id),
                        lang: common.getLang()
                    });
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", decodeURIComponent(name));
                    link.style.cssText = "display:none";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } catch (e) {
                    console.error(e);
                }
                    }}>Download randomly sampled Reports</button>
                </div>
            </div>
        </div>

        <input type="text" id="set-bucket-acl" className="font-size-20" />
        <input type="button" onClick={setBucketACL} value="bucket acl 설정" className="font-size-20" />
            <input type="button" onClick={() => {
                Api.post("/v1/dev/test");
            }}
                value="Test"
                className="font-size-20"
            />

        <KakaoShareButton />
        <div>
        <button onClick={() => {
            if (interval.current) return;

            interval.current = setInterval(() => {
                console.log('progress ' + loading.progress);

                if (progress.current >= 100) {
                    setLoading({...loading, isOpen:false});
                    clearInterval(interval.current);
                    interval.current = null;
                    progress.current = 0;
                    return;
                }
                progress.current = progress.current + 0.7;
                progressbar.current.updateProgress(progress.current);
            }, 1000);
            progressbar.current.resetProgress();
            setLoading({...loading, isOpen: true});
        }}>LoadingSpinner Test</button>
        <LoadingSpinner isOpen={loading.isOpen} ref={progressbar}/>
        </div>
        <div>
            <button onClick={() => {
                logEvent('select_content', {
                    content_type: 'button',
                    content_id: 'Test'
                });
            }}>Google Analytics logEvent</button>
        </div>
        <div style={{background: 'white'}}>
            {getUA} / isMobile = {isMobile ? 'true':'false'} / IsWebview = {IsWebview() ? 'true':'false'}
        </div>
        <GetGraph />
        </React.Fragment>
    );
}
