import React, { useState, useEffect } from "react";
import Api from "../../Api";
import { POPUP as GeneralPopup } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Row, Col } from "react-bootstrap";
import "./RequestOrganization.css";
import ImageDropZone2 from '../common/ImageDropZone2';
import { useIsMount } from "../../common/customHook";
import { InputText } from "../Input";
import { PopupValidateFile } from "../common/ValidateModal";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
};

const MAX_FILES = 10;
const MAX_SIZE = (1024 * 1024) * 200; // 200MB

export default function RequestOrganizationContent({
    ID, // html element's ID
    onSubmit, // 신청하기 버튼을 눌렀을때의 callback
    applicationInfo, // 정보를 보여줄 organization request 신청서 내용
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState({
        certificateFiles: null,
        userInfo: {},
        groupList: [],

        popup: POPUP.None,
        popupTarget: null,
    });
    const isMount = useIsMount();
    const [errors, setErrors] = useState({ nickname: '', groupName: '', name: '', contact: '', affiliation: '', position: '', files: '', cause: '' });

    useEffect(() => {
        const getContents = async () => {
            try {
                const userInfo = await Api.getCurrentUserInfo();
                const groupList = applicationInfo ? [applicationInfo.group] : await Api.getNotOrganizationGroupList(); // 아직 단체 요청을 받지 않은 그룹 목록만 획득함.

                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    userInfo: userInfo,
                    groupList: groupList,
                }));
            } catch (e) {
                dgLogger.error(e)();
                if (!isMount.current) return;
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            };
        };
        getContents();
    }, [applicationInfo, isMount]);


    // '소속' 파일이 첨부(변경)될 경우
    useEffect(() => {
        if (state.certificateFiles) {
            const files = document.getElementsByClassName("create-organization-files")[0];
            const errorCollection = {};

            if (!state.certificateFiles.length) {
                errorCollection.files = { state: false, message: t("15") };
                files.classList.add("invalid");
            }
            else {
                errorCollection.files = { state: false, message: "" };
                files.classList.remove("invalid");
            }

            setErrors((prev) => ({ ...prev, ...errorCollection }));
        }
    }, [state.certificateFiles, t]);

    const onDropCertificateFiles = (acceptedFiles, fileRejections) => {
        // TBD: 미정의
        const [, allFiles, result] = common.validateOnDrop(acceptedFiles, fileRejections, state.certificateFiles || [], MAX_FILES, MAX_SIZE);
        if (!result.valid) {
            return setState(prev => ({...prev, popup: POPUP.ValidateFileField, }));
        }

        setState({...state, certificateFiles:allFiles});
    };

    const validateFields = async () => {
        let flag = true;
        const errorCollection = {};

        const nickname = document.getElementById(ID.organizationName);
        const groupName = document.getElementById(ID.organizationGroupName);
        const name = document.getElementById(ID.organizationUserName);
        const contact = document.getElementById(ID.organizationUserContact);
        const affiliation = document.getElementById(ID.organizationAffiliation);
        const position = document.getElementById(ID.organizationUserPosition);
        const files = document.getElementsByClassName("create-organization-files")[0];
        const cause = document.getElementById(ID.organizationRequestCause);

        common.validateInputField(errorCollection, 'nickname', nickname) || (flag = false);
        common.validateSelectField(errorCollection, 'groupName', groupName) || (flag = false);
        common.validateInputField(errorCollection, 'name', name) || (flag = false);
        common.validateInputField(errorCollection, 'affiliation', affiliation) || (flag = false);
        common.validateInputField(errorCollection, 'position', position) || (flag = false);
        common.validateFileField(errorCollection, 'files', files, state.certificateFiles) || (flag = false);
        common.validateInputField(errorCollection, 'cause', cause) || (flag = false);
        common.validateInputField(errorCollection, 'contact', contact) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const removeFileFromFileList = (elementId, targetFile) => {
        const dt = new DataTransfer();
        const input = document.getElementById(elementId);
        const { files } = input;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file !== targetFile)
            dt.items.add(file); // here you exclude the file. thus removing it.
        }

        input.files = dt.files; // Assign the updates list
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);
        setErrors({ ...errors, ...errorCollection });
        return flag;
    };


    /**
     *
     * @returns popup 상태에 따라 popup 호출
     */
     const popups = () => {
        if (state.popup === POPUP.ValidateFileField)
            return (
                <PopupValidateFile
                    maxFiles={MAX_FILES}
                    maxSizeInMB={MAX_SIZE / (1024 * 1024)}
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                />
            );
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    return (
        <>
            {popups()}
            <Row className="item-row gx-0">
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("90")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText id={ID.organizationName} disabled={true} defaultValue={state.userInfo.name} placeholder={t("90")} className="w-75"/>
                    <div className="notosanskr-17 c-f00 mb-16px mt-10px"></div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("684")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    <select id={ID.organizationGroupName} name="groupName" disabled={!onSubmit} defaultValue={applicationInfo?.group?._id || ""} onChange={(e) => isValid([e.target])} className="w-75">
                        {applicationInfo ? (
                            ""
                        ) : (
                            <option value="" disabled>
                                {t("255")}
                            </option>
                        )}
                        {state.groupList.map((group) => (
                            <option key={group._id} value={group._id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                    <div className="notosanskr-17 c-f00 mb-16px mt-10px">{errors?.groupName?.message}</div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("91")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText id={ID.organizationUserName} name="name" disabled={!onSubmit} defaultValue={applicationInfo?.name || ""} onBlur={(e) => isValid([e.target])} maxLength={20} />
                    <div className="notosanskr-17 c-f00 mb-16px mt-10px">{errors?.name?.message}</div>
                </Col>
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("388")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText id={ID.organizationUserContact} name="contact" disabled={!onSubmit} defaultValue={applicationInfo?.contact || ""} onBlur={(e) => isValid([e.target])} maxLength={20} />
                    <div className="notosanskr-17 c-f00 mb-16px mt-10px">{errors?.contact?.message}</div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("389")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText id={ID.organizationAffiliation} name="affiliation" disabled={!onSubmit} defaultValue={applicationInfo?.affiliation || ""} onBlur={(e) => isValid([e.target])} maxLength={20} />
                    <div className="notosanskr-17 c-f00 mb-16px mt-10px">{errors?.affiliation?.message}</div>
                </Col>
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("685")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText id={ID.organizationUserPosition} name="position" disabled={!onSubmit} defaultValue={applicationInfo?.position || ""} onBlur={(e) => isValid([e.target])} maxLength={20} />
                    <div className="notosanskr-17 c-f00 mb-16px mt-10px">{errors?.position?.message}</div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("686")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    {applicationInfo ? (
                        applicationInfo.certificate_files.map((file, i) => (
                            <div className="files-wrap align-middle" key={file._id}>
                                <a href={file?.url} target="_blank" rel="noopener noreferrer">
                                    {common.convertFileName(file.name, 40)}
                                </a>
                            </div>
                        ))
                    ) : (
                        <React.Fragment>
                            <div className="caution nanumsquarer-14 c-f00">{t("96")}</div>
                            <div className="file-group d-flex flex-row">
                                <ImageDropZone2
                                    className="create-organization-files notosanskr-14 c-999"
                                    inputFileId={ID.organizationFilesInput}
                                    disabled={state.certificateFiles?.length >= MAX_FILES}
                                    imgId={ID.organizationFiles}
                                    accept=""
                                    ref={onDropCertificateFiles}
                                    multiple={true}
                                    placeholder={t("97")}
                                />
                            </div>
                            <div className="notosanskr-17 c-f00 mb-16px mt-10px">{errors?.files?.message}</div>
                            {state.certificateFiles?.map((file, i) => (
                                <div className="files-wrap align-middle" key={i}>
                                    <span className="file-name nanumsquare-14 lh-1.43 ls--0.7px c-333 mr-15px">{common.convertFileName(file.name)}</span>
                                    <button
                                        className="remove-file"
                                        onClick={(e) => {
                                            removeFileFromFileList(ID.organizationFilesInput, file);
                                            setState({ ...state, certificateFiles: state.certificateFiles?.filter((certFile) => certFile !== file) || [] });
                                        }}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/19171.png`}
                                            srcSet={`${process.env.PUBLIC_URL}/19171@2x.png 2x,
                                            ${process.env.PUBLIC_URL}/19171@3x.png 3x`}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-lg-auto item-title nanumsquareb-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t("92")}</Col>
                    </Row>
                </Col>
                <Col className="item">
                    <textarea id={ID.organizationRequestCause} name="cause" disabled={!!applicationInfo} defaultValue={applicationInfo?.cause || ""} onBlur={(e) => isValid([e.target])} maxLength={500} />
                    <div className="notosanskr-17 c-f00 mb-16px mt-10px">{errors?.cause?.message}</div>
                </Col>
            </Row>

            {onSubmit &&
                <Row className="confirm-btn-group" style={{ textAlign: "right" }}>
                    <Col>
                        <button
                            className="save-btn notosanskr-18 c-white"
                            onClick={async () => {
                                if (!(await validateFields())) {
                                    common.scrollToInvalidElement();
                                    return;
                                }
                                onSubmit && onSubmit(state.certificateFiles);
                            }}
                        >
                            {t("99")}
                        </button>
                    </Col>
                </Row>
            }
        </>
    );
}
