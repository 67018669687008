import { useCallback } from "react";
import Api from '../../../Api';

export default function useTypeaheadHook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const autocompleteValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.autocompleteValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setTypeaheadValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    const getTypeaheadValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    // parameter로 전달된 name이 포함되는 사용자 정보 목록을 받환
    const searchUserName = useCallback(async (name) => {
        const { users } = await Api.searchUserName({ name: name });

        users.sort((a, b) => {
            let pos_a = a.name.toLowerCase().indexOf(name.toLowerCase());
            let pos_b = b.name.toLowerCase().indexOf(name.toLowerCase());

            if (pos_a < pos_b) return -1;
            else if (pos_a === pos_b) return a.name.localeCompare(b.name);
            else return 1;
        });
        return users;
    }, []);

    return {
        autocompleteValidation,
        setTypeaheadValue,
        getTypeaheadValue,
        searchUserName
    };
};
