import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import WithOptionalHandle from "../hoc/WithOptionalHandle";
import useCheckboxHook from './CheckboxHook';
import CheckboxView from "./CheckboxView";
import common from '../../../common/common';

const Checkbox = forwardRef(({ _id, defaultValue, type, fields, sub_items, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const i18n_sub_items = useMemo(() => {
        const lang = common.getLang();
        if (i18n?.[lang]) {
            return sub_items.map((f) => ({ ...f, ...(i18n[lang].sub_items.find((x) => x.index == f.index) || {}) }));
        } else return sub_items;
    }, [sub_items, i18n]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const {
        optionalValidation,
        setCheckboxValue,
        getCheckboxValue
    } = useCheckboxHook(ref);

    const formattedDefaultValue = useMemo(() => (
        defaultValue || []
    ), [defaultValue]);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        setCheckboxValue(defaultValue);
        forceUpdate();
    }, [defaultValue, setCheckboxValue, forceUpdate]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = getCheckboxValue();
        const { valid, errorCollection } = optionalValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [type, required, getCheckboxValue, optionalValidation]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    // custom_string에 적용 될 handle
    const onBlurHandle = useCallback((e, subItemId) => {
        const currentValue = getCheckboxValue();
        if (currentValue) {
            const targetObject = currentValue.find(value => value._id === subItemId);
            targetObject["custom_string"] = e.target.value.trim();
        }
        else {
            setCheckboxValue([{ _id: subItemId, "custom_string": e.target.value.trim() }]);
            validation();
        }
    }, [validation, setCheckboxValue, getCheckboxValue]);

    // 현재 element에서 값이 바뀌었을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    // Checkbox에 적용 될 handle
    const onChangeHandle = useCallback((e) => {
        const currentValue = getCheckboxValue();

        // 기존 value가 있을 때
        if (currentValue) {
            // 기존 value에서 현재 선택 된 item의 index 탐색
            const findIndex = currentValue.findIndex(value => value._id === e.target.value);
            // 이미 있다면 삭제
            if (findIndex >= 0) {
                const newValue = currentValue.filter((_, index) => findIndex !== index);
                setCheckboxValue(newValue);
            }
            // 없다면 추가
            else {
                const newValue = [...currentValue, { _id: e.target.value }];
                setCheckboxValue(newValue);
            }
        }
        //기존 value가 없을 때
        else {
            setCheckboxValue([{ _id: e.target.value }]);
        }
        validation();
    }, [validation, setCheckboxValue, getCheckboxValue]);

    const textDisabled = useCallback((subItemId) => {
        const currentValue = getCheckboxValue();
        if (currentValue) {
            return !currentValue.find(value => value._id === subItemId);
        }
        return true;
    }, [getCheckboxValue]);

    const textDefaultValue = useCallback((subItemId) => {
        const currentValue = getCheckboxValue();
        if (currentValue) {
            return currentValue.find(value => value._id === subItemId)?.["custom_string"] || "";
        }
    }, [getCheckboxValue]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        type,
        defaultValue : formattedDefaultValue,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        sub_items: i18n_sub_items,
        onChangeHandle,
        onBlurHandle,
        textDisabled,
        textDefaultValue
    };

    return (
        <CheckboxView {...viewProps} />
    );
});

export default WithOptionalHandle(Checkbox);
