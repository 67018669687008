export default function CustomCheckbox({
    onChange = undefined,
    id = undefined,
    name = undefined,
    children = undefined,
    className = "d-flex align-items-center",
    type="checkbox", // could be 'checkbox' or 'radio'
    value = undefined, // options for 'radio'
    checked = undefined,
    defaultChecked = undefined, // options for 'radio'
    disabled = false,
    style = undefined,
    inputClassName = ""
}) {
    return (
        <span className={className} style={style}>
            <label className={`custom-checkbox ${type}`}>
                <input
                    type={type}
                    id={id}
                    name={name}
                    checked = {checked}
                    defaultChecked={defaultChecked}
                    value={value}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    // onClick={(e) => {
                    //     if (onChange) {
                    //         onChange(e);
                    //     }
                    // }}
                    disabled={disabled}
                    className={inputClassName}
                />
                <span htmlFor={id} className="checkmark"></span>
            </label>
            {children}
        </span>
    );
}
