import React, { useState } from "react";
import ReactModal from "react-modal";
import "./Modal.css";
import { useTranslation } from "react-i18next";

ReactModal.setAppElement("#root");

export default function Modal(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({ buttonDisabledState: !props.reason });

    const changeButtonState = (e) => {
        if (state.buttonDisabledState && e.target.value.length) {
            setState({...state, buttonDisabledState: false});
        }
        else if (!e.target.value.length) {
            setState({...state, buttonDisabledState: true});
        }
    };

    return (
        props.textareaId || props.reason?
            <ReactModal
                isOpen={true}
                // onRequestClose={props.onRequestClose}
                className={props.className ? props.className : "default"}
                style={{ overlay: {}, content: {} }}
                portalClassName={"custom-modal-no-title"}
            >
                { props.onRequestClose ?
                    <div style={{ textAlign: "right" }}>
                        <img className="close-icon" src={process.env.PUBLIC_URL + `/popup_close.png`} srcSet={`${process.env.PUBLIC_URL}/popup_close@2x.png 2x, ${process.env.PUBLIC_URL}/popup_close@3x.png 3x`} onClick={() => props.onRequestClose()} alt=""/>
                    </div>
                    : <div className="pt-4"></div>
                }
                <div className="custom-modal-reject-content">
                    <div className="custom-modal-header notosanskr-24b">
                        {props.header}
                    </div>
                    <div className="custom-modal-body nanumsquarer-15">
                        {props.body}
                    </div>
                    <div className="custom-textarea-wrap">
                        <textarea
                            id={props.textareaId}
                            className="custom-textarea nanumsquarer-15"
                            disabled={props.reason}
                            defaultValue={props.reason}
                            onChange={changeButtonState}
                            maxLength={props.maxLength || 500}
                        >
                        </textarea>
                    </div>
                </div>
                <div className="custom-modal-reject-footer" style={{ textAlign: "right" }}>
                    <button className={`${props.onCancel ? "isCancel " : ""}confirm nanumsquareb-14`}
                        onClick={() => props.onConfirm()}
                        disabled={state.buttonDisabledState}
                    >
                        {props.overrideConfirmText ? props.overrideConfirmText : (props.footer ? props.footer : (props.reason ? t("79") : t("421")))}
                    </button>
                    {props.onCancel && <button className="cancel nanumsquareb-14" onClick={() => props.onCancel()}>{t("80")}</button>}
                </div>
            </ReactModal>
            :
            <ReactModal
                isOpen={true}
                // onRequestClose={props.onRequestClose}
                className={props.className ? props.className : "default"}
                style={{ overlay: {}, content: {} }}
                portalClassName={"custom-modal-no-title"}
            >
                { props.onRequestClose ?
                    <div style={{ textAlign: "right" }}>
                        <img className="close-icon" src={process.env.PUBLIC_URL + `/popup_close.png`} srcSet={`${process.env.PUBLIC_URL}/popup_close@2x.png 2x, ${process.env.PUBLIC_URL}/popup_close@3x.png 3x`} onClick={() => props.onRequestClose()} alt=""/>
                    </div>
                    : <div className="pt-4"></div>
                }
                <div className="custom-modal-content">
                    <div className="custom-modal-icon">
                        {props.icon}
                    </div>
                    <div className="custom-modal-header notosanskr-24b">
                        {props.header}
                    </div>
                    <div className="custom-modal-body nanumsquarer-15">
                        {props.body}
                    </div>
                </div>
                <div className="custom-modal-footer" style={{ textAlign: "right" }}>
                    <button className={`${props.onCancel ? "isCancel " : ""}confirm nanumsquareb-14`}
                        onClick={() => props.onConfirm()}
                    >
                        {props.overrideConfirmText ? props.overrideConfirmText : (props.footer ? props.footer : t("79"))}
                    </button>
                    {props.onCancel && <button className="cancel nanumsquareb-14" onClick={() => props.onCancel()}>
                        {props.overrideCancelText ? props.overrideCancelText : t("80")}
                    </button>}
                </div>
            </ReactModal>
    );
}
