import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import Api from "../Api";
import { AppContext } from "../AppContext";
import { Container, Row, Col } from "react-bootstrap";
import ActiveProjectsSection from "./Project/ActiveProjectsSection";
import ArchivedProjectsSection from "./Project/ArchivedProjectSection";
import { useIsMount } from "../common/customHook";
import Map from "./common/Map";
import * as Config from "./common/MapConfig";
import common from "../common/common";
import GeneralErrorWindow from "./common/GeneralErrorWindow";
import { PopupRequestJoinGroup, PopupRequestedJoinGroup, PopupJoinGroupRejectReason } from "./common/RequestGroupMemberWindow";
import { POPUP as GeneralPopup } from "../common/defines";
import { useNavigate } from "react-router-dom";
import Editor from "./TinyEditor";
import { utility } from "@ocean-knight/shared";
import ImageGallery from "react-image-gallery";
import validator from 'validator';
import Emblem from "./common/Emblem";
import "./DetailedGroup.css";
import ImagePreviewWindow from "./common/ImagePreviewWindow";
import PlannedProjectsSection from './Project/PlannedProjectsSection';
import LoadingSpinner from "./common/LoadingSpinner";
import { Mobile, IsPC, IsTabletOrPC, TabletOrPC } from "./common/MediaQuery";
import { LinkCopyWindow } from "./common/LinkCopyWindow";
import dgLogger from '../common/dgLogger';
import { useTranslation, Trans } from "react-i18next";
import { mutate } from "swr";

const POPUP = {
    ...GeneralPopup,
    ImagePreview: 1004,
    LinkCopy: 1005,
};

export default function DetailedGroup() {
    const { t } = useTranslation();
    const { _id } = useParams();
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        group: null,
        locations: [],
        reporters: [],
        isGroupMember: utility.STATE.ACQUIRED,
    });
    const isPC = IsPC();
    const isTabletOrPC = IsTabletOrPC();
    const isMount = useIsMount();
    const basedOption = useRef(common.getDefaultMapSetting());
    const imageGalleryRef = useRef();

    useEffect(() => {
        document.getElementById("App").className = "group";
        const getGroupDetails = async () => {
            try {
                const group = await Api.getGroupItem({ _id: _id, optManagers: true });
                const locations = await Api.getReportsMap({ groups: [group._id] });
                const reporterList = await Api.getTopReporterList({ groupId: _id, page: 1, itemsCountPerPage: 6 });
                for (let i = reporterList.reporters.length; i < 6; ++i) { reporterList.reporters.push(null); }

                if (!isMount.current) return;
                setState((prev) => ({ ...prev, group: group, locations: locations, reporters: reporterList.reporters }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };
        getGroupDetails();
    }, [_id, isMount]);

    useEffect(() => {
        (async () => {
            if (context.loggedIn && state.group) {
                let isGroupMember = null;
                const user = await Api.getCurrentUserInfo();
                const found = user.groups.find((item) => item.group === state.group._id);
                if (found) isGroupMember = utility.STATE.ACQUIRED;

                if (!isGroupMember) { // 그룹원 신청이 리뷰 상태인지 확인
                    const payload = await Api.getGroupMemberHistoryMine({ states: [utility.STATE.REVIEWING] });
                    const found = payload.histories.find(history => history.group === state.group._id);
                    if (found) isGroupMember = utility.STATE.REVIEWING;
                }

                if (!isMount.current) return;
                setState((prev) => ({ ...prev, isGroupMember: isGroupMember }));
            }
        })();
    }, [context.loggedIn, isMount, state.group]);

    const popups = () => {
        if (state.popup === POPUP.Request) { // (그룹) 참여 신청
            dgLogger.assert(context.loggedIn)();
            return (
                <PopupRequestJoinGroup
                    onConfirm={async () => {
                        try {
                            await Api.requestJoinGroupMember({
                                _id: state.popupTarget._id, // group._id
                                lang: common.getLang()
                            });

                            const user = await Api.getCurrentUserInfo({ optPermissions: true });
                            const found = user.groups.find((item) => item.group === state.group._id);
                            if (found) {
                                await mutate("useUserinfo");
                                common.storePermissionsInfo(user.optPermissions);
                                setState({ ...state, isGroupMember: utility.STATE.ACQUIRED, popup: POPUP.Requested });
                            } else {
                                const payload = await Api.getGroupMemberHistoryMine({ states: [utility.STATE.REVIEWING] });
                                const found = payload.histories.find((history) => history.group === state.group._id);
                                const isGroupMember = found ? utility.STATE.REVIEWING : null;
                                setState({ ...state, isGroupMember: isGroupMember, popup: POPUP.Requested });
                            }
                        } catch (e) {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: null });
                        }
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    groupName={state.group.name}
                />
            );
        }
        if (state.popup === POPUP.Requested) {
            return <PopupRequestedJoinGroup require_join_confirm={state.popupTarget.require_join_confirm} onClick={() => setState({ ...state, popup: POPUP.None, popupTarget: null })} />;
        }
        if (state.popup === POPUP.RejectReason) {
            return <PopupJoinGroupRejectReason state_reason={""} onClick={() => setState({ ...state, popup: POPUP.None, popupTarget: null })} />;
        }
        if (state.popup === POPUP.ImagePreview) {
            return (
                <ImagePreviewWindow
                    images={state.group.pictures.map((picture) => ({ watermark: picture.url, thumbnail: picture.url }))}
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                    index={state.popupTarget}
                />
            );
        }
        if (state.popup === POPUP.LinkCopy) {
            return <LinkCopyWindow onConfirm={() => setState({ ...state, popup: POPUP.None })} />;
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const showRequestGroupMember = () => {
        if (!context.loggedIn) return false;
        if (common.hasSiteAdminPermission()) return false;
        if (state.isGroupMember === utility.STATE.ACQUIRED) return false;

        return true;
    };

    const getReportListBasedUrl = useCallback((groupId, name) => {
        return common.getReportListBasedUrl({
            groups:[{value: groupId}],
            name: encodeURIComponent(name)});
    }, []);

    if (state.popup === POPUP.GeneralError)
        return (
            <GeneralErrorWindow
                message={state.popupTarget}
                onClick={() => {
                    navigate("/");
                }}
            />
        );
    if (!state.group) return <LoadingSpinner isOpen={true} />;

    return (
        <div id="group-detail">
            {popups()}
            <Container>
                <span className="group-sub-title">
                    <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff group">Group</span>
                    <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">
                        {t("38")}
                    </span>
                </span>
            </Container>
            <Container className="group-info">
                <Row className="gx-0">
                    <Col className="images maxw-660px col-xl" lg={12}>
                        <ImageGallery
                            ref={imageGalleryRef}
                            showNav={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            thumbnailPosition={"bottom"}
                            originalClass={".original"}
                            onClick={() => setState({ ...state, popup: POPUP.ImagePreview, popupTarget: imageGalleryRef.current.getCurrentIndex() })}
                            items={
                                state.group.pictures.reduce((acc, cur) => {
                                    acc.push({
                                        original: cur?.url,
                                        thumbnail: cur?.url,
                                        originalWidth: "659px",
                                        originalHeight: `${isTabletOrPC ? "440px" : "224px"}`,
                                        originalClass: "group-info-images-original",
                                        thumbnailWidth: "101px",
                                        thumbnailHeight: `${isTabletOrPC ? "87px" : "78px"}`,
                                        thumbnailClass: "group-info-images-thumbnail",
                                    });
                                    return acc;
                                }, [])
                            } />
                    </Col>
                    <Col className="info col-xl" lg={12}>
                        <Row className="gx-0 info-row-head">
                            <Col className="group-emblem col-auto">
                                <Emblem
                                    width={`${isTabletOrPC ? "151px" : "75px"}`}
                                    height={`${isTabletOrPC ? "151px" : "75px"}`}
                                    url={state.group.emblem_picture ? state.group.emblem_picture?.url : undefined}
                                    char={state.group.name}
                                    charClass={`avatar ${isTabletOrPC ? "avatar-151" : "avatar-75"} bg-e8ebf00`}
                                    button="sns"
                                    onClick={() => {
                                        common.copyUrlToClipboard(window.location.href);
                                        setState({ ...state, popup: POPUP.LinkCopy });
                                    }}
                                />
                            </Col>
                            <Col className="">
                                <Row className="gx-0">
                                    <Col className="group-name notosanskr-700 font-size-33 c-333 lh-0.95 ls--1.65px text-start">
                                        {common.i18nGroupName(state.group.name)}
                                    </Col>
                                </Row>
                                <Row className="gx-0">
                                    <Col className="group-desc notosanskr-600 font-size-18 c-666 text-break lh-1.56 ls--0.9px">
                                        <Editor viewer={true} HTMLData={state.group.about} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <TabletOrPC>
                            <Row className="gx-0 info-row align-items-center text-start">
                                <Col className={`notosanskr-600 font-size-18 col-auto ${isTabletOrPC && "pl-29px"} mr-8px`}>•</Col>
                                <Col className="const-manager notosanskr-600 font-size-18 c-1b1b1b col-auto">
                                    {t("229")}
                                </Col>
                                <Col className="group-owner notosanskr-400 font-size-16 c-333">
                                    {state.group.optManagers
                                        .filter((manager) => manager._id === state.group.owner)
                                        .map((manager, i, { length }) => (
                                            <React.Fragment key={manager._id}>
                                                {manager.name}
                                                {i + 1 !== length ? ", " : ""}
                                            </React.Fragment>
                                        ))}
                                </Col>
                                <Col className={`notosanskr-600 font-size-18 col-auto ${isTabletOrPC && "pl-29px"} mr-8px`}>•</Col>
                                <Col className="const-register-date notosanskr-600 font-size-18 c-1b1b1b col-auto">
                                    {t("230")}
                                </Col>
                                <Col className="notosanskr-400 font-size-16 c-333">
                                    {new Date(state.group.register_date).toLocaleDateString()}
                                </Col>
                            </Row>
                        </TabletOrPC>
                        <Mobile>
                            <Row className="gx-0 info-row align-items-center text-start">
                                <Col className={`notosanskr-600 font-size-18 col-auto ${isTabletOrPC && "pl-29px"} mr-8px`}>•</Col>
                                <Col className="const-manager notosanskr-600 font-size-18 c-1b1b1b col-auto">
                                    {t("229")}
                                </Col>
                                <Col className="group-owner notosanskr-400 font-size-16 c-333">
                                    {state.group.optManagers
                                        .filter((manager) => manager._id === state.group.owner)
                                        .map((manager, i, { length }) => (
                                            <React.Fragment key={manager._id}>
                                                {manager.name}
                                                {i + 1 !== length ? ", " : ""}
                                            </React.Fragment>
                                        ))}
                                </Col>
                            </Row>
                            <Row className="gx-0 info-row align-items-center text-start">
                                <Col className={`notosanskr-600 font-size-18 col-auto ${isTabletOrPC && "pl-29px"} mr-8px`}>•</Col>
                                <Col className="const-register-date notosanskr-600 font-size-18 c-1b1b1b col-auto">
                                    {t("230")}
                                </Col>
                                <Col className="notosanskr-400 font-size-16 c-333">
                                    {new Date(state.group.register_date).toLocaleDateString()}
                                </Col>
                            </Row>
                        </Mobile>
                        {/* <Row className="gx-0 info-row align-items-center text-start">
                            <Col className="const-project-duration notosanskr-600 font-size-18 c-1b1b1b col-auto">
                                프로젝트 기간
                            </Col>
                            <Col className="notosanskr-400 font-size-16 c-333">
                                {new Date(state.group.start_date).toLocaleDateString()} ~ {new Date(state.group.end_date).toLocaleDateString()}
                            </Col>
                        </Row> */}
                        <Row className="gx-0 info-row align-items-center text-start">
                            <Col className={`notosanskr-600 font-size-18 col-auto ${isTabletOrPC && "pl-29px"} mr-8px`}>•</Col>
                            <Col className="const-rel-link notosanskr-600 font-size-18 c-1b1b1b col-auto">
                                {t("231")}
                            </Col>
                            <Col className="notosanskr-400 font-size-16 c-333 text-break">
                                {validator.isURL(state.group.rel_link) ?
                                    <a href={state.group.rel_link.search("//") !== -1 ? `${state.group.rel_link}` : `//${state.group.rel_link}`}>
                                        {state.group.rel_link}
                                    </a>
                                    : state.group.rel_link}
                            </Col>
                        </Row>
                        <Row className="gx-0 info-row align-items-center text-start">
                            <Col className={`notosanskr-600 font-size-18 col-auto ${isTabletOrPC && "pl-29px"} mr-8px`}>•</Col>
                            <Col className="const-research notosanskr-600 font-size-18 c-1b1b1b col-auto">
                                {t("232")}
                            </Col>
                            <Col className="notosanskr-400 font-size-16 c-333">
                                {state.group.research}
                            </Col>
                        </Row>
                        {showRequestGroupMember() && (
                            <Row className="gx-0 join">
                                <Col className="text-end">
                                    <button className="notosanskr-16 c-white"
                                        disabled={state.isGroupMember === utility.STATE.REVIEWING}
                                        onClick={() => {
                                            setState({ ...state, popup: POPUP.Request, popupTarget: state.group });
                                        }}
                                    >
                                        {t("224")}
                                    </button>
                                </Col>
                            </Row>)}
                    </Col>
                </Row>
            </Container>


            <div className="reports-wrap">
                <Container className="reports">
                    <Row>
                        <Col className="mr-81px col-xl" lg={12}>
                            <Row className="gx-0 align-items-center top-bar">
                                <Col className="col-auto notosanskr-300 font-size-30 title lh-2 ls--0.75px">
                                    <Trans i18nKey={'233'} components={{tag: <span className="notosanskr-600 font-size-30"></span>}} />
                                </Col>
                                <Col><div className="line" /></Col>
                                <Col className="more notosanskr-400 font-size-14 c-white col-auto text-end lh-0.95 ls--1.05px">
                                    <span className="notosanskr-700 font-size-21 c-92c83d ls--0.7px pe-2">{state.locations.length}</span>{t("234")}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Map
                                        style={{
                                            height: "540px",
                                            width: "100%",
                                            marginLeft: "0%",
                                            borderRadius: "10px",
                                            overflow: "hidden",
                                            boxShadow: "0 3px 13px 0 rgba(5, 70, 132, 0.07)",
                                            border: "solid 1px #fff",
                                            marginBottom: `${isPC ? "0px" : "40px"}`,
                                        }}
                                        data={state.locations ? state.locations : undefined}
                                        center={{
                                            lat: basedOption.current.lat ? parseFloat(basedOption.current.lat) : Config.lat,
                                            lng: basedOption.current.lng ? parseFloat(basedOption.current.lng) : Config.lng,
                                        }}
                                        zoom={basedOption.current.z ? parseInt(basedOption.current.z) : Config.zoom}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className={`${isPC && "col-auto maxw-350px"} col-xl`} lg={12}>
                            <Row className="gx-0 align-items-center top-bar">
                                <Col className="col-auto notosanskr-300 font-size-30 title lh-2 ls--0.75px">
                                    <Trans i18nKey={'235'} components={{tag: <span className="notosanskr-600 font-size-30"></span>}} />
                                </Col>
                                <Col><div className="line" /></Col>
                                <Col className="col-auto"><div className="dot" /></Col>
                            </Row>
                            {state.reporters.map((reporter, i) => (
                                <Row key={reporter?._id || i} className={`contributor gx-0 align-items-center ${i % 2 === 0 ? "bg-e9edf2" : "bg-fff"}`}>
                                    <Col className="profile col-auto h-56px">
                                        {reporter && <Emblem
                                            width={"56px"}
                                            height={"56px"}
                                            url={reporter.profile_picture ? reporter.profile_picture.url : undefined}
                                            urlClass="profile-image"
                                            char={reporter.name}
                                            charClass="avatar avatar-56 bg-e8ebf00"
                                        />}
                                    </Col>
                                    <Col className="user text-start">
                                        <Row className="gx-0">
                                            <Col className="group notosanskr-600 font-size-14 lh-1.29 ls--0.7px c-666">
                                                {reporter && state.group.name}
                                            </Col>
                                        </Row>
                                        <Row className="gx-0">
                                            <Col className="name notosanskr-700 font-size-21 lh-1.3 ls--0.53px c-000">
                                                {reporter && reporter.name}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="count notosanskr-400 font-size-16 ls--0.8px col-auto">
                                        {reporter && <Link to={getReportListBasedUrl(state.group._id, reporter.name)} className="text-decoration-none">
                                            {(i < 3) && <img className="medal-image" src={`${process.env.PUBLIC_URL}/icon_medal_${i}.png`} srcSet={`${process.env.PUBLIC_URL}/icon_medal_${i}@2x.png 2x, ${process.env.PUBLIC_URL}/icon_medal_${i}@3x.png 3x`} alt="" />}
                                            <span className="notosanskr-700 font-size-26 ls--0.65px c-4270e0">{reporter.reportCount}</span> {t("234")}
                                        </Link>}
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <div>
                    <ActiveProjectsSection group={state.group._id} itemsCountPerPage={4} />
                </div>
                <div>
                    <PlannedProjectsSection group={state.group._id} itemsCountPerPage={4} />
                </div>
                <div>
                    <ArchivedProjectsSection group={state.group._id} itemsCountPerPage={4} />
                </div>
            </Container>
        </div>
    );
}
