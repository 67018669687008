import "./Tag.css";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MAX_TEXTAREA_LENGTH = 500;

const TextareaView = ({
    title,
    description,
    type,
    defaultValue,
    invalid,
    errorMessage,
    onBlurHandle,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                {description && <label className="top-description notosanskr-500 font-size-15">{description}</label>}
                <textarea
                    className={invalid}
                    name={type}
                    onBlur={onBlurHandle}
                    defaultValue={defaultValue}
                    placeholder={t("157")}
                    maxLength={MAX_TEXTAREA_LENGTH}
                />
                <label className="error">
                    {errorMessage}
                </label>
            </Col>
        </>
    );
};

export default TextareaView;
