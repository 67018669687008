import { useCallback } from "react";

export default function useVendorUI2Hook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const imageValidation = useCallback(({ required, errorKey, min_item_count, fileListLength }) => {
        return ref.current?.imageValidation?.({ required, errorKey, min_item_count, fileListLength });
    }, [ref]);

    const pushDeletedFilesKey = useCallback((value) => {
        ref.current?.pushDeletedFilesKey?.(value);
    }, [ref]);

    const pushAddedFilesKey = useCallback((value) => {
        ref.current?.pushAddedFilesKey?.(value);
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setVendorUI2Value = useCallback((value) => {
        const formattingValue = Object.keys(value).reduce((acc, key) => {
            const itemValueArray = Object.keys(value[key]).map(itemKey => {
                // report form result에 포함 될 object value array의 value array 생성
                const item = value[key][itemKey];
                // text box에 적은 item의 경우
                if (typeof item === 'string') {
                    return {
                        _id: itemKey,
                        type: 'textbox',
                        value: item
                    };
                }
                // file을 등록 한 경우
                else {
                    return {
                        _id: itemKey,
                        type: 'file',
                        value: [item]
                    };
                }
            });

            // report form result에 포함 될 object value array 생성
            if (itemValueArray.length) {
                acc.push({
                    _id: key,
                    value: itemValueArray
                });
            }

            return acc;
        }, []);

        // formatting이 된 value를 저장
        ref.current?.setValue(formattingValue);
    }, [ref]);

    // 데이터 조작 없이 그대로 저장
    const setVendorUI2DefaultValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    const getVendorUI2Value = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    const processUploadedFiles = useCallback(async ({ acceptedFiles, fileRejections, containFiles = [], max_item_count }) => {
        return await ref.current?.processUploadedFiles({
            acceptedFiles,
            fileRejections,
            containFiles,
            max_item_count
        });
    }, [ref]);

    const uploadImage = useCallback((file) => {
        return ref.current?.uploadImage?.(file);
    }, [ref]);

    return {
        imageValidation,
        pushDeletedFilesKey,
        pushAddedFilesKey,
        setVendorUI2Value,
        setVendorUI2DefaultValue,
        getVendorUI2Value,
        processUploadedFiles,
        uploadImage,
    };
};
