import React, { forwardRef } from "react";
import './Pagination.css';
import ReactPagination from 'react-paginate';

const Pagination = forwardRef((props, ref) => {
    /**
     * page가 변경 될 때 호출 되는 함수
     *
     * @param {*} page page 번호
     */
     const handlePageChange = (page) => {
        ref(page.selected + 1);
    };


    return (
        <ReactPagination
            containerClassName="pagination"
            pageCount={Math.ceil(props.totalItemsCount / props.itemsCountPerPage)} // 페이지 개수
            pageRangeDisplayed={props.pageRangeDisplayed} // 페이지 범위
            onPageChange={handlePageChange}
            renderOnZeroPageCount={null} // 1개의 데이터도 없을 때 pagination 표시 여부 null이면 표시 안 함
            breakLabel="•••" // 줄임표 label
            previousLabel={<img src ={process.env.PUBLIC_URL+`/previous_arrow.png`} alt="" />} // 이전을 나타내는 node
            nextLabel={<img src ={process.env.PUBLIC_URL+`/next_arrow.png`} srcSet={`${process.env.PUBLIC_URL}/next_arrow@2x.png 2x, ${process.env.PUBLIC_URL}/next_arrow@3x.png 3x`} alt="" />} // 다음을 나타내는 node
            marginPagesDisplayed={props.marginPagesDisplayed ? props.marginPagesDisplayed : 2}
            forcePage={props.totalItemsCount ? props.page ? props.page - 1 : 0 : -1}
            activeClassName="active"
            pageClassName=""
            pageLinkClassName=""
            breakClassName="break"
            breakLinkClassName="break"
            hrefBuilder={props.hrefBuilder}
        />
    );
});

export default Pagination;
