import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const LinkCopyWindow = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/icon_data_request.png`} srcSet={`${process.env.PUBLIC_URL}/icon_data_request@2x.png 2x`} alt="" />;
    const header = <div>{t("104").split('\n').map((v, i) => <div key={i}>{v}</div>)}</div>;

    return (
        <Modal
            onRequestClose={props.onConfirm}
            onConfirm={props.onConfirm}
            icon={icon}
            header={header}
        />
    );
};

export {
    LinkCopyWindow,
};
