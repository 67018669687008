import { createContext, useState, useCallback, useMemo } from "react";

const DatetimeContext = createContext(null);

const useDatetimeContext = () => {
    // set을 했을 때 값이 어떤 것이 들어오든 function을 다시 생성하기 위해 object로 생성
    const [datetime, setDatetimeState] = useState({ value: null });

    const setDatetime = useCallback((value) => {
        setDatetimeState({ value });
    }, []);

    const getDatetime = useCallback(() => {
        return datetime.value;
    }, [datetime]);

    const datetimeContextValue = useMemo(() => ({
        setDatetime,
        getDatetime
    }), [getDatetime, setDatetime]);

    return {
        DatetimeContext,
        datetimeContextValue
    };
};

export default useDatetimeContext;
