import React from "react";
import { useIsMount } from "../../common/customHook";
import '@toast-ui/chart/dist/toastui-chart.min.css';
import { PieChart } from '@toast-ui/react-chart';
import { IsMobile, IsPC, IsTabletOrPC, TabletOrPC } from "../common/MediaQuery";
import { useTranslation } from "react-i18next";

export default function ReportGraphPieChart(props) {
    const { t } = useTranslation();
    const isMount = useIsMount();
    if (!isMount) return <></>;

    const sum = (array) => array.reduce((acc, cur) => acc + cur, 0);

    // toast ui 는 0 값인 data 를 포함하고 있는 경우, tooltip 을 바르게 표시하지 못하는 현상이 발생함.
    // 예) 카테고리 [a, b, c, d], 시리즈 [0, 0, 1, 0] 의 경우 c 가 100% 를 차지하고 있더라도, 툴팁은 첫번째 카테고리 항목을 표시하게 됨.
    // 따라서, 카테고리를 값에 따라 내림차순으로 정렬([c, a, b, d]) 하여 범례에 출력하도록 합니다.
    const series = sum(props.series.map(x => sum(x.data))) > 0 ? props.series[0].data.map((data, i) => ({ name: props.categories[i], data: data })).sort((a, b) => b.data - a.data) : [];
    const categories = props.series.map(x => x.name);

    const plotHeight = 300;
    const legendLineHeight = 40;
    const legendHeight = series.length * legendLineHeight;

    const options = {
        exportMenu: {
            visible: false
        },
        chart: {
            width: 'auto',
            height: 'auto',
            title: {
                text: props.title,
                position: 'left',
                offsetY: -10,
            }
        },
        tooltip: {
            template: (model, _/*defaultTooltipTemplate*/, theme) => {
              const { background } = theme;
        
              return `<div style="
                background: ${background};
                min-width: 140px;
                color: white;
                border-radius: 3px;"
                class="d-flex">
                    <div class="p-4" style="color: ${model.data[0].color}">■</div>
                    <div class="py-4">${model.data[0].label}</div>
                    <div class="p-4" style="margin-left: auto !important">${model.data[0].formattedValue}</div>
            </div>`;
            }
        },
        legend: {
            align: 'bottom',
            showCheckbox: false,
            visible: props.legendVisible,
            height: legendHeight,
            item: {
                width: 300,
                overflow: 'ellipsis'
            }
        },
        plot: {
            // 모바일에서 볼때는 width 도 명시. height 과 같은 값이라면, 
            // 차트가 매우 작게 표시되는 (toast ui chart 자체 버그로 추정) 현상이 있어서, 
            // 1픽셀 더 크게 출력하도록 처리
            ...IsMobile() && {width: (plotHeight+1)},
            height: plotHeight,
        },
        series: {
            radiusRange: props.doughnut && {
                inner: '60%',
                outer: '100%',
            },
            dataLabels: {
                visible: true,
                anchor: 'outer',
                pieSeriesName: {
                    visible: true,
                    anchor: 'outer'
                }
            }
        },
        lang: {
            noData: t("690"),
        },
        theme: {
            chart: {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                fontFamily: "Noto Sans KR",
                fontWeight: "400",
            },
            series: {
                dataLabels: {
                    fontSize: 15,
                    fontWeight: 400,
                },
                pieSeriesName: {
                    fontSize: 14,
                    fontWeight: 400,
                }
            },
            legend: {
                label: {
                    fontSize: 13,
                    fontWeight: 400,
                }
            },
            noData: {
                fontSize: 24,
                fontFamily: "Noto Sans KR",
                fontWeight: 'bold',
                color: '#3ee',
            },
        }
    };

    return (<div className="position-relative" style={{ width: "100%", minHeight: `${legendHeight + plotHeight}px`, maxHeight: "unset" }}>
        <PieChart
            data={{
                categories,
                series,
            }}
            options={options}
            style={{
                width: '99%',
                height: `${legendHeight + plotHeight}px`,
            }}
        />
    </div>);
}