import React from "react";
import { InputText } from "../Input";
import { useTranslation } from "react-i18next";

export default function PostcodeForm(props) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div id="postcode-form-address1">
                <InputText id={props.address1Id} name="address1" placeholder="" defaultValue={props.address1DefaultValue || ""} disabled={true} />{" "}
                {props.onClick ? (
                    <button
                        onClick={() => {
                            document.getElementById(props.address2Id).setAttribute("disabled", "disabled");
                            props.onClick();
                        }}
                    >
                        {t("70")}
                    </button>
                ) : undefined}
            </div>
            <div className={`${props.font ? props.font : "notosanskr-17"} c-f00 mb-16px mt-10px errors`}>{props?.errors?.address1?.message}</div>
            <div id="postcode-form-address2">
                <InputText
                    id={props.address2Id}
                    name="address2"
                    placeholder={t("338")}
                    defaultValue={props.address2DefaultValue || ""}
                    onChange={() => {
                        if (props.onChangeAddress2) props.onChangeAddress2();
                    }}
                    maxLength={50}
                    disabled={props.disabled}
                />
            </div>
        </React.Fragment>
    );
}
