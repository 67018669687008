import React, { useCallback, useState } from "react";
import Api from "../../Api";
import TestComponent from "../TestComponent";
import { Container } from "react-bootstrap";
import common from "../../common/common";
import LoadingSpinner from "./LoadingSpinner";
import GeneralErrorWindow from "./GeneralErrorWindow";
import { useNavigate } from "react-router-dom";
import { isRunOnMobileAppWebview, sendMessageToMobileAppWebview } from "./RNBridge";

export default function Dev() {
    const isSiteAdmin = common.hasSiteAdminPermission();
    const [state, setState] = useState({ isLoading: false });
    const navigate = useNavigate();
    const dropDatabase = useCallback (() => {
        setState({ ...state, isLoading: true });
        Api.devResetDatabase()
        .then(() => {
            alert("완료");
            setState({ ...state, isLoading: false });
        })
        .catch((e) => {
            alert("비정상");
            setState({ ...state, isLoading: false });
        });
    }, [state]);

    if (!isSiteAdmin && process.env.NODE_ENV === "production")
        return (
            <GeneralErrorWindow
                onClick={() => {
                    navigate("/");
                }}
            />
        );

    return (
        <Container>
            <LoadingSpinner isOpen={state.isLoading} />
            {process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET === "oceancloud.private" && (<button onClick={dropDatabase}>DB 초기화</button>)}
            <TestComponent></TestComponent>
            <hr/>
            <textarea id="override-json" className="font-size-14 w-100" style={{minHeight: "200px"}} defaultValue={`\
{
    "BASE_URI":"https://oceancloud.co.kr",
    "WHITE_URI_LIST":["https://oceancloud.co.kr"],
    "FB_SOCIAL_LOGIN_CB_URL":"https://ocean-cloud-dev.firebaseapp.com/__/auth/handler",
    "GOOG_SIGNIN_WEB_CLIENT_ID":"561164521105-9uisbrq5k4g1g38jdqpbov1iuf0v4tae.apps.googleusercontent.com"
}`
            }/>
            <input type="button"
                className="font-size-14"
                onClick={() => {
                    if (isRunOnMobileAppWebview()) {
                        const content = document.getElementById("override-json").value;
                        sendMessageToMobileAppWebview({type: "override", content: content});
                    } else {
                        alert("is not run on mobile app. will not work properly");
                    }
                }}
                value="Apply override.json on mobile app"
            />
            <hr/>
            <a href="https://oceancloud.co.kr/">move to https://oceancloud.co.kr/</a>
            <hr/>
            <input type="text" id="url_webview_test" className="font-size-20" />
            <input type="button"
                onClick={(e) => {
                    const url_value = document.getElementById("url_webview_test").value;
                    window.location = url_value;
                }}
                value="Navigate URL"
                className="font-size-20" />
        </Container>
    );
}
