import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { POPUP as GeneralPopup } from "../../common/defines";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import "./RequestGroupMember.css";
import { useIsMount } from "../../common/customHook";
import { useNavigate, Link, useLocation } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { PopupRequestJoinGroup, PopupRequestedJoinGroup, PopupJoinGroupRejectReason, PopupJoinGroupApproved } from "../common/RequestGroupMemberWindow";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { useNotification } from "../../common/customHook";
import { mutate } from "swr";
import Pagination from "../common/Pagination";
import { IsPC } from "../common/MediaQuery";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";
import QueryString from 'qs';
import { REQUEST_GROUP_MEMBER_TAB_ID } from "@ocean-knight/shared/src/utility";

const POPUP = {
    ...GeneralPopup,
    Notification: 1010,
};


export default function RequestGroupMember(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [REQUEST_GROUP_MEMBER_TAB_ID.request, REQUEST_GROUP_MEMBER_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : REQUEST_GROUP_MEMBER_TAB_ID.request, // 현재 tab
        popup: POPUP.None,
        popupTarget: null,
        groups: [],
        histories: [],
    });
    const isPc = IsPC();
    const navigate = useNavigate();
    const isMount = useIsMount();
    const { notifications } = useNotification();
    const groupsPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const historiesPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: isPc ? 10 : 3, totalItemsCount: 0, marginPagesDisplayed: isPc ? 2 : 1 });
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        const hasPermission = common.hasSiteMemberPermission(JSON.parse(sessionStorage.getItem("permissions")));
        if (!hasPermission) {
            if (!isMount.current) { return; }
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }

        const getContents = async () => {
            if (!isMount.current) { return; }
            try {
                const groupListPayload = await Api.getNotMemberedGroupList({
                    currentPage: groupsPaginationOption.current.currentPage,
                    itemsCountPerPage: groupsPaginationOption.current.itemsCountPerPage,
                });
                groupsPaginationOption.current.totalItemsCount = groupListPayload.totalItemsCount;

                const historyPayload = await Api.getGroupMemberHistoryMine({
                    currentPage: historiesPaginationOption.current.currentPage,
                    itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                });
                historiesPaginationOption.current.totalItemsCount = historyPayload.totalItemsCount;

                setState((prev) => ({ ...prev, groups: groupListPayload.groups, histories: historyPayload.histories }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: t("539") }));
            }
        };

        getContents();
    }, [isMount, t]);

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            const item = notifications.find((notification) => notification.from === "group.member.approval.histories");
            if (!item) return;
            Api.getNotificationDetail(item)
            .then((payload) => {
                setState((prev) => ({ ...prev, popup: POPUP.Notification, popupTarget: payload }));
            })
            .catch((e) => {
                dgLogger.error(e)();
                setState((prev) => ({
                    ...prev,
                    popup: POPUP.GeneralError,
                    popupTarget: t("673"),
                }));
            });
        }
    }, [notifications, t]);

    useEffect(() => {
        groupsPaginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        groupsPaginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        historiesPaginationOption.current.pageRangeDisplayed = isPc ? 10 : 3;
        historiesPaginationOption.current.marginPagesDisplayed = isPc ? 2 : 1;
        forceUpdate();
    }, [isPc, forceUpdate]);

    const popups = () => {
        if (state.popup === POPUP.Request) {
            return (
                <PopupRequestJoinGroup
                    onConfirm={async () => {
                        try {
                            await Api.requestJoinGroupMember({
                                _id: state.popupTarget._id, // group._id
                                lang: common.getLang()
                            });
                            let page = groupsPaginationOption.current.currentPage;
                            if (page > 1 && state.groups.length === 1) page = page - 1;
                            groupsPaginationOption.current.currentPage = page;

                            const payload = await Api.getNotMemberedGroupList({
                                currentPage: groupsPaginationOption.current.currentPage,
                                itemsCountPerPage: groupsPaginationOption.current.itemsCountPerPage,
                            });

                            groupsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                            setState({ ...state, groups: payload.groups, popup: POPUP.Requested });
                        } catch (e) {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: null });
                        }
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    groupName={state.popupTarget.name}
                />
            );
        }
        if (state.popup === POPUP.Requested) {
            return (
                <PopupRequestedJoinGroup
                    require_join_confirm={state.popupTarget.require_join_confirm}
                    onClick={() => {
                        if (!state.popupTarget.require_join_confirm) {
                            props.renewPermissions();
                        }
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.RejectReason) {
            return <PopupJoinGroupRejectReason state_reason={state.popupTarget.state_reason} onClick={() => setState({ ...state, popup: POPUP.None, popupTarget: null })} />;
        }
        if (state.popup === POPUP.Notification) {
            if (state.popupTarget.state === utility.STATE.ACQUIRED) {
                return (
                    <PopupJoinGroupApproved
                        onClick={() => {
                            props.renewPermissions();
                            Api.removeNotification(state.popupTarget.notificationId)
                                .then(() => {
                                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                                    mutate("useNotification");
                                })
                                .catch((e) => dgLogger.error(e)());
                        }}
                    />
                );
            }
            if (state.popupTarget.state === utility.STATE.REJECTED) {
                return (
                    <PopupJoinGroupRejectReason
                        state_reason={state.popupTarget.state_reason}
                        onClick={() => {
                            Api.removeNotification(state.popupTarget.notificationId)
                                .then(() => {
                                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                                    mutate("useNotification");
                                })
                                .catch((e) => dgLogger.error(e)());
                        }}
                    />
                );
            }
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const processedState = (item) => {
        if (item.state === utility.STATE.REVIEWING) return <span className="reviewing-state c-white notosanskr-500 font-size-15">{t("378")}</span>;
        if (item.state === utility.STATE.ACQUIRED) return <span className="acquired-state c-white notosanskr-500 font-size-15">{t("377")}</span>;
        if (item.state === utility.STATE.REJECTED)
            return (
                <button className="rejected-state c-white notosanskr-500 font-size-15" onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}>
                    {t("379")}
                </button>
            );
    };

    return (
        <div id="request-group-member">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("358")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("359")}</div>
                </Col>
            </Row>

            <Tabs
                activeKey={state.activeTab}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
                onSelect={(key) => {
                    if (state.activeTab === key) return;

                    switch (key) {
                        case REQUEST_GROUP_MEMBER_TAB_ID.request:
                            return Api.getNotMemberedGroupList({
                                currentPage: groupsPaginationOption.current.currentPage,
                                itemsCountPerPage: groupsPaginationOption.current.itemsCountPerPage,
                            })
                                .then((payload) => {
                                    groupsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: key, groups: payload.groups });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });

                        case REQUEST_GROUP_MEMBER_TAB_ID.histories:
                            return Api.getGroupMemberHistoryMine({
                                currentPage: historiesPaginationOption.current.currentPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: key, histories: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        default:
                            return;
                    }
                }}
            >
                <Tab className={"request"} eventKey={REQUEST_GROUP_MEMBER_TAB_ID.request} title={t("358")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item first">{t("665")}</Col>
                        <Col className="item second">{t("230")}</Col>
                        <Col className="item third">{t("371")}</Col>
                        <Col className="item fourth">{t("372")}</Col>
                    </Row>
                    {state.groups.map((group) => (
                        <Row key={group._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className="item first">
                                <Row className="gx-0 align-items-center">
                                    <Col className="name"><Link to={`/Group/${group._id}`}>{common.i18nGroupName(group.name)}</Link></Col>
                                    <Col className="col-lg-auto button">
                                        <button
                                            className="c-white notosanskr-500 font-size-15"
                                            onClick={() => {
                                                setState({ ...state, popup: POPUP.Request, popupTarget: group });
                                            }}
                                        >
                                            {t("99")}
                                            <img src={`${process.env.PUBLIC_URL}/arrow.png`}
                                            srcSet={`${process.env.PUBLIC_URL}/arrow@2x.png 2x, ${process.env.PUBLIC_URL}/arrow@3x.png 3x`}
                                            alt="" />
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="item second">{new Date(group.register_date).toLocaleDateString()}</Col>
                            <Col className="item third">{group.numberOfUsers}</Col>
                            <Col className="item fourth">{group.reportCount}</Col>
                        </Row>
                    ))}
                    <Pagination
                        ref={(newPage) => {
                            Api.getNotMemberedGroupList({
                                currentPage: newPage,
                                itemsCountPerPage: groupsPaginationOption.current.itemsCountPerPage,
                            })
                                .then((payload) => {
                                    groupsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    groupsPaginationOption.current.currentPage = newPage;
                                    setState({ ...state, groups: payload.groups });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        }}
                        page={groupsPaginationOption.current.currentPage}
                        marginPagesDisplayed={groupsPaginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={groupsPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={groupsPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={groupsPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
                <Tab className={"histories"} eventKey={REQUEST_GROUP_MEMBER_TAB_ID.histories} title={t("373")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className={`item ${isPc ? "col-lg-auto" : "first"}`}>{t("374")}</Col>
                        <Col className={`item ${isPc ? "" : "second"}`}>{t("375")}</Col>
                        <Col className={`item ${isPc ? "col-lg-auto" : "third"}`}>{t("376")}</Col>
                    </Row>
                    {state.histories.map((history) => (
                        <Row key={history._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className={`item ${isPc ? "col-lg-auto" : "first"}`}>{new Date(history.requested_date).toLocaleDateString()}</Col>
                            <Col className={`item ${isPc ? "" : "second"}`}>
                                {
                                    history.groupState === "active" ?
                                        <Link to={`/Group/${history.group}`}>{common.i18nGroupName(history.groupName)}</Link>
                                        :
                                        <div>{common.i18nGroupName(history.groupName)}</div>
                                }
                            </Col>
                            <Col className={`item ${isPc ? "col-lg-auto" : "third"}`}>{processedState(history)}</Col>
                        </Row>
                    ))}

                    <Pagination
                        ref={(newPage) => {
                            Api.getGroupMemberHistoryMine({
                                currentPage: newPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    historiesPaginationOption.current.currentPage = newPage;
                                    setState({ ...state, histories: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        }}
                        page={historiesPaginationOption.current.currentPage}
                        marginPagesDisplayed={historiesPaginationOption.current.marginPagesDisplayed} // 처음, 끝에 고정적으로 보여줄 아이템 개수
                        itemsCountPerPage={historiesPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={historiesPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={historiesPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
