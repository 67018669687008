import { useCallback, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

export default function useTitleHook(ref) {
    const { t } = useTranslation();
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const textValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.textValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setTitleValue = useCallback((value) => {
        ref.current?.setValue?.(value.trim());
    }, [ref]);

    const getTitleValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    const hasLegalTitle = (value, illegalReportTitle = []) => {
        // 현재 문자열이 금지 문자열 array에 있는 문자열을 포함하는 지 여부
        // 포함 하지 않으면 true (통과) 포함 하면 false (실패)
        return !illegalReportTitle.some((illegalTitle) =>
            value.includes(illegalTitle)
        );
    };

    // 현재 form에서만 사용 될 validation
    // 자료 제목으로 사용 할 수 없는 문자가 있는 지 확인
    const childValidation = useCallback(({ value, errorKey }) => {
        const illegalReportTitle = [`\\`, `/`, `:`, `*`, `?`, `"`, `<`, `>`, `|`];
        const valid = hasLegalTitle(value, illegalReportTitle);
        const state = { state: valid };
        const message = { message: valid ? '' : `${t("527")}\n${illegalReportTitle.join(', ')}` };
        const errorCollection = { [errorKey]: { ...state, ...message } };

        return { valid, errorCollection };
    }, [t]);

    useImperativeHandle(ref, () => (
        { ...ref.current, childValidation }
    ), [ref, childValidation]);

    return {
        textValidation,
        setTitleValue,
        getTitleValue,
    };
};
