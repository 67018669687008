import "./VendorUI2.css";
import { useCallback } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageDropZone from "../../common/ImageDropZone";
import { InputText } from "../../Input";
import { useTranslation } from "react-i18next";

const VendorUI2View = ({
    title,
    description,
    type,
    invalid,
    errorMessage,
    sub_items,
    tempValue,
    onDrop,
    removeFile,
    onChangeHandle,
    onPasteHandle,
    onBlurHandle
}) => {
    const { t } = useTranslation();
    const FileOrDropZone = useCallback(({ sub_item, fileItem, file, textboxItem }) => {
        return (
            file ?
                <Col className="file" key={file.url + file.name + file.size}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{file.name}</Tooltip>}>
                        <div>
                            <button className="remove-picture" onClick={() => removeFile(sub_item._id, fileItem._id)} />
                            <img
                                src={file.thumbnail_url || file.url}
                                alt=""
                            />
                        </div>
                    </OverlayTrigger>
                </Col>
                :
                // Note. vendor-ui-2 는 개별 image 업로드를 가지고 있으며, 각각 단일 파일만 업로드 하도록 구성되어 있음.
                // 만약 에러 메세지가 있는데 image drop zone 을 출력 한다는 것은, 이미지 파일 선택이 안되었다는 것이므로
                // invalid style 이 적용되어야 함.
                <Col className={`image-drop-zone ${invalid}`}>
                    <ImageDropZone
                        name={type}
                        ref={onDrop.bind(this, sub_item._id, fileItem._id, textboxItem._id)}
                        maxFiles={1}
                        multiple={false}
                        droppableText=""
                        droppingText=""
                    />
                </Col>
        );
    }, [onDrop, removeFile, type, invalid]);

    const TextBox = useCallback(({ sub_item, fileItem, file, textboxItem }) => {
        return (
            <Col className="text">
                <InputText
                    key={file}
                    onChange={onChangeHandle}
                    onPaste={onPasteHandle}
                    onBlur={(e) => onBlurHandle(e, sub_item._id, textboxItem._id)}
                    placeholder={sub_item.name}
                    disabled={!file?.url && !file?.thumbnail_url}
                    defaultValue={tempValue.current?.[sub_item._id]?.[textboxItem._id] || ""}
                />
            </Col>
        );
    }, [onBlurHandle, onChangeHandle, onPasteHandle, tempValue]);

    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                <Row className="gx-0 w-100">
                    <label className="c-f00 top-description">
                        {t("658").split("\n").map((v, i) => <div key={i}>{v}</div>)}
                    </label>
                </Row>
                {sub_items.map((sub_item) => {
                    const fileItem = sub_item.items.find(item => item.sub_item_type === 'file');
                    const file = tempValue.current?.[sub_item._id]?.[fileItem._id];
                    const textboxItem = sub_item.items.find(item => item.sub_item_type === 'textbox');

                    return (
                        <Row className="gx-0 sub-item" key={sub_item._id}>
                            <TextBox
                                sub_item={sub_item}
                                fileItem={fileItem}
                                file={file}
                                textboxItem={textboxItem}
                            />
                            <FileOrDropZone
                                sub_item={sub_item}
                                fileItem={fileItem}
                                file={file}
                                textboxItem={textboxItem}
                            />
                        </Row>
                    );
                })}
                <Row className="gx-0 w-100">
                    <label className="error">
                        {errorMessage}
                    </label>
                    {description && <label className="c-f00 text-break bottom-description">{description}</label>}
                </Row>
            </Col>
        </>
    );
};

export default VendorUI2View;
