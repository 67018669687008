import { AsyncTypeahead as OriginalAsyncTypeahead, Token } from "react-bootstrap-typeahead";
import { getOptionLabel } from "react-bootstrap-typeahead/types/utils";
import { InputText } from "../Input";
import 'react-bootstrap-typeahead/css/Typeahead.css';

/**
 * Typeahead에서 입력칸을 렌더링할 때 사용하는 핸들러
 * @param {*} inputProps input에 직접 전달할 props
 * @param {*} props Typeahead 자체 pros
 * @returns ReactNode
 */
function TypeaheadInput({ inputRef, inputClassName, referenceElementRef, toeknClassName = "token", ...inputProps }, props) {
    // Ref: https://github.com/ericgio/react-bootstrap-typeahead/blob/main/src/components/TypeaheadInputMulti/TypeaheadInputMulti.tsx

    let wrapperNode = null;
    let inputNode = null;
    let containerNode = null;

    function handleContainerClickOrFocus(e) {
        // Don't focus the input if it's disabled.
        let input = inputNode;

        if (inputProps.disabled) {
            e.currentTarget.blur();
            return;
        }

        if (
            !input ||
            // Ignore if the clicked element is a child of the container, ie: a token
            // or the input itself.
            (e.currentTarget.contains(e.target) &&
                e.currentTarget !== e.target)
        ) {
            return;
        }

        if (input.selectionStart != null) {
            // Move cursor to the end if the user clicks outside the actual input.
            input.selectionStart = input.value.length;
        }

        input.focus();
    }

    function handleKeyDown(e) {
        if (e.key === 'Backspace' && props.selected.length && !inputProps.value) {
            // Prevent browser from going back.
            e.preventDefault();

            // If the input is selected and there is no text, focus the last
            // token when the user hits backspace.

            const wrapperChildren = wrapperNode?.children;
            if (wrapperChildren?.length) {
                const lastToken = wrapperChildren[
                    wrapperChildren.length - 2
                ];
                lastToken?.focus();
            }
        }

        inputProps.onKeyDown && inputProps.onKeyDown(e);
    }

    return (
        <div
            className="rbt-input-multi report_form_textbox_input notosanskr-400 font-size-14"
            style={{
                height: 'auto',
                minHeight: '4.5rem'
            }}
            ref={(node) => {
                referenceElementRef(node);
                containerNode = node;
            }}
            tabIndex={-1}
            onClick={handleContainerClickOrFocus}
            onFocus={(e) => {
                handleContainerClickOrFocus(e);
                containerNode.style.outline = 'highlight 1px auto'; // For firefox
                containerNode.style.outline = '-webkit-focus-ring-color 1px auto'; // For webkit browsers (i.e. Chrome, Safari, etc.)
            }}
            onBlur={(e) => {
                containerNode.style.outline = 'none';
            }}>
            <div
                className="rbt-input-wrapper"
                ref={(node) => wrapperNode = node }>
                {props.selected.map((option, idx) =>
                    <Token
                        className={toeknClassName}
                        disabled={inputProps.disabled}
                        key={idx}
                        onRemove={props.onRemove}
                        option={option}
                        tabIndex={inputProps.tabIndex}>
                        {getOptionLabel(option, props.labelKey)}
                    </Token>
                )}
                <div style={{
                    display: 'flex',
                    flex: '1 1 0%',
                }}>
                    <InputText
                        className={inputClassName}
                        {...inputProps}
                        ref={(node) => {
                            inputRef(node);
                            inputNode = node;
                        }}
                        onKeyDown={handleKeyDown}
                        style={{
                            ...inputProps.style,
                            border: 'none',
                            minWidth: '240px',
                            width: '100%',
                            outline: 'none'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

/**
 * Typeahead에서 선택 후보 항목 표시 시 사용하는 핸들러
 *
 * 매칭되는 문자열을 강조함
 *
 * @param {*} option 현재 렌더링하는 옵션 객체
 * @param {*} text 현재 입력된 text
 * @param {*} labelKey option 객체 항목 중 화면에 표시되는 key
 */
function MenuItemChildren({ option, text, labelKey }) {
    const label = getOptionLabel(option, labelKey);
    const className = "rbt-highlight-text";

    const wrapper = (children) => (
        <span
            className="notosanskr-400 font-size-14"
            style={{
                height: '4.5rem',
                display: 'inline-flex',
                alignItems: 'center',
                whiteSpace: 'pre'
            }}
            >
            {children}
        </span>
    );

    if (!text || !label) return wrapper(label);

    let splits = label
                    .split(new RegExp(`(${text})`, 'gi'))
                    .map((split, index) => (
                        split.toLowerCase() === text.toLowerCase()
                        ? <mark className={className} key={index}>{split}</mark>
                        : split
                    ));

    return wrapper(splits);
}

export function AsyncTypeahead({
    ...props
}) {
    return <OriginalAsyncTypeahead
            {...props}
            renderMenuItemChildren={(option, { text, labelKey }) => (
                <MenuItemChildren
                    option={option}
                    text={text}
                    labelKey={labelKey}
                    />
            )}
            renderInput={TypeaheadInput}
        />;
}
