import React, { useCallback, useEffect, useState } from "react";
import Api from "../Api";
import Filter from "./Report/Filter";
import queryString from 'query-string';
import { VIEW } from "../common/defines";
import { useIsMount } from "../common/customHook";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ReportListBased from "./Report/ReportListBased";
import ReportMapBased from "./Report/ReportMapBased";
import ReportBasedButton from "./Report/ReportBasedButton";
import ReportDownloadBased from "./Report/ReportDownloadBased";
import ReportGraphBased from "./Report/ReportGraphBased";
import "./Report.css";
import { Mobile, TabletOrPC } from "./common/MediaQuery";
import * as Config from "./common/MapConfig";
import dgLogger from "../common/dgLogger";
import { useTranslation } from "react-i18next";

/**
 * Report의 url은 다음과 같은 구조를 가집니다.
 * /Report/{view}/{filter}/{based}
 * view: 기반 표시 3종류를 각각 0, 1, 2 로 표현
 * filter: 사용자가 filter를 선택하여 검색 했을 때 선택 된 filter option
 * based: 목록 기반 표시 - page, 지도 기반 표시 - 위치 정보
 */

export default function Report() {
    const { t } = useTranslation();
    const [state, setState] = useState({ groups: [] });
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const filterOption = queryString.parse(location.pathname.split("/").find(item => item.startsWith("groups")));
    const basedOption = queryString.parse(params.based);
    // 자료 보기 > 목록 기반 > page 1인 경우 자료보기를 다시 클릭했을 때 모든 옵션이 동일하여 자료를 가지고 오지 못 하는 현상 존재
    // 해당 현상을 처리하기 위해서 filterOption에 location key를 삽입하여 router가 변경 된 것을 확인
    if (Object.keys(filterOption).length && basedOption.page) {
        filterOption.key = location.key;
    }

    /**
     * group 목록을 가져와 state에 저장하는 함수
     */
    const getGroupList = useCallback(() => {
        Api.getActiveGroupListAll()
            .then((payload) => {
                setState((prev) => ({
                    ...prev,
                    groups: payload.map((item) => ({ _id: item._id, name: item.name, report_form_id: item.report_form_id }))
                }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, []);

    useEffect(() => {
        getGroupList();
        document.getElementById("App").className = "report";
    }, [getGroupList]);

    /**
     * Report의 url 기본 format(/Report/{view}/{filter})을 반환 하는 함수
     * 현재 url(filterOption)에 있는 내용을 기반으로 format을 생성
     *
     * @param {number} view view 상태 번호
     * @returns url 기본 format
     */
    const createDefaultParam = useCallback((view) => {
        const groups = filterOption.groups ? filterOption.groups : '';
        const projects = filterOption.projects ? filterOption.projects : '';
        const locations = filterOption.locations ? filterOption.locations : '';
        const startDate = filterOption.startDate ? filterOption.startDate : '';
        const endDate = filterOption.endDate ? filterOption.endDate : '';
        const date = filterOption.date ? encodeURIComponent(filterOption.date) : '';
        const name = filterOption.name ? encodeURIComponent(filterOption.name) : '';
        const myReport = filterOption.myReport ? filterOption.myReport : false;
        const keyword = filterOption.keyword ? encodeURIComponent(filterOption.keyword) : '';
        return `/Report/${view}/groups=${groups}&projects=${projects}&locations=${locations}&startDate=${startDate}&endDate=${endDate}&date=${date}&name=${name}&myReport=${myReport}&keyword=${keyword}`;
    }, [filterOption]);

    useEffect(() => {
        if (Object.keys(filterOption).length) return;
        let param = createDefaultParam(VIEW.ListBased);
        param += `/page=1`;

        navigate(param, { replace: true });
    }, [filterOption, createDefaultParam, navigate]);

    /**
     * 검색 버튼을 눌렀을 때 filterOption에 따라 url을 생성하고 이동하는 함수
     *
     * @param {string} filter filterOption
     */
    const searchButtonClick = (filter) => {
        if (params.filter === decodeURIComponent(filter)) return;
        let param = `/Report/${params.view?params.view:VIEW.ListBased}/`;
        param += filter;

        switch (parseInt(params.view)){
            case VIEW.MapBased:
                param += `/lat=${Config.lat}&lng=${Config.lng}&z=${Config.zoom}`;
                break;
            case VIEW.GraphBased:
                break;
            default:
                param += `/page=1`;
        }

        navigate(param);
    };

    /**
     * 현재 view 상태에 따라 content를 반환해 주는 함수
     *
     * @returns view 상태에 따른 HTML Code
     */
    const content = () => {
        switch (parseInt(params.view)){
            case VIEW.MapBased:
                return (
                    <ReportMapBased
                        filterOption={filterOption}
                        basedOption={basedOption}
                        createDefaultParam={createDefaultParam}
                    />
                );
            case VIEW.GraphBased:
                return (
                    <ReportGraphBased
                        filterOption={filterOption}
                        basedOption={basedOption}
                        createDefaultParam={createDefaultParam}
                        groupList={state.groups}
                    />
                );
            case VIEW.DownloadBased:
                return (
                    <ReportDownloadBased
                        filterOption={filterOption}
                        basedOption={basedOption}
                        createDefaultParam={createDefaultParam}
                    />
                );
            default:
                return (
                    <ReportListBased
                        filterOption={filterOption}
                        basedOption={basedOption}
                        createDefaultParam={createDefaultParam}
                    />
                );
        }
    };

    return (
        <Container>
            <div>
                <Row className="gx-0">
                    <Col className="col-lg-auto report-sub-title">
                        <span className="data adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff">Data</span>
                        <span className="report notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t("2")}</span>
                    </Col>
                    <TabletOrPC>
                        <Col className="text-end mt-auto">
                            <ReportBasedButton
                                params={params}
                                createDefaultParam={createDefaultParam}
                            />
                        </Col>
                    </TabletOrPC>
                </Row>
                <Mobile>
                    <Row>
                        <Col className="text-center mt-auto">
                            <ReportBasedButton
                                params={params}
                                createDefaultParam={createDefaultParam}
                            />
                        </Col>
                    </Row>
                </Mobile>
                <Filter
                    key={params.view} // key 값 설정 안 하면 Report로 다시 들어갈 때 filter 초기화 되지 않음
                    groups={state.groups}
                    filterOption={filterOption}
                    view={params.view}
                    basedOption={basedOption}
                    searchButtonClick={searchButtonClick}
                />
                {content()}
            </div>
        </Container>
    );
}
