import React, { useEffect, useRef, useState, useCallback } from "react";
import Api from "../../Api";
import { useIsMount } from "../../common/customHook";
import { POPUP } from "../../common/defines";
import common from "../../common/common";
import LoadingSpinner from "../common/LoadingSpinner";
import ReportGraphPieChart from "./ReportGraphPieChart";
import ReportGraphBarChart from "./ReportGraphBarChart";
import "./ReportGraphBased.css";
import { Row, Col } from "react-bootstrap";
import dgLogger from "../../common/dgLogger";
import { utility } from "@ocean-knight/shared";
import { useTranslation } from "react-i18next";

const CHART_TYPE = utility.CHART_TYPE;

const CHART_ROW_MARGIN_CLASS = "mb-110px";
const FORM_ITEM_CHART_COL_CLASS = "col-md-6 col-12 py-4 report-form-item-chart";

export default function ReportGraphBased(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        isLoading: false,
        popup: POPUP.None,
        termChart: undefined,
        groupChart: undefined,
        legionChart: undefined,
        reportCharts: [],
        allGroupSummary: false,
        popupTarget: null,
    });
    const filterOption = props.filterOption;
    const basedOption = props.basedOption;
    const prevFilterOption = useRef({});
    const isMount = useIsMount();
    const lang = common.getLang();

    const getFilterOptions = useCallback((page) => {
        // update startDate
        let newStartDate = undefined;
        if (filterOption.startDate) {
            // update date into local one
            newStartDate = common.convertToIsoDate(filterOption.startDate);
        }

        // update endDate
        let newEndDate = undefined;
        if (filterOption.endDate) {
            // update date into local one
            newEndDate = common.convertToIsoDate(filterOption.endDate, 1);
        }

        // 선택된 항목들을 구분자(|)로 나누어 array로 생성
        const groupsArray = filterOption.groups ? filterOption.groups.split("|") : undefined;
        const projectsArray = filterOption.projects ? filterOption.projects.split("|") : undefined;
        const locationsArray = filterOption.locations ? filterOption.locations.split("|") : undefined;

        return {
            groups: groupsArray,
            projects: projectsArray,
            locations: locationsArray,
            startDate: newStartDate,
            endDate: newEndDate,
            registered_by: filterOption.name,
            keyword: filterOption.keyword,
            page: page,
            user_id: filterOption.myReport === "true" ? sessionStorage.getItem("_id") : undefined
        };
    }, [filterOption]);

    /**
     * 특정 기간내 수집된 자료건수를 저장하고 있는 자료구조(termChart) 에 값이 없다면 (기간내 등록된 자료가 없는 경우)
     * Chart library 에 전달해야 할 자료구조를 맞추기 위해 임의로 (지정된 기간이 있다면, 기간만큼. 기간이 지정되어 있지 않다면 현재 날짜로부터 1년 전까지)
     * 카테고리 이름 (월/년) 을 채워주도록 합니다.
     * 
     * @param {*} termChart 특정 기간내 수집된 자료 건수를 저장하고 있는 자료구조
     * @param {*} query 월별/년별 자료 요청 여부 (count_per_month / count_per_year)
     * @param {*} startDate 수집 자료건수 검색 조건인 기간의 시작날짜
     * @param {*} endDate 수집 자료건수 검색 조건인 기간의 종료 날짜
     */
    const updateTermChartCategories = useCallback(async (termChart, query, startDate = undefined, endDate = undefined) => {
        // 만약 얻어온 레포트 정보가 비어 있다면 (즉, 등록한 자료가 없는 경우)
        if (termChart.categories.length == 0) {
            // 지정된 날짜가 있다면, 해당 날짜 사이의 기간으로 계산, 
            // 지정된 날짜가 없다면 현재 날짜를 기점으로 1년 전까지의 기간을 보여줌.
            const queryEndDate = endDate ? new Date(endDate) : new Date();
            const queryStartDate = startDate ? new Date(startDate) : new Date();
            if (!startDate && endDate == startDate) {
                queryStartDate.setFullYear(queryEndDate.getFullYear() - 1);
            }

            const start = { year: queryStartDate.getFullYear(), month: queryStartDate.getMonth() };
            const end = { year: queryEndDate.getFullYear(), month: queryEndDate.getMonth() };
            if (query == 'count_per_month') {
                termChart.categories = [...Array(((end.year - start.year) * 12) - (start.month - end.month) + 1).keys()]
                    .map(category => new Date(start.year, start.month + category, 1))
                    .map(category => t("691", {'%1$d': category.getFullYear(), '%2$d': category.getMonth() + 1}));
            } else if (query == 'count_per_year') {
                termChart.categories = [...Array(end.year - start.year + 1).keys()]
                    .map(category => new Date(start.year + category, 1, 1))
                    .map(category => category.getFullYear());
            }
        }
    }, [t]);

    /**
     * filter options 에 따라 출력에 필요한 정보들을 rest-api 를 통해 획득하여 state 에 저장합니다.
     * 정보 획득이 완료되면, loading state 를 종료합니다.
     * @param {*} page N/A 실질적으로 쓰이지 않음.
     */
    const getGraphBasedData = useCallback(async (page, groupList) => {
        const payload = getFilterOptions(page);
        const termChart = [];
        let groupChart;
        let legionChart;
        const reportCharts = [];
        let trashAmountPer;

        const compareArrays = (a, b) => a.length === b.length && a.every((element, index) => element === b[index]);
        const allGroupSummary = payload.groups ? compareArrays(payload.groups.sort(), groupList.map(x => x._id).sort()) : true;

        const reportOverview = allGroupSummary ? undefined : await Api.getReportsGraph({ ...payload, lang: lang });
        const vendorUI2ReportOverview = reportOverview?.reports.find(x => x.itemType == 'vendor-ui-2');

        // 전체 그룹의 경우, 단일 report 에 모든 그룹의 정보가 포함되어야 함.
        // 그외의 경우, 개별 그룹별로 report 가 만들어 져야 함.
        // 일반화 하기 위해, 전체 그룹의 경우는 item 이 한개인 array 로 처리
        const termChartFutures = allGroupSummary
            ? [Api.getReportsGraph({ ...payload, field: "count_per_month" /* 그래프로 표시할 필드 */, lang: lang })]
            : payload.groups.map((v) => Api.getReportsGraph({ ...payload, groups: [v], field: "count_per_month", lang: lang }));
        const legionChartFuture = Api.getReportsGraph({ ...payload, field: 'count_per_location', lang: lang });
        const groupChartFuture = Api.getReportsGraph({ ...payload, field: 'count_per_group', lang: lang });
        const groupFormFuture = groupList.map((v) => Api.getReportForm({ report_form_id: v.report_form_id }));
        const trashAmountPerLoc = vendorUI2ReportOverview ? Api.getReportsGraph({ ...payload, field: "sum_trash_amount_per_location", lang: lang }) : new Promise((resolve) => resolve());
        const reportChartFutures = reportOverview ? reportOverview.reports.filter((v) => v.chart?.type != "none").map((v) => Api.getReportsGraph({ ...payload, field: v.itemId, lang: lang })) : [];
        Promise.all([legionChartFuture, groupChartFuture, trashAmountPerLoc, ...groupFormFuture, ...termChartFutures, ...reportChartFutures]).then(results => {
            let termGroupIndex = 0;
            results.forEach((v, i) => {
                switch (i) {
                    case 0: { legionChart = v.reports; break; }
                    case 1: { groupChart = v.reports; break; }
                    // 페이로드로 전달된 프로젝트(들) 의 쓰레기 양평가 합을 모두 더한 결과를 반환받음.
                    case 2: { trashAmountPer = v?.reports; break; }
                    default: {
                        // 그룹별 report form 정보
                        if (i <= (2 + groupFormFuture.length)) {
                            const group = groupList.find(x => x.report_form_id == v._id);
                            group.report_form = v;
                        }
                        // 기간별 수집 자료 정보
                        else if (i <= (2 + groupFormFuture.length + termChartFutures.length)) {
                            const group = payload.groups && groupList.find(x => x._id == payload.groups[termGroupIndex]);
                            v.reports.group = group;

                            updateTermChartCategories(v.reports, 'count_per_month', payload.startDate, payload.endDate);

                            // 수집자료 건수는, 그룹별로 reports 를 따로 수집. (전체 그룹의 경우, 단일 reports 에 모든 그룹 정보를 수집)
                            termChart.push(v.reports);
                            termGroupIndex++;
                        } else {
                            // 얻어온 report 는 대략적인 레포트 정보가 들어 있는 reportOverview 에 추가로 삽입하도록 처리
                            // (reportOverview 에 있는 그룹 이름이나, 프로젝트 정보등이 필요한 경우가 발생할 수 있음) 
                            const reportItem = reportOverview.reports.find(x => x.itemId == v.reports.series[0]._id);
                            reportItem.categories = v.reports.categories;
                            reportItem.series = v.reports.series;
                            reportCharts.push(reportItem);
                        }
                        break;
                    }
                }
            });

            // 자동 생성된 그룹인지 여부를 확인할수 있는 attribute 를 임의 추가
            groupChart.series = groupChart.series.map(v => ({ ...v, built_in: groupList.find(x => x._id == v._id)?.built_in }));

            // vendor-ui-2 의 경우, 쓰레기 양 평가 (합) 의 reports 를 출력할수 있도록 준비
            if (vendorUI2ReportOverview) {
                vendorUI2ReportOverview.categories = trashAmountPer.categories;
                vendorUI2ReportOverview.series = trashAmountPer.series;
            }

            // 그룹 전체 보기에서는, 그룹별 수집자료건수가 아닌, 합산된 수집 자료건수로 표시
            if (allGroupSummary) {
                termChart[0].series = [{ _id: null, name: t("404"), data: sum(termChart[0].series.map(x => x?.data)) }];
                legionChart.series = [{ _id: null, name: t("404"), data: sum(legionChart.series.map(x => x?.data)) }];
            }

            setState((prev) => ({ ...prev, groupChart, termChart, legionChart, reportCharts, allGroupSummary, isLoading: false }));
        }).catch(e => dgLogger.error(e)());
    }, [getFilterOptions, updateTermChartCategories, lang, t]);

    // filterOption이 바뀌었을 때 현재 filterOption에 따라 graph에서 사용 할 data 요청
    useEffect(() => {
        if (isMount?.current && JSON.stringify(prevFilterOption.current) !== JSON.stringify(filterOption) && props.groupList.length) {
            prevFilterOption.current = filterOption;
            setState((prev) => ({ ...prev, isLoading: true }));
            getGraphBasedData(basedOption.page, props.groupList);
        }
    }, [filterOption, basedOption.page, getGraphBasedData, isMount, props.groupList]);


    if (!isMount) return <LoadingSpinner isOpen={true} />;

    // 다수의 동일 length 의 array 를 각 index 의 item 을 sum 하여 새로운 array 를 반환
    const sum = (matrix) => matrix.reduce((acc, array) => acc.map((sum, i) => sum + (array[i] || 0)), new Array(matrix[0]?.length).fill(0));

    const popups = () => {
        // if (state.popup === POPUP.Request) return popupDownloadRequest();
    };

    if (state.isLoading) return <LoadingSpinner isOpen={true} />;

    // 공통 차트
    const renderCommonChart = (groupFilter = undefined, legendVisible = true) => {
        const chart = groupFilter ? state.termChart.find(x => groupFilter.name == x.group.name) : state.termChart[0];
        return (<React.Fragment>
            <Col className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                <ReportGraphBarChart
                    categories={chart.categories}
                    series={chart.series}
                    onChangeScale={async (scale) => { // possible scale is 'month', 'year'
                        const payload = getFilterOptions(basedOption.page);
                        payload.field = scale === 'month' ? 'count_per_month' : 'count_per_year'; // 그래프로 표시할 필드
                        payload.lang = lang;

                        if (groupFilter) payload.groups = [groupFilter._id];
                        const termChart = (await Api.getReportsGraph(payload)).reports;

                        updateTermChartCategories(termChart, payload.field, payload.startDate, payload.endDate);

                        if (state.allGroupSummary) {
                            termChart.series = [{ _id: null, name: t("404"), data: sum(termChart.series.map(x => x.data)) }];
                        }

                        return termChart;
                    }}
                    title={t("692")}
                    // yAxisTitle={"건수"}
                    // xAxisTitle={"기간"}
                    legendVisible={legendVisible}
                    showScaleDropbox={true}
                    vertical={true}
                />
            </Col>
            <Col className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                <ReportGraphBarChart
                    categories={state.legionChart.categories}
                    series={state.legionChart.series.filter(x => groupFilter ? groupFilter.name == x.name[0] : true)}
                    title={t("693")}
                    // yAxisTitle={"건수"}
                    // xAxisTitle={"지역"}
                    legendVisible={false}
                    colorByCategories={false}
                    vertical={true}
                    interval={1}
                />
            </Col>
        </React.Fragment>);
    };

    const renderAllGroupSummary = () => {
        // 전체 그룹을 출력하는 상황이 아니라면, 아무것도 출력하지 않습니다.
        if (!state.allGroupSummary) return <></>;

        return (<React.Fragment>
            <Row className="gx-0">
                <Col className="col-md-12 col-sm-12 c-000 notosanskr-500 font-size-21 bg-e8ebf0 mb-4 p-4" style={{ borderRadius: "5px" }} >
                    {t("39")}
                </Col>
            </Row>
            <Row className={`gx-0 ${CHART_ROW_MARGIN_CLASS}`}>
                {renderCommonChart(undefined, false)}
                <Col className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                    <ReportGraphPieChart
                        categories={state.groupChart.series.map(x => common.i18nGroupName(x.name[0]))}
                        series={[{ label: t("404"), data: state.groupChart.series.map((series) => series.data) }]}
                        title={t("694")}
                        doughnut={false}
                        legendVisible={true}
                    />
                </Col>
            </Row>
        </React.Fragment>);
    };

    const renderFormCharts = () => {
        if (!state.termChart) return <></>;

        const groups = state.termChart.reduce((acc, cur) => {
            if (!Object.keys(acc).includes(cur.group.name)) {
                acc[cur.group.name] = [];
            }
            state.reportCharts.filter(x => x.name == cur.group.name).map(v => acc[cur.group.name].push(v));
            return acc;
        }, {});

        // 그룹에 등록된 report form item 순서대로 출력되도록 정렬
        props.groupList.forEach(group => {
            const targetGroupName = Object.keys(groups).find(v => v == group.name);
            if (targetGroupName) {
                groups[targetGroupName] = group.report_form.report_form_item_ids.reduce((acc, cur) => {
                    const item = groups[targetGroupName].find(v => v.itemId == cur);
                    if (item) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
            }
        });

        return (<React.Fragment>
            {
                Object.keys(groups).map((groupName) => (
                    <React.Fragment key={groupName}>
                        <Row className="gx-0">
                            <Col className="col-md-12 col-sm-12 c-000 notosanskr-500 font-size-21 bg-e8ebf0 mb-4 p-4" style={{ borderRadius: "5px" }} >
                                {common.i18nGroupName(groupName)}
                            </Col>
                        </Row>
                        <Row className={`${CHART_ROW_MARGIN_CLASS}`}>
                            {renderCommonChart(props.groupList.find(x => x.name == groupName), false)}
                            {groups[groupName].map((formItem) => {
                                // built-in 그룹 (바다기사단) 의 경우, common chart 만 출력
                                if (state.termChart.find(x => groupName == x.group.name).group.built_in) {
                                    dgLogger.debug(`${groupName} is built-in group. do not show form charts`)();
                                    return (<Col key={formItem.itemName} />);
                                }

                                if (!formItem.chart?.type || formItem.chart?.type == CHART_TYPE.None) {
                                    dgLogger.info(`${formItem.itemName} in ${groupName} is set to hidden`)();
                                    return (<Col key={formItem.itemName}></Col>);
                                }

                                const categories = formItem.categories.map(v => ["기타","기타:","기타 :"].includes(v.trim()) ? t("59") : v);
                                switch (formItem.chart.type) {
                                    case CHART_TYPE.Pie: return (<Col key={formItem.itemName} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                        <ReportGraphPieChart
                                            categories={categories}
                                            series={formItem.series.map(x => ({ ...x, name: groupName }))}
                                            title={formItem.itemName}
                                            doughnut={false}
                                        /></Col>);
                                    case CHART_TYPE.Doughnut: return (<Col key={formItem.itemName} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                        <ReportGraphPieChart
                                            categories={categories}
                                            series={formItem.series.map(x => ({ ...x, name: groupName }))}
                                            title={formItem.itemName}
                                            doughnut={true}
                                        /></Col>);
                                    case CHART_TYPE.Vertical: return (<Col key={formItem.itemName} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                        <ReportGraphBarChart
                                            categories={categories}
                                            series={formItem.series.map(x => ({ ...x, name: groupName }))}
                                            title={formItem.itemName}
                                            showScaleDropbox={false}
                                            legendVisible={false}
                                            vertical={true}
                                            // item type 이 vendor-ui-2 인 경우, 지역을 출력해야 하므로, internal 을 1 로 설정하도록 함.
                                            // see: renderCommonChart 의 지역별 수집 자료 건수 Chart attributes
                                            interval={formItem.itemType === "vendor-ui-2" && 1}
                                        /></Col>);
                                    case CHART_TYPE.Horizontal: return (<Col key={formItem.itemName} className={`${FORM_ITEM_CHART_COL_CLASS}`}>
                                        <ReportGraphBarChart
                                            categories={categories}
                                            series={formItem.series.map(x => ({ ...x, name: groupName }))}
                                            title={formItem.itemName}
                                            legendVisible={false}
                                        /></Col>);
                                    default: return (<Col key={formItem.itemName}></Col>);
                                }
                            }
                            )}
                        </Row>
                    </React.Fragment>))
            }
        </React.Fragment>);
    };

    return (
        <div className="report-graph-based">
            {popups()}
            {state.allGroupSummary ? renderAllGroupSummary() : renderFormCharts()}
        </div>
    );
}
