import React, { useState, forwardRef, useImperativeHandle } from "react";
import ReactModal from "react-modal";
import HashLoader from "react-spinners/HashLoader";

const LoadingSpinner = forwardRef(({ isOpen }, ref) => {
    const [progress, setProgress] = useState(-1);

    const resetProgress = () => {
        setProgress(0);
    };
    const updateProgress = (percent) => {
        setProgress(percent);
    };

    // 부모가 호출할 함수 선언
    useImperativeHandle(ref, () => {
        return {
            resetProgress: resetProgress,
            updateProgress: updateProgress,
        };
    });

    return (
        <ReactModal
            isOpen={isOpen}
            className={"loading-spinner"}
            style={{
                overlay: { textAlign: "left" },
                content: { height: "unset", transform: "unset" },
            }}
            portalClassName={"custom-modal-no-title"}
        >
            <HashLoader color={"#4270e0"} size={100} speedMultipleier={0.7} />
            {progress >= 0 && (
                <div className="position-relative">
                    <div className="progress position-absolute top-50 translate-middle w-200px mt-125px" style={{ height: "20px" }}>
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {parseInt(progress)} %
                        </div>
                    </div>
                </div>
            )}
        </ReactModal>
    );
});

export default LoadingSpinner;
