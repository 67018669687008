import { useCallback } from "react";

export default function useRadioHook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const optionalValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.optionalValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setRadioValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    const getRadioValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    return {
        optionalValidation,
        setRadioValue,
        getRadioValue,
    };
};
