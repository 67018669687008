import "./CustomDate.css";
import { Col } from "react-bootstrap";

const CustomDateView = ({
    title,
    description,
    type,
    defaultValue,
    max,
    invalid,
    errorMessage,
    onBlurHandle,
    onChangeHandle
}) => {
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-half-content flex-wrap ${type}`}>
                {/* description은 현재 존재할 수 없으나 추후 추가 될 수 있으니 삽입 */}
                {description && <label className="top-description notosanskr-500 font-size-15">{description}</label>}
                <input
                    key={defaultValue}
                    type="date"
                    className={invalid}
                    name={type}
                    onBlur={onBlurHandle}
                    defaultValue={defaultValue}
                    max={max}
                    onChange={onChangeHandle}
                />
                <label className="error">
                    {errorMessage}
                </label>
            </Col>
        </>
    );
};

export default CustomDateView;
