import "./Location.css";
import { Col } from "react-bootstrap";
import { InputText } from "../../Input";
import ManualInputMap from "../../common/ManualInputMap";
import * as Config from "../../common/MapConfig";
import { useTranslation } from "react-i18next";

const LocationView = ({
    title,
    description,
    type,
    latitude,
    longitude,
    latitudeInvalid,
    latitudeErrorMessage,
    longitudeInvalid,
    longitudeErrorMessage,
    onBlurHandle,
    onChangeLatitudeHandle,
    onChangeLongitudeHandle,
    onPasteHandle,
    handleChangeMap,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                {description && <label className="top-description notosanskr-500 font-size-15">{description}</label>}
                <InputText
                    className={latitudeInvalid}
                    value={latitude || ""}
                    onChange={onChangeLatitudeHandle}
                    onPaste={onPasteHandle}
                    onBlur={onBlurHandle}
                    placeholder={t("151")}
                />
                <InputText
                    className={longitudeInvalid}
                    value={longitude || ""}
                    onChange={onChangeLongitudeHandle}
                    onPaste={onPasteHandle}
                    onBlur={onBlurHandle}
                    placeholder={t("152")}
                />
                <ManualInputMap
                    center={Config.defaultCenter}
                    zoom={Config.zoom}
                    marker={{ lat: latitude, lng: longitude }}
                    handleChangeMap={handleChangeMap}
                />
                <label className="error">
                    {latitudeErrorMessage || longitudeErrorMessage}
                </label>
            </Col>
        </>
    );
};

export default LocationView;
