import { useCallback, useContext } from "react";
import common from "../../../common/common";
import useDatetimeContext from "../Context/DatetimeContext";

export default function useCustomDateHook(ref) {
    const { DatetimeContext } = useDatetimeContext();
    const datetimeContext = useContext(DatetimeContext);

    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const dateValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.dateValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setDateValue = useCallback((value) => {
        ref.current?.setValue?.(common.convertToIsoDate(value)); // iso date 삽입
    }, [ref]);

    const getDateValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    // 현재 context에 있는 datetime 반환
    const getContextDatetimeValue = useCallback(() => {
        return datetimeContext.getDatetime();
    }, [datetimeContext]);

    /**
     * date 객체를 특정 포멧의 날짜로 변환하여 반환
     * @param {*} source date 객체
     * @param {*} delimiter 년,월,일 구분자
     * @returns `년-월-일` 포멧의 문자열
     */
    const dateObjectToString = useCallback((source, delimiter = "-") => {
        const leftPad = (value) => {
            return value >= 10 ? value : `0${value}`;
        };
        const year = source.getFullYear();
        const month = leftPad(source.getMonth() + 1);
        const day = leftPad(source.getDate());
        return [year, month, day].join(delimiter);
    }, []);

    return {
        dateValidation,
        setDateValue,
        getDateValue,
        getContextDatetimeValue,
        dateObjectToString,
        convertToDate : common.convertToDate,
        convertToIsoDate : common.convertToIsoDate,
    };
};
