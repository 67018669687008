import React, {useReducer, forwardRef, useRef, useEffect} from "react";
import GoogleMap from "google-map-react";
import "./ManualInputMap.css";
import { IsPC } from "../common/MediaQuery";

const Marker = ({ image, width, height }) => <div className="marker" style={{ height: height + 'px', width: width + 'px' }}><img src={image} alt="marker" ></img></div>;

const ManualInputMap = forwardRef((props, ref) => {
    const mapRef = useRef();
    const mapsRef = useRef();
    const marker = useRef();
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const isPc = IsPC();

    useEffect(() => {
        const lat = parseFloat(props.marker.lat);
        const lng = parseFloat(props.marker.lng);
        if (isNaN(lat) || isNaN(lng)) {
            marker.current = null;
        } else {
            marker.current = { lat: lat, lng: lng, width: 24, height: 41 };
        }
        forceUpdate();
    }, [props.marker]);

    /**
     * 부모에서 넘어온 ref의 gps 값을 update 하는 함수
     *
     * @param {*} lat
     * @param {*} lng
     */
    const updateGps = (lat, lng) => {
        if (ref?.current) {
            ref.current.lat = lat;
            ref.current.lng = lng;
        }
    };

    /**
     * gps 좌표를 바꿀 때 바뀐 gps 좌표가 중앙이 되도록 설정하는 함수
     *
     * @param {*} lat 위도
     * @param {*} lng 경도
     * @param {*} zoom zoomlevel
     */
    const moveCenter = (lat, lng, zoom) => {
        mapRef.current.panTo({lat: lat, lng: lng});
        if (zoom)
            mapRef.current.setZoom(zoom);
    };

    /**
     * 지도 검색란에 검색 할 문자열을 작성하고 enter를 눌렀을 때 해당하는 장소의 gps를 찾아 이동, marker 삽입
     *
     * @param {*} e event
     */
    // const getLocation = async (e) => {
    //     if (e.key === 'Enter' || e.type === 'click'){
    //         const localName = document.getElementById("local-name").value;
    //         const location = (await Api.geocode(localName))[0];
    //         if (!location) {
    //             document.getElementById("no-search-result").style.display = "block";
    //             document.getElementById("local-name").focus();
    //             forceUpdate();
    //             return;
    //         }
    //         document.getElementById("no-search-result").style.display = "none";
    //         marker.current = { key: location.place_id, lat: location.geometry.location.lat, lng: location.geometry.location.lng, width: 36, height: 50 };
    //         updateGps(marker.current.lat, marker.current.lng);
    //         moveCenter(ref.current.lat, ref.current.lng, 18);
    //         props.handleChangeMap();
    //         forceUpdate();
    //     }
    // };

    /**
     * 지도 최초 로드 시 콜백
     *
     * @param {*} param0
     */
    const handleApiLoaded = ({map, maps}) => {
        mapRef.current = map;
        mapsRef.current = maps;
        mapClickListener();
        forceUpdate();
    };

    /**
     * map을 클릭 했을 때 새로운 marker를 추가하는 함수
     */
    const mapClickListener = () => {
        mapsRef.current.event.clearListeners(mapRef.current, 'click');
        mapRef.current.addListener('click', e => {

            // 주소 검색하여 생긴 marker를 선택 했을 때 marker event, map event가 차례대로 실행
            // map event는 새로운 marker를 추가하는 함수이므로 marker를 선택 했을 때는 map event 동작하지 않도록 처리
            marker.current = {
                key: 'current',
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                width: 36,
                height: 50,
            };
            updateGps(e.latLng.lat(), e.latLng.lng());
            moveCenter(e.latLng.lat(), e.latLng.lng());
            props.handleChangeMap(e.latLng.lat(), e.latLng.lng());
            const localNameElement = document.getElementById("local-name");
            localNameElement && (localNameElement.value = "");
            forceUpdate();
        });
    };

    /**
     * markerList를 이용하여 component 생성
     *
     * @returns 생성 된 component
     */
    const createdMarker = () => {
        if (!mapRef.current) return;

        if (marker.current?.lat && marker.current?.lng) {
            moveCenter(marker.current.lat, marker.current.lng);
            return (
                <Marker
                    key={marker.current.key}
                    lat={marker.current.lat}
                    lng={marker.current.lng}
                    width={marker.current.width}
                    height={marker.current.height}
                    image={process.env.PUBLIC_URL + `/map_mark.png`}
                />
            );
        }
    };

    return (
        <div className="manual-input-map">
            <div className="manual-map">
                <GoogleMap
                    options={{ mapTypeControl: true, gestureHandling: isPc ? "greedy" : "cooperative", mapTypeId: "hybrid", maxZoom: 15, }}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: "KR" }}
                    center={props.center}
                    zoom={props.zoom}
                    onGoogleApiLoaded={handleApiLoaded}
                    yesIWantToUseGoogleMapApiInternals
                >
                    {createdMarker()}
                </GoogleMap>
            </div>
            {/* <InputText className="notosanskr-14" id="local-name" onKeyPress={getLocation} placeholder="주소로 검색" onBlur={() => document.getElementById("no-search-result").style.display = "none"} />
            <img id="search-button" onClick={getLocation} src={process.env.PUBLIC_URL + `/icon_magnifying_glass.png`} srcSet={`${process.env.PUBLIC_URL}/icon_magnifying_glass@2x.png 2x, ${process.env.PUBLIC_URL}/icon_magnifying_glass@3x.png 3x`} />
            <div id="no-search-result" className="c-f00 notosanskr-14" style={{ display: "none" }}>{document.getElementById("local-name")?.value}에 대한 검색 결과가 없습니다.</div> */}
        </div>
    );
});

export default ManualInputMap;
