import React from "react";
import "./ReportCard.css";
import CustomCheckbox from "../common/CustomCheckbox";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import common from "../../common/common";
import { useTranslation } from "react-i18next";

/**
 * Report Card Component
 * @param {*} props properties
 *  ```
 *  {
 *      report_id: string // report id
 *      checkbox: boolean // 체크 박스 표시 여부
 *      button_id: string // checkbox 가 있을때 사용되는 변수
 *      id_prefix: string // checkbox 의 id 값(숫자) 앞에 붙을 문자열 (다른 id 와 중복 방지 목적)
 *      reportLength: number // report 갯수
 *  }
 *  ```
 * @returns HTMLElement
 */
const ReportCard = (props) => {
    const { t } = useTranslation();
    // convert iso date to 'YYYY-MM-DD' date
    const convertToLocaleDate = (date) => {
        // notes, redkur; 'fr-CA' formats string as 'YYYY-MM-DD'
        return new Date(date).toLocaleDateString('fr-CA');
    };

    const changeActivateRequestReportLicenseButton = () => {
        let disabled = true;
        for (let i = 0; i < props.reportLength; i++) {
            if (document.getElementById(props.id_prefix + i) && document.getElementById(props.id_prefix + i).checked) {
                disabled = false;
                break;
            }
        }
        document.getElementById(props.button_id).disabled = disabled;
    };

    const backgroundUrl = props.report_file_url ?
        props.report_file_url
        :
        `${process.env.PUBLIC_URL}/main_recent_register_03@3x.png`;

    return (
        <div
            className="report-card"
        >
            <Link className="image" style={{ backgroundImage: `url(${backgroundUrl})` }}
                onClick={(e) => {
                    if (e.target.className !== "image") {
                        e.nativeEvent.stopImmediatePropagation();
                    }
                }}
                to={`/Report/${props.report_id}`}
            >
                <div className="checkbox">
                    {props.checkbox &&
                        <div className="custom-checkbox-wrap" onClick={(e) => {
                            e.stopPropagation();
                            document.getElementById(props.id_prefix + props.index).checked = !document.getElementById(props.id_prefix + props.index).checked;
                            if (props.onClick) props.onClick(e);
                            else changeActivateRequestReportLicenseButton(e);
                        }}>
                            <CustomCheckbox
                                id={props.id_prefix + props.index}
                                // onChange={(e) => {
                                //     e.stopPropagation();
                                //     if (props.onClick) props.onClick(e);
                                //     else changeActivateRequestReportLicenseButton(e);
                                // }}
                            />
                        </div>
                    }
                </div>
                {common.getDayDiff(props.register_date, new Date()) <= 7 &&
                    <div className="new-badge notosanskr-400 font-size-15 c-fff">
                        <div className="new-text">NEW</div>
                    </div>
                }
            </Link>
            <div className="report-info">
                <div className="report-title c-000 notosanskr-500 font-size-21">{props.title}</div>
                <div className="report-content c-333 notosanskr-400 font-size-13">
                    <div>
                        <div className="registered_by">{t("519")} {props.registered_by}</div>
                        <div>{t("520")} {convertToLocaleDate(props.date)}</div>
                    </div>
                </div>
            </div>
            <Row className="report-group align-items-center gx-0">
                <Col className="text-start c-68769a notosanskr-500 font-size-14 col-auto">
                    GROUP
                </Col>
                <Col className="report-group-name text-end c-000 notosanskr-500 font-size-16">
                    {props.group_name}
                </Col>
            </Row>
        </div>
    );
};

const updateGroupNameRowHeight = () => {
    const elements = document.getElementsByClassName("report-group");
    const items = Array.prototype.slice.call(elements);
    // set height to equal all cards
    const maxHeight = items.map(v => v.offsetHeight).sort((a, b) => b - a)?.[0];
    items.forEach((_, i) => elements[i].style["height"] = `${maxHeight}px`);
};

export { ReportCard, updateGroupNameRowHeight };
