import React, { useState } from "react";
import { POPUP as GeneralPopup } from "../../common/defines";
import Api from "../../Api";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Modal from "../common/Modal";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { InputText } from "../Input";
import ReactModal from "react-modal";
import "./AskQuestion.css";
import common from "../../common/common";
import ImageDropZone2 from "../common/ImageDropZone2";
import CustomCheckbox from "../common/CustomCheckbox";
import { PopupValidateFile } from "../common/ValidateModal";
import LoadingSpinner from "../common/LoadingSpinner";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
};

const MAX_FILES = 10;
const MAX_SIZE = (1024 * 1024) * 25; // 25MB

export default function AskQuestion({ className, onSend, onCancel, user }) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        files: [],

        popup: POPUP.None,
        popupTarget: null,
        isLoading: false,
    });
    const [errors, setErrors] = useState({ askQuestionName: "", askQuestionEmail: "", askQuestionTitle: "", askQuestionContent: "" });
    const navigate = useNavigate();

    /**
     * 현재 page의 이름, 메일, 내용을 묶어 email을 보내는 함수
     * 정상적으로 response가 오면 popup을 띄워줌
     */
    const sendAskQuestion = () => {
        if (!validateFields()) {
            common.scrollToInvalidElement();
            return;
        }
        setState({ ...state, isLoading: true });

        const payload = {
            type: document.querySelector(`input[name="inquiry-type"]:checked`).value,
            name: document.getElementById("askQuestionName").value.trim(),
            title: document.getElementById("askQuestionTitle").value.trim(),
            email: document.getElementById("askQuestionEmail").value.trim(),
            content: document.getElementById("askQuestionContent").value.trim(),
            files: state.files,
        };

        Api.sendAskQuestion(payload)
            .then((payload) => {
                setState({ ...state, popup: POPUP.Requested, isLoading: false });
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    /**
     *
     * @returns email을 보낸 후 띄워주는 popup
     */
    const popupAskQuestionRequested = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/icon_inquiry.png`} srcSet={`${process.env.PUBLIC_URL}/icon_inquiry@2x.png 2x, ${process.env.PUBLIC_URL}/icon_inquiry@3x.png 3x`} alt="" />;
        const header = <div>{t("281")}</div>;
        const body = <div>{t("282").split('\n').map((v, i) => <span key={i}>{v}<br/></span>)}</div>;

        return (
            <Modal
                onRequestClose={() => { setState({ ...state, popup: POPUP.None, popupTarget: null }); onCancel(); }}
                onConfirm={() => { setState({ ...state, popup: POPUP.None, popupTarget: null }); onCancel(); }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    /**
     *
     * @returns popup 상태에 따라 popup 호출
     */
    const popups = () => {
        if (state.popup === POPUP.Requested) return popupAskQuestionRequested();
        if (state.popup === POPUP.ValidateFileField)
            return (
                <PopupValidateFile
                    maxFiles={MAX_FILES}
                    maxSizeInMB={MAX_SIZE / (1024 * 1024)}
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                />
            );
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    /**
     * email 입력 란에 공백 입력 시 입력 되지 않도록 하는 함수
     *
     * @param {} e event
     */
    const blankBlocking = (e) => {
        if (e.code === "Space") {
            e.preventDefault();
        }
    };

    const validateFields = () => {
        let flag = true;
        const errorCollection = {};
        const name = document.getElementById("askQuestionName");
        const email = document.getElementById("askQuestionEmail");
        const title = document.getElementById("askQuestionTitle");
        const content = document.getElementById("askQuestionContent");

        common.validateInputField(errorCollection, 'askQuestionName', name, t("15")) || (flag = false);
        common.validateEmailField(errorCollection, 'askQuestionEmail', email, true, t("15")) || (flag = false);
        common.validateInputField(errorCollection, 'askQuestionTitle', title, t("15")) || (flag = false);
        common.validateInputField(errorCollection, 'askQuestionContent', content, t("15")) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const removeFileFromFileList = (elementId, targetFile) => {
        const dt = new DataTransfer();
        const input = document.getElementById(elementId);
        const { files } = input;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file !== targetFile)
            dt.items.add(file); // here you exclude the file. thus removing it.
        }

        input.files = dt.files; // Assign the updates list
    };

    const onDrop = (acceptedFiles, fileRejections) => {
        // 모든 파일 업로드 가능
        const [, allFiles, result] = common.validateOnDrop(acceptedFiles, fileRejections, state.files, MAX_FILES, MAX_SIZE);
        if (!result.valid) {
            return setState(prev => ({...prev, popup: POPUP.ValidateFileField, }));
        }

        setState({...state, files:allFiles});
    };

    return (
        <>
            {popups()}
            <ReactModal
                isOpen={true}
                className={className ? className : "default"}
                style={{ overlay: {}, content: {} }}
                portalClassName={"ask-question"}
            >
                <div className="title nanumsquareeb-18 nanumsquareeb-16:sm ">{t("7")}
                    <div>
                        <img src={process.env.PUBLIC_URL + `/icon_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`} onClick={onCancel}  style={{ cursor: "pointer" }} alt=""/>
                    </div>
                </div>
                <div className="body">
                    <div className="content">
                        {popups()}
                        <LoadingSpinner isOpen={state.isLoading} />
                        <Row className="item-row gx-0 text-start notosanskr-14-500 notosanskr-12-500:sm">
                            <Col className="item item-title col-lg" sm={12}>{t("267")}</Col>
                            <Col className="item inquiry-type mt-auto mb-auto col-lg" sm={12}>
                                <Row className="gx-0">
                                    <Col>
                                        <CustomCheckbox
                                            type="radio"
                                            value="usage"
                                            name="inquiry-type"
                                            className = "d-flex align-items-center h-100"
                                            defaultChecked={true}
                                            children={t("268")}
                                        />
                                    </Col>
                                    <Col>
                                        <CustomCheckbox
                                            type="radio"
                                            value="affiliate"
                                            name="inquiry-type"
                                            children={t("269")}
                                        />
                                    </Col>
                                    <Col>
                                        <CustomCheckbox
                                            type="radio"
                                            value="infra"
                                            name="inquiry-type"
                                            children={t("270")}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="item-row gx-0 text-start notosanskr-14-500 notosanskr-12-500:sm">
                            <Col className="item item-title col-lg" sm={12}>{t("271")}</Col>
                            <Col className="item">
                                <InputText id="askQuestionName" placeholder={t("272")} onBlur={(e) => {
                                    const errorCollection = {};
                                    common.validateInputField(errorCollection, 'askQuestionName', e.target, t("15"));
                                    setErrors({ ...errors, ...errorCollection });
                                }}
                                    maxLength={20}
                                    defaultValue={user.name}
                                />
                                {errors?.askQuestionName?.message && <div className="c-f00 mt-6px">{errors?.askQuestionName?.message}</div>}
                            </Col>
                        </Row>
                        <Row className="item-row gx-0 text-start notosanskr-14-500 notosanskr-12-500:sm">
                            <Col className="item item-title col-lg" sm={12}>{t("273")}</Col>
                            <Col className="item">
                                <input type="email" id="askQuestionEmail" placeholder={t("274")} onKeyPress={blankBlocking} onBlur={(e) => {
                                    const errorCollection = {};
                                    common.validateEmailField(errorCollection, 'askQuestionEmail', e.target);
                                    setErrors({ ...errors, ...errorCollection });
                                }}
                                    defaultValue={user.email}
                                />
                                {errors?.askQuestionEmail?.message && <div className="c-f00 mt-6px">{errors?.askQuestionEmail?.message}</div>}
                            </Col>
                        </Row>
                        <Row className="item-row gx-0 text-start notosanskr-14-500 notosanskr-12-500:sm">
                            <Col className="item item-title col-lg" sm={12}>{t("275")}</Col>
                            <Col className="item">
                                <InputText id="askQuestionTitle" placeholder={t("276")} onBlur={(e) => {
                                    const errorCollection = {};
                                    common.validateInputField(errorCollection, 'askQuestionTitle', e.target, t("15"));
                                    setErrors({ ...errors, ...errorCollection });
                                }}
                                    maxLength={50}
                                />
                                {errors?.askQuestionTitle?.message && <div className="c-f00 mt-6px">{errors?.askQuestionTitle?.message}</div>}
                            </Col>
                        </Row>
                        <Row className="item-row gx-0 text-start notosanskr-14-500 notosanskr-12-500:sm">
                            <Col className="item item-title col-lg" sm={12}>{t("277")}</Col>
                            <Col className="item">
                                <textarea id="askQuestionContent" placeholder={t("278")} onBlur={(e) => {
                                    const errorCollection = {};
                                    common.validateInputField(errorCollection, 'askQuestionContent', e.target, t("15"));
                                    setErrors({ ...errors, ...errorCollection });
                                }}
                                    maxLength={500}
                                />
                                {errors?.askQuestionContent?.message && <div className="c-f00 mt-6px">{errors?.askQuestionContent?.message}</div>}
                            </Col>
                        </Row>
                        <Row className="item-row gx-0 text-start notosanskr-14-500 notosanskr-12-500:sm">
                            <Col className="item item-title col-lg" sm={12}>{t("95")}</Col>
                            <Col className="item">
                                <div className="file-group">
                                    <ImageDropZone2
                                        className="ask-question-transfer-files notosanskr-14 notosanskr-12:sm c-999"
                                        inputFileId={"files-id"}
                                        imgId={"files"}
                                        disabled={state.files.length >= MAX_FILES}
                                        ref={onDrop}
                                        accept=""
                                        buttonText={t("98")}
                                        multiple={true}
                                        placeholder={t("279")}
                                    />
                                </div>
                                {state.files.map((file, i) => (
                                    <div className="files-wrap align-middle mt-6px" key={i}>
                                        <span className="file-name nanumsquare-14 nanumsquare-12:sm lh-1.43 ls--0.7px c-333 mr-15px">{common.convertFileName(file.name)}</span>
                                        <button
                                            className="remove-file"
                                            onClick={(e) => {
                                                removeFileFromFileList("files-id", file);
                                                setState({ ...state, files: state.files.filter((certFile) => certFile !== file) });
                                            }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/19171.png`}
                                                srcSet={`${process.env.PUBLIC_URL}/19171@2x.png 2x,
                                            ${process.env.PUBLIC_URL}/19171@3x.png 3x`}
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        <Row className="button-group gx-0 text-end notosanskr-14-500 notosanskr-12-500:sm">
                            <Col>
                                <button type="submit" id="send" onClick={onSend || sendAskQuestion}>
                                    {t("280")}
                                </button>
                                <button type="reset" id="cancel" onClick={onCancel}>
                                    {t("80")}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </ReactModal>
        </>
    );
}
