import { useCallback } from "react";

export default function useVendorUI3Hook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const imageValidation = useCallback(({ required, errorKey, min_item_count, fileListLength }) => {
        return ref.current?.imageValidation?.({ required, errorKey, min_item_count, fileListLength });
    }, [ref]);

    const pushDeletedFilesKey = useCallback((value) => {
        ref.current?.pushDeletedFilesKey?.(value);
    }, [ref]);

    const pushAddedFilesKey = useCallback((value) => {
        ref.current?.pushAddedFilesKey?.(value);
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setVendorUI3Value = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    const getVendorUI3Value = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    const processUploadedFiles = useCallback(async ({ acceptedFiles, fileRejections, containFiles = [], max_item_count }) => {
        return await ref.current?.processUploadedFiles({
            acceptedFiles,
            fileRejections,
            containFiles,
            max_item_count
        });
    }, [ref]);

    const uploadImage = useCallback((file) => {
        return ref.current?.uploadImage?.(file);
    }, [ref]);

    return {
        imageValidation,
        pushDeletedFilesKey,
        pushAddedFilesKey,
        setVendorUI3Value,
        getVendorUI3Value,
        processUploadedFiles,
        uploadImage,
    };
};
